import Header from '@app/components/base/dialogs/_detailViewDialogs/_components/Header'
import { DialogContent as DialogContentMUI, Tab, Tabs } from '@mui/material'
import { ReactNode, useState } from 'react'

type Props<T> = {
  title: string
  tabs: {
    component: (data: T) => ReactNode
    header: string
    isHidden?: boolean
  }[]
  data: T
  endHeader?: ReactNode
}

const DialogContent = <T,>({ title, tabs, data, endHeader }: Props<T>) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0)

  const activeTabs = tabs.filter((tab) => !tab.isHidden)
  return (
    <DialogContentMUI>
      <Header
        title={title}
        paddingBottom={tabs.length > 1 ? 4 : 8}
        endHeader={endHeader}
      />
      {activeTabs.length > 1 && (
        <Tabs
          value={currentTabIndex}
          onChange={(_, index) => {
            setCurrentTabIndex(index)
          }}
          sx={{ marginBottom: 4 }}
        >
          {activeTabs.map(({ header }) => (
            <Tab iconPosition="start" label={header} key={header} />
          ))}
        </Tabs>
      )}
      {activeTabs[currentTabIndex].component(data)}
    </DialogContentMUI>
  )
}

export default DialogContent
