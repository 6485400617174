import { GetMachineInstancesResponseBody } from '@aeqoom/contracts'
import { GridColDef } from '@mui/x-data-grid'

const machineInstancesDataCol: GridColDef<
  GetMachineInstancesResponseBody[number]
>[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 80,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'machineTemplate',
    headerName: 'Machine template',
    valueGetter: (params) => params.row.machineTemplate?.name,
    flex: 1,
  },
  {
    field: 'hall',
    headerName: 'Hall',
    flex: 1,
  },
  {
    field: 'companyUnit',
    headerName: 'Company unit',
    valueGetter: (params) => params.row.companyUnit?.name,
    flex: 1,
  },
]

export default machineInstancesDataCol
