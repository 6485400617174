import { GridColDef } from '@mui/x-data-grid'

export const contactsDataCol: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 80 },

  { field: 'firstName', headerName: 'First name', flex: 1 },
  { field: 'lastName', headerName: 'Last name', flex: 1 },
  { field: 'emailAddress', headerName: 'Email address', flex: 1 },
  { field: 'phoneNumber', headerName: 'Phone number', flex: 1 },
  {
    field: 'position',
    headerName: 'Position',
    flex: 1
  }
]

export default contactsDataCol
