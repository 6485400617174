import { Address } from '@aeqoom/shared'

export const serializeAddress = ({
  street,
  houseNumber,
  zipCode,
  city,
  country,
}: Partial<Address>) => {
  return [
    street &&
      `${street}${houseNumber ? ' ' : zipCode || city || country ? ', ' : ' '}`,
    houseNumber && `${houseNumber}${zipCode || city || country ? ', ' : ' '}`,
    city && `${city}${zipCode || country ? ', ' : ' '}`,
    zipCode && `${zipCode}${country ? ', ' : ' '}`,
    country && `${country}`,
  ]
    .filter(Boolean)
    .join('')
}
