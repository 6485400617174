import { Add, Remove } from '@mui/icons-material'
import { IconButton, Stack } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField'
import { Control, FieldValues, Path, useController } from 'react-hook-form'

import FormHelperTooltip from '../FormHelperTooltip'
import { MuiNumberField } from './styled'

type Props<T extends FieldValues> = {
  control: Control<T>
  name: Path<T>
} & TextFieldProps

export const FormNumberField = <T extends FieldValues>({
  name,
  control,
  InputProps: propInputProps = {},
  disabled: propDisabled,
  ...rest
}: Props<T>) => {
  const { field, fieldState } = useController({
    name,
    control,
    disabled: propDisabled,
  })

  const { onChange, value, onBlur, disabled } = field
  const { error } = fieldState

  const helperText = error?.message ?? rest.helperText ?? ' '

  const parsedValue = parseInt(value)
  const correctedValue = isNaN(parsedValue) ? 0 : parsedValue

  const InputProps = {
    ...propInputProps,
    startAdornment: !propInputProps.readOnly ? (
      <IconButton
        disabled={disabled}
        onClick={() => onChange(Math.max(correctedValue - 1, 0))}
        aria-label="decrement value"
      >
        <Remove />
      </IconButton>
    ) : undefined,
    endAdornment: !propInputProps.readOnly ? (
      <IconButton
        disabled={disabled}
        onClick={() => onChange(correctedValue + 1)}
        aria-label="increment value"
      >
        <Add />
      </IconButton>
    ) : undefined,
  }

  return (
    <Stack minWidth={150}>
      <MuiNumberField
        autoComplete="off"
        type="number"
        error={!disabled ? !!error : undefined}
        {...field}
        {...rest}
        disabled={disabled}
        helperText={null}
        onBlur={onBlur}
        onChange={(event) => {
          const parsed = parseInt(event.target.value)
          if (isNaN(parsed)) {
            onChange(null)
          } else {
            onChange(parsed)
          }
        }}
        value={!value && value !== 0 ? '' : value}
        inputProps={{ style: { textAlign: 'center' } }}
        InputProps={InputProps}
      />
      <FormHelperTooltip helperText={helperText} error={!!error} />
    </Stack>
  )
}
