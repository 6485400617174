import { z } from 'zod'

export const toBoolean = z.coerce
  .string()
  .transform((x) => (x as unknown) === 'true')
  .pipe(z.boolean())

export const nullable = <TSchema extends z.AnyZodObject>(schema: TSchema) => {
  const entries = Object.entries(schema.shape) as [
    keyof TSchema['shape'],
    z.ZodTypeAny
  ][]

  const newProps = entries.reduce(
    (acc, [key, value]) => {
      acc[key] = value.nullable()
      return acc
    },
    {} as {
      [key in keyof TSchema['shape']]: z.ZodNullable<TSchema['shape'][key]>
    }
  )

  return z.object(newProps)
}

z.setErrorMap((issue, ctx) => {
  if (issue.code === 'invalid_type' && issue.received === 'null')
    return { message: 'You provided empty value' }
  return { message: ctx.defaultError }
})

export { z }
