import { AspectRatio, BrokenImage } from '@mui/icons-material'
import { Box, BoxProps, IconButton, useTheme } from '@mui/material'
import { useState } from 'react'

const CustomImage = (props: BoxProps<'img'>) => {
  const [hasFailedToLoad, setHasFailedToLoad] = useState(false)
  const [isContained, setIsContained] = useState(true)
  const theme = useTheme()

  return hasFailedToLoad ? (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 2,
        backgroundColor: theme.palette.grey[200],
        ...props.sx,
      }}
    >
      <BrokenImage fontSize="large" />
    </Box>
  ) : (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        borderRadius: 2,
        backgroundColor: theme.palette.background.default,
        overflow: 'hidden',
      }}
    >
      <Box
        component="img"
        onError={() => setHasFailedToLoad(true)}
        {...props}
        sx={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          objectFit: isContained ? 'contain' : 'cover',
          top: '50%',
          left: '50%',
          minHeight: '100%',
          minWidth: '100%',
          transform: 'translate(-50%, -50%)',
          ...props.sx,
        }}
      />
      <IconButton
        sx={{
          position: 'absolute',
          right: 8,
          bottom: 8,
          backgroundColor: theme.palette.grey[100],
          '&:hover': {
            backgroundColor: theme.palette.grey[200],
          },
        }}
        onClick={() => setIsContained(!isContained)}
      >
        <AspectRatio />
      </IconButton>
    </Box>
  )
}

export default CustomImage
