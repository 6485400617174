import { AddCompanyForm } from '@aeqoom/forms'
import useEditingContext from '@app/hooks/useEditingContext'
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  PersonSearch,
} from '@mui/icons-material'
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Stack,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { get, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import ContactItem from '../ContactItem'
import ContactMain from '../ContactMain'

type Props = {
  defaultNumberOfRecords?: number
}

const ContactList = ({ defaultNumberOfRecords = 4 }: Props) => {
  const [showNumberOfRecords, setShowNumberOfRecords] = useState(
    defaultNumberOfRecords
  )

  const { isEditing } = useEditingContext()

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<AddCompanyForm>()

  const { t } = useTranslation(['web', 'common'])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  })

  const isError = get(errors, 'contacts')

  const contacts = useWatch({
    control,
    name: 'contacts',
  })

  const showMore = () => {
    setShowNumberOfRecords(showNumberOfRecords + defaultNumberOfRecords)
  }

  const showLess = () => {
    setShowNumberOfRecords(showNumberOfRecords - defaultNumberOfRecords)
  }

  const mainContact = contacts?.find((item) => item.main === true)

  const handleSwitchChange = (index: number) => {
    fields.forEach((_, idx) => {
      if (idx !== index) {
        setValue(`contacts.${idx}.main`, false)
      }
    })
  }

  const shouldBeVisible = fields.length > defaultNumberOfRecords

  return (
    <Stack spacing={2}>
      {fields.length === 0 ? (
        <Card variant="outlined">
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ height: '100%', p: 8 }}
            spacing={2}
          >
            <PersonSearch fontSize="large" color="disabled" />
            <Typography variant="subtitle1" color="disabled">
              {t('web:contacts.noContactsFound')}
            </Typography>
          </Stack>
        </Card>
      ) : (
        <Stack spacing={2}>
          <ContactMain id={mainContact?.contactId} />
          {isError && (
            <Alert severity="error" variant="outlined">
              {t('contacts.notFilledInCorrectly')}
            </Alert>
          )}
          <Stack spacing={2} width="100%">
            {fields.map(
              (field, index) =>
                index < showNumberOfRecords && (
                  <ContactItem
                    path={`contacts.${index}`}
                    key={field.id}
                    handleSwitchChange={handleSwitchChange}
                    index={index}
                    remove={remove}
                  />
                )
            )}
          </Stack>
        </Stack>
      )}
      <Stack width="100%" spacing={4}>
        <Stack direction="row" justifyContent="space-between">
          <ButtonGroup
            variant="outlined"
            sx={{
              visibility: shouldBeVisible ? 'visible' : 'hidden',
            }}
          >
            <Button
              onClick={showMore}
              startIcon={<ArrowDownward />}
              disabled={fields?.length <= showNumberOfRecords}
            >
              {t('media.showMore')}
            </Button>
            <Button
              onClick={showLess}
              startIcon={<ArrowUpward />}
              disabled={showNumberOfRecords <= defaultNumberOfRecords}
            >
              {t('media.showLess')}
            </Button>
          </ButtonGroup>
          {isEditing && (
            <Button
              onClick={() => append({ contactId: 0, main: false })}
              startIcon={<Add />}
              color="secondary"
              variant="outlined"
            >
              {t('contacts.addNewContact')}
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ContactList
