import { Stack } from '@mui/material'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'

import FormHelperTooltip from '../FormHelperTooltip'

type Props<T extends FieldValues> = {
  controller: UseControllerProps<T>
  field: TextFieldProps
}

export const FormTextField = <T extends FieldValues>({
  controller,
  field,
}: Props<T>) => {
  return (
    <Controller
      {...controller}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
      }) => {
        const helperText = !controller.disabled
          ? error?.message ?? field.helperText ?? ' '
          : ' '

        return (
          <Stack>
            <TextField
              autoComplete="off"
              error={!controller.disabled ? !!error : undefined}
              {...field}
              onBlur={onBlur}
              onChange={(event) => onChange(event.target.value || null)}
              value={value ?? ''}
              helperText={null}
            />
            <FormHelperTooltip helperText={helperText} error={!!error} />
          </Stack>
        )
      }}
    />
  )
}
