import { useState } from 'react'

export const useHandledCheckboxes = (argArray: unknown[]) => {
  const [checkedComponents, setCheckedComponents] = useState(new Set<number>())

  const checkBoxIndexes = argArray.map((_, index) => index)

  const isSomethingSelected = !!checkedComponents.size

  return {
    parentCheckBoxes: {
      isChecked:
        isSomethingSelected &&
        checkedComponents.size === checkBoxIndexes.length,
      isIndeterminate:
        isSomethingSelected &&
        checkedComponents.size !== checkBoxIndexes.length,
      toggleAll: () => {
        if (!isSomethingSelected) {
          setCheckedComponents(new Set(checkBoxIndexes))
        } else {
          setCheckedComponents(new Set())
        }
      },
    },
    childCheckBoxes: {
      isChecked: (index: number) => {
        return checkedComponents.has(index)
      },
      setIsChecked: (index: number) => {
        if (checkedComponents.has(index)) {
          setCheckedComponents((prev) => {
            const next = new Set(prev)
            next.delete(index)
            return next
          })
        } else {
          setCheckedComponents((prev) => new Set(prev).add(index))
        }
      },
    },
    data: {
      values: checkedComponents,
      clear: () => setCheckedComponents(new Set()),
    },
  }
}
