import { Warning } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const MediaCarouselNotFound = () => {
  const { t } = useTranslation('web')

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100%' }}
      spacing={2}
    >
      <Warning fontSize="large" htmlColor="white" />
      <Typography color="white">{t('media.failedToLoad')}</Typography>
    </Stack>
  )
}

export default MediaCarouselNotFound
