import { useEffect, useState } from 'react'

export const useIsWrapperActive = (
  ref: React.MutableRefObject<HTMLElement | null>,
  initialValue = false
) => {
  const [isActive, setIsActive] = useState(initialValue)

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {

      // TODO extremely naive solution, will need to create much more robust solution
      setIsActive(
        !(
          e.target instanceof Node &&
          ref.current &&
          !ref.current.contains(e.target)
        ) ||
          (e.target instanceof Element &&
            e.target.role === 'option' &&
            isActive)
      )
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  })

  return isActive
}
