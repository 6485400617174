import { FormAutoComplete, FormSwitchField } from '@aeqoom/components-web'
import { AddCompanyForm } from '@aeqoom/forms'
import { GetContacts } from '@aeqoom/shared'
import useEditingContext from '@app/hooks/useEditingContext'
import { useGetContact, useGetContacts } from '@app/queries/useContacts'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { DeleteForever, ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import {
  get,
  UseFieldArrayRemove,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CreateContactDialogContent from '../base/dialogs/createForms/CreateContactDialogContent'

type Props = {
  path: `contacts.${number}`
  handleSwitchChange: (index: number) => void
  index: number
  remove: UseFieldArrayRemove
}

type ContactItem = {
  id: number
  contactId: number | undefined
  main: boolean
}

type Option = { firstName: string; lastName: string }

const getOptionLabel = (option: Option) => {
  return `${option?.firstName} ${option?.lastName}`
}

const ContactItem = ({ path, handleSwitchChange, index, remove }: Props) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<AddCompanyForm>()

  const { t } = useTranslation()

  const error = get(errors, path)

  const { isEditing } = useEditingContext()

  const [isOpen, setIsOpen] = useState(false)

  const { openDialog, openPreviousDialog } = useDialogStore()

  const { contactId } = useWatch({
    control,
    name: path,
  })

  const contacts = useWatch({
    control,
    name: 'contacts',
  })

  const contactIds = contacts.map((contact) => contact.contactId)

  const filterOptions = (options: GetContacts[]) =>
    options.filter((item) => !contactIds.includes(item.id))

  const { data } = useGetContacts()

  const { data: contact, isLoading, isError } = useGetContact({ id: contactId })

  const theme = useTheme()

  if (isLoading) return <Skeleton variant="rounded" height={48} />

  if (isError)
    return <Alert color="error">{t('contacts.failedToLoadContact')}</Alert>

  return (
    <Accordion
      expanded={isOpen}
      onChange={() => setIsOpen(!isOpen)}
      elevation={0}
      variant="outlined"
      disableGutters
      square={true}
      sx={{
        borderColor: error ? 'error.main' : 'divider',
        transition: theme.transitions.create(['border-radius']),
        borderRadius: isOpen ? 2 : 1,
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Stack direction="row" alignItems="center" spacing={2} width="100%">
          <Stack direction="row" spacing={2} alignItems="center" width="100%">
            <Typography variant="h2" fontSize={16} fontWeight={700}>
              {contact
                ? `${contact?.firstName} ${contact?.lastName}`
                : t('contacts.noContactChosen')}
            </Typography>
            <Typography color="primary">{contact?.position}</Typography>
          </Stack>
          {isEditing && (
            <IconButton onClick={() => remove(index)}>
              <DeleteForever />
            </IconButton>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <FormAutoComplete
          control={control}
          name={`${path}.contactId`}
          fieldProps={{
            label: t('contacts.contact'),
            required: true,
          }}
          autocomplete={{
            getOptionLabel,
            filterOptions,
            options: data,
            fullWidth: true,
            readOnly: !isEditing,
          }}
          add={{
            onClick: () =>
              openDialog(
                <CreateContactDialogContent
                  callback={(id) => {
                    openPreviousDialog()
                    setValue(`${path}.contactId`, id)
                  }}
                />,
                t('contacts.newContact')
              ),
          }}
          actions={[
            isEditing && (
              <FormAutoComplete.ActionButton
                key="edit"
                onClick={() => {
                  openDialog(
                    <CreateContactDialogContent
                      isEditModal={true}
                      id={contactId}
                      callback={(id) => {
                        openPreviousDialog()
                      }}
                    />,
                    t('contacts.editContact')
                  )
                }}
              />
            ),
          ]}
        />
        <FormSwitchField
          controller={{ control, name: `${path}.main` }}
          label={t('contacts.mainContact')}
          readOnly={!isEditing || !contactId}
          callback={() => handleSwitchChange(index)}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default ContactItem
