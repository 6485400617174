import { AddMediaForm, addMediaForm } from '@aeqoom/forms'
import { AccessLevel, MediaType } from '@aeqoom/shared'
import { isObjectEmpty } from '@aeqoom/utils'
import BackdropUpload from '@app/components/BackdropUpload'
import MediaUploadCard from '@app/components/MediaUploadCard'
import useUpload from '@app/hooks/useUpload'
import { useCreateMedia } from '@app/queries/useMedia'
import useDialog from '@app/src/hooks/useDialog'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { zodResolver } from '@hookform/resolvers/zod'
import { ImageNotSupported, PlusOne, Upload } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import MediaDetailDialog from '../_detailViewDialogs/MediaDetailDialog'

type Props = {
  fileAttachments?: File[]
  callback?: (data: number[]) => void
  defaultAccessLevel?: AccessLevel[]
  defaultMediaType?: MediaType[]
}

const AddMediaDialog = ({
  fileAttachments,
  callback,
  defaultAccessLevel = ['companyUnit'],
  defaultMediaType = ['general'],
}: Props) => {
  const { t } = useTranslation()
  const { hasParent } = useDialog()

  const methods = useForm<AddMediaForm>({
    resolver: zodResolver(addMediaForm),
    defaultValues: {
      media: [],
    },
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'media',
  })

  const [isUploading, setIsUploading] = useState(false)

  const { uploadPercentage, addMedia } = useUpload()

  const createMediaMutation = useCreateMedia()

  const { openDialog, closeTab, closeDialog } = useDialogStore()

  const firstAccessLevel = defaultAccessLevel[0]

  const firstMediaType = defaultMediaType[0]

  const isError = isObjectEmpty(errors)

  const isEmpty = fields.length === 0

  const addNewMedia = () => {
    prepend({
      name: '',
      description: '',
      file: null,
      defaultAccessLevel: firstAccessLevel,
      mediaType: firstMediaType,
    })
  }

  useEffect(() => {
    if (fileAttachments) {
      reset({
        media: fileAttachments.map((item) => ({
          name: '',
          description: '',
          file: [item],
          defaultAccessLevel: firstAccessLevel,
          mediaType: firstMediaType,
        })),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileAttachments, firstAccessLevel, firstMediaType])

  const uploadFile: SubmitHandler<AddMediaForm> = async (data) => {
    setIsUploading(true)
    const uploadedMedia = await addMedia(data.media)
    if (uploadedMedia.length > 0) {
      createMediaMutation.mutate(uploadedMedia, {
        onSuccess: ({ data, message }) => {
          toast.success(message)

          const singleUpload = data.length === 1

          hasParent ? closeTab() : closeDialog()

          callback
            ? callback(data)
            : singleUpload &&
              openDialog(
                <MediaDetailDialog activeMedia={data[0]} />,
                'Media detail'
              )

          setIsUploading(false)
        },
        onError: () => {
          setIsUploading(false)
        },
      })
    }
  }

  return (
    <FormProvider {...methods}>
      <BackdropUpload
        launchAction={(acceptedFiles) => {
          for (const item of acceptedFiles) {
            prepend({
              name: '',
              description: '',
              file: [item],
              defaultAccessLevel: firstAccessLevel,
              mediaType: firstMediaType,
            })
          }
        }}
      >
        <DialogContent>
          {fields.length > 0 ? (
            <Grid container spacing={4}>
              {isError && (
                <Grid item xs={12}>
                  <Alert severity="error" variant="outlined">
                    {t('media.mediaNotFilledInCorrectly')}
                  </Alert>
                </Grid>
              )}
              {fields.map((field, index) => (
                <Grid item key={field.id} xs={4}>
                  <MediaUploadCard
                    path={`media.${index}`}
                    remove={() => remove(index)}
                    uploadPercentage={uploadPercentage[index]}
                    defaultAccessLevel={defaultAccessLevel}
                    defaultMediaType={defaultMediaType}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ height: '100%' }}
              spacing={2}
            >
              <ImageNotSupported fontSize="large" color="disabled" />
              <Typography variant="subtitle1" color="disabled">
                {t('media.noMedia')}
              </Typography>
              <Button onClick={addNewMedia} variant="outlined">
                {t('media.addNewMedia')}
              </Button>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<PlusOne />}
            onClick={addNewMedia}
            variant="outlined"
          >
            {t('media.addNewMedia')}
          </Button>

          <LoadingButton
            disabled={isError || isEmpty}
            startIcon={<Upload />}
            loading={isUploading}
            onClick={handleSubmit(uploadFile)}
            variant="contained"
          >
            {t('media.uploadFiles', { count: fields.length })}
          </LoadingButton>
        </DialogActions>
      </BackdropUpload>
    </FormProvider>
  )
}

export default AddMediaDialog
