import { relations } from 'drizzle-orm'
import { boolean, integer, pgTable, serial, varchar } from 'drizzle-orm/pg-core'

import { companies, companyUnits } from '.'
import { users } from './users'

export const contacts = pgTable('contacts', {
  id: serial('id').primaryKey(),
  firstName: varchar('first_name', { length: 256 }).notNull(),
  lastName: varchar('last_name', { length: 256 }).notNull(),
  position: varchar('position', { length: 256 }).notNull(),
  emailAddress: varchar('email_address', { length: 256 }).notNull(),
  phoneNumber: varchar('phone_number', { length: 256 }).notNull(),
  userId: integer('user_id').references(() => users.id),
})

export const contactsRelations = relations(contacts, ({ one, many }) => ({
  user: one(users, {
    fields: [contacts.userId],
    references: [users.id],
  }),
  companyContacts: many(companyContacts),
  companyUnitContacts: many(companyUnitContacts),
}))

export const companyContacts = pgTable('company_contacts', {
  id: serial('id').primaryKey(),
  main: boolean('main'),
  companyId: integer('company_id')
    .references(() => companies.id, { onDelete: 'cascade' })
    .notNull(),
  contactId: integer('contact_id')
    .references(() => contacts.id, { onDelete: 'cascade' })
    .notNull(),
})

export const companyContactsRelations = relations(
  companyContacts,
  ({ one }) => ({
    contact: one(contacts, {
      fields: [companyContacts.contactId],
      references: [contacts.id],
    }),
    company: one(companies, {
      fields: [companyContacts.companyId],
      references: [companies.id],
    }),
  })
)

export const companyUnitContacts = pgTable('company_unit_contacts', {
  id: serial('id').primaryKey(),
  main: boolean('main'),
  companyUnitId: integer('company_unit_id')
    .references(() => companyUnits.id, { onDelete: 'cascade' })
    .notNull(),
  contactId: integer('contact_id')
    .references(() => contacts.id, { onDelete: 'cascade' })
    .notNull(),
})

export const companyUnitContactsRelations = relations(
  companyUnitContacts,
  ({ one }) => ({
    contact: one(contacts, {
      fields: [companyUnitContacts.contactId],
      references: [contacts.id],
    }),
    companyUnit: one(companyUnits, {
      fields: [companyUnitContacts.companyUnitId],
      references: [companyUnits.id],
    }),
  })
)

export type Contact = typeof contacts.$inferSelect
export type NewContact = typeof contacts.$inferInsert

export type CompanyContact = typeof companyContacts.$inferSelect
export type NewCompanyContact = typeof companyContacts.$inferInsert

export type CompanyUnitContact = typeof companyUnitContacts.$inferSelect
export type NewCompanyUnitContact = typeof companyUnitContacts.$inferInsert
