import { Box, Paper } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useState } from 'react'

import ForgotPassword from './_components/ForgotPassword'
import SignIn from './_components/SignIn'
import VerifyPasswordReset from './_components/VerifyPasswordReset'

const SignInLayout = () => {
  const [tab, setTab] = useState(0)
  const [cachedEmail, setCachedEmail] = useState('')

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(/images/sign-in-background.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {tab === 0 && (
            <SignIn
              setTab={setTab}
              cachedEmail={cachedEmail}
              setCachedEmail={setCachedEmail}
            />
          )}
          {tab === 1 && (
            <ForgotPassword
              setTab={setTab}
              cachedEmail={cachedEmail}
              setCachedEmail={setCachedEmail}
            />
          )}
          {tab === 2 && (
            <VerifyPasswordReset
              setTab={setTab}
              cachedEmail={cachedEmail}
              setCachedEmail={setCachedEmail}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default SignInLayout
