import { relations } from 'drizzle-orm'
import {
  AnyPgColumn,
  date,
  integer,
  pgEnum,
  pgTable,
  serial,
  text,
  uuid,
  varchar,
} from 'drizzle-orm/pg-core'

import {
  companies,
  companyUnits,
  componentInstanceOnServiceLevelAgreementMachine,
  componentTemplateOnToDoLists,
  machineInstances,
  media,
  mediaOnComponentInstance,
} from './index'

export const counterTypes = pgEnum('counter_type', [
  'envelopes',
  'lists',
  'cuts',
])

export const componentInstances = pgTable('component_instances', {
  id: serial('id').primaryKey(),
  avatar: integer('avatar_id').references(() => media.id, {
    onDelete: 'set null',
  }),
  name: varchar('name', { length: 256 }).notNull(),
  description: text('description'),
  inventoryNumber: varchar('inventory_number', { length: 256 }),
  yearProduced: date('year_produced'),
  warrantyUntil: date('warranty_until'),
  counterState: integer('counter_state'),
  counterType: counterTypes('counter_type'),
  companyId: integer('company_id')
    .references(() => companies.id, {
      onDelete: 'cascade',
    })
    .notNull(),
  companyUnitId: integer('company_unit_id')
    .references(() => companyUnits.id, {
      onDelete: 'cascade',
    })
    .notNull(),
  machineInstanceId: integer('machine_instance_id').references(
    () => machineInstances.id,
    { onDelete: 'cascade' }
  ),
  componentTemplateId: integer('component_template_id').references(
    () => componentTemplates.id,
    { onDelete: 'set null' }
  ),
  uuid: uuid('uuid').defaultRandom().unique().notNull(),
  parentId: integer('parent_id').references(
    (): AnyPgColumn => componentInstances.id,
    {
      onDelete: 'cascade',
    }
  ),
})

export const componentInstancesRelations = relations(
  componentInstances,
  ({ one, many }) => ({
    avatar: one(media, {
      fields: [componentInstances.avatar],
      references: [media.id],
    }),
    machineInstance: one(machineInstances, {
      fields: [componentInstances.machineInstanceId],
      references: [machineInstances.id],
    }),
    componentTemplate: one(componentTemplates, {
      fields: [componentInstances.componentTemplateId],
      references: [componentTemplates.id],
    }),
    serviceLevelAgreementMachines: many(
      componentInstanceOnServiceLevelAgreementMachine
    ),
    media: many(mediaOnComponentInstance),
    parent: one(componentInstances, {
      fields: [componentInstances.parentId],
      references: [componentInstances.id],
    }),
    company: one(companies, {
      fields: [componentInstances.companyId],
      references: [companies.id],
    }),
    companyUnit: one(companyUnits, {
      fields: [componentInstances.companyUnitId],
      references: [companyUnits.id],
    }),
  })
)

export type ComponentInstance = typeof componentInstances.$inferSelect
export type NewComponentInstance = typeof componentInstances.$inferInsert

export const componentTemplates = pgTable('component_templates', {
  id: serial('id').primaryKey(),
  name: varchar('name', { length: 256 }).notNull(),
  description: text('description'),
  producer: varchar('producer', { length: 256 }),
})

export const componentTemplatesRelations = relations(
  componentTemplates,
  ({ many }) => ({
    componentInstances: many(componentInstances),
    toDoLists: many(componentTemplateOnToDoLists),
  })
)

export type ComponentTemplate = typeof componentTemplates.$inferSelect
export type NewComponentTemplate = typeof componentTemplates.$inferInsert
