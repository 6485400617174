import { UserGroups } from '@aeqoom/shared'

import { Permission, PERMISSIONS } from './definition'

export const ProviderSuperAdmin = [PERMISSIONS.ALL.ANY]

export const ProviderAdmin = [
  PERMISSIONS.BACKLOG.ANY,
  PERMISSIONS.COMPANY.READ,
  PERMISSIONS.COMPANY.LIST,
  PERMISSIONS.COMPANY_UNIT.READ,
  PERMISSIONS.COMPANY_UNIT.LIST,
  PERMISSIONS.COMPONENT_INSTANCE.READ,
  PERMISSIONS.COMPONENT_INSTANCE.LIST,
  PERMISSIONS.COMPONENT_TEMPLATE.READ,
  PERMISSIONS.COMPONENT_TEMPLATE.LIST,
  PERMISSIONS.DASHBOARD.ANY,
  PERMISSIONS.MACHINE_INSTANCE.READ,
  PERMISSIONS.MACHINE_INSTANCE.LIST,
  PERMISSIONS.MACHINE_TEMPLATE.READ,
  PERMISSIONS.MACHINE_TEMPLATE.LIST,
  PERMISSIONS.QRCODE.ANY,
  PERMISSIONS.MEDIA.ANY,
  PERMISSIONS.MEDIA_TYPE.ANY,
  PERMISSIONS.MEDIA_VERSION.ANY,
  PERMISSIONS.SERVICE_LEVEL_AGREEMENT.READ,
  PERMISSIONS.SERVICE_LEVEL_AGREEMENT.LIST,
  PERMISSIONS.TASK.READ,
  PERMISSIONS.TASK.LIST,
  PERMISSIONS.TASK.CREATE,
  PERMISSIONS.TASK.UPDATE,
  PERMISSIONS.TASK.ASSIGN_USER,
  PERMISSIONS.TASK.CHANGE_STATUS,
  PERMISSIONS.TASK_TEMPLATE.READ,
  PERMISSIONS.TASK.PLAN,
  PERMISSIONS.TO_DO_LIST.READ,
  PERMISSIONS.TO_DO_LIST.LIST,
  PERMISSIONS.TO_DO_LIST.CREATE,
  PERMISSIONS.TO_DO_LIST.UPDATE,
  PERMISSIONS.TO_DO_LIST_ITEM.READ,
  PERMISSIONS.TO_DO_LIST_ITEM.LIST,
  PERMISSIONS.TO_DO_LIST_ITEM.CREATE,
  PERMISSIONS.TO_DO_LIST_ITEM.UPDATE,
  PERMISSIONS.TO_DO_LIST_ITEM.CHECK_UPDATE,
  PERMISSIONS.USER.READ,
  PERMISSIONS.USER.LIST,
  PERMISSIONS.WORK_PROTOCOL.READ,
  PERMISSIONS.WORK_PROTOCOL.LIST,
  PERMISSIONS.WORK_PROTOCOL.UPDATE,
  PERMISSIONS.CONTACT.READ,
  PERMISSIONS.CONTACT.LIST,
  PERMISSIONS.ENTITY_ACCESS.NO_LIMIT,
  PERMISSIONS.SERVICE_LOG.READ,
]

export const ClientSuperAdmin = [
  PERMISSIONS.BACKLOG.ANY,
  PERMISSIONS.COMPANY.READ,
  PERMISSIONS.COMPANY.LIST,
  PERMISSIONS.COMPANY_UNIT.ANY,
  PERMISSIONS.COMPONENT_INSTANCE.READ,
  PERMISSIONS.COMPONENT_INSTANCE.LIST,
  PERMISSIONS.DASHBOARD.ANY,
  PERMISSIONS.MACHINE_INSTANCE.READ,
  PERMISSIONS.MACHINE_INSTANCE.LIST,
  PERMISSIONS.QRCODE.ANY,
  PERMISSIONS.MEDIA.ANY,
  PERMISSIONS.MEDIA_TYPE.ANY,
  PERMISSIONS.MEDIA_VERSION.ANY,
  PERMISSIONS.SERVICE_LEVEL_AGREEMENT.READ,
  PERMISSIONS.SERVICE_LEVEL_AGREEMENT.LIST,
  PERMISSIONS.TASK.READ,
  PERMISSIONS.TASK.LIST,
  PERMISSIONS.TASK.CREATE,
  PERMISSIONS.TASK.ASSIGN_USER,
  PERMISSIONS.TASK.CHANGE_STATUS,
  PERMISSIONS.TASK.PLAN,
  PERMISSIONS.TO_DO_LIST.READ,
  PERMISSIONS.TO_DO_LIST.LIST,
  PERMISSIONS.TO_DO_LIST_ITEM.READ,
  PERMISSIONS.TO_DO_LIST_ITEM.LIST,
  PERMISSIONS.TO_DO_LIST_ITEM.CHECK_UPDATE,
  PERMISSIONS.USER.ANY,
  PERMISSIONS.WORK_PROTOCOL.READ,
  PERMISSIONS.WORK_PROTOCOL.LIST,
  PERMISSIONS.WORK_PROTOCOL.UPDATE,
  PERMISSIONS.CONTACT.READ,
  PERMISSIONS.CONTACT.LIST,
  PERMISSIONS.ENTITY_ACCESS.BOTH,
  PERMISSIONS.SERVICE_LOG.READ,
]

export const ClientUnitAdmin = [
  PERMISSIONS.BACKLOG.ANY,
  PERMISSIONS.COMPANY.READ,
  PERMISSIONS.COMPANY.LIST,
  PERMISSIONS.COMPANY_UNIT.READ,
  PERMISSIONS.COMPANY_UNIT.LIST,
  PERMISSIONS.COMPONENT_INSTANCE.READ,
  PERMISSIONS.COMPONENT_INSTANCE.LIST,
  PERMISSIONS.DASHBOARD.ANY,
  PERMISSIONS.MACHINE_INSTANCE.READ,
  PERMISSIONS.MACHINE_INSTANCE.LIST,
  PERMISSIONS.QRCODE.ANY,
  PERMISSIONS.MEDIA.ANY,
  PERMISSIONS.MEDIA_TYPE.ANY,
  PERMISSIONS.MEDIA_VERSION.ANY,
  PERMISSIONS.SERVICE_LEVEL_AGREEMENT.READ,
  PERMISSIONS.SERVICE_LEVEL_AGREEMENT.LIST,
  PERMISSIONS.TASK.READ,
  PERMISSIONS.TASK.LIST,
  PERMISSIONS.TASK.CREATE,
  PERMISSIONS.TASK.ASSIGN_USER,
  PERMISSIONS.TASK.CHANGE_STATUS,
  PERMISSIONS.TASK.PLAN,
  PERMISSIONS.TO_DO_LIST.READ,
  PERMISSIONS.TO_DO_LIST.LIST,
  PERMISSIONS.TO_DO_LIST_ITEM.READ,
  PERMISSIONS.TO_DO_LIST_ITEM.LIST,
  PERMISSIONS.TO_DO_LIST_ITEM.CHECK_UPDATE,
  PERMISSIONS.USER.READ,
  PERMISSIONS.USER.LIST,
  PERMISSIONS.WORK_PROTOCOL.READ,
  PERMISSIONS.WORK_PROTOCOL.LIST,
  PERMISSIONS.WORK_PROTOCOL.UPDATE,
  PERMISSIONS.CONTACT.READ,
  PERMISSIONS.CONTACT.LIST,
  PERMISSIONS.ENTITY_ACCESS.BY_COMPANY_UNIT,
  PERMISSIONS.SERVICE_LOG.READ,
]

export const Technician = [
  PERMISSIONS.BACKLOG.ANY,
  PERMISSIONS.COMPANY.READ,
  PERMISSIONS.COMPANY.LIST,
  PERMISSIONS.COMPANY_UNIT.READ,
  PERMISSIONS.COMPANY_UNIT.LIST,
  PERMISSIONS.COMPONENT_INSTANCE.READ,
  PERMISSIONS.COMPONENT_INSTANCE.LIST,
  PERMISSIONS.MACHINE_INSTANCE.READ,
  PERMISSIONS.MACHINE_INSTANCE.LIST,
  PERMISSIONS.QRCODE.ANY,
  PERMISSIONS.MEDIA.READ,
  PERMISSIONS.MEDIA.LIST,
  PERMISSIONS.MEDIA.CREATE,
  PERMISSIONS.MEDIA.UPDATE,
  PERMISSIONS.MEDIA_TYPE.AVATAR,
  PERMISSIONS.MEDIA_TYPE.GENERAL,
  PERMISSIONS.MEDIA_VERSION.READ,
  PERMISSIONS.MEDIA_VERSION.LIST,
  PERMISSIONS.MEDIA_VERSION.CREATE,
  PERMISSIONS.MEDIA_VERSION.UPDATE,
  PERMISSIONS.TASK.READ,
  PERMISSIONS.TASK.LIST,
  PERMISSIONS.TASK.CHANGE_STATUS,
  PERMISSIONS.TASK.CREATE,
  PERMISSIONS.TO_DO_LIST.READ,
  PERMISSIONS.TO_DO_LIST.LIST,
  PERMISSIONS.TO_DO_LIST_ITEM.READ,
  PERMISSIONS.TO_DO_LIST_ITEM.LIST,
  PERMISSIONS.TO_DO_LIST_ITEM.CHECK_UPDATE,
  PERMISSIONS.USER.READ,
  PERMISSIONS.WORK_PROTOCOL.READ,
  PERMISSIONS.WORK_PROTOCOL.LIST,
  PERMISSIONS.WORK_PROTOCOL.UPDATE,
  PERMISSIONS.CONTACT.READ,
  PERMISSIONS.CONTACT.LIST,
  PERMISSIONS.ENTITY_ACCESS.NO_LIMIT,
  PERMISSIONS.SERVICE_LOG.READ,
  PERMISSIONS.SERVICE_LEVEL_AGREEMENT.LIST,
  PERMISSIONS.SERVICE_LEVEL_AGREEMENT.READ,
]

export const roles: Record<UserGroups, Permission[]> = {
  ProviderSuperAdmin,
  ProviderAdmin,
  ClientSuperAdmin,
  ClientUnitAdmin,
  Technician,
}
