import { Address } from '@aeqoom/shared'
import { serializeAddress } from '@aeqoom/utils'
import { KeyboardArrowRight, MyLocationOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'

type Props = {
  handleOnClick: () => void
  name: string
  inventoryNumber?: string | null
  address?: Partial<Address>
  avatarUrl?: string
  isAvatarLoading?: boolean
}

export const DetailButton = ({
  handleOnClick,
  inventoryNumber,
  name,
  address,
  avatarUrl,
  isAvatarLoading,
}: Props) => {
  return (
    <Button
      fullWidth
      variant="contained"
      color="inherit"
      onClick={handleOnClick}
      sx={{
        borderRadius: 2,
        paddingX: 1,
        maxWidth: 500,
      }}
    >
      <Stack
        direction="row"
        gap={1}
        width="100%"
        sx={{
          overflow: 'hidden',
        }}
      >
        {isAvatarLoading ? (
          <Box
            sx={{
              width: '25%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          avatarUrl && (
            <Box
              component="img"
              src={avatarUrl}
              sx={{
                width: '25%',
                borderRadius: 2,
                aspectRatio: 1,
                objectFit: 'cover',
              }}
            />
          )
        )}
        <Stack gap={1} padding={1} width="100%">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {/*@TODO - update with theme h4 after theme font update*/}
            <Typography
              variant="body1"
              textAlign="left"
              style={{
                fontWeight: 600,
              }}
            >
              {name}
            </Typography>
            <IconButton
              sx={{
                padding: 0,
              }}
            >
              <KeyboardArrowRight />
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="spaceBetween"
          >
            <Typography variant="body2">{inventoryNumber}</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="spaceBetween"
            gap={1}
          >
            {address && (
              <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="flex-start"
                gap={1}
              >
                <MyLocationOutlined sx={{ padding: 0 }} />
                <Typography variant="body2" textAlign="left">
                  {serializeAddress(address)}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Button>
  )
}
