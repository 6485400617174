import { PERMISSIONS } from '@aeqoom/permissions'
import CheckPermissions from '@app/components/CheckPermissions'
import ErrorScreen from '@app/components/ErrorScreen'
import { useGetComponentInstance } from '@app/queries/useComponentInstances'
import { useDialogStore } from '@app/stores/useDialogStore'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import CreateComponentInstanceDialogContent from '../../createForms/CreateComponentInstanceDialogContent'
import Content from './Content'

type Props = {
  id?: number
}

const ComponentInstanceDialog = ({ id }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'componentInstances.detail',
  })

  const { isPending, isError, refetch, data } = useGetComponentInstance({
    id,
  })

  const { openDialog, closeTab } = useDialogStore()

  if (isPending)
    return (
      <DialogContent>
        <Stack alignItems="center" justifyContent="center" height="100%">
          <CircularProgress />
        </Stack>
      </DialogContent>
    )

  if (isError)
    return (
      <DialogContent>
        <Stack alignItems="center" justifyContent="center" height="100%">
          <ErrorScreen refetch={refetch} />
        </Stack>
      </DialogContent>
    )

  return (
    <>
      <Content
        data={{
          ...data,
          yearProduced: data?.yearProduced?.slice(0, 4) ?? null,
        }}
      />
      <CheckPermissions permissions={[PERMISSIONS.COMPONENT_INSTANCE.UPDATE]}>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              openDialog(
                <CreateComponentInstanceDialogContent
                  isEditModal
                  id={id}
                  callback={() => {
                    closeTab()
                  }}
                />,
                'Edit component instance'
              )
            }}
          >
            {t('actions.edit')}
          </Button>
        </DialogActions>
      </CheckPermissions>
    </>
  )
}

export default ComponentInstanceDialog
