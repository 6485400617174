import { companyContacts, companyUnitContacts, contacts } from '@aeqoom/db'
import { getContactsSchema } from '@aeqoom/shared'
import {
  ClientInferRequest,
  ClientInferResponseBody,
  initContract,
} from '@ts-rest/core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { globalFilter } from '../schemas'

const c = initContract()

export const contactsRouter = c.router({
  getContacts: {
    method: 'GET',
    path: '/contacts',
    query: globalFilter,
    responses: {
      200: z.array(getContactsSchema),
    },
  },
  getContact: {
    method: 'GET',
    path: '/contacts/:id/get',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: z.object(createSelectSchema(contacts).shape),
      404: z.object({ message: z.string() }),
    },
  },
  addContact: {
    method: 'POST',
    path: '/contacts',
    body: z.object(createInsertSchema(contacts).shape),
    responses: {
      200: z.object({ id: z.number(), message: z.string() }),
    },
  },
  editContact: {
    method: 'POST',
    path: '/contacts/:id/edit',
    pathParams: z.object({ id: z.coerce.number() }),
    body: z.object(createInsertSchema(contacts).shape),
    responses: {
      200: z.object({ id: z.number(), message: z.string() }),
    },
  },
  deleteContact: {
    method: 'DELETE',
    path: '/contacts/:id/delete',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({}),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
      404: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  getCompanyContacts: {
    method: 'GET',
    path: '/company-contacts',
    query: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: z.array(
        z.object({
          ...createSelectSchema(companyContacts).shape,
          contact: createSelectSchema(contacts),
        })
      ),
    },
  },
  getCompanyContact: {
    method: 'GET',
    path: '/company-contacts/:id/get',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: z.object(createSelectSchema(companyContacts).shape),
      404: z.object({ message: z.string() }),
    },
  },
  addCompanyContact: {
    method: 'POST',
    path: '/company-contacts',
    body: z.object({
      companyId: z.number(),
      contactId: z.number(),
      main: z.boolean(),
    }),
    responses: {
      200: z.object({ id: z.number(), message: z.string() }),
    },
  },
  editCompanyContact: {
    method: 'POST',
    path: '/company-contacts/:id/edit',
    pathParams: z.object({ id: z.coerce.number() }),
    body: z.object({
      companyId: z.number(),
      contactId: z.number(),
      main: z.boolean(),
    }),
    responses: {
      200: z.object({ id: z.number(), message: z.string() }),
    },
  },
  deleteCompanyContact: {
    method: 'DELETE',
    path: '/company-contacts/:id/delete',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({}),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
      404: z.object({ message: z.string() }),
    },
  },
  getCompanyUnitContacts: {
    method: 'GET',
    path: '/company-unit-contacts',
    query: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: z.array(
        z.object({
          ...createSelectSchema(companyUnitContacts).shape,
          contact: createSelectSchema(contacts),
        })
      ),
    },
  },
  getCompanyUnitContact: {
    method: 'GET',
    path: '/company-unit-contacts/:id/get',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: z.object(createSelectSchema(companyUnitContacts).shape),
      404: z.object({ message: z.string() }),
    },
  },
  addCompanyUnitContact: {
    method: 'POST',
    path: '/company-unit-contacts',
    body: z.object({
      companyUnitId: z.number(),
      contactId: z.number(),
      main: z.boolean(),
    }),
    responses: {
      200: z.object({ id: z.number(), message: z.string() }),
    },
  },
  editCompanyUnitContact: {
    method: 'POST',
    path: '/company-unit-contacts/:id/edit',
    pathParams: z.object({ id: z.coerce.number() }),
    body: z.object({
      companyUnitId: z.number(),
      contactId: z.number(),
      main: z.boolean(),
    }),
    responses: {
      200: z.object({ id: z.number(), message: z.string() }),
    },
  },
  deleteCompanyUnitContact: {
    method: 'DELETE',
    path: '/company-unit-contacts/:id/delete',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({}),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
      404: z.object({ message: z.string() }),
    },
  },
})

export type AddContactRequest = ClientInferRequest<
  typeof contactsRouter.addContact
>

export type ContactBody = ClientInferResponseBody<
  typeof contactsRouter.getContact,
  200
>

export type EditContactRequest = ClientInferRequest<
  typeof contactsRouter.editContact
>

export type DeleteContactRequest = ClientInferRequest<
  typeof contactsRouter.deleteContact
>

export default contactsRouter
