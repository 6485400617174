import { Flatten, GetDashboardResponseBody } from '@aeqoom/contracts'
import { TaskType } from '@aeqoom/db'
import { useThemeColors } from '@aeqoom/hooks'
import CustomAvatar from '@app/components/CustomAvatar'
import IncidentsBox from '@app/components/Dashboard/IncidentsBox'
import InfoBox from '@app/components/Dashboard/InfoBox'
import useUserStore from '@app/src/stores/useUserStore'
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined'
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined'
import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

type Props = {
  unit: Flatten<GetDashboardResponseBody>
}

const DashboardItem = ({ unit }: Props) => {
  const { name, city, country, street, numberOfMachineInstances, avatar } = unit
  const theme = useTheme()
  const location = `${street}, ${city}, ${country}`
  const { secondary } = useThemeColors()

  const navigate = useNavigate()
  const { setCurrentActiveCompany, setCurrentActiveCompanyUnit } =
    useUserStore()

  const handleNavigate = () => {
    setCurrentActiveCompany(unit.companyId)
    setCurrentActiveCompanyUnit(unit.id)
    navigate('/admin/backlog')
  }

  return (
    <Card
      sx={{
        transition: theme.transitions.create('transform'),
        '&:hover': { transform: theme.transforms.scale },
        cursor: 'pointer',
      }}
      onClick={handleNavigate}
    >
      <CardContent>
        <Stack direction="row" gap={2}>
          <Stack sx={{ width: '50%' }} gap={1}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <CustomAvatar src={avatar} />
              <Typography variant="body1Bold">{name}</Typography>
            </Stack>
            <Stack direction="row" color={secondary.main}>
              <PrecisionManufacturingOutlinedIcon sx={{ fontSize: 20 }} />
              <Typography
                variant="body2Regular"
                gutterBottom
                sx={{ paddingLeft: 1 }}
              >
                Machines: {numberOfMachineInstances}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              sx={{ height: theme.spacing(5) }}
              color={secondary.main}
            >
              <MyLocationOutlinedIcon sx={{ fontSize: 20 }} />
              <Typography
                variant="body2Regular"
                gutterBottom
                sx={{ paddingLeft: 1 }}
              >
                {location}
              </Typography>
            </Stack>
            <Box sx={{ mt: 'auto' }}>
              <InfoBox unit={unit} type={TaskType.MAINTENANCE} />
            </Box>
          </Stack>
          <Box sx={{ width: '50%', height: '60%', mt: 'auto' }}>
            {unit?.numberOfIncidents?.new === 0 ? (
              <InfoBox unit={unit} type={TaskType.INCIDENT} />
            ) : (
              <IncidentsBox unit={unit} />
            )}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default DashboardItem
