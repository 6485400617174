import { GridMapper, LabeledValue } from '@aeqoom/components-web'
import { UserResponse } from '@aeqoom/contracts'
import CompanyField from '@app/components/base/dialogs/_detailViewDialogs/_components/CompanyField'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CoverImage from '../../../../_components/CoverImage'
import { FIELD_SHARED_STYLES } from '../../../../constants'

type Props = {
  data: UserResponse
}

const GRID_MAP = [6, 5, 6, 5]

const UserInfo = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'user.detail.tabs.userDetail.content',
  })

  const content = [
    <LabeledValue
      label={t('firstName')}
      value={data.firstName}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('lastName')}
      value={data.lastName}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('emailAddress')}
      value={data.emailAddress}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('group')}
      value={data.group}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
  ]

  const companyContent = [
    <CompanyField companyId={data.companyId} />,
    <CompanyField companyUnitId={data.companyUnitId} />,
  ]

  return (
    <Grid container justifyContent="space-between">
      <Grid
        item
        xs={6}
        sx={{
          overflow: 'hidden',
        }}
      >
        <GridMapper template={GRID_MAP} paddingBottom={8}>
          {content}
        </GridMapper>
        <GridMapper template={GRID_MAP}>{companyContent}</GridMapper>
      </Grid>
      <Grid xs={5}>
        <CoverImage avatar={data.avatar} />
      </Grid>
    </Grid>
  )
}

export default UserInfo
