import { GetSLAsResponseBody } from '@aeqoom/contracts'
import { formatDate } from '@app/src/utils/formatDate'
import { GridColDef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'

export const getSlasDataColumns = (
  t: TFunction<'web', 'contracts'>
): GridColDef<GetSLAsResponseBody[number]>[] => [
  { field: 'id', headerName: t('table.header.id'), width: 80 },
  { field: 'name', headerName: t('table.header.name'), width: 230 },
  {
    field: 'companyUnit',
    headerName: t('table.header.companyUnit'),
    width: 230,
    valueGetter: (params) => params.row.companyUnit?.name,
  },
  {
    field: 'dateFrom',
    headerName: t('table.header.validFrom'),
    width: 230,
    valueFormatter: (params) => formatDate(dayjs.utc(params.value)),
  },
  {
    field: 'dateTo',
    headerName: t('table.header.validTill'),
    width: 230,
    valueFormatter: (params) =>
      params.value ? formatDate(dayjs.utc(params.value)) : 'Date to is not set',
  },
]
