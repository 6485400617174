import {
  companies,
  companyUnits,
  componentInstanceOnServiceLevelAgreementMachine,
  machineInstanceOnServiceLevelAgreement,
  serviceLevelAgreements,
} from '@aeqoom/db'
import { z } from '@aeqoom/zod'
import {
  ClientInferRequest,
  ClientInferResponseBody,
  initContract,
} from '@ts-rest/core'
import { createSelectSchema } from 'drizzle-zod'

import {
  componentInstanceSchema,
  serviceLevelAgreementSchema,
} from '../schemas'
import { globalFilter } from '../schemas'

const contractResponse200 = z.object({
  ...createSelectSchema(serviceLevelAgreements).shape,
  company: createSelectSchema(companies),
  companyUnit: createSelectSchema(companyUnits).nullable(),
  machineInstances: z.array(
    z.object({
      ...createSelectSchema(machineInstanceOnServiceLevelAgreement).shape,
      componentInstances: z.array(
        createSelectSchema(componentInstanceOnServiceLevelAgreementMachine)
      ),
      newComponentInstances: componentInstanceSchema.array(),
      componentFrequencies: z.record(
        z.number(),
        createSelectSchema(componentInstanceOnServiceLevelAgreementMachine)
      ),
    })
  ),
  media: z.array(z.number()),
})

const c = initContract()

const serviceLevelAgreementsRouter = c.router({
  getServiceLevelAgreements: {
    method: 'GET',
    path: '/service-level-agreements',
    query: z.intersection(
      z.object({
        companyUnitId: z.coerce.number().optional(),
        companyId: z.coerce.number().optional(),
      }),
      globalFilter
    ),
    responses: {
      200: z.array(
        z.object({
          ...createSelectSchema(serviceLevelAgreements).shape,
          companyUnit: createSelectSchema(companyUnits).nullable(),
        })
      ),
    },
  },
  getServiceLevelAgreement: {
    method: 'GET',
    path: '/service-level-agreements/:id/get',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: contractResponse200,
      404: z.object({ message: z.string() }),
    },
  },
  addServiceLevelAgreement: {
    method: 'POST',
    path: '/service-level-agreements',
    body: serviceLevelAgreementSchema,
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  deleteServiceLevelAgreement: {
    method: 'DELETE',
    path: '/service-level-agreements/delete',
    body: z.object({
      id: z.number(),
    }),
    responses: {
      200: z.object({
        id: z.number(),
      }),
    },
  },
  editServiceLevelAgreement: {
    method: 'POST',
    path: '/service-level-agreements/:id/edit',
    pathParams: z.object({ id: z.coerce.number() }),
    body: serviceLevelAgreementSchema,
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  generateTasks: {
    method: 'POST',
    path: '/service-level-agreements/:id/generate-tasks',
    pathParams: z.object({ id: z.coerce.number() }),
    body: z.object({}),
    responses: {
      200: z.object({
        message: z.string(),
      }),
    },
  },
})

export type AddSLARequest = ClientInferRequest<
  typeof serviceLevelAgreementsRouter.addServiceLevelAgreement
>

export type GetSLAsRequest = ClientInferRequest<
  typeof serviceLevelAgreementsRouter.getServiceLevelAgreements
>

export type EditSLARequest = ClientInferRequest<
  typeof serviceLevelAgreementsRouter.editServiceLevelAgreement
>

export type DeleteSLARequest = ClientInferRequest<
  typeof serviceLevelAgreementsRouter.deleteServiceLevelAgreement
>

export type GenerateTasksRequest = ClientInferRequest<
  typeof serviceLevelAgreementsRouter.generateTasks
>

export type ContractResponse = z.infer<typeof contractResponse200>

export type GetSLAsResponseBody = ClientInferResponseBody<
  typeof serviceLevelAgreementsRouter.getServiceLevelAgreements,
  200
>

export default serviceLevelAgreementsRouter
