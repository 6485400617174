import { media, mediaVersions, users } from '@aeqoom/db'
import {
  FileType,
  getMediaSchema,
  mediaSchema,
  mediaTypeSchema,
} from '@aeqoom/shared'
import { accessLevelSchema } from '@aeqoom/shared'
import { toBoolean, z } from '@aeqoom/zod'
import {
  ClientInferRequest,
  ClientInferResponseBody,
  initContract,
} from '@ts-rest/core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

import { paginationSchema } from '../schemas'

const c = initContract()

const mediaRouter = c.router({
  getMedia: {
    method: 'GET',
    path: '/media',
    query: z.object({
      page: z.coerce.number(),
      search: z.string(),
      orderBy: z.string(),
      orderDirection: z.enum(['asc', 'desc']),
      filter: z
        .object({
          fileType: z.array(z.nativeEnum(FileType)).optional(),
          accessLevel: z.array(accessLevelSchema).optional(),
          orderByPriority: z.array(z.coerce.number()).optional(),
          addedByMe: toBoolean,
          companyId: z.coerce.number().optional().nullable(),
          companyUnitId: z.coerce.number().optional().nullable(),
          mediaType: z.array(mediaTypeSchema).optional(),
        })
        .optional(),
    }),
    responses: {
      200: z.object({
        ...paginationSchema.shape,
        media: z.array(mediaSchema),
      }),
    },
  },
  getOneMedia: {
    method: 'GET',
    path: '/media/:id/get',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    query: z.object({
      version: z.coerce.number().optional(),
    }),
    responses: {
      200: getMediaSchema,
      404: z.object({ message: z.string() }),
    },
  },
  getManyMedia: {
    method: 'GET',
    path: '/media/get-many',
    query: z.object({ media: z.array(z.coerce.number()) }),
    responses: {
      200: z.array(mediaSchema),
      404: z.object({ message: z.string() }),
    },
  },
  addMedia: {
    method: 'POST',
    path: '/media',
    body: z.array(
      z.object({
        ...createInsertSchema(media).pick({
          name: true,
          description: true,
          accessLevel: true,
          companyId: true,
          companyUnitId: true,
          mediaType: true,
        }).shape,
        ...createInsertSchema(mediaVersions).pick({
          originalFilename: true,
          mimeType: true,
          fileSize: true,
          key: true,
        }).shape,
      })
    ),
    responses: {
      200: z.object({
        data: z.array(z.number()),
        message: z.string(),
      }),
    },
  },
  editMedia: {
    method: 'POST',
    path: '/media/:id/edit',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: createInsertSchema(media).pick({
      name: true,
      description: true,
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  deleteMedia: {
    method: 'DELETE',
    path: '/media/:id/delete',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({}),
    responses: {
      200: z.object({ id: z.number(), message: z.string() }),
    },
  },
  deleteBatchMedia: {
    method: 'DELETE',
    path: '/media',
    body: z.array(z.number()),
    responses: {
      200: z.object({ message: z.string() }),
    },
  },
  getSignedUrl: {
    method: 'GET',
    path: '/media/signed-url',
    query: z.object({
      fileName: z.string(),
      accessLevel: accessLevelSchema,
      contentType: z.string(),
    }),
    responses: {
      200: z.object({
        key: z.string(),
        signedUrl: z.string(),
        expiresAt: z.string(),
        contentDisposition: z.string(),
      }),
    },
  },
  getMediaVersions: {
    method: 'GET',
    path: '/media/:id/get-versions',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: z.array(
        z.object({
          ...createSelectSchema(mediaVersions).pick({
            id: true,
            originalFilename: true,
            mimeType: true,
            fileSize: true,
            dateCreated: true,
            isCurrent: true,
          }).shape,
          uploadedBy: z.object({
            ...createSelectSchema(users).pick({
              id: true,
              firstName: true,
              lastName: true,
            }).shape,
            avatar: z.string().optional(),
          }),
        })
      ),
    },
  },
  addNewVersion: {
    method: 'POST',
    path: '/media/:id/add-version',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.any(),
    responses: {
      200: z.object({
        data: z.number(),
        message: z.string(),
      }),
    },
  },
})

export type AddMediaRequest = ClientInferRequest<typeof mediaRouter.addMedia>

export type GetOneMediaResponse = ClientInferResponseBody<
  typeof mediaRouter.getOneMedia
>

export type GetMediaResponse = ClientInferResponseBody<
  typeof mediaRouter.getMedia
>

export type EditMediaRequest = ClientInferRequest<typeof mediaRouter.editMedia>

export type AddNewVersionRequest = ClientInferRequest<
  typeof mediaRouter.addNewVersion
>

export type DeleteBatchMediaRequest = ClientInferRequest<
  typeof mediaRouter.deleteBatchMedia
>

export default mediaRouter
