import { relations } from 'drizzle-orm'
import {
  doublePrecision,
  integer,
  pgTable,
  serial,
  varchar,
} from 'drizzle-orm/pg-core'

import { companyUnits, machineInstances } from './index'

export const halls = pgTable('halls', {
  id: serial('id').primaryKey(),
  name: varchar('name', { length: 256 }).notNull(),
  number: varchar('number', { length: 256 }),
  lat: doublePrecision('lat'),
  lng: doublePrecision('lng'),
  companyUnitId: integer('company_unit_id').references(() => companyUnits.id),
})

export const hallsRelations = relations(halls, ({ one, many }) => ({
  companyUnit: one(companyUnits, {
    fields: [halls.companyUnitId],
    references: [companyUnits.id],
  }),
  machineInstances: many(machineInstances),
  hallSeparators: many(hallSeparators),
}))

export type Hall = typeof halls.$inferSelect
export type NewHall = typeof halls.$inferInsert

export const hallSeparators = pgTable('hall_separators', {
  id: serial('id').primaryKey(),
  name: varchar('name', { length: 256 }),
  hallId: integer('hall_id')
    .references(() => halls.id)
    .notNull(),
})

export const hallSeparatorsRelations = relations(
  hallSeparators,
  ({ one, many }) => ({
    hall: one(halls, {
      fields: [hallSeparators.hallId],
      references: [halls.id],
    }),
    machineInstances: many(machineInstances),
  })
)

export type HallSeparator = typeof hallSeparators.$inferSelect
export type NewHallSeparator = typeof hallSeparators.$inferInsert
