import FormHelperTooltip from '@app/components/base/form/FormHelperTooltip'
import { FormControlLabel } from '@mui/material'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'

type Props<T extends FieldValues> = {
  controller: UseControllerProps<T>
  field: CheckboxProps
  label: string
}

const FormCheckBox = <T extends FieldValues>({
  controller,
  field,
  label,
}: Props<T>) => {
  return (
    <Controller
      {...controller}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
      }) => {
        const helperText = !controller.disabled ? error?.message ?? ' ' : ' '

        return (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  value={!!value}
                  checked={!!value}
                  {...field}
                  onBlur={onBlur}
                  onChange={(event) => {
                    onChange(event.target.checked)
                  }}
                />
              }
              label={label}
            />
            <FormHelperTooltip helperText={helperText} error={!!error} />
          </>
        )
      }}
    />
  )
}

export default FormCheckBox
