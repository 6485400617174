import { createUniqueKey } from '@aeqoom/utils'
import { useGridDefinitions } from '@app/components/base/dialogs/_detailViewDialogs/MachineInstanceDialog/Content/_tabs/ServiceLog/cols'
import TaskDetailDialogContent from '@app/components/base/dialogs/_detailViewDialogs/TaskDetailDialogContent'
import CreateTaskDialogContent from '@app/components/base/dialogs/createForms/CreateTaskDialogContent'
import { useGetServiceLogForMachineInstance } from '@app/queries/useServiceLog'
import { useDialogStore } from '@app/stores/useDialogStore'
import { AddOutlined, Search as SearchIcon } from '@mui/icons-material'
import TaskIcon from '@mui/icons-material/Task'
import {
  Alert,
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { isNotNil } from 'ramda'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export type Props = {
  machineInstanceId: number
  companyId: number | null
  companyUnitId: number | null
}

export function ServiceLog({
  machineInstanceId,
  companyId,
  companyUnitId,
}: Props) {
  const { t } = useTranslation(['web', 'common'])
  const { openDialog } = useDialogStore()

  const [currentTabIndex, setCurrentTabIndex] = useState(1)
  const [searchValue, setSearchValue] = useState('')

  const indexToType = ['history', 'planned'] as const

  const { data, isPending, isError } = useGetServiceLogForMachineInstance({
    machineInstanceId,
    type: indexToType[currentTabIndex],
  })

  const filteredTasks = isPending
    ? []
    : data?.tasks.filter((task) =>
        [task.name, task.user?.firstName, task.user?.lastName]
          .filter(isNotNil)
          .map((field) => field.toLowerCase())
          .some((field) => field.includes(searchValue.toLowerCase()))
      ) ?? []

  const colsDefinition = useGridDefinitions()

  const renderContent = () => {
    if (isPending)
      return (
        <Stack alignItems="center" justifyContent="center" height="50%">
          <CircularProgress size={80} />
        </Stack>
      )

    if (isError)
      return (
        <Stack marginTop={2}>
          <Alert severity="error" variant="outlined">
            {t('web:machineInstances.detail.tabs.serviceLog.errorLoadingData')}
          </Alert>
        </Stack>
      )

    return (
      <DataGrid
        sx={{
          marginTop: 2,
          height: 'auto', // to disable scrollbar for the grid
        }}
        autoHeight={filteredTasks.length <= 10}
        hideFooterSelectedRowCount={true}
        rows={filteredTasks}
        columns={colsDefinition}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10]}
        rowSelection={false}
        onRowClick={({ row }) => {
          openDialog(
            <TaskDetailDialogContent selectedId={row.id} />,
            `${t('web:task.task')} #${row.id} ${row.name}`,
            {
              icon: TaskIcon,
              uniqueKey: createUniqueKey(['task', row.id]),
            }
          )
        }}
      />
    )
  }

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <TextField
          label={t('common:search')}
          placeholder={t('common:search')}
          sx={{
            width: 300,
          }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          size="medium"
          onClick={() =>
            openDialog(
              <CreateTaskDialogContent
                initValues={{
                  companyId: companyId ?? undefined,
                  companyUnitId: companyUnitId ?? undefined,
                  machineInstanceId,
                }}
              />,
              'Create task'
            )
          }
          variant="contained"
          startIcon={<AddOutlined />}
        >
          {t('common:add')}
        </Button>
      </Stack>

      <Tabs
        value={currentTabIndex}
        onChange={(_, index) => {
          setCurrentTabIndex(index)
        }}
        sx={{
          marginTop: 2,
        }}
      >
        <Tab
          label={t(
            'web:machineInstances.detail.tabs.serviceLog.historyLog.label'
          )}
        />
        <Tab
          label={t(
            'web:machineInstances.detail.tabs.serviceLog.plannedTasks.label'
          )}
        />
      </Tabs>

      {renderContent()}
    </>
  )
}
