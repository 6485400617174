import { PERMISSIONS } from '@aeqoom/permissions'
import { createUniqueKey } from '@aeqoom/utils'
import CustomDataGrid from '@app/components/base/datagrid/CustomDataGrid'
import MachineInstanceDialog from '@app/components/base/dialogs/_detailViewDialogs/MachineInstanceDialog'
import CreateMachineInstanceDialogContent from '@app/components/base/dialogs/createForms/CreateMachineInstanceDialogContent'
import DeleteConfirmDialog from '@app/components/base/dialogs/DeleteConfirmDialog'
import { useDeleteAction } from '@app/hooks/useDeleteAction'
import {
  useDeleteMachineInstance,
  useGetMachineInstances,
} from '@app/queries/useMachineInstances'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import useUserStore from '@app/src/stores/useUserStore'
import getDeleteActionCol from '@app/src/utils/getDeleteActionCol'
import { PrecisionManufacturingOutlined } from '@mui/icons-material'

import machineInstancesDataCol from './col'

const MachineInstances = () => {
  const { data, isPending } = useGetMachineInstances()
  const mutation = useDeleteMachineInstance()

  const { hasPermission } = useUserStore()

  const { openDialog } = useDialogStore()

  const {
    handleOpen,
    handleClose,
    handleConfirmDelete,
    isOpen,
    setSelectedItemId,
  } = useDeleteAction(mutation)

  const deleteActionCol = getDeleteActionCol({ handleOpen, setSelectedItemId })

  const columns = [
    ...machineInstancesDataCol,
    ...(hasPermission(PERMISSIONS.MACHINE_INSTANCE.DELETE)
      ? [deleteActionCol]
      : []),
  ]

  return (
    <>
      <DeleteConfirmDialog
        isPending={mutation.isPending}
        onClose={handleClose}
        onConfirm={handleConfirmDelete}
        isOpen={isOpen}
      />
      <CustomDataGrid
        pageLabel="Machine instances"
        rows={data || []}
        loading={isPending}
        columns={columns}
        disableCreateNew={!hasPermission(PERMISSIONS.MACHINE_INSTANCE.CREATE)}
        onRowClick={({ row }) => {
          openDialog(<MachineInstanceDialog id={row.id} />, row.name, {
            uniqueKey: createUniqueKey(['machineInstance', row.id]),
          })
        }}
        addClickOptions={{
          Dialog: CreateMachineInstanceDialogContent,
          title: 'Create machine instance',
          options: {
            icon: PrecisionManufacturingOutlined,
            isUnique: false,
          },
        }}
      />
    </>
  )
}

export default MachineInstances
