import { toDoListItems, toDoLists } from '@aeqoom/db'
import { z } from '@aeqoom/zod'
import { ClientInferRequest, initContract } from '@ts-rest/core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

import { globalFilter } from '../schemas'

const c = initContract()

const toDoListsRouter = c.router({
  getToDoLists: {
    method: 'GET',
    path: '/to-do-lists',
    query: globalFilter,
    responses: {
      200: z.array(createSelectSchema(toDoLists)),
    },
  },
  getToDoList: {
    method: 'GET',
    path: '/to-do-lists/:id/get',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: createSelectSchema(toDoLists),
      404: z.object({ message: z.string() }),
    },
  },
  addToDoList: {
    method: 'POST',
    path: '/to-do-lists',
    body: z.object({
      ...createInsertSchema(toDoLists).shape,
      companyId: z.coerce.number().optional().nullable(),
      toDoListItems: z.array(
        z.object({
          ...createInsertSchema(toDoListItems).shape,
          media: z.array(z.number()),
        })
      ),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  deleteToDoList: {
    method: 'DELETE',
    path: '/to-do-lists/:id/delete',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({}),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  editToDoList: {
    method: 'POST',
    path: '/to-do-lists/:id/edit',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({
      ...createInsertSchema(toDoLists).shape,
      toDoListItems: z
        .array(
          z.object({
            ...createInsertSchema(toDoListItems).shape,
            media: z.array(z.number()),
          })
        )
        .optional(),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
})

export type AddToDoListRequest = ClientInferRequest<
  typeof toDoListsRouter.addToDoList
>

export type EditToDoListRequest = ClientInferRequest<
  typeof toDoListsRouter.editToDoList
>

export type DeleteToDoListRequest = ClientInferRequest<
  typeof toDoListsRouter.deleteToDoList
>

export default toDoListsRouter
