import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  useTheme,
} from '@mui/material'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'

import FormHelperTooltip from '../FormHelperTooltip'

type Props<T extends FieldValues> = {
  controller: UseControllerProps<T>
  field?: ToggleButtonGroupProps
  options: { label: string; value: string }[]
  helperText?: string
}

const FormButtonGroup = <T extends FieldValues>({
  controller,
  field,
  options,
  helperText = ' ',
}: Props<T>) => {
  const theme = useTheme()

  return (
    <Controller
      {...controller}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Stack>
            <ToggleButtonGroup
              color={error ? 'error' : 'primary'}
              value={value}
              exclusive
              onChange={onChange}
              sx={{
                height: '100%',
                '& .MuiToggleButtonGroup-grouped': {
                  borderColor: error
                    ? theme.palette.error.main
                    : theme.palette.grey[300],
                },
              }}
              {...field}
            >
              {options?.map(({ label, value }) => (
                <ToggleButton
                  value={value}
                  key={value}
                  sx={{ borderColor: 'error' }}
                >
                  {label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <FormHelperTooltip
              helperText={error?.message ?? helperText ?? ' '}
              error={!!error}
            />
          </Stack>
        )
      }}
    />
  )
}

export default FormButtonGroup
