import { styled } from "@mui/material/styles";

export const SupportHeader = styled('h1')`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 3em;
`

export const SupportContent = styled('p')`
  text-wrap: balance;

  & a {
    color: ${({theme}) => theme.palette.primary.main};
    font-weight: bold;
  }
`
