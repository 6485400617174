import {
  CompanyType,
  CounterState,
  TaskPriority,
  TaskStatus,
  TaskType,
} from '@aeqoom/db'
import { UserGroups } from '@aeqoom/shared'

export const TASK_STATUS = {
  [TaskStatus.ACKNOWLEDGED]: 'Acknowledged',
  [TaskStatus.ARCHIVED]: 'Archived',
  [TaskStatus.BLOCKED]: 'Blocked',
  [TaskStatus.DONE]: 'Done',
  [TaskStatus.PLANNED]: 'Planned',
  [TaskStatus.IN_PROGRESS]: 'In progress',
  [TaskStatus.WAITING_ON_WP]: 'Waiting on working protocol',
  [TaskStatus.NEW]: 'New',
  [TaskStatus.RECURRED]: 'Recurred',
  [TaskStatus.TO_BE_CHECKED_BY_ADMIN]: 'To be checked by admin',
  [TaskStatus.TO_BE_CHECKED_BY_CLIENT]: 'To be checked by client',
}

export const TASK_PRIORITY = {
  [TaskPriority.HIGH]: 'High',
  [TaskPriority.MEDIUM]: 'Medium',
  [TaskPriority.LOW]: 'Low',
}

export const TASK_TYPE = {
  [TaskType.GENERAL]: 'General',
  [TaskType.INCIDENT]: 'Incident',
  [TaskType.MAINTENANCE]: 'Maintenance',
  [TaskType.EXAMINATION]: 'Examination',
  [TaskType.FULL_SERVICE]: 'Full service',
  [TaskType.INSTALLATION]: 'Installation',
}

export const USER_GROUPS = {
  [UserGroups.CLIENT_SUPER_ADMIN]: 'Superadmin of a client',
  [UserGroups.CLIENT_UNIT_ADMIN]: 'Admin of a client',
  [UserGroups.PROVIDER_ADMIN]: 'Admin of a provider',
  [UserGroups.PROVIDER_SUPER_ADMIN]: 'Super admin of a provider',
  [UserGroups.TECHNICIAN]: 'Technician',
}

export const COMPANY_TYPE = {
  [CompanyType.CUSTOMER]: 'Customer',
  [CompanyType.OWN_COMPANY]: 'Own company',
  [CompanyType.PARTS_SUPPLIER]: 'Parts supplier',
  [CompanyType.PRODUCER]: 'Producer',
  [CompanyType.REVISION]: 'Revision',
  [CompanyType.SERVICE_PARTNER]: 'Service partner',
  [CompanyType.SUPPLIER]: 'Supplier',
}

export const COUNTER_STATE = {
  [CounterState.CUTS]: 'Cuts',
  [CounterState.ENVELOPES]: 'Envelopes',
  [CounterState.LISTS]: 'Lists',
}
