import { GetTaskResponseBody } from '@aeqoom/contracts'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { Description, FileOpenOutlined } from '@mui/icons-material'
import { Button, Divider, Stack } from '@mui/material'
import { includes } from 'ramda'
import { useTranslation } from 'react-i18next'

import WorkProtocolDialogContent from '../../../WorkProtocolDialogContent'
import ActionPanel from '../ActionPanel'
import { IdentifierText, TitleText } from './styled'

const IS_WORK_DONE = [
  'waiting_on_wp',
  'done',
  'to_be_checked_by_admin',
  'to_be_checked_by_client',
] as const

type Props = {
  task: GetTaskResponseBody
}

const TaskHeader = ({ task }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'backlog.taskDetail.header',
  })

  const { openDialog } = useDialogStore()

  return (
    <Stack direction="column" spacing={4}>
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Stack direction="row" gap={2}>
          <IdentifierText>{t('id', { id: task.id })}</IdentifierText>
          <TitleText>{task.name}</TitleText>
        </Stack>
        <Button
          startIcon={<FileOpenOutlined />}
          variant={
            includes(task.status, IS_WORK_DONE) ? 'contained' : 'outlined'
          }
          onClick={() =>
            openDialog(
              <WorkProtocolDialogContent taskId={task.id} />,
              `Working protocol - #${task.id} ${task.name}`,
              {
                icon: Description,
              }
            )
          }
        >
          {t('workingProtocol')}
        </Button>
      </Stack>
      {!includes(task.status, IS_WORK_DONE) && (
        <Stack direction="row" justifyContent="space-between">
          <ActionPanel task={task} />
        </Stack>
      )}
      <Divider orientation="horizontal" flexItem />
    </Stack>
  )
}

export default TaskHeader
