import { common_en, web_en, zod_en } from '@aeqoom/translations'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

export const defaultNS = 'web'
export const resources = {
  en: {
    common: common_en,
    web: web_en,
    zod: zod_en,
  },
} as const

i18n.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  lng: 'en',
  ns: ['common', 'web', 'zod'],
  defaultNS,
  resources,
})

export default i18n
