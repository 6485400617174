import { Box, CircularProgress, Typography, useTheme } from '@mui/material'

type Props = { value: number; count: number; visible: boolean }

const CircularCountdown = ({ value, count, visible }: Props) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        visibility: visible ? 'visible' : 'hidden',
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: theme.palette.grey[300],
        }}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{ position: 'absolute', left: 0 }}
        value={value}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="body2Bold"
          component="div"
          sx={{ color: 'text.secondary' }}
        >
          {count}
        </Typography>
      </Box>
    </Box>
  )
}

export default CircularCountdown
