import { workProtocols } from '@aeqoom/db'
import { z } from '@aeqoom/zod'
import { ClientInferRequest, initContract } from '@ts-rest/core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

const c = initContract()

const workProtocolsRouter = c.router({
  addWorkProtocol: {
    method: 'POST',
    path: '/work-protocols',
    body: createInsertSchema(workProtocols, {
      userId: z.coerce.number(),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  getWorkProtocol: {
    method: 'GET',
    path: '/work-protocols/:id/get',
    pathParams: z.object({ id: z.coerce.number() }),
    responses: {
      200: z.object({
        ...createSelectSchema(workProtocols).shape,
      }),
      404: z.object({ message: z.string() }),
    },
  },
})

export type AddWorkProtocolRequest = ClientInferRequest<
  typeof workProtocolsRouter.addWorkProtocol
>

export type GetWorkProtocolRequest = ClientInferRequest<
  typeof workProtocolsRouter.getWorkProtocol
>

export default workProtocolsRouter
