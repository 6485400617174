import { z } from '@aeqoom/zod'
import { initContract } from '@ts-rest/core'

import { serviceLogSchema, serviceLogType } from '../schemas'

const c = initContract()

const serviceLogRouter = c.router({
  getServiceLog: {
    method: 'GET',
    path: '/service-log/:machineInstanceId/get',
    pathParams: z.object({
      machineInstanceId: z.coerce.number(),
    }),
    query: z.object({
      type: serviceLogType,
    }),
    responses: {
      200: serviceLogSchema,
      404: z.object({ message: z.string() }),
    },
  },
})

export default serviceLogRouter
