import { AddWorkProtocolRequest } from '@aeqoom/contracts'
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { client } from './client'
import parseIncoming from './parseIncoming'

const keys = {
  getWorkProtocol: ['work-protocol'],
  getWorkProtocols: ['work-protocols'],
}

const invalidateWorkProtocolQueries = (queryClient: QueryClient) => {
  const allKeys = Object.values(keys).flat()
  queryClient.invalidateQueries({
    predicate: (query) => allKeys.includes(query.queryKey[0] + ''),
  })
}

export const useCreateWorkProtocol = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateWorkProtocolQueries(queryClient)
    },
    mutationFn: (body: AddWorkProtocolRequest['body']) =>
      client.workProtocols.addWorkProtocol({ body }).then(parseIncoming),
  })
}

export const useGetWorkProtocol = ({
  id,
  enabled = !!id,
  staleTime,
}: {
  id?: number
  enabled?: boolean
  staleTime?: number
}) => {
  return useQuery({
    queryKey: [keys.getWorkProtocol, id],
    queryFn: () =>
      client.tasks
        .getWorkProtocol({ params: { taskId: id! } })
        .then(parseIncoming),
    retry: false,
    staleTime,
    enabled,
  })
}
