import { companyTypeSchema } from '@aeqoom/shared'
import { z } from '@aeqoom/zod'

export const addCompanyForm = z.object({
  avatar: z.number().nullish(),
  name: z.string().min(1),
  registrationNumber: z.string().nullish(),
  companyType: companyTypeSchema,
  taxId: z.string().nullish(),
  contacts: z.array(
    z.object({
      contactId: z.number().positive(),
      main: z.boolean().nullish(),
    })
  ),
  street: z.string().nullish(),
  houseNumber: z.string().nullish(),
  zipCode: z.string().nullish(),
  city: z.string().nullish(),
  country: z.string().nullish(),
  currency: z.string().nullish(),
})

export type AddCompanyForm = z.infer<typeof addCompanyForm>
