import { GridMapper, LabeledValue } from '@aeqoom/components-web'
import { ContactBody } from '@aeqoom/contracts'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { FIELD_SHARED_STYLES } from '../../../../constants'

type Props = {
  data: ContactBody
}

const GRID_MAP = [6, 5, 6, 5]

const ContactInfo = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'contact.detail.tabs.contactDetail.content',
  })

  const content = [
    <LabeledValue
      label={t('firstName')}
      value={data.firstName}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('lastName')}
      value={data.lastName}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('emailAddress')}
      value={data.emailAddress}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('phoneNumber')}
      value={data.phoneNumber}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('position')}
      value={data.position}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
  ]

  return (
    <Grid container justifyContent="space-between">
      <Grid
        item
        xs={6}
        sx={{
          overflow: 'hidden',
        }}
      >
        <GridMapper template={GRID_MAP} paddingBottom={8}>
          {content}
        </GridMapper>
      </Grid>
    </Grid>
  )
}

export default ContactInfo
