import { AddContactForm, addContactForm } from '@aeqoom/forms'
import { PERMISSIONS } from '@aeqoom/permissions'
import { isObjectEmpty } from '@aeqoom/utils'
import { FormTextField } from '@app/components/base/form/FormTextField'
import CheckPermissions from '@app/components/CheckPermissions'
import Section from '@app/components/Section'
import {
  useCreateContact,
  useEditContact,
  useGetContact,
} from '@app/queries/useContacts'
import { useDialogStore } from '@app/stores/useDialogStore'
import { zodResolver } from '@hookform/resolvers/zod'
import { Email, Phone } from '@mui/icons-material'
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  Stack,
} from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import ActionControlButtons from '../ActionControlButtons'
import DialogDetailError from '../DialogDetailError'

type Props = {
  isEditModal?: boolean
  id?: number
  callback?: (id: number) => void
}

const CreateContactDialogContent = ({
  isEditModal = false,
  id,
  callback,
}: Props) => {
  const { closeDialog, setShouldAskBeforeClose } = useDialogStore()

  const { t } = useTranslation()

  const {
    handleSubmit,
    control,
    reset,
    formState: { dirtyFields },
  } = useForm<AddContactForm>({ resolver: zodResolver(addContactForm) })

  const { data, isLoading, isError, refetch } = useGetContact({
    id,
  })

  const createContactMutation = useCreateContact()
  const editContactMutation = useEditContact()

  useEffect(() => {
    setShouldAskBeforeClose(isObjectEmpty(dirtyFields))
  }, [isObjectEmpty(dirtyFields)])

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data, reset])

  const onCreateSubmit = async (data: AddContactForm) => {
    createContactMutation.mutate(
      { body: data },
      {
        onSuccess: ({ message, id }) => {
          toast.success(message)
          callback ? callback(id) : closeDialog()
          reset()
        },
      }
    )
  }

  const onEditSubmit = async (data: AddContactForm) => {
    editContactMutation.mutate(
      { body: data, params: { id: id! } },
      {
        onSuccess: (data) => {
          if (callback) callback(data.id)
          toast.success(data.message)
        },
      }
    )
  }

  if (isLoading)
    return (
      <Stack alignItems="center">
        <CircularProgress size={80} />
      </Stack>
    )

  if (isError) return <DialogDetailError onRetry={() => refetch()} />

  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={8}>
          <Section title="Basic information">
            <Grid item xs={12} lg={6}>
              <FormTextField
                controller={{ control, name: 'firstName' }}
                field={{ label: t('contacts.firstName') }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormTextField
                controller={{ control, name: 'lastName' }}
                field={{ label: t('contacts.lastName') }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                controller={{ control, name: 'position' }}
                field={{ label: t('contacts.position') }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                controller={{ control, name: 'phoneNumber' }}
                field={{
                  label: t('contacts.phoneNumber'),
                  type: 'tel',
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                controller={{ control, name: 'emailAddress' }}
                field={{
                  type: 'email',
                  label: t('contacts.emailAddress'),
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Grid>
          </Section>
        </Stack>
      </DialogContent>
      <CheckPermissions permissions={[PERMISSIONS.COMPANY.UPDATE]}>
        <DialogActions>
          <ActionControlButtons
            isEditing={isEditModal}
            onSave={handleSubmit(onEditSubmit)}
            onCreate={handleSubmit(onCreateSubmit)}
            disabled={editContactMutation.isPending}
          />
        </DialogActions>
      </CheckPermissions>
    </>
  )
}

export default CreateContactDialogContent
