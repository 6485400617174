import { FormControl, InputLabel, Select, SelectProps } from '@mui/material'
import { ReactElement } from 'react'
import {
  Control,
  FieldValues,
  Path,
  useController,
} from 'react-hook-form'

import FormHelperTooltip from '../FormHelperTooltip'

type Props<T extends FieldValues> = {
  name: Path<T>
  control: Control<T>
  select?: Omit<SelectProps, 'variant'>
  label: string
  helperText?: string
  options: ReactElement[]
}

export const FormSelect = <T extends FieldValues>({
  name,
  control,
  select,
  label,
  helperText = ' ',
  options,
}: Props<T>) => {
  const { field, fieldState } = useController({ name, control })

  const { onChange, value } = field
  const { error } = fieldState

  return (
    <FormControl fullWidth={true} error={!!error}>
      <InputLabel required={select?.required}>{label}</InputLabel>
      <Select label={label} {...select} onChange={onChange} value={value || ''}>
        {options}
      </Select>
      <FormHelperTooltip
        helperText={error?.message ?? helperText}
        error={!!error}
      />
    </FormControl>
  )
}
