import {
  AddToDoListItemRequest,
  CheckToDoListItemRequest,
  DeleteToDoListItemRequest,
  EditToDoListItemRequest,
} from '@aeqoom/contracts'
import { ToDoListItem } from '@aeqoom/db'
import { TASK_KEYS, TO_DO_LIST_ITEMS_KEYS } from '@aeqoom/types'
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

import { client } from './client'
import parseIncoming from './parseIncoming'

const invalidateToDoListItemQueries = (queryClient: QueryClient) => {
  const allKeys: string[] = Object.values(TO_DO_LIST_ITEMS_KEYS).flat()

  queryClient.invalidateQueries({
    predicate: (query) => allKeys.includes(query.queryKey[0] + ''),
  })
}

export const useGetToDoListItems = ({
  options,
}: { options?: UseQueryOptions<ToDoListItem[], Error> } = {}) => {
  return useQuery<ToDoListItem[]>({
    ...options,
    queryKey: [TO_DO_LIST_ITEMS_KEYS.getToDoListItems],
    queryFn: () => client.toDoListItems.getToDoListItems().then(parseIncoming),
  })
}

export const useGetToDoListItem = ({
  id,
  enabled = !!id,
}: {
  id?: number
  enabled?: boolean
}) => {
  return useQuery({
    enabled,
    queryKey: [TO_DO_LIST_ITEMS_KEYS.getToDoListItem, id],
    queryFn: () =>
      client.toDoListItems
        .getToDoListItem({ params: { id: id! } })
        .then(parseIncoming),
  })
}

export const useCreateToDoListItem = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => invalidateToDoListItemQueries(queryClient),
    mutationFn: (body: AddToDoListItemRequest['body']) =>
      client.toDoListItems.addToDoListItem({ body }).then(parseIncoming),
  })
}

export const useEditToDoListItem = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateToDoListItemQueries(queryClient)
    },
    mutationFn: ({
      body,
      params,
    }: {
      body: EditToDoListItemRequest['body']
      params: EditToDoListItemRequest['params']
    }) =>
      client.toDoListItems
        .editToDoListItem({ body, params })
        .then(parseIncoming),
  })
}

export const useDeleteToDoListItem = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: DeleteToDoListItemRequest['params']) =>
      client.toDoListItems.deleteToDoListItem({ params }).then(parseIncoming),
    onSuccess: () => invalidateToDoListItemQueries(queryClient),
  })
}

export const useCheckToDoListItem = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TO_DO_LIST_ITEMS_KEYS.getToDoListItems],
      })
      queryClient.invalidateQueries({
        queryKey: [TO_DO_LIST_ITEMS_KEYS.getToDoListItem],
      })
      queryClient.invalidateQueries({
        queryKey: [TASK_KEYS.getTask],
      })
      queryClient.invalidateQueries({
        queryKey: [TASK_KEYS.getToDoList],
      })
    },
    mutationFn: ({
      id,
      value,
    }: CheckToDoListItemRequest['params'] & CheckToDoListItemRequest['body']) =>
      client.toDoListItems
        .checkToDoListItem({ params: { id }, body: { value } })
        .then(parseIncoming),
  })
}
