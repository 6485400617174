import { useThemeColors } from '@aeqoom/hooks'
import { useDropzoneValidation } from '@aeqoom/hooks'
import { FileType, MIME_TYPES } from '@aeqoom/shared'
import { UploadFile } from '@mui/icons-material'
import { alpha, Box, Button, Stack, Typography } from '@mui/material'
import { filesize } from 'filesize'
import { useDropzone } from 'react-dropzone'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import DropzonePreview from '../DropzonePreview'

type Props = {
  value?: File[]
  onChange: (data: File[]) => void
  disabled?: boolean
  maxSize?: number
  multiple?: boolean
  allowedFileType?: FileType[]
  fieldError?: FieldError
}

const Dropzone = ({
  value,
  onChange,
  disabled = false,
  maxSize = 50000000,
  multiple = false,
  allowedFileType = [],
  fieldError,
}: Props) => {
  const { t } = useTranslation('web')

  const accept = allowedFileType.reduce<Record<string, string[]>>(
    (acc, fileType) => {
      MIME_TYPES[fileType].forEach((mimeType) => (acc[mimeType] = []))
      return acc
    },
    {}
  )

  const { fileRejections, getRootProps, getInputProps, open, isDragActive } =
    useDropzone({
      onDropAccepted: (file, e) => {
        e?.stopPropagation();
        onChange(file);
      },
      maxSize,
      noClick: true,
      noKeyboard: true,
      multiple,
      accept,
      disabled,
    })

  const isError = fileRejections.length > 0 || fieldError

  const { primary } = useThemeColors()

  const { getValidationMessage } = useDropzoneValidation()

  return (
    <Stack sx={{ height: '100%', width: '100%' }}>
      <Button
        {...getRootProps()}
        onClick={open}
        color={isError ? 'error' : 'primary'}
        variant="outlined"
        disabled={disabled}
        sx={{
          py: 4,
          width: '100%',
          height: '100%',
          borderStyle: 'dashed',
          backgroundColor: isDragActive ? alpha(primary.main, 0.1) : 'default',
          borderWidth: 2,
          '&:hover': {
            borderStyle: 'dashed',
            borderWidth: 2,
          },
        }}
      >
        <Box component="input" {...getInputProps()} />
        <Stack spacing={2} alignItems="center">
          <Stack alignItems="center" spacing={2}>
            <UploadFile fontSize="large" />
            <Stack sx={{ mb: 2 }}>
              <Typography textAlign="center" gutterBottom>
                {t('media.chooseFile')}
              </Typography>
              <Typography
                variant="body2"
                textAlign="center"
                gutterBottom
                textTransform="none"
              >
                {t('media.maxFileSize', { number: filesize(maxSize) })}
              </Typography>
            </Stack>
            {fileRejections.length > 0 && (
              <Stack>
                {fileRejections.map(({ errors }) => (
                  <Typography variant="body2">
                    {errors.map(({ code }) =>
                      getValidationMessage(code, { maxSize, multiple })
                    )}
                  </Typography>
                ))}
              </Stack>
            )}
          </Stack>
          {value && value.length > 0 && (
            <Stack direction="row">
              {value.map((item, index) => (
                <DropzonePreview
                  key={index}
                  value={item}
                  onDelete={() => onChange([])}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </Button>
    </Stack>
  )
}

export default Dropzone
