import { CompanyType } from '@aeqoom/shared'
import { useTranslation } from 'react-i18next'

type CompanyTypeTranslationKey =
  | 'companyType.customer'
  | 'companyType.ownCompany'
  | 'companyType.partsSupplier'
  | 'companyType.producer'
  | 'companyType.revision'
  | 'companyType.servicePartner'
  | 'companyType.supplier'

const COMPANY_TYPE_MAP: Record<CompanyType, CompanyTypeTranslationKey> = {
  customer: 'companyType.customer',
  own_company: 'companyType.ownCompany',
  parts_supplier: 'companyType.partsSupplier',
  producer: 'companyType.producer',
  revision: 'companyType.revision',
  service_partner: 'companyType.servicePartner',
  supplier: 'companyType.supplier',
}

export const useGetTranslations = () => {
  const { t } = useTranslation('common')

  const companyTypeTranslation = (companyType: CompanyType) =>
    t(COMPANY_TYPE_MAP[companyType])

  return { companyTypeTranslation }
}
