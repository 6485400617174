import { z } from '@aeqoom/zod'
import { useTranslation } from 'react-i18next'
import { makeZodI18nMap } from 'zod-i18n-map'

const updateProfileSchema = z.object({
  avatar: z.number().nullable(),
})

export const useUpdateProfile = () => {
  const { t } = useTranslation(['web', 'zod'])

  z.setErrorMap(makeZodI18nMap({ t }))

  return updateProfileSchema
}

export type UpdateProfile = z.infer<ReturnType<typeof useUpdateProfile>>

const updatePasswordSchema = z.object({
  oldPassword: z.string(),
  newPassword: z.string(),
  confirmPassword: z.string(),
})

export const useUpdateUserPassword = () => {
  const { t } = useTranslation(['web', 'zod', 'common'])

  z.setErrorMap(makeZodI18nMap({ t }))

  return updatePasswordSchema.refine(
    (data) => data.newPassword === data.confirmPassword,
    {
      message: t('zod:profile.passwordDoesNotMatch'),
      path: ['confirmPassword'],
    }
  )
}

export type UpdateUserPassword = z.infer<
  ReturnType<typeof useUpdateUserPassword>
>

const userBaseSchema = z.object({
  emailAddress: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  avatar: z.number().nullish(),
})

const createUserTSchema = z.object({
  group: z.literal('Technician'),
  companyId: z.number().nullish(),
  companyUnitId: z.number().nullish(),
})

const createUserPSASchema = z.object({
  group: z.literal('ProviderSuperAdmin'),
  companyId: z.number().nullish(),
  companyUnitId: z.number().nullish(),
})

const createUserPASchema = z.object({
  group: z.literal('ProviderAdmin'),
  companyId: z.number().nullish(),
  companyUnitId: z.number().nullish(),
})

const createUserCSASchema = z.object({
  group: z.literal('ClientSuperAdmin'),
  companyId: z.number(),
  companyUnitId: z.number().nullish(),
})

const createUserCUASchema = z.object({
  group: z.literal('ClientUnitAdmin'),
  companyId: z.number(),
  companyUnitId: z.number(),
})

export const useCreateUserSchema = () => {
  const { t } = useTranslation(['web', 'zod', 'common'])

  z.setErrorMap(makeZodI18nMap({ t }))

  return z
    .discriminatedUnion('group', [
      createUserTSchema,
      createUserPSASchema,
      createUserPASchema,
      createUserCSASchema,
      createUserCUASchema,
    ])
    .and(userBaseSchema)
}

export type CreateUser = z.infer<ReturnType<typeof useCreateUserSchema>>
