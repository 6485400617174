/**
 * Colors from design system in Figma, don't add custom colors.
 * https://www.figma.com/file/jOjqJvrmswuwuBBWsFBd8k/Aeqoom-2.0?type=design&node-id=4765-32145&mode=dev
 */
export const PRIMARY = '#01A544'
export const PRIMARY_LIGHT = '#12BB57'
export const PRIMARY_DARK = '#018A39'
export const SECONDARY = '#4A5963'
export const SECONDARY_LIGHT = '#758A9A'
export const SECONDARY_DARK = '#252D32'
export const ERROR = '#EA3641'
export const ERROR_LIGHT = '#E6686F'
export const ERROR_DARK = '#D5232E'
export const WARNING = '#F79942'
export const WARNING_LIGHT = '#FFB169'
export const WARNING_DARK = '#DA7B23'
export const INFO = '#4FB1F9'
export const INFO_LIGHT = '#40BAFF'
export const INFO_DARK = '#2199DC'
export const SUCCESS = '#61B942'
export const SUCCESS_LIGHT = '#B2EC9E'
export const SUCCESS_DARK = '#4E9336'
export const TEXT = '#001020'
export const TEXT_SECONDARY = '#37424A'
export const BACKGROUND = '#F7F6F6'

export const BLACK = '#000000'
export const WHITE = '#FFFFFF'

/**
 * @deprecated Old custom color
 */
export const ANTHRACITE = '#37424A'
/**
 * @deprecated Old custom color
 */
export const LIGHT_GRAY = '#F5F5F5'
/**
 * @deprecated Old custom color
 */
export const SILVER = '#D0D0D0'
/**
 * @deprecated Old custom color
 */
export const RED = '#E14A4A'
/**
 * @deprecated Old custom color
 */
export const PASTEL_RED = '#FFF0F0'
