import { relations } from 'drizzle-orm'
import {
  integer,
  pgTable,
  primaryKey,
  serial,
  varchar,
} from 'drizzle-orm/pg-core'

import {
  companies,
  componentTemplates,
  machineTemplates,
  mediaOnToDoListItem,
} from './index'

export const toDoLists = pgTable('to_do_lists', {
  id: serial('id').primaryKey(),
  name: varchar('name', { length: 256 }).notNull(),
  componentInstanceId: integer('component_instance_id'),
  companyId: integer('company_id').references(() => companies.id, {
    onDelete: 'cascade',
  }),
})

export const toDoListsRelations = relations(toDoLists, ({ many, one }) => ({
  machineTemplates: many(machineTemplateOnToDoLists),
  componentTemplates: many(componentTemplateOnToDoLists),
  toDoListItems: many(toDoListItems),
  company: one(companies, {
    fields: [toDoLists.companyId],
    references: [companies.id],
  }),
}))

export const machineTemplateOnToDoLists = pgTable(
  'machine_template_to_do_lists',
  {
    machineTemplateId: integer('machine_template_id').references(
      () => machineTemplates.id
    ),
    toDoListId: integer('to_do_list_id').references(() => toDoLists.id),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.machineTemplateId, t.toDoListId] }),
  })
)

export const machineTemplateOnToDoListsRelations = relations(
  machineTemplateOnToDoLists,
  ({ one }) => ({
    machineTemplate: one(machineTemplates, {
      fields: [machineTemplateOnToDoLists.machineTemplateId],
      references: [machineTemplates.id],
    }),
    toDoList: one(toDoLists, {
      fields: [machineTemplateOnToDoLists.toDoListId],
      references: [toDoLists.id],
    }),
  })
)

export const componentTemplateOnToDoLists = pgTable(
  'component_template_to_do_lists',
  {
    componentTemplateId: integer('component_template_id').references(
      () => componentTemplates.id
    ),
    toDoListId: integer('to_do_list_id').references(() => toDoLists.id),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.componentTemplateId, t.toDoListId] }),
  })
)

export const componentTemplateOnToDoListsRelations = relations(
  componentTemplateOnToDoLists,
  ({ one }) => ({
    componentTemplate: one(componentTemplates, {
      fields: [componentTemplateOnToDoLists.componentTemplateId],
      references: [componentTemplates.id],
    }),
    toDoList: one(toDoLists, {
      fields: [componentTemplateOnToDoLists.toDoListId],
      references: [toDoLists.id],
    }),
  })
)

export type ToDoList = typeof toDoLists.$inferSelect

export type ToDoListExtended = ToDoList & {
  toDoListItems: ToDoListItem[]
}

export type NewToDoList = typeof toDoLists.$inferInsert

export const toDoListItems = pgTable('to_do_list_items', {
  id: serial('id').primaryKey(),
  order: integer('order').notNull().default(0),
  name: varchar('name', { length: 256 }).notNull(),
  content: varchar('content', { length: 256 }),
  toDoListId: integer('to_do_list_id').references(() => toDoLists.id, {
    onDelete: 'cascade',
  }),
})

export const toDoListItemsRelations = relations(
  toDoListItems,
  ({ one, many }) => ({
    toDoList: one(toDoLists, {
      fields: [toDoListItems.toDoListId],
      references: [toDoLists.id],
    }),
    media: many(mediaOnToDoListItem),
  })
)

export type ToDoListItem = typeof toDoListItems.$inferSelect
export type NewToDoListItem = typeof toDoListItems.$inferInsert
