import { z } from '@aeqoom/zod'
import { initContract } from '@ts-rest/core'

const c = initContract()

const authRouter = c.router({
  authDevice: {
    method: 'POST',
    path: '/auth/device',
    body: z.object({}),
    responses: {
      200: z.object({
        token: z.string(),
      }),
    },
  },
})

export default authRouter
