import {
  AddTaskTemplateRequest,
  DeleteTaskTemplateRequest,
  EditTaskTemplateRequest,
} from '@aeqoom/contracts'
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { client } from './client'
import parseIncoming from './parseIncoming'

const invalidateTaskTemplateQueries = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({
    predicate: (query) =>
      query.queryKey[0] === keys.getTaskTemplates[0] ||
      query.queryKey[0] === keys.getTaskTemplate[0],
  })
}

const keys = {
  getTaskTemplates: ['task-templates'],
  getTaskTemplate: ['task-template'],
}

export const useGetTaskTemplates = () => {
  return useQuery({
    queryKey: keys.getTaskTemplates,
    queryFn: () => client.taskTemplates.getTaskTemplates().then(parseIncoming),
  })
}

export const useGetTaskTemplate = ({
  id,
  enabled = !!id,
}: {
  id?: number | null
  enabled?: boolean
}) => {
  return useQuery({
    enabled,
    queryKey: [...keys.getTaskTemplate, id],
    queryFn: () =>
      client.taskTemplates
        .getTaskTemplate({ params: { id: id! } })
        .then(parseIncoming),
  })
}

export const useCreateTaskTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => invalidateTaskTemplateQueries(queryClient),
    mutationFn: (body: AddTaskTemplateRequest['body']) =>
      client.taskTemplates.addTaskTemplate({ body }).then(parseIncoming),
  })
}

export const useEditTaskTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateTaskTemplateQueries(queryClient)
    },
    mutationFn: ({
      body,
      params,
    }: {
      body: EditTaskTemplateRequest['body']
      params: EditTaskTemplateRequest['params']
    }) =>
      client.taskTemplates
        .editTaskTemplate({ body, params })
        .then(parseIncoming),
  })
}

export const useDeleteTaskTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: DeleteTaskTemplateRequest['params']) =>
      client.taskTemplates.deleteTaskTemplate({ params }).then(parseIncoming),
    onSuccess: () => invalidateTaskTemplateQueries(queryClient),
  })
}
