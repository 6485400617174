import { FileType } from '@aeqoom/shared'
import { useGetOneMedia } from '@app/queries/useMedia'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { Portrait, Upload } from '@mui/icons-material'
import {
  Avatar,
  AvatarProps,
  Box,
  FormControl,
  FormHelperText,
  FormHelperTextProps,
  SvgIconTypeMap,
  useTheme,
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { useRef } from 'react'
import {
  Control,
  FieldValues,
  Path,
  useController,
  useWatch,
} from 'react-hook-form'
import { useHover } from 'usehooks-ts'

import GalleryDialog from '../../dialogs/GalleryDialog'
import AvatarIcon from './AvatarIcon'

type Props<T extends FieldValues> = {
  control: Control<T>
  name: Path<T>
  variant?: AvatarProps['variant']
  fullWidth?: boolean
  placeHolderIcon?: OverridableComponent<SvgIconTypeMap>
  readOnly?: boolean
  width?: number | string
  height?: number | string
  helperText?: FormHelperTextProps['children']
  defaultAddedByMe?: boolean
  disableAddedByMe?: boolean
}

export const FormAvatarField = <T extends FieldValues>({
  control,
  name,
  variant = 'circular',
  fullWidth = false,
  placeHolderIcon = Portrait,
  readOnly = false,
  width = 112,
  height = 112,
  helperText = ' ',
  defaultAddedByMe = false,
  disableAddedByMe = false,
}: Props<T>) => {
  const { openDialog, closeTab } = useDialogStore()
  const theme = useTheme()
  const hoverRef = useRef(null)
  const isHovered = useHover(hoverRef)

  const id = useWatch({ control, name })

  const { data, isError, isLoading } = useGetOneMedia({
    id,
  })

  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  })

  return (
    <FormControl fullWidth={fullWidth}>
      <Box
        ref={hoverRef}
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: readOnly ? 'default' : 'pointer',
          pointerEvents: readOnly ? 'none' : 'auto',
        }}
        onClick={() => {
          openDialog(
            <GalleryDialog
              defaultFileType={[FileType.Image]}
              defaultAccessLevel={['public']}
              disableAccessLevel
              disableFileType
              isMultiple={false}
              alreadyPresentMedia={value ? [value] : []}
              callback={(data) => {
                onChange(data[0])
                closeTab()
              }}
              defaultMediaType={['avatar']}
              defaultAddedByMe={defaultAddedByMe}
              disableAddedByMe={disableAddedByMe}
            />,
            'Gallery Dialog',
            { isUnique: false }
          )
        }}
      >
        <Upload
          sx={{
            position: 'absolute',
            width: '75%',
            height: '75%',
            maxWidth: 256,
            opacity: isHovered ? 1 : 0,
            transition: theme.transitions.create('opacity'),
            zIndex: 1,
          }}
          color="primary"
        />
        <Avatar
          src={data?.signedUrl || undefined}
          variant={variant}
          sx={{
            height,
            width: fullWidth ? '100%' : width,
            border: 1,
            borderColor: theme.palette.grey[300],
            opacity: isHovered ? 0.25 : 1,
            transition: theme.transitions.create('opacity'),
            backgroundColor: theme.palette.common.white,
          }}
          slotProps={{
            img: {
              sx: {
                objectFit: fullWidth ? 'contain' : 'cover',
              },
            },
          }}
        >
          <AvatarIcon isLoading={isLoading} icon={placeHolderIcon} />
        </Avatar>
      </Box>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}
