import { GetTaskResponseBody } from '@aeqoom/contracts'
import { PlanTaskForm, usePlanTaskSchema } from '@aeqoom/forms'
import { usePlanTask } from '@app/queries/useTasks'
import { zodResolver } from '@hookform/resolvers/zod'
import { Stack } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import AllDay from './AllDay'
import Assignee from './Assignee'
import PlanButton from './PlanButton'
import PlannedFrom from './PlannedFrom'
import PlannedTo from './PlannedTo'

type Props = {
  task: GetTaskResponseBody
}

const Plan = ({ task }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'backlog.taskDetail.header.plan',
  })

  const planTaskForm = usePlanTaskSchema()

  const methods = useForm<PlanTaskForm>({
    resolver: zodResolver(planTaskForm),
    mode: 'onChange',
    defaultValues: {
      plannedFrom: dayjs(),
      plannedTo: dayjs().add(1, 'day'),
      allDay: false,
      userId: null,
    },
  })

  const { reset } = methods

  useEffect(() => {
    if (task) {
      reset({
        userId: task.userId,
        allDay: task.allDay,
        plannedFrom: dayjs(task.plannedFrom),
        plannedTo: dayjs(task.plannedTo),
      })
    }
  }, [task, reset])

  const planTaskMutation = usePlanTask()

  const onSubmit = (data: PlanTaskForm) => {
    planTaskMutation.mutate(
      { body: data, params: { id: task.id } },
      {
        onSuccess: () => {
          toast.success(t('movedToPlanned'))
        },
      }
    )
  }

  return (
    <FormProvider {...methods}>
      <Stack spacing={2} alignItems="flex-start">
        <Stack
          spacing={4}
          sx={{ width: '100%' }}
          alignItems="center"
          direction="row"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Assignee />
            <PlannedFrom />
            <PlannedTo />
            <AllDay />
          </Stack>
          <PlanButton status={task.status} onSubmit={onSubmit} />
        </Stack>
      </Stack>
    </FormProvider>
  )
}

export default Plan
