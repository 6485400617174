import { FormAutoComplete } from '@aeqoom/components-web'
import { PlanTaskForm } from '@aeqoom/forms'
import { useGetUsers } from '@app/queries/useUsers'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const Assignee = () => {
  const { control } = useFormContext<PlanTaskForm>()

  const { data: users = [] } = useGetUsers()

  const { t } = useTranslation('web', {
    keyPrefix: 'backlog.taskDetail.header.plan',
  })

  return (
    <FormAutoComplete
      control={control}
      name="userId"
      autocomplete={{
        options: users.filter((user) => user.activationState !== 'disabled'),
        getOptionLabel: (option) =>
          option.firstName && option.lastName
            ? `${option.firstName} ${option.lastName}`
            : option.emailAddress,
        sx: { minWidth: 256 },
      }}
      fieldProps={{
        disableHelperText: true,
        label: t('assignee'),
      }}
    />
  )
}

export default Assignee
