import { PERMISSIONS } from '@aeqoom/permissions'
import { PageLabel } from '@app/components/base/datagrid/CustomDataGrid'
import SelectClient from '@app/components/SelectClient'
import { useGetTasks } from '@app/queries/useTasks'
import useUserStore from '@app/src/stores/useUserStore'
import { CalendarMonth } from '@mui/icons-material'
import { Paper, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CalendarView from './CalendarComponent'

const Calendar = () => {
  const { t } = useTranslation('web', { keyPrefix: 'calendar' })

  const {
    hasPermission,
    getCurrentActiveCompany,
    getCurrentActiveCompanyUnit,
  } = useUserStore()

  const activeCompany = getCurrentActiveCompany()
  const activeCompanyUnit = getCurrentActiveCompanyUnit()

  const { data: tasks } = useGetTasks({
    onlyAssignedToMe: !hasPermission(PERMISSIONS.TASK.ASSIGN_USER),
    companyUnitId: activeCompanyUnit,
    companyId: activeCompany,
    onlyPlanned: true,
    sort: {
      priority: 'desc',
    },
  })

  return (
    <Stack mb={2} direction="column" justifyContent="space-between">
      <PageLabel>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ marginBlock: 2 }}
        >
          <CalendarMonth />
          <div>{t('title')}</div>
        </Stack>
      </PageLabel>

      <SelectClient />

      <Paper sx={{ pt: 4, height: '100%', borderRadius: 2, overflowX: 'auto' }}>
        <div style={{ padding: '24px', minWidth: '800px' }}>
          <CalendarView data={tasks} />
        </div>
      </Paper>
    </Stack>
  )
}

export default Calendar
