import { BrokenImage } from '@mui/icons-material'
import { CardMedia, CardMediaProps, SxProps, useTheme } from '@mui/material'
import { useState } from 'react'

const CustomCardMedia = (props: CardMediaProps) => {
  const [hasFailedToLoad, setHasFailedToLoad] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { children } = props

  const theme = useTheme()

  const sx: SxProps = {
    height: 112,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: 'contain',
    backgroundColor: theme.palette.grey[200],
  }

  if (hasFailedToLoad) {
    return (
      <CardMedia sx={sx}>
        <BrokenImage fontSize="large" />
      </CardMedia>
    )
  }

  if (children) {
    return <CardMedia sx={sx}>{children}</CardMedia>
  }

  return (
    <CardMedia
      component="img"
      {...props}
      sx={{
        ...sx,
        visibility: isLoading ? 'hidden' : 'visible',
      }}
      onError={() => setHasFailedToLoad(true)}
      onLoad={() => setIsLoading(false)}
    />
  )
}

export default CustomCardMedia
