import { relations } from 'drizzle-orm'
import {
  integer,
  pgTable,
  serial,
  timestamp,
  varchar,
} from 'drizzle-orm/pg-core'

import { componentInstances, users } from './index'

export const serviceBooks = pgTable('service_books', {
  id: serial('id').primaryKey(),
  name: varchar('name', { length: 256 }).notNull(),
  componentInstanceId: integer('component_instance_id').notNull(),
})

export const serviceBooksRelations = relations(
  serviceBooks,
  ({ one, many }) => ({
    componentInstance: one(componentInstances, {
      fields: [serviceBooks.componentInstanceId],
      references: [componentInstances.id],
    }),
    serviceBookRecords: many(serviceBookRecords),
  })
)

export type ServiceBook = typeof serviceBooks.$inferSelect
export type NewServiceBook = typeof serviceBooks.$inferInsert

export const serviceBookRecords = pgTable('service_book_records', {
  id: serial('id').primaryKey(),
  name: varchar('name', { length: 256 }).notNull(),
  counter: integer('counter'),
  date: timestamp('date').notNull().defaultNow(),
  serviceBookId: integer('service_book_id')
    .references(() => serviceBooks.id)
    .notNull(),
  userId: integer('user_id')
    .references(() => users.id)
    .notNull(),
})

export const serviceBookRecordsRelations = relations(
  serviceBookRecords,
  ({ one }) => ({
    serviceBook: one(serviceBooks, {
      fields: [serviceBookRecords.serviceBookId],
      references: [serviceBooks.id],
    }),
    user: one(users, {
      fields: [serviceBookRecords.userId],
      references: [users.id],
    }),
  })
)

export type ServiceBookRecord = typeof serviceBookRecords.$inferSelect
export type NewServiceBookRecord = typeof serviceBookRecords.$inferInsert
