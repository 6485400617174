import { LabeledValue } from '@aeqoom/components-web'
import CompanyDialog from '@app/components/base/dialogs/_detailViewDialogs/CompanyDialog'
import CompanyUnitDialog from '@app/components/base/dialogs/_detailViewDialogs/CompanyUnitDialog'
import { FIELD_SHARED_STYLES } from '@app/components/base/dialogs/_detailViewDialogs/constants'
import { useGetCompany } from '@app/queries/useCompanies'
import { useGetCompanyUnit } from '@app/queries/useCompanyUnits'
import { useGetOneMedia } from '@app/queries/useMedia'
import { useDialogStore } from '@app/stores/useDialogStore'
import { Avatar, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  companyId?: number | null
  companyUnitId?: number | null
}

const CompanyField = ({ companyId, companyUnitId }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'detailViewDialog.companyField',
  })

  const { openDialog } = useDialogStore()

  const {
    isLoading: isCompanyLoading,
    isError: isCompanyError,
    data: company,
  } = useGetCompany({
    id: companyId,
  })

  const {
    isLoading: isCompanyUnitLoading,
    isError: isCompanyUnitError,
    data: companyUnit,
  } = useGetCompanyUnit({
    id: companyUnitId,
  })

  const data = companyUnit || company

  const avatar = useGetOneMedia({
    id: data?.avatar,
  })

  if (!companyId && !companyUnitId) return null

  if (isCompanyLoading || isCompanyUnitLoading)
    return (
      <Skeleton
        variant="rectangular"
        sx={{ ...FIELD_SHARED_STYLES, borderRadius: 1 }}
      />
    )

  if ((companyId && isCompanyError) || (companyUnitId && isCompanyUnitError))
    return null

  return (
    <LabeledValue
      onClick={() =>
        openDialog(
          companyUnitId ? (
            <CompanyUnitDialog id={companyUnitId} />
          ) : (
            <CompanyDialog id={companyId} />
          ),
          companyUnit?.name || company?.name || t('company')
        )
      }
      avatar={<Avatar src={avatar.data?.signedUrl || undefined} />}
      label={companyId ? t('company') : t('companyUnit')}
      value={data?.name}
      sx={{ ...FIELD_SHARED_STYLES, ':hover': { cursor: 'pointer' } }}
      type="single-line"
    />
  )
}

export default CompanyField
