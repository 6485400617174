import { useThemeColors } from '@aeqoom/hooks'
import { DownloadOutlined } from '@mui/icons-material'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { ElementType, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  title: string
  children: ReactNode
  icon?: ElementType
  qr?: {
    image: string | null | undefined
    name?: string
  }
}

const Section = ({ title, children, icon: Icon, qr }: Props) => {
  const { t } = useTranslation('web', { keyPrefix: 'createMachineDialog' })
  const { secondary } = useThemeColors()

  return (
    <Grid container>
      <Grid item xs={4}>
        <Stack spacing={2} alignItems="flex-start">
          <Stack direction="row" spacing={2}>
            {Icon && <Icon />}
            <Typography fontSize={16} component="p" fontWeight={700}>
              {title}
            </Typography>
          </Stack>
          {qr?.image && (
            <>
              <Box
                component="img"
                sx={{
                  width: 200,
                  border: `solid 1px ${secondary.light}`,
                  borderRadius: 2,
                }}
                src={qr.image}
                alt={t('qr.qrAltText')}
              />
              <Button
                sx={{ width: 200 }}
                startIcon={<DownloadOutlined />}
                size="small"
                variant="outlined"
                color="secondary"
                fullWidth={false}
                onClick={() => {
                  if (!qr?.image) return

                  const a = document.createElement('a')

                  a.href = qr.image
                  a.download = t('qr.fileName', {
                    machineName: qr.name,
                  })
                  a.click()
                }}
              >
                {t('qr.downloadButton')}
              </Button>
            </>
          )}
        </Stack>
      </Grid>
      <Grid item xs={8}>
        <Grid container spacing={2.5}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Section
