import { ContractResponse } from '@aeqoom/contracts'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import MachineRow from './MachineRow'

type Props = {
  data: ContractResponse
}

const MachinesList = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'contracts.detail.tabs.machinesList',
  })

  return (
    <Grid container>
      <Grid item xs>
        <TableContainer>
          <Table sx={{ width: '100%', minWidth: 700 }}>
            <TableHead>
              <TableRow>
                <TableCell>{t('table.header.name')}</TableCell>
                <TableCell>{t('table.header.inventoryNumber')}</TableCell>
                <TableCell>{t('table.header.frequency')}</TableCell>
                <TableCell>{t('table.header.taskTemplate')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.machineInstances?.map((machineInstance) => (
                <MachineRow
                  key={machineInstance.id}
                  machineInstance={machineInstance}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default MachinesList
