import { Grid, GridProps, Stack } from '@mui/material'

const GridItem = ({ children, ...props }: GridProps) => {
  return (
    <Grid item {...props}>
      <Stack direction="row" height="100%" alignItems="center" gap={1}>
        {children}
      </Stack>
    </Grid>
  )
}

export default GridItem
