import { createUniqueKey } from '@aeqoom/utils'
import TaskDetailDialogContent from '@app/components/base/dialogs/_detailViewDialogs/TaskDetailDialogContent'
import { useDialogStore } from '@app/src/stores/useDialogStore'

type Props = {
  id: number
  name: string
}

const useDialogHandler = ({ id, name }: Props) => {
  const { openDialog } = useDialogStore()

  const handleDialogOpen = () => {
    openDialog(<TaskDetailDialogContent selectedId={id} />, name, {
      uniqueKey: createUniqueKey(['task', id]),
    })
  }

  return handleDialogOpen
}

export default useDialogHandler
