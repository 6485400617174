import { useGetContact } from '@app/queries/useContacts'
import { Email, Phone } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  Chip,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  id?: number
}

const ContactMain = ({ id }: Props) => {
  const { data, isPending, isError } = useGetContact({ id })

  const { t } = useTranslation(['web', 'common'])

  if (!id) return null

  if (isPending) return <Skeleton variant="rounded" height={188} />

  if (isError)
    return (
      <Alert color="error">
        <AlertTitle>{t('common:errorOccured')}</AlertTitle>
        {t('contacts.failedToLoadMainContact')}
      </Alert>
    )

  return (
    <Card sx={{ width: '100%', backgroundColor: '#edf4f0' }}>
      <CardContent>
        <Stack spacing={2} alignItems="flex-start">
          <Chip label={t('contacts.mainContact')} variant="outlined" />
          <Stack>
            <Typography variant="h5" fontWeight={700} gutterBottom>
              {data.firstName} {data.lastName}
            </Typography>
            <Typography fontSize={12} variant="h6" color="primary">
              {data.position}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Stack direction="row" spacing={2} alignItems="center">
              <Phone fontSize="small" />
              <Typography>{data.phoneNumber}</Typography>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <Email fontSize="small" />
              <Typography>{data.emailAddress}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default ContactMain
