import { MEDIA_KEYS } from '@aeqoom/types'
import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query'

import { client } from './client'
import parseIncoming from './parseIncoming'

const invalidateAuthQueries = (queryClient: QueryClient) => {
  const allKeys: string[] = Object.values(MEDIA_KEYS).flat()
  queryClient.invalidateQueries({
    predicate: (query) => allKeys.includes(query.queryKey[0] + ''),
  })
}

export const useAddNewDevice = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateAuthQueries(queryClient)
    },
    mutationFn: () => client.auth.authDevice().then(parseIncoming),
  })
}
