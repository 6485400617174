import { MachineInstanceResponse } from '@aeqoom/contracts'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import ComponentRow from './ComponentRow'

type Props = {
  data: MachineInstanceResponse
}

const Components = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'machineInstances.detail.tabs.components',
  })

  return (
    <Grid container>
      <Grid item xs>
        <TableContainer>
          <Table sx={{ width: '100%', minWidth: 700 }}>
            <TableHead>
              <TableRow>
                <TableCell>{t('table.header.name')}</TableCell>
                <TableCell>{t('table.header.inventoryNumber')}</TableCell>
                <TableCell>{t('table.header.company')}</TableCell>
                <TableCell>{t('table.header.companyUnit')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.componentInstances?.map((componentInstance) => (
                <ComponentRow
                  key={componentInstance.id}
                  componentInstance={componentInstance}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default Components
