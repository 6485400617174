import { PERMISSIONS } from '@aeqoom/permissions'
import { createUniqueKey } from '@aeqoom/utils'
import CustomDataGrid from '@app/components/base/datagrid/CustomDataGrid'
import UserDialog from '@app/components/base/dialogs/_detailViewDialogs/UserDialog'
import CreateUserDialogContent from '@app/components/base/dialogs/createForms/CreateUserDialogContent'
import DeleteConfirmDialog from '@app/components/base/dialogs/DeleteConfirmDialog'
import { useDeleteAction } from '@app/hooks/useDeleteAction'
import { useGetUsers, useSetUserActivationStatus } from '@app/queries/useUsers'
import useUserStore from '@app/src/stores/useUserStore'
import { useDialogStore } from '@app/stores/useDialogStore'
import { PersonAddAlt } from '@mui/icons-material'
import { GridColDef, GridLogicOperator } from '@mui/x-data-grid'

import ChangeUserStateCell from './ChangeUserStateCell'
import usersDataCol, { changeActivationCellProps } from './col'

const Users = () => {
  const usersQuery = useGetUsers()

  const { openDialog } = useDialogStore()
  const { hasPermission } = useUserStore()
  const setUserStatusMutation = useSetUserActivationStatus()

  const {
    handleOpen,
    handleClose,
    handleConfirmDeleteWithParams,
    isOpen,
    setSelectedItemId,
  } = useDeleteAction(setUserStatusMutation)

  const changeActivationStateCol: GridColDef = {
    ...changeActivationCellProps,
    renderCell: ({ row }) => {
      return (
        <ChangeUserStateCell
          isActive={row.activationState !== 'disabled'}
          handleAction={() => {
            setSelectedItemId(row.id)
            handleOpen()
          }}
        />
      )
    },
  }

  const columns = [
    ...usersDataCol,
    ...(hasPermission(PERMISSIONS.USER.DELETE)
      ? [changeActivationStateCol]
      : []),
  ]

  return (
    <>
      <DeleteConfirmDialog
        isPending={setUserStatusMutation.isPending}
        onClose={handleClose}
        onConfirm={() => {
          handleConfirmDeleteWithParams((userId) => ({
            body: {
              isActive:
                usersQuery.data!.find((user) => user.id === userId)!
                  .activationState === 'disabled',
            },
            params: {
              id: userId,
            },
          }))
        }}
        isOpen={isOpen}
      />

      <CustomDataGrid
        initialState={{
          pagination: { paginationModel: { pageSize: 50 } },
          filter: {
            filterModel: {
              logicOperator: GridLogicOperator.Or,
              items: [
                {
                  field: 'activationState',
                  operator: 'contains',
                  value: 'Active',
                },
              ],
            },
          },
        }}
        disableCreateNew={!hasPermission(PERMISSIONS.USER.CREATE)}
        addClickOptions={{
          Dialog: CreateUserDialogContent,
          title: 'Create new user',
          options: {
            icon: PersonAddAlt,
            isUnique: false,
          },
        }}
        onRowClick={({ row }) => {
          openDialog(
            <UserDialog id={row.id} />,
            `${row.firstName} ${row.lastName}`,
            {
              uniqueKey: createUniqueKey(['user', row.id]),
            }
          )
        }}
        pageLabel="Users"
        rows={usersQuery.data || []}
        loading={usersQuery.isLoading}
        columns={columns}
      />
    </>
  )
}

export default Users
