import { useDialogStore } from '@app/stores/useDialogStore'
import Button from '@mui/material/Button'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  hasView?: boolean
  isEditing: boolean
  onSave: () => void
  onEdit?: () => void
  onCreate?: () => void
  disabled?: boolean
}

const ActionControlButtons = ({
  hasView = true,
  isEditing,
  onSave,
  onEdit,
  onCreate,
  disabled = false,
}: Props) => {
  const { setShouldAskBeforeClose } = useDialogStore()

  const { t } = useTranslation('common')

  const handleSave = () => {
    setShouldAskBeforeClose(false)
    onSave()
  }

  if (isEditing)
    return (
      <Button
        disabled={disabled}
        type="submit"
        variant="contained"
        onClick={handleSave}
      >
        {t('save')}
      </Button>
    )

  if (!hasView)
    return (
      <Button
        disabled={disabled}
        type="submit"
        variant="contained"
        onClick={onEdit}
      >
        {t('edit')}
      </Button>
    )

  return (
    <Button variant="contained" onClick={onCreate}>
      {t('create')}
    </Button>
  )
}

export default ActionControlButtons
