import { PERMISSIONS } from '@aeqoom/permissions'
import { createUniqueKey } from '@aeqoom/utils'
import CheckPermissions from '@app/components/CheckPermissions'
import ErrorScreen from '@app/components/ErrorScreen'
import { useGetUser } from '@app/queries/useUsers'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import CreateUserDialogContent from '../../createForms/CreateUserDialogContent'
import Content from './Content'

type Props = {
  id: number
}

const UserDialog = ({ id }: Props) => {
  const { isPending, isError, refetch, data } = useGetUser({
    id,
  })

  const { t } = useTranslation(['common', 'web'])

  const { openDialog, closeTab } = useDialogStore()

  const openUserDialog = () =>
    openDialog(
      <CreateUserDialogContent
        isEditModal
        id={id}
        callback={() => {
          closeTab()
        }}
      />,
      t('web:users.editUser'),
      { uniqueKey: createUniqueKey(['createUser', id]) }
    )

  if (isPending)
    return (
      <DialogContent>
        <Stack alignItems="center" justifyContent="center" height="100%">
          <CircularProgress />
        </Stack>
      </DialogContent>
    )

  if (isError)
    return (
      <DialogContent>
        <Stack alignItems="center" justifyContent="center" height="100%">
          <ErrorScreen refetch={refetch} />
        </Stack>
      </DialogContent>
    )

  return (
    <>
      <Content data={data} />
      <CheckPermissions permissions={[PERMISSIONS.USER.UPDATE]}>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={openUserDialog}>
            {t('actions.edit')}
          </Button>
        </DialogActions>
      </CheckPermissions>
    </>
  )
}

export default UserDialog
