import { relations } from 'drizzle-orm'
import {
  integer,
  pgEnum,
  pgTable,
  primaryKey,
  serial,
  text,
  varchar,
} from 'drizzle-orm/pg-core'

import {
  componentInstances,
  machineInstances,
  serviceLevelAgreements,
  tasks,
  users,
} from './index'

export const parentTaskType = pgEnum('parent_task_type', [
  'maintenance',
  'incident',
  'general',
])

export const parentTaskStatus = pgEnum('parent_task_status', [
  'new',
  'recurred',
  'acknowledged',
  'in_progress',
  'planned',
  'blocked',
  'archived',
  'to_be_checked_by_admin',
  'to_be_checked_by_client',
  'done',
])

export const parentTaskPriority = pgEnum('parent_task_priority', [
  'low',
  'medium',
  'high',
])

export const parentTasks = pgTable('parent_tasks', {
  id: serial('id').primaryKey(),
  name: varchar('name', { length: 256 }).notNull(),
  description: text('description'),
  expectedDuration: integer('expected_duration'),
  type: parentTaskType('type'),
  status: parentTaskStatus('status').default('new'),
  priority: parentTaskPriority('priority').default('medium'),
  serviceLevelAgreementId: integer('service_level_agreement_id').references(
    () => serviceLevelAgreements.id
  ),
  userId: integer('user_id')
    .references(() => users.id)
    .notNull(),
})

export type ParentTask = typeof parentTasks.$inferSelect

export type NewParentTask = typeof parentTasks.$inferInsert

export const parentTaskRelations = relations(parentTasks, ({ many, one }) => ({
  possibleAssignees: many(userOnParentTasks),
  possibleMachineInstances: many(machineInstances),
  possibleComponentInstances: many(componentInstances),
  serviceLevelAgreement: one(serviceLevelAgreements, {
    fields: [parentTasks.serviceLevelAgreementId],
    references: [serviceLevelAgreements.id],
  }),
  tasks: many(tasks),
  managedBy: one(users, {
    fields: [parentTasks.userId],
    references: [users.id],
  }),
}))

export const componentInstanceOnParentTasks = pgTable(
  'component_instance_parent_tasks',
  {
    componentInstanceId: integer('component_instance_id').references(
      () => componentInstances.id
    ),
    parentTaskId: integer('parent_task_id').references(() => parentTasks.id),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.componentInstanceId, t.parentTaskId] }),
  })
)

export const componentInstanceOnParentTaskRelations = relations(
  componentInstanceOnParentTasks,
  ({ one }) => ({
    componentInstance: one(componentInstances, {
      fields: [componentInstanceOnParentTasks.componentInstanceId],
      references: [componentInstances.id],
    }),
    parentTask: one(parentTasks, {
      fields: [componentInstanceOnParentTasks.parentTaskId],
      references: [parentTasks.id],
    }),
  })
)

export const machineInstanceOnParentTasks = pgTable(
  'machine_instance_parent_tasks',
  {
    machineInstanceId: integer('machine_instance_id').references(
      () => machineInstances.id
    ),
    parentTaskId: integer('parent_task_id').references(() => parentTasks.id),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.machineInstanceId, t.parentTaskId] }),
  })
)

export const machineInstanceOnParentTaskRelations = relations(
  machineInstanceOnParentTasks,
  ({ one }) => ({
    machineInstance: one(machineInstances, {
      fields: [machineInstanceOnParentTasks.machineInstanceId],
      references: [machineInstances.id],
    }),
    parentTask: one(parentTasks, {
      fields: [machineInstanceOnParentTasks.parentTaskId],
      references: [parentTasks.id],
    }),
  })
)

export const userOnParentTasks = pgTable(
  'user_parent_tasks',
  {
    userId: integer('user_id').references(() => users.id),
    parentTaskId: integer('parent_task_id').references(() => parentTasks.id),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.userId, t.parentTaskId] }),
  })
)

export const userOnParentTaskRelations = relations(
  userOnParentTasks,
  ({ one }) => ({
    user: one(users, {
      fields: [userOnParentTasks.userId],
      references: [users.id],
    }),
    parentTask: one(parentTasks, {
      fields: [userOnParentTasks.parentTaskId],
      references: [parentTasks.id],
    }),
  })
)
