import { TEXT } from '@aeqoom/styles'
import { opacityColor } from '@aeqoom/utils'
import { Stack, SxProps, Typography } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  label: string
  value: ReactNode
  avatar?: ReactNode
  sx?: SxProps
  type?: 'single-line' | 'multi-line' | 'react-node'
  onClick?: () => void
}

const Value = ({
  value,
  type,
  onClick,
}: Pick<Props, 'value' | 'type' | 'onClick'>) => {
  if (!value) return <Typography color={opacityColor(TEXT, 50)}>--</Typography>

  switch (type) {
    case 'react-node':
      return <Stack>{value}</Stack>
    case 'single-line':
      return (
        <Typography
          noWrap
          sx={{
            ':hover': { textDecoration: onClick ? 'underline' : 'auto' },
          }}
        >
          {value}
        </Typography>
      )
    case 'multi-line':
      return <Typography>{value}</Typography>
  }
}

export const LabeledValue = ({
  label,
  value,
  avatar,
  sx,
  type = 'react-node',
  onClick,
}: Props) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      sx={sx}
      onClick={onClick}
    >
      {avatar && avatar}
      <Stack sx={{ ...sx, maxWidth: '100%' }} gap={0.5}>
        <Typography
          sx={{
            //@TODO - update with theme h4 after theme font update
            fontWeight: '700',
            fontSize: 22,
          }}
        >
          {label}
        </Typography>
        <Value value={value} onClick={onClick} type={type} />
      </Stack>
    </Stack>
  )
}
