import { relations } from 'drizzle-orm'
import {
  date,
  integer,
  pgTable,
  primaryKey,
  serial,
  text,
  uuid,
  varchar,
} from 'drizzle-orm/pg-core'

import {
  companies,
  companyUnits,
  componentInstances,
  componentTemplates,
  halls,
  hallSeparators,
  machineTemplateOnToDoLists,
  media,
  mediaOnMachineInstance,
  serviceLevelAgreements,
  tasks,
} from './index'

export const machineInstances = pgTable('machine_instances', {
  id: serial('id').primaryKey(),
  avatar: integer('avatar_id').references(() => media.id, {
    onDelete: 'set null',
  }),
  name: varchar('name', { length: 256 }).notNull(),
  hallId: integer('hall_id').references(() => halls.id),
  hallSeparatorId: integer('hall_separator_id').references(
    () => hallSeparators.id
  ),
  companyId: integer('company_id')
    .references(() => companies.id, {
      onDelete: 'cascade',
    })
    .notNull(),
  companyUnitId: integer('company_unit_id')
    .references(() => companyUnits.id, {
      onDelete: 'cascade',
    })
    .notNull(),
  machineTemplateId: integer('machine_template_id').references(
    () => machineTemplates.id,
    { onDelete: 'set null' }
  ),
  serviceLevelAgreementId: integer('service_level_agreement_id').references(
    () => serviceLevelAgreements.id
  ),
  yearProduced: date('year_produced'),
  inventoryNumber: varchar('inventory_number', { length: 256 }).unique(),
  uuid: uuid('uuid').defaultRandom().unique().notNull(),
  description: text('description'),
})

export const machineInstancesRelations = relations(
  machineInstances,
  ({ one, many }) => ({
    avatar: one(media, {
      fields: [machineInstances.avatar],
      references: [media.id],
    }),
    company: one(companies, {
      fields: [machineInstances.companyId],
      references: [companies.id],
    }),
    companyUnit: one(companyUnits, {
      fields: [machineInstances.companyUnitId],
      references: [companyUnits.id],
    }),
    hall: one(halls, {
      fields: [machineInstances.hallId],
      references: [halls.id],
    }),
    hallSeparator: one(hallSeparators, {
      fields: [machineInstances.hallSeparatorId],
      references: [hallSeparators.id],
    }),
    machineTemplate: one(machineTemplates, {
      fields: [machineInstances.machineTemplateId],
      references: [machineTemplates.id],
    }),
    serviceLevelAgreement: one(serviceLevelAgreements, {
      fields: [machineInstances.serviceLevelAgreementId],
      references: [serviceLevelAgreements.id],
    }),
    componentInstances: many(componentInstances),
    tasks: many(tasks),
    media: many(mediaOnMachineInstance),
  })
)

export type MachineInstance = typeof machineInstances.$inferSelect
export type NewMachineInstance = typeof machineInstances.$inferInsert

export const machineTemplates = pgTable('machine_templates', {
  id: serial('id').primaryKey(),
  name: varchar('name', { length: 256 }).notNull(),
  producer: varchar('producer', { length: 256 }),
  description: text('description'),
})

export const machineTemplatesRelations = relations(
  machineTemplates,
  ({ one, many }) => ({
    toDoLists: one(machineTemplateOnToDoLists, {
      fields: [machineTemplates.id],
      references: [machineTemplateOnToDoLists.machineTemplateId],
    }),
    componentTemplates: one(componentTemplatesOnMachineTemplates, {
      fields: [machineTemplates.id],
      references: [componentTemplatesOnMachineTemplates.machineTemplateId],
    }),
    machineInstances: many(machineInstances),
  })
)

export const componentTemplatesOnMachineTemplates = pgTable(
  'component_template_machine_template',
  {
    componentTemplateId: integer('component_template_id').references(
      () => componentTemplates.id
    ),
    machineTemplateId: integer('machine_template_id').references(
      () => machineTemplates.id
    ),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.componentTemplateId, t.machineTemplateId] }),
  })
)

export const componentTemplatesOnMachineTemplatesRelations = relations(
  componentTemplatesOnMachineTemplates,
  ({ one }) => ({
    componentTemplate: one(componentTemplates, {
      fields: [componentTemplatesOnMachineTemplates.componentTemplateId],
      references: [componentTemplates.id],
    }),
    machineTemplate: one(machineTemplates, {
      fields: [componentTemplatesOnMachineTemplates.machineTemplateId],
      references: [machineTemplates.id],
    }),
  })
)

export type MachineTemplate = typeof machineTemplates.$inferSelect
export type NewMachineTemplate = typeof machineTemplates.$inferInsert
