import {
  useVerifyPasswordSchema,
  VerifyPasswordSchemaForm,
} from '@aeqoom/forms'
import { FormTextField } from '@app/components/base/form/FormTextField'
import useUser from '@app/hooks/useUser'
import { zodResolver } from '@hookform/resolvers/zod'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Avatar,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material'
import * as Sentry from '@sentry/react'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { Props } from '../types'

const VerifyPasswordReset = ({ setTab, cachedEmail }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [signInError, setSignInError] = useState<string | null>(null)

  const { t } = useTranslation('web', {
    keyPrefix: 'signInFlow.verifyPasswordReset',
  })

  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const { handleSubmit, control } = useForm<VerifyPasswordSchemaForm>({
    defaultValues: {
      emailAddress: cachedEmail,
      newPassword: '',
      confirmationCode: '',
    },
    resolver: zodResolver(useVerifyPasswordSchema()),
  })

  const { handleConfirmResetPassword } = useUser()
  const onSubmit: SubmitHandler<VerifyPasswordSchemaForm> = async (data) => {
    setIsLoading(true)
    try {
      await handleConfirmResetPassword(data)
      toast.success(t('successMessage'))
      setTab(0)
    } catch (error) {
      if (error instanceof Error) {
        setSignInError(error?.message)
      }
      Sentry.captureException(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Stack alignItems="center" spacing={2} sx={{ mb: 2 }}>
        <Avatar sx={{ bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('header')}
        </Typography>
      </Stack>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 1, width: '100%' }}
        noValidate
      >
        {signInError && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {signInError}
          </Alert>
        )}
        <Stack spacing={1}>
          <FormTextField
            field={{
              label: t('confirmationCodeLabel'),
              required: true,
              fullWidth: true,
              type: 'text',
              autoComplete: 'off',
            }}
            controller={{
              control,
              name: 'confirmationCode',
            }}
          />
          <FormTextField
            field={{
              label: t('newPasswordLabel'),
              required: true,
              fullWidth: true,
              type: showPassword ? 'text' : 'password',
              autoComplete: 'off',
              helperText: t('newPasswordHelperText'),
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
            controller={{
              control,
              name: 'newPassword',
            }}
          />
        </Stack>
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={isLoading}
          loadingPosition="start"
        >
          {t('submitButton')}
        </LoadingButton>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="secondary"
          sx={{ mb: 2 }}
          onClick={() => setTab(0)}
          disabled={isLoading}
        >
          {t('backToSignInButton')}
        </Button>
      </Box>
    </>
  )
}
export default VerifyPasswordReset
