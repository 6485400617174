import { AccessLevel, FileType, MediaType } from '@aeqoom/shared'
import Dropzone from '@app/components/Dropzone'
import MediaPreview from '@app/components/MediaPreview'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { Collections } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormHelperTextProps,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { useRef } from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import AddMediaDialog from '../../dialogs/AddMediaDialog'
import GalleryDialog from '../../dialogs/GalleryDialog'

type Props<T extends FieldValues> = {
  control: Control<T>
  name: Path<T>
  companyId?: number | null
  companyUnitId?: number | null
  isEditing?: boolean
  defaultAccessLevel?: AccessLevel[]
  defaultFileType?: FileType[]
  defaultMediaType?: MediaType[]
  disableAccessLevel?: boolean
  disableFileType?: boolean
  helperText?: FormHelperTextProps['children']
}

export const FormUploadField = <T extends FieldValues>({
  control,
  name,
  isEditing = true,
  defaultAccessLevel = ['company', 'companyUnit'],
  defaultFileType = [],
  defaultMediaType = ['general'],
  disableAccessLevel = true,
  disableFileType = false,
  helperText = ' ',
  companyId,
  companyUnitId,
}: Props<T>) => {
  const { openDialog, closeTab } = useDialogStore()
  const formUploadDialogIdRef = useRef(uuidv4())

  const { t } = useTranslation('web')

  const openUploadModal = (
    data: File[],
    onChange: (data: number[]) => void,
    value: number[]
  ) => {
    openDialog(
      <AddMediaDialog
        fileAttachments={data}
        defaultAccessLevel={defaultAccessLevel}
        defaultMediaType={defaultMediaType}
        callback={(data) => onChange([...(value ?? []), ...data])}
      />,
      'Add media'
    )
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <FormControl fullWidth>
          <Stack spacing={8}>
            <MediaPreview
              value={value}
              onChange={onChange}
              isEditing={isEditing}
            />
            <Box>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  <Dropzone
                    onChange={(data) => openUploadModal(data, onChange, value)}
                    disabled={!isEditing}
                    multiple={true}
                    allowedFileType={defaultFileType}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button
                    variant="outlined"
                    disabled={!isEditing}
                    fullWidth
                    sx={{ height: '100%', paddingY: 4 }}
                    onClick={() =>
                      openDialog(
                        <GalleryDialog
                          alreadyPresentMedia={value}
                          disableAccessLevel={disableAccessLevel}
                          disableFileType={disableFileType}
                          defaultFileType={defaultFileType}
                          defaultMediaType={defaultMediaType}
                          callback={(data) => {
                            onChange(data)
                            closeTab()
                          }}
                          defaultAccessLevel={defaultAccessLevel}
                          companyId={companyId}
                          companyUnitId={companyUnitId}
                        />,
                        'Gallery Dialog'
                      )
                    }
                  >
                    <Stack alignItems="center" spacing={2}>
                      <Collections fontSize="large" />
                      <Typography>{t('media.chooseFromGallery')}</Typography>
                    </Stack>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Stack>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    />
  )
}
