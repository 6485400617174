import { FormControl, FormHelperText } from '@mui/material'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'

import Dropzone from '../Dropzone'

type Props<T extends FieldValues> = {
  control: Control<T>
  name: Path<T>
}

const FormDropzone = <T extends FieldValues>({ control, name }: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormControl fullWidth error={!!error} sx={{ height: '100%' }}>
          <Dropzone onChange={onChange} value={value} fieldError={error} />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default FormDropzone
