import { ComponentInstanceResponse } from '@aeqoom/contracts'
import { createUniqueKey } from '@aeqoom/utils'
import DialogContent from '@app/components/base/dialogs/_detailViewDialogs/_components/DialogContent'
import QrDialog from '@app/components/base/dialogs/QrDialog'
import { useDialogStore } from '@app/stores/useDialogStore'
import { QrCode } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Media from '../../_components/Media'
import ComponentInfo from './_tabs/ComponentInfo'

type Props = {
  data: ComponentInstanceResponse
}

const Content = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'componentInstances.detail',
  })

  const { openDialog } = useDialogStore()

  const TABS = [
    {
      component: (data: ComponentInstanceResponse) => (
        <ComponentInfo data={data} />
      ),
      header: t('tabs.componentDetail.label'),
    },
    {
      component: (data: ComponentInstanceResponse) => (
        <Media media={data.media} />
      ),
      header: t('tabs.media.label'),
      isHidden: data.media.length <= 0,
    },
  ]

  return (
    <DialogContent
      data={data}
      tabs={TABS}
      title={t('header.title', {
        name: data.name,
        yearProduced: data.yearProduced,
      })}
      endHeader={
        <Button
          variant="outlined"
          startIcon={<QrCode />}
          color="secondary"
          onClick={() => {
            if (data.qrcode)
              openDialog(
                <QrDialog qrCode={data.qrcode} />,
                `qrCode - ${data.name}`,
                {
                  uniqueKey: createUniqueKey(['qrcode', 'component', data.id])
                }
              )
          }}
        >
          {t('actions.qrCode')}
        </Button>
      }
    />
  )
}

export default Content
