import 'yet-another-react-lightbox/styles.css'
import '@fontsource/noto-sans/400.css'
import '@fontsource/noto-sans/500.css'
import '@fontsource/noto-sans/700.css'
import 'dayjs/locale/cs'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'

import DialogManager from '@app/components/DialogManager'
import { theme } from '@app/styles/theme'
import { Alert, AlertColor, CssBaseline, ThemeProvider } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Amplify } from 'aws-amplify'
import dayjs from 'dayjs'
import utcDayjsPlugin from 'dayjs/plugin/utc'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { resolveValue, Toaster } from 'react-hot-toast'
import { I18nextProvider } from 'react-i18next'
import { pdfjs } from 'react-pdf'
import {
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import MediaCarousel from './components/MediaCarousel'
import i18n from './i18n'
import { router } from './routes'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString()

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
      identityPoolId: import.meta.env.VITE_IDENTITY_POOL_ID,
    },
  },
})

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate:
    import.meta.env.VITE_SENTRY_ENVIRONMENT === 'production' ? 1.0 : 0,
  tracePropagationTargets: [
    'locahost',
    /^https:\/\/api.dev.aeqoom.qestapp\.net/,
    /^https:\/\/api.stage.aeqoom.qestapp\.net/,
    /^https:\/\/api.aeqoom.qestapp\.net/,
  ],
})

const queryClient = new QueryClient({})

dayjs.extend(utcDayjsPlugin)

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools
              initialIsOpen={false}
              buttonPosition="top-left"
            />
            <CssBaseline />
            <RouterProvider router={router} />
            <DialogManager />
            <Toaster position="top-right">
              {(t) => (
                <Alert
                  style={{
                    width: 384,
                  }}
                  variant="filled"
                  severity={t.type as AlertColor}
                >
                  {resolveValue(t.message, t)}
                </Alert>
              )}
            </Toaster>
            <MediaCarousel />
          </QueryClientProvider>
        </LocalizationProvider>
      </I18nextProvider>
    </ThemeProvider>
  </React.StrictMode>
)
