import { UserResponse } from '@aeqoom/contracts'
import DialogContent from '@app/components/base/dialogs/_detailViewDialogs/_components/DialogContent'
import { useTranslation } from 'react-i18next'

import UserInfo from './_tabs/UserInfo'

type Props = {
  data: UserResponse
}

const Content = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'user.detail',
  })

  const TABS = [
    {
      component: (data: UserResponse) => <UserInfo data={data} />,
      header: t('tabs.userDetail.label'),
    },
  ]

  return (
    <DialogContent
      title={t('header.title', {
        name: `${data.firstName} ${data.lastName}`,
        group: data.group,
      })}
      tabs={TABS}
      data={data}
    />
  )
}

export default Content
