import {
  AddUserRequest,
  EditProfileRequest,
  EditUserRequest,
  SetUserActivationStatusRequest,
} from '@aeqoom/contracts'
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { client } from './client'
import parseIncoming from './parseIncoming'

const keys = {
  getUsers: ['users'],
  getUser: ['user'],
  getMe: ['me'],
}

const invalidateUserQueries = (queryClient: QueryClient) => {
  const allKeys = Object.values(keys).flat()
  queryClient.invalidateQueries({
    predicate: (query) => allKeys.includes(query.queryKey[0] + ''),
  })
}

export const useGetMe = () => {
  return useQuery({
    queryKey: [...keys.getMe],
    queryFn: () => client.users.getMe().then(parseIncoming),
  })
}

export const useEditProfile = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => invalidateUserQueries(queryClient),
    mutationFn: (body: EditProfileRequest['body']) =>
      client.users.editProfile({ body }).then(parseIncoming),
  })
}

export const useGetUsers = () => {
  return useQuery({
    queryKey: [...keys.getUsers],
    queryFn: () => client.users.getUsers().then(parseIncoming),
  })
}

export const useGetUser = ({
  id,
  enabled = !!id,
}: {
  id?: number
  enabled?: boolean
}) => {
  return useQuery({
    enabled,
    queryKey: [...keys.getUser, id],
    queryFn: () =>
      client.users.getUser({ params: { id: id! } }).then(parseIncoming),
  })
}

export const useCreateUser = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => invalidateUserQueries(queryClient),
    mutationFn: (body: AddUserRequest['body']) =>
      client.users.addUser({ body }).then(parseIncoming),
  })
}

export const useEditUser = ({ id }: { id?: number }) => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => invalidateUserQueries(queryClient),
    mutationFn: (body: EditUserRequest['body']) =>
      client.users.editUser({ params: { id: id! }, body }).then(parseIncoming),
  })
}

export const useSetUserActivationStatus = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => invalidateUserQueries(queryClient),
    mutationFn: ({
      body,
      params,
    }: Pick<SetUserActivationStatusRequest, 'body' | 'params'>) =>
      client.users
        .setActivationStatus({
          params,
          body,
        })
        .then(parseIncoming),
  })
}
