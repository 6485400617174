import { PERMISSIONS } from '@aeqoom/permissions'
import { createUniqueKey } from '@aeqoom/utils'
import CustomDataGrid from '@app/components/base/datagrid/CustomDataGrid'
import ContactDialog from '@app/components/base/dialogs/_detailViewDialogs/ContactDialog'
import CreateContactDialogContent from '@app/components/base/dialogs/createForms/CreateContactDialogContent'
import DeleteConfirmDialog from '@app/components/base/dialogs/DeleteConfirmDialog'
import { useDeleteAction } from '@app/hooks/useDeleteAction'
import { useDeleteContact, useGetContacts } from '@app/queries/useContacts'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import useUserStore from '@app/src/stores/useUserStore'
import getDeleteActionCol from '@app/utils/getDeleteActionCol'
import { PersonAddAlt } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import contactsDataCol from './col'

const Contacts = () => {
  const { data, isLoading } = useGetContacts()

  const { t } = useTranslation()

  const { openDialog } = useDialogStore()
  const { hasPermission } = useUserStore()

  const mutation = useDeleteContact()
  const {
    isOpen,
    handleOpen,
    handleClose,
    handleConfirmDelete,
    setSelectedItemId,
  } = useDeleteAction(mutation)

  const deleteActionCol = getDeleteActionCol({ handleOpen, setSelectedItemId })

  const columns = [
    ...contactsDataCol,
    ...(hasPermission(PERMISSIONS.CONTACT.DELETE) ? [deleteActionCol] : []),
  ]

  return (
    <>
      <DeleteConfirmDialog
        isPending={mutation.isPending}
        onClose={handleClose}
        onConfirm={handleConfirmDelete}
        isOpen={isOpen}
      />

      <CustomDataGrid
        disableCreateNew={!hasPermission(PERMISSIONS.CONTACT.CREATE)}
        addClickOptions={{
          Dialog: CreateContactDialogContent,
          title: t('contacts.createContact'),
          options: {
            icon: PersonAddAlt,
            isUnique: false,
          },
        }}
        onRowClick={({ row }) => {
          openDialog(
            <ContactDialog id={row.id} />,
            `${row.firstName} ${row.lastName}`,
            {
              uniqueKey: createUniqueKey(['contact', row.id]),
            }
          )
        }}
        pageLabel={t('contacts.name')}
        rows={data || []}
        loading={isLoading}
        columns={columns}
      />
    </>
  )
}

export default Contacts
