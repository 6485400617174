import { Accordion } from '@aeqoom/components-web'
import { AddSLARequest } from '@aeqoom/contracts'
import { useIsWrapperActive, useThemeColors } from '@aeqoom/hooks'
import { useGetMachineInstances } from '@app/queries/useMachineInstances'
import { DeleteForever } from '@mui/icons-material'
import { alpha, IconButton } from '@mui/material'
import dayjs from 'dayjs'
import React, { useRef } from 'react'
import {
  useFieldArray,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFormContext,
  useWatch,
} from 'react-hook-form'

import { ComponentsForm } from './_components/ComponentsForm'
import MachineForm from './_components/MachineForm'
import Title from './_components/Title'

type Props = {
  defaultOpen: boolean
  parent: {
    append: UseFieldArrayAppend<AddSLARequest['body'], 'machineInstances'>
    remove: UseFieldArrayRemove
    index: number
    path: `machineInstances.${number}`
  }
}

const MachineInstanceIterator = ({ parent, defaultOpen }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const { primary, secondary } = useThemeColors()

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<AddSLARequest['body']>()

  const [companyId, companyUnitId] = watch(['companyId', 'companyUnitId'])

  const getMachineInstancesQuery = useGetMachineInstances({
    query: { companyId, companyUnitId },
  })

  const [machineId] = useWatch({
    control,
    name: [`${parent.path}.machineInstanceId`],
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${parent.path}.componentInstances`,
  })

  const isActive = useIsWrapperActive(ref, parent.index === 0)

  const currentMachine = machineId
    ? getMachineInstancesQuery.data?.find((option) => {
        return machineId === option.id
      }) ?? null
    : null

  const hasErrors = Array.isArray(errors.machineInstances)
    ? errors.machineInstances[parent.index]
    : false

  return (
    <Accordion
      isActive={isActive}
      accordionRef={ref}
      title={<Title>{currentMachine?.name}</Title>}
      subtitle={
        currentMachine?.yearProduced
          ? dayjs(currentMachine?.yearProduced).format('YYYY')
          : null
      }
      summaryActions={[
        <IconButton
          key="remove"
          onClick={(e) => {
            e.stopPropagation()
            parent.remove(parent.index)
          }}
        >
          <DeleteForever />
        </IconButton>,
      ]}
      defaultOpen={defaultOpen}
      error={hasErrors}
    >
      <Accordion.Details>
        <MachineForm path={parent.path} append={append} />
      </Accordion.Details>
      <Accordion.Actions
        sx={{
          backgroundColor: alpha(
            isActive ? primary.main : secondary.main,
            0.05
          ),
        }}
      >
        <ComponentsForm
          path={parent.path}
          append={append}
          remove={remove}
          fields={fields}
        />
      </Accordion.Actions>
    </Accordion>
  )
}

export default MachineInstanceIterator
