import { Box, ListItem, ListItemProps } from '@mui/material'

const CustomListItem = (props: ListItemProps) => {
  return (
    <ListItem
      {...props}
      secondaryAction={
        <Box
          sx={{ display: 'none' }}
          component="div"
          className="secondary-action"
        >
          {props.secondaryAction}
        </Box>
      }
      sx={{
        '&:hover .secondary-action': {
          display: 'block',
        },
      }}
    >
      {props.children}
    </ListItem>
  )
}

export default CustomListItem
