import { QrCodeRenderer } from '@aeqoom/components-web'
import { QrCode } from '@mui/icons-material'
import {
  Alert,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  isError: boolean
  isPending: boolean
  qrCode?: string
  startGeneration: () => void
}

const Content = ({ isError, isPending, qrCode, startGeneration }: Props) => {
  const { t } = useTranslation('web')

  if (isError)
    return <Alert color="error">{t('auth.qrCode.errorGenerating')}</Alert>

  if (isPending)
    return (
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <CircularProgress />
        <Typography>{t('auth.qrCode.generatePending')}</Typography>
      </Stack>
    )

  if (qrCode)
    return (
      <Stack alignItems="center" spacing={2}>
        <QrCodeRenderer value={qrCode} />
        <Typography>{t('auth.qrCode.scanWithAnotherDevice')}</Typography>
      </Stack>
    )

  return (
    <Stack alignItems="center" spacing={2}>
      <QrCode fontSize="large" />
      <Typography>{t('auth.qrCode.generateByButton')}</Typography>
      <Button variant="contained" onClick={startGeneration}>
        {t('auth.qrCode.generate')}
      </Button>
    </Stack>
  )
}

export default Content
