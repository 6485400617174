import useMediaCarouselStore from '@app/src/stores/useMediaCarouselStore'
import { Box, Container } from '@mui/material'

import MediaCarouselNotFound from './NotFound'

const MediaCarouselAudio = () => {
  const { currentMedia } = useMediaCarouselStore()

  if (!currentMedia || !currentMedia.signedUrl) return <MediaCarouselNotFound />

  return (
    <Container
      maxWidth="sm"
      sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
    >
      <Box
        component="audio"
        src={currentMedia.signedUrl}
        sx={{ width: '100%', zIndex: 1000 }}
        controls
      />
    </Container>
  )
}

export default MediaCarouselAudio
