import { AddToDoListItemForm, addToDoListItemForm } from '@aeqoom/forms'
import { isObjectEmpty } from '@aeqoom/utils'
import CreateTodoListDialogContent from '@app/components/base/dialogs/createForms/CreateTodoListDialogContent'
import FormAutoComplete from '@app/components/base/form/FormAutoComplete'
import { FormTextField } from '@app/components/base/form/FormTextField'
import Section from '@app/components/Section'
import {
  useCreateToDoListItem,
  useEditToDoListItem,
  useGetToDoListItem,
} from '@app/queries/useToDoListItems'
import { useGetToDoLists } from '@app/queries/useToDoLists'
import { useDialogStore } from '@app/stores/useDialogStore'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
} from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ActionControlButtons from '../ActionControlButtons'
import DialogDetailError from '../DialogDetailError'

type Props = {
  id?: number
  readOnly?: boolean
  isEditModal?: boolean
  callback?: () => void
}

const CreateTodoListItemDialogContent = ({
  readOnly = false,
  isEditModal = false,
  id,
  callback,
}: Props) => {
  const [isEditing, setIsEditing] = useState(!readOnly)

  const { closeDialog, openDialog, closeTab, setShouldAskBeforeClose } =
    useDialogStore()

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { dirtyFields },
  } = useForm<AddToDoListItemForm>({
    resolver: zodResolver(addToDoListItemForm),
    defaultValues: {
      name: '',
      content: '',
      toDoListId: null,
    },
  })

  const getToDoListItemQuery = useGetToDoListItem({ id })
  const getToDoListsQuery = useGetToDoLists()
  const mutation = useCreateToDoListItem()
  const editMutation = useEditToDoListItem()

  useEffect(() => {
    if (!getToDoListItemQuery.isPending || !getToDoListItemQuery.isError) {
      reset(getToDoListItemQuery.data)
    }
  }, [getToDoListItemQuery.data, reset])

  useEffect(() => {
    setShouldAskBeforeClose(isObjectEmpty(dirtyFields))
  }, [isObjectEmpty(dirtyFields)])

  const onSubmit = (data: AddToDoListItemForm) => {
    mutation.mutate(data, {
      onSuccess: (data) => {
        callback ? callback() : closeDialog()
        toast.success(data.message)
        reset()
      },
    })
  }

  const onEditSubmit = (data: AddToDoListItemForm) => {
    editMutation.mutate(
      { body: data, params: { id: id! } },
      {
        onSuccess: (data) => {
          toast.success(data.message)
          callback ? callback() : closeDialog()
          setIsEditing(false)
        },
      }
    )
  }

  if (getToDoListItemQuery.isPending && readOnly)
    return (
      <Stack alignItems="center">
        <CircularProgress size={80} />
      </Stack>
    )

  if (getToDoListItemQuery.isError)
    return <DialogDetailError onRetry={() => getToDoListItemQuery.refetch()} />

  return (
    <>
      <DialogContent>
        <Section title="Details">
          <Grid item xs={6}>
            <FormTextField
              controller={{
                control,
                name: 'name',
              }}
              field={{
                label: 'Name',
                fullWidth: true,
                required: true,
                InputProps: { readOnly: !isEditing },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row">
              <FormAutoComplete
                autocomplete={{
                  getOptionLabel: (option) => {
                    return option.name
                  },
                  options: getToDoListsQuery.data || [],
                  fullWidth: true,
                  readOnly: !isEditing,
                }}
                controller={{
                  control,
                  name: 'toDoListId',
                }}
                field={{ label: 'To do list' }}
                add={{
                  tooltip: 'Create new to do list',
                  onClick: () =>
                    openDialog(
                      <CreateTodoListDialogContent
                        callback={(id) => {
                          setValue('toDoListId', id)
                          closeTab()
                        }}
                      />,
                      'Create to do list'
                    ),
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              controller={{
                control,
                name: 'content',
              }}
              field={{
                multiline: true,
                rows: 4,
                label: 'Content',
                fullWidth: true,
                InputProps: { readOnly: !isEditing },
              }}
            />
          </Grid>
        </Section>
      </DialogContent>
      <DialogActions>
        {isEditModal || (
          <Button
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={mutation.isPending}
          >
            Create todo list item
          </Button>
        )}
        {isEditModal && (
          <ActionControlButtons
            hasView={false}
            isEditing={isEditing}
            onSave={handleSubmit(onEditSubmit)}
            onEdit={() => setIsEditing(true)}
            disabled={editMutation.isPending}
          />
        )}
      </DialogActions>
    </>
  )
}

export default CreateTodoListItemDialogContent
