import { GridColDef } from '@mui/x-data-grid'

const tasksDataCol: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 80 },
  { field: 'name', headerName: 'Name', width: 250 },
  {
    field: 'toDoList',
    headerName: 'To do list name',
    minWidth: 150,
    flex: 1,
    valueGetter: (params) => params.row.toDoList?.name,
  },
  { field: 'description', headerName: 'Description', minWidth: 150, flex: 3 },
]

export default tasksDataCol
