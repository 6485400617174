import { AddSLARequest } from '@aeqoom/contracts'
import MachineInstanceIterator from '@app/components/MachineInstanceIterator'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Stack } from '@mui/material'
import { useFieldArray, useFormContext } from 'react-hook-form'

const SecondStep = () => {
  const { control } = useFormContext<AddSLARequest['body']>()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'machineInstances',
  })

  return (
    <Box>
      <Stack mb={4} spacing={2}>
        {fields.map((field, index) => (
          <MachineInstanceIterator
            key={field.id}
            defaultOpen={index === 0}
            parent={{
              path: `machineInstances.${index}`,
              append,
              remove,
              index,
            }}
          />
        ))}
      </Stack>
      <Stack width="100%" alignItems="center">
        <Button
          onClick={() =>
            append({
              machineInstanceId: null!,
              taskTemplateId: null,
              frequency: null,
              type: null,
              onCall: false,
              componentInstances: [],
            })
          }
          startIcon={<AddIcon />}
        >
          Add machine
        </Button>
      </Stack>
    </Box>
  )
}

export default SecondStep
