import {
  AddMediaRequest,
  AddNewVersionRequest,
  DeleteBatchMediaRequest,
  EditMediaRequest,
} from '@aeqoom/contracts'
import { AccessLevel, FileType, MediaType } from '@aeqoom/shared'
import { MEDIA_KEYS } from '@aeqoom/types'
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { client } from './client'
import parseIncoming from './parseIncoming'

const invalidateMediaQueries = (queryClient: QueryClient) => {
  const allKeys: string[] = Object.values(MEDIA_KEYS).flat()
  queryClient.invalidateQueries({
    predicate: (query) => allKeys.includes(query.queryKey[0] + ''),
  })
}

export const useGetMediaVersions = ({
  id,
  enabled = !!id,
}: {
  id?: number
  enabled?: boolean
}) => {
  return useQuery({
    enabled,
    queryKey: [MEDIA_KEYS.getMediaVersions, id],
    queryFn: () =>
      client.media
        .getMediaVersions({ params: { id: id! } })
        .then(parseIncoming),
  })
}

export const useGetMedia = ({
  page,
  search,
  orderBy,
  isAscending,
  filter,
}: {
  page: number
  search: string
  orderBy: string
  isAscending: boolean
  filter: {
    addedByMe: boolean
    fileType?: FileType[]
    accessLevel: AccessLevel[]
    orderByPriority?: number[]
    companyId?: number | null
    companyUnitId?: number | null
    mediaType: MediaType[]
  }
}) => {
  const orderDirection = isAscending ? 'asc' : 'desc'
  const addedByMe = filter.addedByMe ? 'true' : 'false'

  return useQuery({
    queryKey: [MEDIA_KEYS.getMedia, page, search, orderBy, isAscending, filter],
    queryFn: () =>
      client.media
        .getMedia({
          query: {
            page,
            search,
            orderBy,
            orderDirection,
            filter: { ...filter, addedByMe },
          },
        })
        .then(parseIncoming),
  })
}

export const useGetOneMedia = ({
  id,
  enabled = !!id,
  version,
}: {
  id?: number | null
  enabled?: boolean
  version?: number
}) => {
  return useQuery({
    enabled,
    queryKey: [MEDIA_KEYS.getOneMedia, id, version],
    queryFn: () =>
      client.media
        .getOneMedia({ params: { id: id! }, query: { version } })
        .then(parseIncoming),
  })
}

export const useGetManyMedia = ({
  media = [],
  enabled = media.length > 0,
}: {
  media?: number[]
  enabled?: boolean
}) => {
  return useQuery({
    enabled,
    queryKey: [MEDIA_KEYS.getMedia, media],
    queryFn: () =>
      client.media
        .getManyMedia({ query: { media: media! } })
        .then(parseIncoming),
  })
}

export const useCreateMedia = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateMediaQueries(queryClient)
    },
    mutationFn: (body: AddMediaRequest['body']) =>
      client.media.addMedia({ body }).then(parseIncoming),
  })
}

export const useDeleteMedia = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateMediaQueries(queryClient)
    },
    mutationFn: ({ params }: { params: EditMediaRequest['params'] }) =>
      client.media.deleteMedia({ params }).then(parseIncoming),
  })
}

export const useDeleteBatchMedia = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateMediaQueries(queryClient)
    },
    mutationFn: ({ body }: { body: DeleteBatchMediaRequest['body'] }) =>
      client.media.deleteBatchMedia({ body }).then(parseIncoming),
  })
}

export const useEditMedia = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateMediaQueries(queryClient)
    },
    mutationFn: ({
      body,
      params,
    }: {
      body: EditMediaRequest['body']
      params: EditMediaRequest['params']
    }) => client.media.editMedia({ body, params }).then(parseIncoming),
  })
}

export const useAddVersion = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateMediaQueries(queryClient)
    },
    mutationFn: ({
      body,
      params,
    }: {
      body: AddNewVersionRequest['body']
      params: AddNewVersionRequest['params']
    }) => client.media.addNewVersion({ body, params }).then(parseIncoming),
  })
}
