import { machineTemplates } from '@aeqoom/db'
import { z } from '@aeqoom/zod'
import { ClientInferRequest, initContract } from '@ts-rest/core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

const machineTemplateResponse200 = createSelectSchema(machineTemplates)

const c = initContract()

const machineTemplatesRouter = c.router({
  getMachineTemplates: {
    method: 'GET',
    path: '/machine-templates',
    responses: {
      200: z.array(createSelectSchema(machineTemplates)),
    },
  },
  getMachineTemplate: {
    method: 'GET',
    path: '/machine-templates/:id/get',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: machineTemplateResponse200,
      404: z.object({ message: z.string() }),
    },
  },
  addMachineTemplate: {
    method: 'POST',
    path: '/machine-templates',
    body: createInsertSchema(machineTemplates),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  deleteMachineTemplate: {
    method: 'DELETE',
    path: '/machine-templates/:id/delete',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({}),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  editMachineTemplate: {
    method: 'POST',
    path: '/machine-templates/:id/edit',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({
      ...createInsertSchema(machineTemplates).shape,
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
})

export type AddMachineTemplateRequest = ClientInferRequest<
  typeof machineTemplatesRouter.addMachineTemplate
>

export type EditMachineTemplateRequest = ClientInferRequest<
  typeof machineTemplatesRouter.editMachineTemplate
>

export type DeleteMachineTemplateRequest = ClientInferRequest<
  typeof machineTemplatesRouter.deleteMachineTemplate
>

export type MachineTemplateResponse = z.infer<typeof machineTemplateResponse200>

export default machineTemplatesRouter
