import { useAddNewDevice } from '@app/queries/useAuth'
import { useGetMe } from '@app/queries/useUsers'
import { LoadingButton } from '@mui/lab'
import { Box, DialogActions, DialogContent, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCountdown, useEventListener } from 'usehooks-ts'

import CircularCountdown from './CircularCountdown'
import Content from './Content'

const RESET_INTERVAL = 48

const AddDeviceDialog = () => {
  const [progress, setProgress] = useState(0)
  const [isCounting, setIsCounting] = useState(false)
  const [qrCode, setQrCode] = useState<string>()
  const [isFocused, setIsFocused] = useState(true)

  useEventListener('focusin', () => setIsFocused(true))

  useEventListener('focusout', () => setIsFocused(false))

  const { t } = useTranslation('web')

  const { data: user } = useGetMe()

  const { isPending, data, isError, mutate } = useAddNewDevice()

  const restartCountdown = () => {
    resetCountdown()
    startCountdown()
  }

  const startGeneration = () => {
    setIsCounting(true)
    mutate()
    restartCountdown()
  }

  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: RESET_INTERVAL,
    intervalMs: 1000,
  })

  useEffect(() => {
    startGeneration()
  }, [])

  useEffect(() => {
    if (isFocused) {
      setProgress((1 - count / RESET_INTERVAL) * 100)
      if (count === 0) startGeneration()
    }
  }, [count, isFocused, startGeneration])

  useEffect(() => {
    if (user && data) {
      setQrCode(
        JSON.stringify({
          type: 'authDevice',
          token: data.token,
          email: user.emailAddress,
          api: import.meta.env.VITE_API_ENDPOINT,
          poolId: import.meta.env.VITE_USER_POOL_ID,
        })
      )
    }
  }, [user, data])

  return (
    <>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Content
            isError={isError}
            isPending={isPending}
            qrCode={qrCode}
            startGeneration={startGeneration}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" spacing={2}>
          <CircularCountdown
            value={progress}
            count={count}
            visible={isCounting}
          />
          <LoadingButton
            variant="contained"
            onClick={startGeneration}
            loading={isPending}
          >
            {t('auth.qrCode.regenerate')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </>
  )
}

export default AddDeviceDialog
