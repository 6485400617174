export const PERMISSIONS = {
  ALL: {
    ANY: '*',
    READ: '*.read',
    LIST: '*.list',
    CREATE: '*.create',
    UPDATE: '*.update',
    DELETE: '*.delete',
  },
  BACKLOG: {
    ANY: 'backlog.*',
    LIST: 'backlog.list',
  },
  COMPANY: {
    ANY: 'company.*',
    READ: 'company.read',
    LIST: 'company.list',
    CREATE: 'company.create',
    UPDATE: 'company.update',
    DELETE: 'company.delete',
  },
  COMPANY_UNIT: {
    ANY: 'company-unit.*',
    READ: 'company-unit.read',
    LIST: 'company-unit.list',
    CREATE: 'company-unit.create',
    UPDATE: 'company-unit.update',
    DELETE: 'company-unit.delete',
  },
  COMPONENT_INSTANCE: {
    ANY: 'component-instance.*',
    READ: 'component-instance.read',
    LIST: 'component-instance.list',
    CREATE: 'component-instance.create',
    UPDATE: 'component-instance.update',
    DELETE: 'component-instance.delete',
  },
  COMPONENT_TEMPLATE: {
    ANY: 'component-template.*',
    READ: 'component-template.read',
    LIST: 'component-template.list',
    CREATE: 'component-template.create',
    UPDATE: 'component-template.update',
    DELETE: 'component-template.delete',
  },
  DASHBOARD: {
    ANY: 'dashboard.*',
    READ: 'dashboard.read',
    LIST: 'dashboard.list',
  },
  MACHINE_INSTANCE: {
    ANY: 'machine-instance.*',
    READ: 'machine-instance.read',
    LIST: 'machine-instance.list',
    CREATE: 'machine-instance.create',
    UPDATE: 'machine-instance.update',
    DELETE: 'machine-instance.delete',
  },
  SERVICE_LOG: {
    ANY: 'service-log.*',
    READ: 'service-log.read',
    LIST: 'service-log.list',
    CREATE: 'service-log.create',
    UPDATE: 'service-log.update',
    DELETE: 'service-log.delete',
  },
  MACHINE_TEMPLATE: {
    ANY: 'machine-template.*',
    READ: 'machine-template.read',
    LIST: 'machine-template.list',
    CREATE: 'machine-template.create',
    UPDATE: 'machine-template.update',
    DELETE: 'machine-template.delete',
  },
  QRCODE: {
    ANY: 'qrcode.*',
    READ: 'qrcode.read',
    LIST: 'qrcode.list',
  },
  MEDIA: {
    ANY: 'media.*',
    READ: 'media.read',
    LIST: 'media.list',
    CREATE: 'media.create',
    UPDATE: 'media.update',
    DELETE: 'media.delete',
  },
  MEDIA_TYPE: {
    ANY: 'media-type.*',
    CONTRACT: 'media-type.contract',
    GENERAL: 'media-type.video',
    AVATAR: 'media-type.avatar',
  },
  MEDIA_VERSION: {
    ANY: 'media-version.*',
    READ: 'media-version.read',
    LIST: 'media-version.list',
    CREATE: 'media-version.create',
    UPDATE: 'media-version.update',
    DELETE: 'media-version.delete',
  },
  SERVICE_LEVEL_AGREEMENT: {
    ANY: 'service-level-agreement.*',
    READ: 'service-level-agreement.read',
    LIST: 'service-level-agreement.list',
    CREATE: 'service-level-agreement.create',
    UPDATE: 'service-level-agreement.update',
    DELETE: 'service-level-agreement.delete',
  },
  TASK: {
    ANY: 'task.*',
    READ: 'task.read',
    LIST: 'task.list',
    CREATE: 'task.create',
    UPDATE: 'task.update',
    DELETE: 'task.delete',
    ASSIGN_USER: 'task.assign-user',
    CHANGE_STATUS: 'task.change-status',
    PLAN: 'task.plan',
  },
  TASK_TEMPLATE: {
    ANY: 'task-template.*',
    READ: 'task-template.read',
    LIST: 'task-template.list',
    CREATE: 'task-template.create',
    UPDATE: 'task-template.update',
    DELETE: 'task-template.delete',
  },
  TO_DO_LIST: {
    ANY: 'to-do-list.*',
    READ: 'to-do-list.read',
    LIST: 'to-do-list.list',
    CREATE: 'to-do-list.create',
    UPDATE: 'to-do-list.update',
    DELETE: 'to-do-list.delete',
  },
  TO_DO_LIST_ITEM: {
    ANY: 'to-do-list-item.*',
    READ: 'to-do-list-item.read',
    LIST: 'to-do-list-item.list',
    CREATE: 'to-do-list-item.create',
    UPDATE: 'to-do-list-item.update',
    DELETE: 'to-do-list-item.delete',
    CHECK_UPDATE: 'to-do-list-item.check-update',
  },
  USER: {
    ANY: 'user.*',
    READ: 'user.read',
    LIST: 'user.list',
    CREATE: 'user.create',
    UPDATE: 'user.update',
    DELETE: 'user.delete',
  },
  WORK_PROTOCOL: {
    ANY: 'work-protocol.*',
    READ: 'work-protocol.read',
    LIST: 'work-protocol.list',
    CREATE: 'work-protocol.create',
    UPDATE: 'work-protocol.update',
    DELETE: 'work-protocol.delete',
  },
  CONTACT: {
    ANY: 'contact.*',
    READ: 'contact.read',
    LIST: 'contact.list',
    CREATE: 'contact.create',
    UPDATE: 'contact.update',
    DELETE: 'contact.delete',
  },
  ENTITY_ACCESS: {
    NO_LIMIT: 'entity-access.no-limit',
    BOTH: 'entity-access.by-both',
    BY_COMPANY: 'entity-access.by-company',
    BY_COMPANY_UNIT: 'entity-access.by-company-unit',
  },
} as const

type RecursiveValues<T> = {
  [Prop in keyof T]: T[Prop] extends Record<string, unknown>
    ? RecursiveValues<T[Prop]>
    : T[Prop]
}[keyof T]

export type Permission = RecursiveValues<typeof PERMISSIONS>

export const permissions = Object.values(PERMISSIONS).reduce<Permission[]>(
  (acc, curr) => [...acc, ...Object.values(curr)],
  []
)
