import useMediaCarouselStore from '@app/src/stores/useMediaCarouselStore'
import { Box, Container } from '@mui/material'
import { useState } from 'react'
import { Document, Page } from 'react-pdf'
import { useResizeDetector } from 'react-resize-detector'

import MediaCarouselNotFound from './NotFound'

const MediaCarouselDocument = () => {
  const { currentMedia } = useMediaCarouselStore()

  const { width, height, ref } = useResizeDetector()

  const [numPages, setNumPages] = useState<number>()

  if (!currentMedia || !currentMedia.signedUrl) return <MediaCarouselNotFound />

  return (
    <Box
      sx={{
        overflowY: 'auto',
        maxHeight: '100vh',
        overflowX: 'hidden',
        height: '100%',
        py: 8,
        '&::-webkit-scrollbar': {
          width: 8,
          height: 8,
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#ACACAC',
          borderRadius: 8,
        },
      }}
    >
      <Container maxWidth="md" ref={ref}>
        <Document
          file={currentMedia.signedUrl}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        >
          {numPages &&
            Array.from({ length: numPages }, (_, index) => index + 1).map(
              (pageNumber) => (
                <Box sx={{ marginBottom: 4 }}>
                  <Page pageNumber={pageNumber} height={height} width={width} />
                </Box>
              )
            )}
        </Document>
      </Container>
    </Box>
  )
}

export default MediaCarouselDocument
