import { GridMapper, LabeledValue } from '@aeqoom/components-web'
import { ComponentInstanceResponse } from '@aeqoom/contracts'
import { PERMISSIONS } from '@aeqoom/permissions'
import CompanyField from '@app/components/base/dialogs/_detailViewDialogs/_components/CompanyField'
import ComponentTemplate from '@app/components/base/dialogs/_detailViewDialogs/_components/ComponentTemplate'
import CoverImage from '@app/components/base/dialogs/_detailViewDialogs/_components/CoverImage'
import MachineInstance from '@app/components/base/dialogs/_detailViewDialogs/_components/MachineInstance'
import { FIELD_SHARED_STYLES } from '@app/components/base/dialogs/_detailViewDialogs/constants'
import CheckPermissions from '@app/components/CheckPermissions'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

type Props = {
  data: ComponentInstanceResponse
}

const GRID_MAP = [6, 5, 6, 5, 12]

const MachineInfo = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'machineInstances.detail.tabs.machineDetail.content',
  })

  const content = [
    <LabeledValue
      label={t('name')}
      value={data.name}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('inventoryNumber')}
      value={data.inventoryNumber}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('yearProduced')}
      value={data.yearProduced}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('warrantyUntil')}
      value={dayjs(data.warrantyUntil).format('LL')}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <CheckPermissions permissions={[PERMISSIONS.COMPONENT_TEMPLATE.LIST]}>
      <ComponentTemplate componentTemplateId={data.componentTemplateId} />
    </CheckPermissions>,
    <LabeledValue
      label={t('description')}
      value={data.description}
      sx={FIELD_SHARED_STYLES}
      type="multi-line"
    />,
  ]

  const companyContent = [
    <CompanyField companyId={data.companyId} />,
    <CompanyField companyUnitId={data.companyUnitId} />,
  ]

  return (
    <Grid container justifyContent="space-between">
      <Grid
        item
        xs={6}
        sx={{
          overflow: 'hidden',
        }}
      >
        <GridMapper template={GRID_MAP} paddingBottom={8}>
          {content}
        </GridMapper>
        <GridMapper template={GRID_MAP} paddingBottom={8}>
          {companyContent}
        </GridMapper>
        <MachineInstance machineInstanceId={data.machineInstanceId} />
      </Grid>
      <Grid xs={5}>
        <CoverImage avatar={data.avatar} />
      </Grid>
    </Grid>
  )
}

export default MachineInfo
