import { PERMISSIONS } from '@aeqoom/permissions'
import CustomDataGrid from '@app/components/base/datagrid/CustomDataGrid'
import ContractDialog from '@app/components/base/dialogs/_detailViewDialogs/ContractDialog'
import CreateSLADialogContent from '@app/components/base/dialogs/createForms/CreateSLADialogContent'
import {
  useGenerateTasks,
  useGetSlas,
} from '@app/queries/useServiceLevelAgreement'
import useUserStore from '@app/src/stores/useUserStore'
import { useDialogStore } from '@app/stores/useDialogStore'
import { DescriptionOutlined, Settings } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

import { getSlasDataColumns } from './col'

const Sla = () => {
  const { hasPermission } = useUserStore()
  const canReadSla = hasPermission(PERMISSIONS.SERVICE_LEVEL_AGREEMENT.READ)

  const getSlasQuery = useGetSlas()

  const generateTasksMutation = useGenerateTasks()
  const { openDialog } = useDialogStore()

  const { t } = useTranslation('web', { keyPrefix: 'contracts' })

  const columns: GridColDef[] = [
    ...getSlasDataColumns(t),
    {
      field: 'action',
      disableColumnMenu: true,
      headerName: t('table.header.action'),
      sortable: false,
      width: 70,
      align: 'left',
      cellClassName: 'actions',
      renderCell: (params) => (
        <Tooltip title={t('table.tooltips.generateTasks')}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              generateTasksMutation.mutate({
                params: { id: params.id as number },
              })
            }}
            className="actionButton"
            color="primary"
          >
            <Settings />
          </IconButton>
        </Tooltip>
      ),
    },
  ]

  return (
    <CustomDataGrid
      disableCreateNew={
        !hasPermission(PERMISSIONS.SERVICE_LEVEL_AGREEMENT.CREATE)
      }
      addClickOptions={{
        Dialog: CreateSLADialogContent,
        title: t('createContract.title'),
        options: {
          icon: DescriptionOutlined,
          isUnique: false,
        },
      }}
      onRowClick={({ row }) => {
        canReadSla &&
          openDialog(<ContractDialog id={row.id} />, row.name, {
            uniqueKey: `contract-${row.id}`,
          })
      }}
      pageLabel={t('title')}
      loading={getSlasQuery.isLoading}
      rows={getSlasQuery.data || []}
      columns={columns}
    />
  )
}

export default Sla
