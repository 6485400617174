import {
  accessLevelEnum,
  activationStateEnum,
  companies,
  companyType,
  companyUnits,
  contacts,
  media,
  mediaTypeEnum,
  mediaVersions,
  taskPriority,
  taskStatus,
  taskType,
  users,
} from '@aeqoom/db'
import { nullable, z } from '@aeqoom/zod'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

export const accessLevelSchema = z.enum(accessLevelEnum.enumValues)

export const mediaTypeSchema = z.enum(mediaTypeEnum.enumValues)

export const activationStateSchema = z.enum(activationStateEnum.enumValues)

export const taskTypeSchema = z.enum(taskType.enumValues)

export const taskStatusSchema = z.enum(taskStatus.enumValues)

export const taskPrioritySchema = z.enum(taskPriority.enumValues)

export const companyTypeSchema = z.enum(companyType.enumValues)

export const mediaSchema = z.object({
  ...createSelectSchema(media).pick({
    id: true,
    name: true,
    description: true,
    dateCreated: true,
    dateModified: true,
    accessLevel: true,
  }).shape,
  ...createSelectSchema(mediaVersions).pick({
    originalFilename: true,
    key: true,
    mimeType: true,
  }).shape,
  signedUrl: z.string().nullish(),
})

export const addMediaSchema = z.object({
  ...createInsertSchema(media).pick({
    name: true,
    description: true,
    accessLevel: true,
    companyId: true,
    companyUnitId: true,
  }).shape,
  ...createInsertSchema(mediaVersions).pick({
    originalFilename: true,
    mimeType: true,
    fileSize: true,
    key: true,
  }).shape,
})

export const getMediaSchema = z.object({
  ...createSelectSchema(media).pick({
    id: true,
    name: true,
    description: true,
    dateCreated: true,
    dateModified: true,
    accessLevel: true,
    mediaType: true,
  }).shape,
  ...createSelectSchema(mediaVersions).pick({
    originalFilename: true,
    key: true,
    mimeType: true,
    fileSize: true,
  }).shape,
  uploadedBy: createSelectSchema(users).pick({
    id: true,
    firstName: true,
    lastName: true,
    avatar: true,
  }),
  company: nullable(
    createSelectSchema(companies).pick({
      id: true,
      avatar: true,
      name: true,
    })
  ),
  companyUnit: createSelectSchema(companyUnits)
    .pick({
      id: true,
      name: true,
    })
    .nullable(),
  signedUrl: z.string().nullish(),
})

export const getManyMediaSchema = z.array(
  z.object({
    ...createSelectSchema(media).pick({
      id: true,
      name: true,
      description: true,
      dateCreated: true,
      dateModified: true,
      accessLevel: true,
      mediaType: true,
    }).shape,
    ...createSelectSchema(mediaVersions).pick({
      originalFilename: true,
      key: true,
      mimeType: true,
      fileSize: true,
    }).shape,
    uploadedBy: createSelectSchema(users).pick({
      id: true,
      firstName: true,
      lastName: true,
      avatar: true,
    }),
    company: nullable(
      createSelectSchema(companies).pick({
        id: true,
        avatar: true,
        name: true,
      })
    ),
    companyUnit: createSelectSchema(companyUnits)
      .pick({
        id: true,
        name: true,
      })
      .nullable(),
    signedUrl: z.string().nullish(),
  })
)

export const getContactsSchema = createSelectSchema(contacts)
