import MediaPreviewList from '@app/components/MediaPreviewList'
import { List } from '@mui/material'

type Props = {
  value: number[]
  showNumberOfRecords: number
  isEditing: boolean
  onRemove: (id: number) => void
}

const ListPreview = ({
  value,
  showNumberOfRecords,
  isEditing,
  onRemove,
}: Props) => {
  return (
    <List>
      {value?.map(
        (item, index) =>
          index < showNumberOfRecords && (
            <MediaPreviewList
              item={item}
              onRemove={onRemove}
              key={item}
              isEditing={isEditing}
            />
          )
      )}
    </List>
  )
}

export default ListPreview
