import { create } from 'zustand'

type Media = { id: number; signedUrl?: string | null; mimeType: string }

interface MediaCarouselState {
  isOpen: boolean
  currentMedia: Media | null
  media: Media[]
  setData: (media: Media[]) => void
  setCurrentMedia: (currentMedia: Media | null) => void
  setPrevious: () => void
  setNext: () => void
  openCarousel: ({
    media,
    currentMedia,
  }: {
    media?: Media[]
    currentMedia: Media
  }) => void
  closeCarousel: () => void
}

const useMediaCarouselStore = create<MediaCarouselState>((set, get) => ({
  isOpen: false,
  currentMedia: null,
  setCurrentMedia: (currentMedia) =>
    set(() => ({
      currentMedia,
      isOpen: !!currentMedia,
    })),
  media: [],
  closeCarousel: () => set({ isOpen: false }),
  openCarousel: ({ media, currentMedia }) =>
    set(() => ({
      media: media || [currentMedia],
      currentMedia,
      isOpen: true,
    })),
  setData: (media) => set({ media }),
  setPrevious: () => {
    const { media, currentMedia } = get()
    const currentIndex = media.findIndex((item) => item.id === currentMedia?.id)
    const previousIndex = currentIndex - 1
    if (previousIndex < 0) {
      set({ currentMedia: media[media.length - 1] })
    } else {
      set({ currentMedia: media[previousIndex] })
    }
  },
  setNext: () => {
    const { media, currentMedia } = get()
    const currentIndex = media.findIndex((item) => item.id === currentMedia?.id)
    const nextIndex = currentIndex + 1
    if (nextIndex >= media.length) {
      set({ currentMedia: media[0] })
    } else {
      set({ currentMedia: media[nextIndex] })
    }
  },
}))

export default useMediaCarouselStore
