import { companyUnits, machineInstances, machineTemplates } from '@aeqoom/db'
import { z } from '@aeqoom/zod'
import {
  ClientInferRequest,
  ClientInferResponseBody,
  initContract,
} from '@ts-rest/core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

import { componentInstanceSchema, globalFilter } from '../schemas'

const c = initContract()

const machineInstanceResponse200 = z.object({
  ...createSelectSchema(machineInstances).shape,
  componentInstances: componentInstanceSchema.array(),
  media: z.array(z.number()),
  qrcode: z.string().nullable(),
})

const machineInstancesRouter = c.router({
  getMachineInstances: {
    method: 'GET',
    path: '/machine-instances',
    query: z.intersection(
      z.object({
        machineInstanceId: z.number().optional(),
        companyId: z.coerce.number().nullish(),
        companyUnitId: z.coerce.number().nullish(),
        serviceLevelAgreementId: z.coerce.number().nullish(),
      }),
      globalFilter
    ),
    responses: {
      200: z.array(
        z.object({
          ...createSelectSchema(machineInstances).shape,
          machineTemplate: createSelectSchema(machineTemplates).nullable(),
          companyUnit: createSelectSchema(companyUnits).nullable(),
        })
      ),
    },
  },
  getMachineInstance: {
    method: 'GET',
    path: '/machine-instances/:id/get',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: machineInstanceResponse200,
      404: z.object({ message: z.string() }),
    },
  },
  addMachineInstance: {
    method: 'POST',
    path: '/machine-instances',
    body: z.object({
      ...createInsertSchema(machineInstances).shape,
      componentInstances: z
        .array(
          z.object({
            id: z.number(),
            parentId: z.number().nullable().optional(),
          })
        )
        .optional(),
      media: z.array(z.number()),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  deleteMachineInstance: {
    method: 'DELETE',
    path: '/machine-instances/:id/delete',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({}),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  editMachineInstance: {
    method: 'POST',
    path: '/company-instances/:id/edit',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({
      ...createInsertSchema(machineInstances).shape,
      componentInstances: z
        .array(
          z.object({
            id: z.number(),
            parentId: z.number().nullable().optional(),
          })
        )
        .optional(),
      media: z.array(z.number()),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
})

export type GetMachineInstancesRequest = ClientInferRequest<
  typeof machineInstancesRouter.getMachineInstances
>

export type GetMachineInstancesResponseBody = ClientInferResponseBody<
  typeof machineInstancesRouter.getMachineInstances
>

export type AddMachineInstanceRequest = ClientInferRequest<
  typeof machineInstancesRouter.addMachineInstance
>

export type EditMachineInstanceRequest = ClientInferRequest<
  typeof machineInstancesRouter.editMachineInstance
>

export type DeleteMachineInstanceRequest = ClientInferRequest<
  typeof machineInstancesRouter.deleteMachineInstance
>

export type MachineInstanceResponse = z.infer<typeof machineInstanceResponse200>

export default machineInstancesRouter
