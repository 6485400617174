import { Accordion, FormNumberField } from '@aeqoom/components-web'
import { AddToDoListForm } from '@aeqoom/forms'
import { DeleteForever } from '@mui/icons-material'
import { AccordionDetails, IconButton, Stack } from '@mui/material'
import { Control, FieldErrors, useWatch } from 'react-hook-form'

import { FormTextField } from '../base/form/FormTextField'
import { FormUploadField } from '../base/form/FormUploadField'

type Props = {
  remove: (index: number) => void
  control: Control<AddToDoListForm>
  index: number
  readOnly?: boolean
  errors: FieldErrors<AddToDoListForm>
}

const ToDoListItem = ({ remove, control, index, readOnly, errors }: Props) => {
  const toDoListItem = useWatch({
    control,
    name: `toDoListItems.${index}`,
  })

  const isEmpty = !toDoListItem?.name

  const hasErrors = Array.isArray(errors.toDoListItems)
    ? errors.toDoListItems[index]
    : false

  return (
    <Accordion
      title={isEmpty ? 'To do list item' : toDoListItem.name}
      summaryActions={[
        <IconButton disabled={readOnly} onClick={() => remove(index)}>
          <DeleteForever />
        </IconButton>,
      ]}
      error={hasErrors}
    >
      <AccordionDetails>
        <Stack spacing={2}>
          <FormTextField
            controller={{
              control,
              name: `toDoListItems.${index}.name`,
            }}
            field={{
              label: 'Name',
              InputProps: { readOnly },
              fullWidth: true,
              required: true,
            }}
          />
          <FormNumberField
            control={control}
            name={`toDoListItems.${index}.order`}
            label="Order"
            required
            fullWidth
            InputProps={{ readOnly }}
          />
          <FormTextField
            controller={{
              control,
              name: `toDoListItems.${index}.content`,
            }}
            field={{
              label: 'Content',
              InputProps: { readOnly },
              rows: 4,
              multiline: true,
              fullWidth: true,
              required: true,
            }}
          />
          <FormUploadField
            control={control}
            name={`toDoListItems.${index}.media`}
            defaultAccessLevel={['public']}
            disableAccessLevel
            isEditing={!readOnly}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default ToDoListItem
