import { relations } from 'drizzle-orm'
import {
  integer,
  pgEnum,
  pgTable,
  serial,
  timestamp,
  varchar,
} from 'drizzle-orm/pg-core'

import {
  companies,
  companyUnits,
  media,
  parentTasks,
  serviceBookRecords,
  tasks,
  userOnParentTasks,
} from './index'
import { UserGroups } from './types'
import { enumToPgEnum } from './utils'

export const userGroupEnum = pgEnum('groups', enumToPgEnum(UserGroups))

export const activationStateEnum = pgEnum('activation_state', [
  'draft',
  'activated',
  'disabled',
])

export const users = pgTable('users', {
  id: serial('id').primaryKey(),
  sub: varchar('sub', { length: 256 }).notNull(),
  avatar: integer('avatar').references(() => media.id, {
    onDelete: 'set null',
  }),
  emailAddress: varchar('email_address', { length: 256 }).unique().notNull(),
  firstName: varchar('first_name', { length: 256 }).default('').notNull(),
  lastName: varchar('last_name', { length: 256 }).default('').notNull(),
  activationState: activationStateEnum('activation_state')
    .default('draft')
    .notNull(),
  companyId: integer('company_id').references(() => companies.id),
  companyUnitId: integer('company_unit_id').references(() => companyUnits.id),
  group: userGroupEnum('group').notNull(),
  createdAt: timestamp('created_at').defaultNow().notNull(),
  updatedAt: timestamp('updated_at').$onUpdate(() => new Date()),
})

export type User = typeof users.$inferSelect
export type UserWithCognito = User & { cognitoId: string; name: string }
export type NewUser = typeof users.$inferInsert

export const userRelations = relations(users, ({ one, many }) => ({
  company: one(companies, {
    fields: [users.companyId],
    references: [companies.id],
  }),
  companyUnit: one(companyUnits, {
    fields: [users.companyUnitId],
    references: [companyUnits.id],
  }),
  serviceBookRecords: many(serviceBookRecords),
  parentTasks: many(userOnParentTasks),
  tasks: many(tasks),
  managedParentTasks: many(parentTasks),
  avatar: one(media, {
    fields: [users.avatar],
    references: [media.id],
  }),
}))
