import { MachineInstanceResponse } from '@aeqoom/contracts'
import ComponentInstanceDialog from '@app/components/base/dialogs/_detailViewDialogs/ComponentInstanceDialog'
import { useGetCompany } from '@app/queries/useCompanies'
import { useGetCompanyUnit } from '@app/queries/useCompanyUnits'
import { useDialogStore } from '@app/stores/useDialogStore'
import { TableCell, TableRow } from '@mui/material'

type Props = {
  componentInstance: Required<MachineInstanceResponse>['componentInstances'][number]
}

const ComponentRow = ({ componentInstance }: Props) => {
  const { openDialog } = useDialogStore()

  const companyQuery = useGetCompany({
    id: componentInstance.companyId,
  })
  const companyUnitQuery = useGetCompanyUnit({
    id: componentInstance.companyUnitId,
  })

  return (
    <TableRow
      hover
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        openDialog(
          <ComponentInstanceDialog id={componentInstance.id} />,
          `Component - ${componentInstance.name}`
        )
      }}
    >
      <TableCell sx={{ width: '40%' }}>{componentInstance.name}</TableCell>
      <TableCell>{componentInstance.inventoryNumber}</TableCell>
      <TableCell>{companyQuery.data && companyQuery.data.name}</TableCell>
      <TableCell>
        {companyUnitQuery.data && companyUnitQuery.data.name}
      </TableCell>
    </TableRow>
  )
}

export default ComponentRow
