import MainDialog from '@app/components/base/dialogs/MainDialog'
import { useDialogStore } from '@app/stores/useDialogStore'
import { DialogStatus } from '@app/stores/useDialogStore/types'
import { Fab, Stack, Zoom } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'

// TODO: change to theme values once AI chat in custom created and can be styled
const CHATBASE_BOTTOM = '83px'
const CHATBASE_RIGHT = '21px'
const CHATBASE_BOX_SHADOW = 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px'

const DialogManager = () => {
  const { activeDialogs, previousDialogs, maximizeDialog } = useDialogStore()

  return (
    <>
      <Stack
        position="fixed"
        bottom={CHATBASE_BOTTOM}
        right={CHATBASE_RIGHT}
        gap={2}
      >
        {Object.values(activeDialogs).map(
          ({ title, status, dialogId, config: { icon: Icon } }) =>
            status === DialogStatus.MINIMIZED && (
              <Tooltip
                sx={{ boxShadow: CHATBASE_BOX_SHADOW }}
                title={title}
                arrow
                placement="left"
                key={dialogId}
              >
                <Zoom in unmountOnExit>
                  <Fab size="small" onClick={() => maximizeDialog(dialogId)}>
                    <Icon />
                  </Fab>
                </Zoom>
              </Tooltip>
            )
        )}
      </Stack>
      {Object.values(activeDialogs).map((dialog) => (
        <MainDialog key={dialog.dialogId} dialog={dialog} />
      ))}
    </>
  )
}

export default DialogManager
