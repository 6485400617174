import { PostUser } from '@aeqoom/db'
import { useThemeColors } from '@aeqoom/hooks'
import { useDialogStore } from '@app/stores/useDialogStore'
import { DialogCustomOptions } from '@app/stores/useDialogStore/types'
import { AddOutlined } from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import { styled } from '@mui/material'
import { DataGrid, DataGridProps, GridRowParams } from '@mui/x-data-grid'
import { ReactElement } from 'react'

export const PageLabel = styled('span')(({ theme }) => ({
  marginTop: 12,
  fontWeight: 600,
  fontSize: 24,
  display: 'block',
  color: theme.palette.secondary.main,
}))

interface CustomDataGridProps extends DataGridProps {
  addClickOptions?: {
    Dialog: ({
      id,
      isEditModal,
      readOnly,
      data,
    }: {
      id?: number
      isEditModal?: boolean
      readOnly?: boolean
      data?: PostUser
    }) => ReactElement
    title: string
    options: DialogCustomOptions
  }
  pageLabel?: string
  pageSize?: number
  onRowClick?: (params: GridRowParams) => void
  disableCreateNew?: boolean
}

const CustomDataGrid = ({
  addClickOptions,
  pageLabel,
  pageSize = 50,
  onRowClick,
  disableCreateNew = false,
  ...dataGridProps
}: CustomDataGridProps) => {
  const { openDialog } = useDialogStore()

  const { white } = useThemeColors()

  return (
    <>
      <Stack
        mb={2}
        direction="row"
        justifyContent="space-between"
        alignItems="end"
      >
        {pageLabel && <PageLabel>{pageLabel}</PageLabel>}
        {addClickOptions && !disableCreateNew && (
          <Button
            size="medium"
            onClick={() =>
              openDialog(<addClickOptions.Dialog />, addClickOptions.title, {
                ...addClickOptions.options,
              })
            }
            variant="contained"
            startIcon={<AddOutlined />}
          >
            Add
          </Button>
        )}
      </Stack>
      <DataGrid
        autoHeight
        sx={{
          background: white.main,
          borderRadius: 2,
          overflow: 'hidden',
          border: 0,
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#FCFCFD',
          },

          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },

          '& .actionButton': {
            display: 'none',
          },
          '& .Mui-hovered:hover': {
            '.actionButton': {
              display: 'inline-flex',
            },
          },
        }}
        onRowClick={(params) => {
          if (onRowClick) return onRowClick(params)

          if (addClickOptions) {
            openDialog(
              <addClickOptions.Dialog
                id={params.row.id}
                isEditModal
                readOnly
                data={params.row}
              />,
              params.row.name || pageLabel,
              addClickOptions.options
            )
          }
          return null
        }}
        initialState={{
          pagination: { paginationModel: { pageSize } },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        {...dataGridProps}
      />
    </>
  )
}

export default CustomDataGrid
