import useMediaCarouselStore from '@app/src/stores/useMediaCarouselStore'
import { ChevronLeft, ChevronRight, Circle, Close } from '@mui/icons-material'
import { Box, IconButton, Modal, Stack } from '@mui/material'

import MediaCarouselContent from './Content'

const MediaCarousel = () => {
  const {
    isOpen,
    media,
    currentMedia,
    setCurrentMedia,
    setPrevious,
    setNext,
    closeCarousel,
  } = useMediaCarouselStore()

  return (
    <Modal
      open={isOpen}
      onClose={() => closeCarousel()}
      slotProps={{
        backdrop: { sx: { backgroundColor: 'rgba(0, 0, 0, 0.75)' } },
      }}
    >
      <Box sx={{ height: '100%' }}>
        <Stack
          spacing={2}
          justifyContent="flex-end"
          direction="row"
          sx={{ position: 'absolute', width: '100%', zIndex: 1000, padding: 2 }}
        >
          <IconButton onClick={() => closeCarousel()} color="white">
            <Close />
          </IconButton>
        </Stack>
        <Stack
          direction="row"
          sx={{
            position: 'absolute',
            width: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1000,
            padding: 2,
          }}
          justifyContent="space-between"
        >
          {media?.length > 1 && (
            <>
              <IconButton onClick={setPrevious} color="white">
                <ChevronLeft />
              </IconButton>
              <IconButton onClick={setNext} color="white">
                <ChevronRight />
              </IconButton>
            </>
          )}
        </Stack>
        <Stack
          spacing={1}
          sx={{
            position: 'absolute',
            width: '100%',
            bottom: 8,
            zIndex: 1000,
          }}
          justifyContent="center"
          direction="row"
        >
          {media?.length > 1 &&
            media.map((item) => (
              <IconButton
                size="small"
                color={item.id === currentMedia?.id ? 'primary' : 'white'}
                onClick={() => setCurrentMedia(item)}
              >
                <Circle fontSize="small" />
              </IconButton>
            ))}
        </Stack>
        <MediaCarouselContent />
      </Box>
    </Modal>
  )
}

export default MediaCarousel
