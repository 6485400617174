import { Stack, Typography } from '@mui/material'

type Props = {
  count: string | number
  label: string
  icon?: React.ReactNode
}

const TaskCountByStatus = ({ count, label, icon }: Props) => {
  return (
    <Stack direction="row" alignItems="center" gap={0.7}>
      {icon}
      <Typography variant="body1Bold">{count}</Typography>
      <Typography variant="body3Medium">{label}</Typography>
    </Stack>
  )
}

export default TaskCountByStatus
