import {
  DialogChain,
  DialogCustomOptions,
  DialogId,
  DialogStatus,
  DialogStore,
  IDialogConfig,
} from '@app/stores/useDialogStore/types'
import { DialogProps } from '@mui/material'
import * as Sentry from '@sentry/react'
import { v4 as uuidv4 } from 'uuid'

const IS_DEV = import.meta.env.MODE === 'development'

const ERROR_MESSAGE = `UNIQUE_KEY is required for unique dialogs!. Please provide a UNIQUE_KEY string (such as [item.name]-[item.id]) or set the IS_UNIQUE flag to false. Unique dialogs are often used for modals that display unique content like specific machine, task etc..`

export const getCurrentOpenDialog = (prev: DialogStore) => {
  const currentOpenDialog = Object.values(prev.activeDialogs).find(
    ({ status }) => status === DialogStatus.OPEN
  )
  return currentOpenDialog ?? null
}

export const getDialogAttributes = (
  config: Partial<IDialogConfig>
): Omit<DialogProps, 'open'> => {
  const dialogAttributes = { ...config }

  delete dialogAttributes.shouldShowTitle
  delete dialogAttributes.closeOnBackdrop
  delete dialogAttributes.closeOnEsc
  delete dialogAttributes.icon
  delete dialogAttributes.shouldShowBreadcrumb
  delete dialogAttributes.isUnique
  delete dialogAttributes.uniqueKey

  return { ...dialogAttributes }
}

export const updateDialogConfig = (
  defaultConfig: Omit<IDialogConfig, 'dialogId'>,
  config: DialogCustomOptions
): IDialogConfig => {
  return { ...defaultConfig, ...config }
}

export const findTargetRoot = (prev: DialogStore, dialogId: DialogId) => {
  const targetRoot = Object.values(prev.previousDialogs).find(
    ({ previousIds }) => previousIds.includes(dialogId)
  )

  return targetRoot
}

export const getDialogOrder = (
  currentOpenDialogId: string,
  targetRoot: DialogChain
) => {
  const indexOfCurrentOpenDialog =
    targetRoot?.previousIds.indexOf(currentOpenDialogId)

  return indexOfCurrentOpenDialog >= 0
    ? indexOfCurrentOpenDialog + 1
    : Number(targetRoot.previousIds.length) + 1
}

export const createDialogId = (
  title: string,
  config: IDialogConfig,
  prev: DialogStore
) => {
  const currentOpenDialog = getCurrentOpenDialog(prev)

  if (currentOpenDialog) {
    const targetRootId = findTargetRoot(
      prev,
      currentOpenDialog.dialogId
    )?.rootId

    return `${targetRootId ?? currentOpenDialog.dialogId}-${title}`
  }

  if (config.isUnique && !config.uniqueKey) {
    if (IS_DEV) {
      throw new Error(ERROR_MESSAGE)
    } else {
      Sentry.captureException(ERROR_MESSAGE)
      return `${uuidv4()}-${title}`
    }
  } else if (config.uniqueKey) {
    return `${config.uniqueKey}-${title}`
  } else {
    return `${uuidv4()}-${title}`
  }
}

export const getDialogToOpenId = (
  targetRoot: DialogChain,
  selectedDialogId: DialogId,
  target: 'next' | 'previous' = 'previous'
): DialogId => {
  const indexOfSelectedDialog = targetRoot.previousIds.indexOf(selectedDialogId)

  if (targetRoot.previousIds.length <= indexOfSelectedDialog + 1) {
    return targetRoot.previousIds[indexOfSelectedDialog - 1]
  }

  if (targetRoot.previousIds.length <= 1) {
    return targetRoot.previousIds[indexOfSelectedDialog + 1]
  }

  if (target === 'previous') {
    return targetRoot.previousIds[indexOfSelectedDialog - 1]
  }

  return targetRoot.previousIds[indexOfSelectedDialog + 1]
}
