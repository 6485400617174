import { PERMISSIONS } from '@aeqoom/permissions'
import AddDeviceDialog from '@app/components/base/dialogs/AddDeviceDialog'
import CreateUserDialogContent from '@app/components/base/dialogs/createForms/CreateUserDialogContent'
import CheckPermissions from '@app/components/CheckPermissions'
import FetchableAvatar from '@app/components/FetchableAvatar'
import useUser from '@app/hooks/useUser'
import { useGetMe } from '@app/queries/useUsers'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { Logout, Person, PersonAdd, PhonelinkSetup } from '@mui/icons-material'
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
} from '@mui/material'
import { MouseEvent, useId, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const IS_DEV = import.meta.env.MODE === 'development'

const UserMenu = () => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorElement)

  const menuId = useId()

  const { t } = useTranslation()

  const { openDialog } = useDialogStore()

  const navigate = useNavigate()

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElement(null)
  }

  const handleAddUser = () => {
    openDialog(<CreateUserDialogContent />, t('profile.menu.createNewUser'), {
      icon: PersonAdd,
      isUnique: false,
    })
    setAnchorElement(null)
  }

  const handleAddDevice = () => {
    openDialog(<AddDeviceDialog />, t('auth.addDevice'), {
      icon: PhonelinkSetup,
      isUnique: false,
    })
    setAnchorElement(null)
  }

  const { signOutClearProfile } = useUser()

  const { data, isPending, isError } = useGetMe()

  if (isPending || isError)
    return <Skeleton variant="circular" width={42} height={42} />

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={isOpen ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
      >
        <FetchableAvatar id={data.avatar} width={42} height={42} />
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorElement}
        open={isOpen}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 1,
          },
        }}
      >
        <MenuItem onClick={() => navigate('/admin/profile')}>
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={`${data.firstName} ${data.lastName}`.trim()}
            secondary={data.emailAddress}
          />
        </MenuItem>
        <Divider />
        {IS_DEV && (
          <MenuItem onClick={handleAddDevice}>
            <ListItemIcon>
              <PhonelinkSetup fontSize="small" />
            </ListItemIcon>
            {t('profile.menu.addDevice')}
          </MenuItem>
        )}
        <CheckPermissions permissions={[PERMISSIONS.USER.CREATE]}>
          <MenuItem onClick={handleAddUser}>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            {t('profile.menu.addUser')}
          </MenuItem>
        </CheckPermissions>
        <MenuItem onClick={signOutClearProfile}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('profile.menu.logout')}
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserMenu
