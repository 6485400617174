import { SignInSchemaForm } from '@aeqoom/forms'
import { UserGroups } from '@aeqoom/shared'
import { client } from '@app/queries/client'
import { useDialogStore } from '@app/stores/useDialogStore'
import useUserStore from '@app/stores/useUserStore'
import * as Auth from 'aws-amplify/auth'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const useUser = () => {
  const { setUser, clearUser } = useUserStore()
  const { clearDialogStore } = useDialogStore()
  const navigate = useNavigate()

  const setStoreUser = async () => {
    const { accessToken } = (await Auth.fetchAuthSession()).tokens ?? {}
    if (!accessToken) return

    await Auth.getCurrentUser()

    const { sub, email } = await Auth.fetchUserAttributes()

    const res = await client.users.getMe()

    if (res.status === 200) {
      const { firstName, lastName, company, companyUnit, avatar, id, group } =
        res.body

      if (email && group && sub) {
        setUser({
          user: {
            id,
            firstName,
            lastName,
            sub,
            email,
            groups: group as UserGroups,
            company,
            companyUnit,
          },
          activeCompany: null,
          activeCompanyUnit: null,
        })
      }
    }
  }

  const signInSetProfile = async ({
    emailAddress,
    password,
  }: SignInSchemaForm) => {
    const { isSignedIn } = await Auth.signIn({
      username: emailAddress,
      password,
      options: {
        authFlowType: 'USER_PASSWORD_AUTH',
      },
    })
    if (!isSignedIn) {
      await Auth.confirmSignIn({ challengeResponse: password })
    }
    await setStoreUser()
    navigate('/')
  }

  const signOutClearProfile = async () => {
    try {
      clearUser()
      clearDialogStore()
      await Auth.signOut()
      navigate('/sign-in')
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message)
      }
    }
  }

  const handleResetPassword = async (data: { emailAddress: string }) => {
    const output = await Auth.resetPassword({
      username: data.emailAddress,
    })
    return output.nextStep
  }

  const handleConfirmResetPassword = async ({
    emailAddress,
    confirmationCode,
    newPassword,
  }: {
    emailAddress: string
    confirmationCode: string
    newPassword: string
  }) => {
    return Auth.confirmResetPassword({
      username: emailAddress,
      confirmationCode,
      newPassword,
    })
  }

  return {
    signInSetProfile,
    setStoreUser,
    signOutClearProfile,
    handleResetPassword,
    handleConfirmResetPassword,
  }
}

export default useUser
