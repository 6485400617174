import { useGetCompanyUnitContacts } from '@app/queries/useContacts'

import ContactView from '../ContactView'

type Props = {
  companyUnitId: number
}

const ContactCompanyUnit = ({ companyUnitId }: Props) => {
  const { data, isPending, isError } = useGetCompanyUnitContacts({
    id: companyUnitId,
  })

  return <ContactView contacts={data} isPending={isPending} isError={isError} />
}

export default ContactCompanyUnit
