import { createUniqueKey, trimStringWithExtension } from '@aeqoom/utils'
import { useGetOneMedia } from '@app/queries/useMedia'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { Download, Remove, Warning } from '@mui/icons-material'
import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
} from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import MediaDetailDialog from '../base/dialogs/_detailViewDialogs/MediaDetailDialog'
import CustomListItem from '../CustomListItem'
import FileIcon from '../FileIcon'

type Props = {
  item: number
  onRemove: (id: number) => void
  isEditing: boolean
}

const MediaPreviewList = ({ item, isEditing, onRemove }: Props) => {
  const { openDialog } = useDialogStore()

  const { t } = useTranslation('web')

  const { data, isPending, isError } = useGetOneMedia({
    id: item,
  })

  if (isPending)
    return (
      <Box sx={{ p: 1 }}>
        <Skeleton width="100%" height={24} variant="rectangular" />
      </Box>
    )

  if (isError)
    return (
      <ListItem sx={{ height: 40 }}>
        <ListItemIcon>
          <Warning color="error" />
        </ListItemIcon>
        <ListItemText>{t('media.failedToLoad')}</ListItemText>
      </ListItem>
    )

  return (
    <CustomListItem
      key={item}
      disablePadding
      disableGutters
      secondaryAction={
        <Stack direction="row">
          <IconButton
            href={data.signedUrl || undefined}
            component="a"
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            <Download />
          </IconButton>
          {isEditing && (
            <IconButton onClick={() => onRemove(item)}>
              <Remove />
            </IconButton>
          )}
        </Stack>
      }
    >
      <ListItemButton
        onClick={(e) => {
          e.stopPropagation()
          openDialog(
            <MediaDetailDialog activeMedia={item} />,
            data.name || trimStringWithExtension(data.originalFilename),
            {
              uniqueKey: createUniqueKey(['mediaDialog', data.id]),
            }
          )
        }}
      >
        <Stack spacing={2} direction="row" alignItems="center">
          <ListItemIcon>
            <FileIcon mimeType={data.mimeType} fontSize="medium" />
          </ListItemIcon>
        </Stack>
        <Stack direction="row" spacing={8} sx={{ width: '100%' }}>
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            primary={
              data.name || trimStringWithExtension(data.originalFilename)
            }
            sx={{ width: '50%' }}
          />
          <ListItemText disableTypography sx={{ width: '50%' }}>
            {data.dateCreated && dayjs(data.dateCreated).format('DD/MM/YYYY')}
          </ListItemText>
        </Stack>
      </ListItemButton>
    </CustomListItem>
  )
}

export default MediaPreviewList
