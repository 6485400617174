import AdminLayout from '@app/components/AdminLayout'
import RequireAuth from '@app/components/AdminLayout/RequireAuth'
import NotFound from '@app/components/NotFound'
import RedirectBasedOnAuth from '@app/components/RedirectBasedOnAuth'
import paths from '@app/constants/paths'
import Backlog from '@app/pages/Backlog'
import Dashboard from '@app/pages/Dashboard'
import Companies from '@app/pages/DatagridPages/Companies'
import CompanyUnits from '@app/pages/DatagridPages/CompanyUnits'
import ComponentInstances from '@app/pages/DatagridPages/ComponentInstances'
import ComponentTemplates from '@app/pages/DatagridPages/ComponentTemplates'
import MachineInstances from '@app/pages/DatagridPages/MachineInstances'
import MachineTemplates from '@app/pages/DatagridPages/MachineTemplates'
import Sla from '@app/pages/DatagridPages/Sla'
import Tasks from '@app/pages/DatagridPages/Tasks'
import TaskTemplates from '@app/pages/DatagridPages/TaskTemplates'
import TodolistItems from '@app/pages/DatagridPages/TodolistItems'
import Todolists from '@app/pages/DatagridPages/Todolists'
import Users from '@app/pages/DatagridPages/Users'
import Maintenance from '@app/pages/Maintenance'
import Profile from '@app/pages/Profile'
import SignIn from '@app/pages/SignIn'
import Support from '@app/pages/Supports'
import UserProvider from '@app/providers/user'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'

import Calendar from './pages/Calendar/Calendar'
import Contacts from './pages/DatagridPages/Contacts'
import Media from './pages/Media/Media'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<UserProvider />}>
      <Route path={paths.index} element={<RedirectBasedOnAuth />} />
      <Route path={paths.signIn} element={<SignIn />} />

      <Route
        path={paths.admin.index}
        element={
          <RequireAuth>
            <AdminLayout />
          </RequireAuth>
        }
      >
        <Route path={paths.admin.profile} element={<Profile />} />
        <Route path={paths.admin.dashboard} element={<Dashboard />} />
        <Route path={paths.admin.backlog} element={<Backlog />} />
        <Route path={paths.admin.sla} element={<Sla />} />
        <Route path={paths.admin.maintenance} element={<Maintenance />} />
        <Route path={paths.admin.support} element={<Support />} />
        <Route path={paths.admin.users} element={<Users />} />
        <Route path={paths.admin.taskTemplates} element={<TaskTemplates />} />
        <Route path={paths.admin.tasks} element={<Tasks />} />
        <Route path={paths.admin.todolists} element={<Todolists />} />
        <Route path={paths.admin.todolistItems} element={<TodolistItems />} />
        <Route path={paths.admin.contacts} element={<Contacts />} />
        <Route
          path={paths.admin.componentInstances}
          element={<ComponentInstances />}
        />
        <Route
          path={paths.admin.componentTemplates}
          element={<ComponentTemplates />}
        />
        <Route
          path={paths.admin.machineInstances}
          element={<MachineInstances />}
        />
        <Route
          path={paths.admin.machineTemplates}
          element={<MachineTemplates />}
        />
        <Route path={paths.admin.companyUnits} element={<CompanyUnits />} />
        <Route path={paths.admin.companies} element={<Companies />} />
        <Route path={paths.admin.media} element={<Media />} />
        <Route path={paths.admin.calendar} element={<Calendar />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  )
)
