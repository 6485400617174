import { PERMISSIONS } from '@aeqoom/permissions'
import paths from '@app/constants/paths'
import useUserStore from '@app/src/stores/useUserStore'
import { getCurrentUser } from 'aws-amplify/auth'
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

const RedirectBasedOnAuth = () => {
  const [redirectTo, setRedirectTo] = useState('')
  const { hasPermission } = useUserStore()

  useEffect(() => {
    const checkAuthStatus = async () => {

      try {
        await getCurrentUser()

        if (hasPermission(PERMISSIONS.DASHBOARD.LIST)) {
          setRedirectTo(paths.admin.dashboard)
        } else {
          setRedirectTo(paths.admin.backlog)
        }
      } catch {
        setRedirectTo(paths.signIn)
      }
    }

    checkAuthStatus()
  }, [])

  return <Navigate to={redirectTo} />
}

export default RedirectBasedOnAuth
