import { useThemeColors } from '@aeqoom/hooks'
import { ExpandMore } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import MUIAccordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import { alpha } from '@mui/material/styles'
import { MutableRefObject,ReactNode, useState } from 'react'

import Actions from './_components/Actions'
import Details from './_components/Details'
import Title from './_components/Title'

type Props = {
  title: ReactNode
  subtitle?: string | number | null
  children?: ReactNode
  readonly?: boolean
  error?: boolean
  summaryActions?: ReactNode[]
  defaultOpen?: boolean
  accordionRef?: MutableRefObject<HTMLDivElement | null>
  isActive?: boolean
}

const Accordion = ({
  title,
  subtitle,
  children,
  error,
  readonly,
  summaryActions,
  defaultOpen = false,
  accordionRef,
  isActive = false,
}: Props) => {
  const [accordionOpen, setAccordionOpen] = useState(defaultOpen)

  const { error: errorColor, primary, secondary } = useThemeColors()

  const handleAccordionToggle = () => {
    if (!readonly) {
      setAccordionOpen(!accordionOpen)
    }
  }

  const borderColor = error
    ? errorColor.main
    : isActive
    ? primary.main
    : secondary.light

  return (
    <MUIAccordion
      ref={accordionRef}
      disableGutters
      elevation={0}
      square
      expanded={!readonly && accordionOpen}
      sx={{
        border: `1px solid ${borderColor}`,
        borderRadius: '16px',
        overflow: 'hidden',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          backgroundColor: isActive
            ? alpha(primary.main, 0.3)
            : alpha(secondary.main, 0.1),
        }}
        onClick={handleAccordionToggle}
        expandIcon={
          <IconButton onClick={handleAccordionToggle}>
            <ExpandMore />
          </IconButton>
        }
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Stack>
            <Title>{title}</Title>
            {subtitle && <Typography variant="caption">{subtitle}</Typography>}
          </Stack>
          <Stack direction="row">{summaryActions}</Stack>
        </Stack>
      </AccordionSummary>
      {children}
    </MUIAccordion>
  )
}

Accordion.Details = Details
Accordion.Actions = Actions

export { Accordion }
