import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Typography variant="h1">404</Typography>
      <Typography variant="h4" component="p">
        Page was not found.
      </Typography>
      <Link to="/sign-in">
        <Button variant="contained">Sign in</Button>
      </Link>
    </Box>
  )
}

export default NotFound
