import { Edit } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  onClick: () => void
  icon?: ReactNode
}

export const ActionButton = ({ icon, onClick }: Props) => {
  const { t } = useTranslation('common')

  return (
    <Tooltip title={t('inputs.autoComplete.defaults.edit')}>
      <IconButton
        onClick={onClick}
        color="secondary"
        sx={{ padding: 0.5, visibility: 'hidden' }}
        // NOTE: MUI solution to show button only on hover
        className="MuiAutocomplete-clearIndicator"
      >
        {icon ?? <Edit fontSize="small" />}
      </IconButton>
    </Tooltip>
  )
}

export default ActionButton
