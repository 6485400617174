import { GetTaskResponseBody } from '@aeqoom/contracts'
import { Divider, Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import MachineDetail from './MachineDetail'
import TaskDetailList from './TaskDetailList'

type Props = {
  task: GetTaskResponseBody
}

const TaskDetail = ({ task }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'backlog.taskDetail.tabs.taskDetail',
  })

  return (
    <Stack gap={4} divider={<Divider orientation="horizontal" flexItem />}>
      <Grid container>
        <Grid item xs>
          <TaskDetailList task={task} />
        </Grid>
        <Grid xs>
          <Stack alignItems="flex-end" width="100%">
            <MachineDetail task={task} />
          </Stack>
        </Grid>
      </Grid>
      <Stack gap={2}>
        <Typography variant="h5">{t('description.label')}</Typography>
        {task.description}
      </Stack>
    </Stack>
  )
}

export default TaskDetail
