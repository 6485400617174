import {
  AddCompanyRequest,
  DeleteCompanyRequest,
  EditCompanyRequest,
} from '@aeqoom/contracts'
import { PERMISSIONS } from '@aeqoom/permissions'
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import useUserStore from '../stores/useUserStore'
import { client } from './client'
import parseIncoming from './parseIncoming'
import { invalidateCompanyUnitQueries } from './useCompanyUnits'
import { invalidateContactQueries } from './useContacts'

const keys = {
  getCompanies: ['companies'],
  getCompany: ['company'],
}

const invalidateCompanyQueries = (queryClient: QueryClient) => {
  const allKeys = Object.values(keys).flat()
  queryClient.invalidateQueries({
    predicate: (query) => allKeys.includes(query.queryKey[0] + ''),
  })
}

export const useGetCompanies = () => {
  const { hasPermission } = useUserStore()

  return useQuery({
    queryKey: keys.getCompanies,
    enabled: hasPermission(PERMISSIONS.COMPANY.LIST),
    queryFn: () => client.companies.getCompanies().then(parseIncoming),
  })
}

export const useGetCompany = ({
  id,
  enabled = !!id,
  staleTime,
}: {
  id?: number | null
  enabled?: boolean
  staleTime?: number
}) => {
  const { hasPermission } = useUserStore()

  return useQuery({
    enabled: enabled && hasPermission(PERMISSIONS.COMPANY.READ),
    queryKey: [...keys.getCompany, id],
    queryFn: () =>
      client.companies.getCompany({ params: { id: id! } }).then(parseIncoming),
    staleTime,
  })
}

export const useCreateCompany = () => {
  const queryClient = useQueryClient()
  
  return useMutation({
    onSuccess: () => {
      invalidateCompanyQueries(queryClient)
      invalidateCompanyUnitQueries(queryClient)
      invalidateContactQueries(queryClient)
    },
    mutationFn: (body: AddCompanyRequest['body']) =>
      client.companies.addCompany({ body }).then(parseIncoming),
  })
}

export const useEditCompany = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateCompanyQueries(queryClient)
      invalidateContactQueries(queryClient)
    },
    mutationFn: async ({
      body,
      params,
    }: {
      body: EditCompanyRequest['body']
      params: EditCompanyRequest['params']
    }) => client.companies.editCompany({ body, params }).then(parseIncoming),
  })
}

export const useDeleteCompany = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: DeleteCompanyRequest['params']) =>
      client.companies.deleteCompany({ params }).then(parseIncoming),
    onSuccess: () => {
      invalidateCompanyQueries(queryClient)
    },
  })
}
