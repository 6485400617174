import { z } from '@aeqoom/zod'

export const addCompanyUnitForm = z.object({
  avatar: z.number().nullish(),
  name: z.string().min(1),
  companyId: z.number(),
  contacts: z.array(
    z.object({
      contactId: z.number().positive(),
      main: z.boolean().nullish(),
    })
  ),
  street: z.string().nullish(),
  houseNumber: z.string().nullish(),
  zipCode: z.string().nullish(),
  city: z.string().nullish(),
  country: z.string().nullish(),
})

export type AddCompanyUnitForm = z.infer<typeof addCompanyUnitForm>
