import { Permission } from '@aeqoom/permissions'
import { SvgIconProps } from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import { Path } from 'react-hook-form'

export type DrawerItem = {
  name: string
  icon: ReactElement<SvgIconProps>
  permission?: Permission[]
  subItems?: DrawerSubItem[]
  to?: string
}

export type DrawerSubItem = {
  name: string
  to: string
  permission?: Permission[]
}

export enum TypographyVariant {
  BODY_1_MEDIUM = 'body1Medium',
  BODY_2_MEDIUM = 'body2Medium',
  BODY_3_MEDIUM = 'body3Medium',
  BODY_1_REGULAR = 'body1Regular',
  BODY_2_REGULAR = 'body2Regular',
  BODY_3_REGULAR = 'body3Regular',
  BODY_1_BOLD = 'body1Bold',
  BODY_2_BOLD = 'body2Bold',
  BODY_3_BOLD = 'body3Bold',
}

export enum DateVariant {
  DEADLINE = 'deadline',
  FROM = 'from',
  EXACT = 'exact',
}

export enum SupportTextType {
  ERROR = 'error',
  HELPER = 'helper',
}

export interface ITabs {
  label: string
  Component: ReactNode
}

export enum Errors {
  REQUIRED = 'This field is required',
}

export interface ISteps<T> {
  label: string
  Component: ReactNode
  fields?: Path<T>[]
}
