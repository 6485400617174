import { GridColDef } from '@mui/x-data-grid'

const machineTemplatesDataCol: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 80,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
  },
  {
    field: 'producer',
    headerName: 'Producer',
    width: 150,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 150,
  },
]

export default machineTemplatesDataCol
