import { createContext } from 'react'

type CompanyContextType =
  | {
      companyId?: number | null
      companyUnitId?: number | null
    }
  | undefined

const CompanyContext = createContext<CompanyContextType>(undefined)

export default CompanyContext
