import { PlanTaskForm } from '@aeqoom/forms'
import { TaskStatus } from '@aeqoom/shared'
import { isObjectEmpty } from '@aeqoom/utils'
import { Button } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  status: TaskStatus
  onSubmit: (data: PlanTaskForm) => void
}

const PlanButton = ({ status, onSubmit }: Props) => {
  const {
    formState: { isDirty, errors },
    handleSubmit,
  } = useFormContext<PlanTaskForm>()

  const { t } = useTranslation('web', {
    keyPrefix: 'backlog.taskDetail.header.plan',
  })

  const isDisabled = isObjectEmpty(errors) || !isDirty

  return (
    <Button
      variant="contained"
      sx={{ flexGrow: 1 }}
      disabled={isDisabled}
      onClick={handleSubmit(onSubmit)}
    >
      {status === 'planned' ? t('updatePlan') : t('plan')}
    </Button>
  )
}

export default PlanButton
