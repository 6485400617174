import { toDoListItems } from '@aeqoom/db'
import { z } from '@aeqoom/zod'
import { ClientInferRequest, initContract } from '@ts-rest/core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

const c = initContract()

const toDoListItemsRouter = c.router({
  getToDoListItems: {
    method: 'GET',
    path: '/to-do-list-items',
    responses: {
      200: z.array(
        z.intersection(
          createSelectSchema(toDoListItems),
          z.object({
            toDoList: z
              .object({
                name: z.string(),
              })
              .nullable(),
          })
        )
      ),
    },
  },
  getToDoListItem: {
    method: 'GET',
    path: '/to-do-list-items/:id/get',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: createSelectSchema(toDoListItems),
      404: z.object({ message: z.string() }),
    },
  },
  addToDoListItem: {
    method: 'POST',
    path: '/to-do-list-items',
    body: createInsertSchema(toDoListItems),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  deleteToDoListItem: {
    method: 'DELETE',
    path: '/to-do-list-items/:id/delete',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({}),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  editToDoListItem: {
    method: 'POST',
    path: '/to-do-list-items/:id/edit',
    pathParams: z.object({ id: z.coerce.number() }),
    body: z.object({
      name: z.string(),
      content: z.string().nullable(),
      toDoListId: z.number().nullable(),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  checkToDoListItem: {
    method: 'POST',
    path: '/to-do-list-items/:id/check-to-do-list-item',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({
      value: z.number(),
    }),
    responses: {
      200: z.object({
        id: z.number(),
      }),
      404: z.object({ message: z.string() }),
    },
  },
})

export type AddToDoListItemRequest = ClientInferRequest<
  typeof toDoListItemsRouter.addToDoListItem
>

export type EditToDoListItemRequest = ClientInferRequest<
  typeof toDoListItemsRouter.editToDoListItem
>

export type DeleteToDoListItemRequest = ClientInferRequest<
  typeof toDoListItemsRouter.deleteToDoListItem
>

export type CheckToDoListItemRequest = ClientInferRequest<
  typeof toDoListItemsRouter.checkToDoListItem
>

export default toDoListItemsRouter
