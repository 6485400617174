import * as Sentry from '@sentry/react'
import { AuthError } from 'aws-amplify/auth'
import { useTranslation } from 'react-i18next'

export const usePasswordChangeError = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'user.password.errors',
  })

  return (error: unknown) => {
    if (!(error instanceof AuthError)) return t('common')

    switch (error.name) {
      case 'InvalidPasswordException':
        return t('InvalidPasswordException')
      case 'LimitExceededException':
        return t('LimitExceededException')
      case 'InvalidParameterException':
        return t('InvalidParameterException')
      case 'NotAuthorizedException':
        return t('NotAuthorizedException')
      default:
        Sentry.captureMessage(
          `Unhandled error, usePasswordChangeError: ${error.name}`
        )
        return t('common')
    }
  }
}
