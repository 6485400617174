import { PrecisionManufacturing } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import React, { ReactNode } from 'react'

type Props = { children?: ReactNode }

const Title = ({ children }: Props) => {
  if (children)
    return (
      <Stack direction="row" gap={1}>
        <PrecisionManufacturing />
        Machine:
        <Typography component="span">{children}</Typography>
      </Stack>
    )

  return 'Machine'
}

export default Title
