import { UserFacingError } from '@app/utils/UserFacingError'
import { Button, Stack, Typography } from '@mui/material'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'

const ErrorScreen = ({
  refetch,
  reason,
}: {
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<unknown, Error>>
  reason?: string | Error | null
}) => {
  const displayReason =
    !!reason &&
    (typeof reason === 'string' || reason instanceof UserFacingError)

  return (
    <Stack alignItems="center">
      <Typography variant="body1Medium">Failed to load data.</Typography>
      {displayReason && (
        <Typography variant="body1">{reason.toString()}</Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: 20 }}
        onClick={() => refetch()}
        sx={{ width: 200 }}
      >
        Try Again
      </Button>
    </Stack>
  )
}

export default ErrorScreen
