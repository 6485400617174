import { DeleteForever, ExpandMore } from '@mui/icons-material'
import { IconButton, Stack, Typography, useTheme } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { ReactNode, useState } from 'react'

type Props = {
  title: ReactNode
  subtitle?: string | number | null
  remove?: () => void
  children?: ReactNode
  readonly?: boolean
  error?: boolean
}
/**
 * @deprecated use Accordion from libs
 */
const CustomAccordion = ({
  title,
  subtitle,
  remove,
  children,
  error,
  readonly,
}: Props) => {
  const [accordionOpen, setAccordionOpen] = useState(false)

  const theme = useTheme()

  return (
    <Accordion
      disableGutters
      elevation={0}
      expanded={accordionOpen}
      sx={{
        border: `1px solid ${
          error
            ? !accordionOpen
              ? theme.palette.error.main
              : theme.palette.divider
            : theme.palette.divider
        }`,
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        overflow: 'hidden',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: theme.palette.grey[50] }}
        onClick={() => setAccordionOpen(!accordionOpen)}
        expandIcon={
          <IconButton onClick={() => setAccordionOpen(!accordionOpen)}>
            <ExpandMore />
          </IconButton>
        }
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Stack>
            <Typography>{title}</Typography>
            {subtitle && <Typography variant="caption">{subtitle}</Typography>}
          </Stack>
          {remove && (
            <IconButton
              aria-label="settings"
              color="error"
              onClick={(e) => {
                e.stopPropagation()
                remove()
              }}
              disabled={readonly}
            >
              <DeleteForever />
            </IconButton>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ marginTop: 2 }}>{children}</AccordionDetails>
    </Accordion>
  )
}

export default CustomAccordion
