import { Media } from '@aeqoom/shared'
import { createUniqueKey, trimStringWithExtension } from '@aeqoom/utils'
import { useDeleteMedia } from '@app/queries/useMedia'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { DeleteForever, Download, Info, MoreVert } from '@mui/icons-material'
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import MediaDetailDialog from '../base/dialogs/_detailViewDialogs/MediaDetailDialog'
import CustomMenu from '../CustomMenu'
import DeleteMediaDialog from '../DeleteMediaDialog'
import MediaCard from '../MediaCard'

type Props = {
  isChecked: boolean
  item: Media
  onChange: (item: Media, checked: boolean) => void
}

const MediaGalleryCard = ({ isChecked, item, onChange }: Props) => {
  const { openDialog } = useDialogStore()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation(['common', 'web'])

  const deleteMediaMutation = useDeleteMedia()

  const deleteMedia = async () => {
    setIsLoading(true)
    deleteMediaMutation.mutate(
      { params: { id: item.id } },
      {
        onSuccess: (data) => {
          setIsLoading(false)
          toast.success(data.message)
        },
      }
    )
  }

  return (
    <>
      <Box
        sx={{
          p: 2,
          backgroundColor: isChecked ? '#F1F6F2' : 'transparent',
          borderRadius: 2,
        }}
      >
        <MediaCard
          accessLevel={item.accessLevel}
          isChecked={isChecked}
          mimeType={item.mimeType}
          fullUrl={item.signedUrl || undefined}
          onClick={() => onChange(item, !isChecked)}
          onDoubleClick={() => {
            openDialog(
              <MediaDetailDialog activeMedia={item.id} />,
              item.name || trimStringWithExtension(item.originalFilename),
              {
                uniqueKey: createUniqueKey(['mediaDialog', item.id]),
              }
            )
          }}
          cardContent={
            <Stack sx={{ minWidth: 0, width: '100%' }}>
              <Tooltip
                title={item.name || item.originalFilename}
                placement="top"
              >
                <Typography variant="body1" noWrap gutterBottom>
                  {item.name || item.originalFilename}
                </Typography>
              </Tooltip>
              <Typography variant="body2">
                {item.dateCreated &&
                  dayjs(item.dateCreated).format('DD/MM/YYYY')}
              </Typography>
            </Stack>
          }
          cardActions={
            <CustomMenu
              slotProps={{
                paper: {
                  elevation: 1,
                },
              }}
              button={
                <IconButton>
                  <MoreVert />
                </IconButton>
              }
            >
              <MenuItem
                href={item.signedUrl || undefined}
                component="a"
                download
                onClick={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <ListItemIcon>
                  <Download fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('common:download')}</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={(e) =>
                  openDialog(
                    <MediaDetailDialog activeMedia={item.id} />,
                    item.name || trimStringWithExtension(item.originalFilename),
                    {
                      uniqueKey: createUniqueKey(['mediaDialog', item.id]),
                    }
                  )
                }
              >
                <ListItemIcon>
                  <Info fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('web:media.mediaDetail')}</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => setIsOpen(true)}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <ListItemIcon>
                  <DeleteForever fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('common:deleteItem')}</ListItemText>
              </MenuItem>
            </CustomMenu>
          }
        />
      </Box>
      <DeleteMediaDialog
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={deleteMedia}
      />
    </>
  )
}

export default MediaGalleryCard
