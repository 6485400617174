import { AddToDoListForm, addToDoListForm } from '@aeqoom/forms'
import { isObjectEmpty } from '@aeqoom/utils'
import { FormTextField } from '@app/components/base/form/FormTextField'
import Section from '@app/components/Section'
import ToDoListItem from '@app/components/ToDoListItem'
import {
  useCreateToDoList,
  useEditToDoList,
  useGetToDoList,
} from '@app/queries/useToDoLists'
import { useDialogStore } from '@app/stores/useDialogStore'
import { zodResolver } from '@hookform/resolvers/zod'
import { AddCircle } from '@mui/icons-material'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ActionControlButtons from '../ActionControlButtons'
import DialogDetailError from '../DialogDetailError'

type Props = {
  id?: number
  readOnly?: boolean
  isEditModal?: boolean
  callback?: (id: number) => void
}

const CreateTodoListDialogContent = ({
  readOnly = false,
  isEditModal = false,
  id,
  callback,
}: Props) => {
  const [isEditing, setIsEditing] = useState(!readOnly)

  const { closeDialog, setShouldAskBeforeClose } = useDialogStore()

  const {
    handleSubmit,
    control,
    reset,
    formState: { dirtyFields, errors },
  } = useForm<AddToDoListForm>({
    resolver: zodResolver(addToDoListForm),
    defaultValues: { name: '', toDoListItems: [] },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'toDoListItems',
  })

  // TODO: query has wrong type, it also returns toDoListItems
  const getToDoListQuery = useGetToDoList({ id })

  const mutation = useCreateToDoList()
  const editMutation = useEditToDoList()

  useEffect(() => {
    setShouldAskBeforeClose(isObjectEmpty(dirtyFields))
  }, [isObjectEmpty(dirtyFields)])

  useEffect(() => {
    if (!getToDoListQuery.isPending || !getToDoListQuery.isError) {
      reset(getToDoListQuery.data)
    }
  }, [getToDoListQuery.data, reset])

  const onSubmit = async (data: AddToDoListForm) => {
    mutation.mutate(data, {
      onSuccess: (data) => {
        callback ? callback(data.id) : closeDialog()
        toast.success(data.message)
        reset()
      },
    })
  }

  const onEditSubmit = async (data: AddToDoListForm) => {
    editMutation.mutate(
      {
        body: data,
        params: { id: id! },
      },
      {
        onSuccess: (data) => {
          toast.success(data.message)
          callback ? callback(data.id) : closeDialog()
          setIsEditing(false)
        },
        onError: () => toast.error('Something went wrong'),
      }
    )
  }

  if (getToDoListQuery.isPending && readOnly)
    return (
      <Stack alignItems="center">
        <CircularProgress size={80} />
      </Stack>
    )

  if (getToDoListQuery.error)
    return <DialogDetailError onRetry={() => getToDoListQuery.refetch()} />

  return (
    <>
      <DialogContent>
        <Section title="General info">
          <Grid item xs={12}>
            <FormTextField
              controller={{ control, name: 'name' }}
              field={{
                label: 'Name',
                fullWidth: true,
                required: true,
                InputProps: { readOnly: !isEditing },
              }}
            />
          </Grid>
        </Section>
        <Section title="To do list items">
          <Grid item xs={12}>
            <Stack spacing={2}>
              {fields.map((item, index) => (
                <ToDoListItem
                  key={item.id}
                  control={control}
                  remove={remove}
                  index={index}
                  readOnly={!isEditing}
                  errors={errors}
                />
              ))}
              <Button
                aria-label="delete"
                color="info"
                startIcon={<AddCircle />}
                onClick={() =>
                  append({
                    name: '',
                    content: '',
                    media: [],
                    order: fields.length + 1,
                  })
                }
                disabled={!isEditing}
              >
                Add new to do list item
              </Button>
            </Stack>
          </Grid>
        </Section>
      </DialogContent>
      <DialogActions>
        {isEditModal || (
          <Button
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={mutation.isPending}
          >
            Create todo list
          </Button>
        )}
        {isEditModal && (
          <ActionControlButtons
            hasView={false}
            isEditing={isEditing}
            onSave={handleSubmit(onEditSubmit)}
            onEdit={() => setIsEditing(true)}
            disabled={editMutation.isPending}
          />
        )}
      </DialogActions>
    </>
  )
}

export default CreateTodoListDialogContent
