import { FormAutoComplete, FormDatePicker } from '@aeqoom/components-web'
import { AddSLARequest } from '@aeqoom/contracts'
import CreateCompanyDialogContent from '@app/components/base/dialogs/createForms/CreateCompanyDialogContent'
import CreateCompanyUnitDialogContent from '@app/components/base/dialogs/createForms/CreateCompanyUnitDialogContent'
import FormCheckBox from '@app/components/base/form/FormCheckBox'
import { FormTextField } from '@app/components/base/form/FormTextField'
import { FormUploadField } from '@app/components/base/form/FormUploadField'
import Section from '@app/components/Section'
import { useGetCompanies } from '@app/queries/useCompanies'
import { useGetCompanyUnits } from '@app/queries/useCompanyUnits'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { Errors } from '@app/src/types'
import { Grid, Stack } from '@mui/material'
import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

type Props = {
  isEditing: boolean
}

const FirstStep = ({ isEditing }: Props) => {
  const { control, trigger, setValue } = useFormContext<AddSLARequest['body']>()

  const { openDialog, closeTab } = useDialogStore()

  const [watchCompanyId, watchCompanyUnitId, watchIsIndefinitely] = useWatch({
    control,
    name: ['companyId', 'companyUnitId', 'isIndefinitely'],
  })

  const getCompaniesQuery = useGetCompanies()
  const getCompanyUnitsQuery = useGetCompanyUnits({
    companyId: watchCompanyId,
  })

  useEffect(() => {
    watchIsIndefinitely && setValue('dateTo', null)
  }, [watchIsIndefinitely])

  const handleCompanyEdit = () =>
    openDialog(
      <CreateCompanyDialogContent
        id={watchCompanyId}
        callback={(id) => {
          setValue('companyId', id)
          trigger('companyId')
          closeTab()
        }}
        isEditModal
      />,
      'Create new company'
    )

  const handleCompanyUnitEdit = () =>
    openDialog(
      <CreateCompanyUnitDialogContent
        id={watchCompanyUnitId ?? undefined}
        callback={(id) => {
          setValue(`companyUnitId`, id)
          trigger('companyUnitId')
          closeTab()
        }}
        formData={{ companyId: watchCompanyId }}
        readOnlyFields={['companyId']}
        isEditModal
      />,
      'Create company unit'
    )

  return (
    <Stack direction="column" spacing={4}>
      <Section title="Basic information">
        <Grid item xs={12}>
          <FormTextField
            field={{
              label: 'Name',
              required: true,
              helperText: 'Enter name of the contract',
              fullWidth: true,
              InputProps: { readOnly: !isEditing },
            }}
            controller={{
              control,
              name: 'name',
            }}
          />
        </Grid>
      </Section>
      <Section title="Company">
        <Grid item xs={6}>
          <FormAutoComplete
            control={control}
            name="companyId"
            fieldProps={{ label: 'Company', required: true }}
            autocomplete={{
              getOptionLabel: (option) => option.name,
              options: getCompaniesQuery.data,
              fullWidth: true,
              readOnly: !isEditing,
            }}
            add={{
              onClick: () =>
                openDialog(
                  <CreateCompanyDialogContent
                    callback={(id) => {
                      setValue('companyId', id)
                      trigger('companyId')
                      closeTab()
                    }}
                  />,
                  'Create new company'
                ),
            }}
            actions={[
              <FormAutoComplete.ActionButton
                key="edit"
                onClick={handleCompanyEdit}
              />,
            ]}
          />
        </Grid>
        {watchCompanyId && (
          <Grid item xs={6}>
            <FormAutoComplete
              control={control}
              name="companyUnitId"
              autocomplete={{
                getOptionLabel: (option) => option.name,
                options: getCompanyUnitsQuery.data,
                fullWidth: true,
                readOnly: !isEditing,
              }}
              fieldProps={{ label: 'Company unit' }}
              add={{
                onClick: () =>
                  openDialog(
                    <CreateCompanyUnitDialogContent
                      callback={(id) => {
                        setValue(`companyUnitId`, id)
                        trigger('companyUnitId')
                        closeTab()
                      }}
                      formData={{ companyId: watchCompanyId }}
                      readOnlyFields={['companyId']}
                    />,
                    'Create company unit'
                  ),
              }}
              actions={[
                <FormAutoComplete.ActionButton
                  key="edit"
                  onClick={handleCompanyUnitEdit}
                />,
              ]}
            />
          </Grid>
        )}
      </Section>
      {watchCompanyId && (
        <>
          <Section title="Validity">
            <Grid item xs={6}>
              <FormDatePicker
                controller={{
                  name: 'dateFrom',
                  control,
                  rules: { required: Errors.REQUIRED },
                }}
                datePicker={{
                  label: 'Date from',
                  readOnly: !isEditing,
                  timezone: 'UTC',
                }}
                field={{ fullWidth: true, required: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormDatePicker
                controller={{
                  name: 'dateTo',
                  control,
                }}
                datePicker={{
                  label: 'Date to',
                  readOnly: !isEditing,
                  disabled: watchIsIndefinitely,
                  timezone: 'UTC',
                }}
                field={{ fullWidth: true, required: !watchIsIndefinitely }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormCheckBox
                controller={{
                  control,
                  name: 'isIndefinitely',
                }}
                field={{
                  readOnly: !isEditing,
                  disabled: !isEditing,
                }}
                label="The contract is for an indefinite period of time"
              />
            </Grid>
          </Section>
          <Section title="Extra">
            <Grid item xs={12}>
              <FormTextField
                field={{
                  label: 'Description',
                  fullWidth: true,
                  multiline: true,
                  rows: 4,
                  InputProps: { readOnly: !isEditing },
                }}
                controller={{
                  control,
                  name: 'description',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormUploadField
                control={control}
                name="media"
                defaultMediaType={['agreement']}
                isEditing={isEditing}
              />
            </Grid>
          </Section>
        </>
      )}
    </Stack>
  )
}

export default FirstStep
