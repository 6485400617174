import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Stack,
  Typography,
} from '@mui/material'

type Props = LinearProgressProps & {
  showPercentage?: boolean
}

const CustomProgressBar = (props: Props) => {
  return (
    <Stack direction="row" alignItems="center">
      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      {props.showPercentage && (
        <Box>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value ?? 0
          )}%`}</Typography>
        </Box>
      )}
    </Stack>
  )
}

export default CustomProgressBar
