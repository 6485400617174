import { Task } from '@aeqoom/db'
import { CreateTaskSchemaForm } from '@aeqoom/forms'
import { useGetTask } from '@app/queries/useTasks'
import { CircularProgress, Stack } from '@mui/material'

import DialogDetailError from '../DialogDetailError'
import CreateTask from './CreateTask'
import { defaultValues } from './defaultValues'
import EditTask from './EditTask'

type Props = {
  id?: Task['id']
  callback?: (id?: number) => void
  isEditModal?: boolean
  initValues?: Partial<CreateTaskSchemaForm>
}

const CreateTaskDialogContent = ({
  id,
  isEditModal: isEditingFromProps = false,
  callback,
  initValues = {},
}: Props) => {
  const getTaskQuery = useGetTask({
    id,
    enabled: isEditingFromProps,
  })

  if (!isEditingFromProps)
    return (
      <CreateTask
        defaultValues={{
          ...defaultValues,
          ...initValues,
        }}
        callback={callback}
      />
    )

  if (getTaskQuery.isPending)
    return (
      <Stack alignItems="center">
        <CircularProgress size={80} />
      </Stack>
    )

  if (getTaskQuery.isError)
    return <DialogDetailError onRetry={() => getTaskQuery.refetch()} />

  const editDefaultValues = {
    ...getTaskQuery.data,
    ...initValues,
    companyUnitId: getTaskQuery.data.companyUnitId,
  }

  return <EditTask defaultValues={editDefaultValues} />
}

export default CreateTaskDialogContent
