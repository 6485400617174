import { GetTaskResponseBody } from '@aeqoom/contracts'
import { TaskStatus } from '@aeqoom/shared'
import WorkProtocolDialogContent from '@app/components/base/dialogs/WorkProtocolDialogContent'
import { useChangeTaskStatus } from '@app/queries/useTasks'
import useUserStore from '@app/src/stores/useUserStore'
import { useDialogStore } from '@app/stores/useDialogStore'
import {
  CheckCircleOutline,
  Description,
  PauseCircleOutline,
  PlayCircleOutline,
} from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IdentifierText } from '../../../TaskHeader/styled'

const IS_DEV = import.meta.env.MODE === 'development'

const IDLE_STATUSES: TaskStatus[] = [
  'new',
  'planned',
  'recurred',
  'acknowledged',
]

type Props = {
  task: GetTaskResponseBody
}

const WorkPanel = ({ task }: Props) => {
  const [isWorking, setIsWorking] = useState(false)
  const [content, setContent] = useState('00:00:00')

  const { openDialog } = useDialogStore()
  const { user } = useUserStore()

  const { t } = useTranslation('web', {
    keyPrefix: 'backlog.taskDetail.header',
  })

  const changeTaskStatusMutation = useChangeTaskStatus()

  useEffect(() => {
    if (isWorking) {
      const now = dayjs().valueOf()

      const intervalId = window.setInterval(() => {
        const time = dayjs(dayjs().valueOf() - now).subtract(1, 'hour')

        setContent(time.format('HH:mm:ss'))
      }, 1000)

      return () => {
        window.clearInterval(intervalId)
      }
    }
  }, [isWorking])

  if (IDLE_STATUSES.includes(task.status)) {
    return (
      <Stack direction="row">
        <Button
          startIcon={<PlayCircleOutline />}
          variant="contained"
          disabled={
            (task.status !== 'planned' && task.status !== 'recurred') ||
            task.userId !== user?.id
          }
          onClick={() => {
            changeTaskStatusMutation.mutate({
              body: { status: 'in_progress' },
              params: { id: task.id },
            })
            setIsWorking(true)
          }}
        >
          {t('startButton')}
        </Button>
      </Stack>
    )
  }

  return (
    <Stack direction="row" sx={{ gap: 2 }}>
      {IS_DEV && (
        <>
          <IdentifierText>{content}</IdentifierText>
          <Button
            variant="outlined"
            startIcon={
              isWorking ? <PauseCircleOutline /> : <PlayCircleOutline />
            }
            onClick={() => setIsWorking((last) => !last)}
          >
            {isWorking ? t('pauseButton') : t('startButton')}
          </Button>
        </>
      )}
      <Button
        variant="contained"
        startIcon={<CheckCircleOutline />}
        onClick={() => {
          changeTaskStatusMutation.mutate({
            body: { status: 'waiting_on_wp' },
            params: { id: task.id },
          })

          openDialog(
            <WorkProtocolDialogContent taskId={task.id} />,
            `Working protocol - #${task.id} ${task.name}`,
            {
              icon: Description,
            }
          )
        }}
      >
        {t('doneButton')}
      </Button>
    </Stack>
  )
}

export default WorkPanel
