import { ReactNode } from 'react'

export const truncateString = (val: ReactNode, num = 30) => {
  if (!val) return undefined

  const str = val.toString()

  if (str.length <= num) {
    return str
  }

  return str.slice(0, num) + '...'
}
