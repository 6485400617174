import { ColumnVariant } from '@app/components/BacklogColumn/types'
import { Card, CardProps } from '@mui/material'
import { styled, Theme } from '@mui/material/styles'

export const StyledColumn = styled(Card)<CardProps & { type: ColumnVariant }>(
  ({ theme, type }) => ({
    overflow: 'hidden',
    background: calcBgColor(type, theme),
    border:
      type === ColumnVariant.HIGH_PRIORITY
        ? `solid 2px ${theme.palette.red.main}`
        : 'none',
    '& > *:first-of-type': {
      color: calcColor(type, theme),
    },
    height: '100%',
  })
)

const calcBgColor = (variant: ColumnVariant, theme: Theme) => {
  switch (variant) {
    case ColumnVariant.HIGH_PRIORITY:
      return theme.palette.red.pastel
    case ColumnVariant.TO_BE_DONE:
      return theme.palette.anthracite.main
    default:
      return theme.palette.lightGray.main
  }
}

const calcColor = (variant: ColumnVariant, theme: Theme) => {
  if (variant === ColumnVariant.TO_BE_DONE) {
    return theme.palette.white.main
  } else if (variant === ColumnVariant.HIGH_PRIORITY) {
    return theme.palette.red.main
  } else {
    return theme.palette.text.primary
  }
}
