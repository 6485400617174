import { DialogProps } from '@mui/material'
import { ElementType, ReactNode } from 'react'

export type ActiveDialogs = Record<DialogId, IDialog>
export type PreviousDialogs = Record<DialogId, DialogChain>
export type DialogChain = { rootId: DialogId; previousIds: DialogId[] }

export type DialogId = string

export interface IDialog {
  title: string
  status: DialogStatus
  mainContent: ReactNode
  dialogId: DialogId
  config: IDialogConfig
}

export interface IDialogConfig extends Omit<DialogProps, 'open'> {
  shouldShowTitle: boolean
  closeOnBackdrop: boolean
  closeOnEsc: boolean
  icon: ElementType
  shouldShowBreadcrumb: boolean
  isUnique: boolean
  uniqueKey: string | null
  dialogId?: string
}

export type DialogCustomOptions = Partial<IDialogConfig>

export enum DialogStatus {
  CLOSED = 'closed',
  OPEN = 'open',
  MINIMIZED = 'minimized',
}

export type Actions = {
  setShouldAskBeforeClose: (shouldAskBeforeClose: boolean) => void
  openDialog: (
    content: ReactNode,
    title: string,
    options?: DialogCustomOptions
  ) => void
  closeDialog: () => void
  closeTab: (id?: DialogId, direction?: 'next' | 'previous') => void
  openPreviousDialog: () => void
  minimizeDialog: () => void
  maximizeDialog: (id: DialogId) => void
  clearDialogStore: () => void
}

export type State = {
  activeDialogs: ActiveDialogs
  previousDialogs: PreviousDialogs
  shouldAskBeforeClose: boolean
}

export type DialogStore = State & Actions
