import { TextFieldProps } from '@mui/material'
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'

type Props<T extends FieldValues> = {
  controller: UseControllerProps<T>
  timePicker: DateTimePickerProps<Dayjs>
  field: TextFieldProps
}

export const FormDateTimePicker = <T extends FieldValues>({
  controller,
  timePicker,
  field,
}: Props<T>) => {
  return (
    <Controller
      {...controller}
      render={({
        field: { ref, onBlur, onChange, value, ...fieldProps },
        fieldState: { error },
      }) => (
        <DateTimePicker
          {...timePicker}
          {...fieldProps}
          value={value ? dayjs(value) : null}
          inputRef={ref}
          onClose={onBlur}
          onChange={(date) => {
            onChange(date?.toISOString() ?? null)
          }}
          slotProps={{
            textField: {
              onBlur,
              error: !!error,
              helperText: error?.message ?? field?.helperText ?? ' ',
              ...field,
            },
          }}
        />
      )}
    />
  )
}

export default FormDateTimePicker
