import { PERMISSIONS, safeHasPermission } from '@aeqoom/permissions'
import { AccessLevel, FileType, MediaOrder, MediaType } from '@aeqoom/shared'
import BackdropUpload from '@app/components/BackdropUpload'
import CheckPermissions from '@app/components/CheckPermissions'
import DeleteMediaDialog from '@app/components/DeleteMediaDialog'
import MediaFilter from '@app/components/MediaFilter'
import MediaGrid from '@app/components/MediaGrid'
import MediaSort from '@app/components/MediaSort'
import PaginationSkeleton from '@app/components/skeletons/PaginationSkeleton'
import { useDeleteBatchMedia, useGetMedia } from '@app/queries/useMedia'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import useMediaStore from '@app/src/stores/useMediaStore'
import useUserStore from '@app/stores/useUserStore'
import {
  Add,
  AttachFile,
  Clear,
  DeleteForever,
  GridView,
  ImageNotSupported,
  Search,
  ViewList,
  Warning,
} from '@mui/icons-material'
import {
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  Pagination,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useDebounceCallback } from 'usehooks-ts'

import AddMediaDialog from '../AddMediaDialog'

type Props = {
  isMultiple?: boolean
  callback?: (data: number[]) => void
  alreadyPresentMedia?: number[]
  defaultFileType?: FileType[]
  disableFileType?: boolean
  defaultAddedByMe?: boolean
  disableAddedByMe?: boolean
  defaultAccessLevel?: AccessLevel[]
  defaultMediaType?: MediaType[]
  disableAccessLevel?: boolean
  companyId?: number | null
  companyUnitId?: number | null
  multiple?: boolean
}

const GalleryDialog = ({
  isMultiple = true,
  callback,
  alreadyPresentMedia,
  defaultFileType = [],
  disableFileType = false,
  defaultAddedByMe = false,
  disableAddedByMe = false,
  defaultAccessLevel = [],
  disableAccessLevel = false,
  companyId,
  companyUnitId,
  defaultMediaType = [],
  multiple = true,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState<MediaOrder>('name')
  const [isAscending, setIsAscending] = useState(true)
  const [checkedMedia, setCheckedMedia] = useState<number[]>([])
  const [fileType, setFileType] = useState(defaultFileType)
  const [isOpen, setIsOpen] = useState(false)
  const [accessLevel, setAccessLevel] = useState(defaultAccessLevel)
  const [mediaType, setMediaType] = useState(defaultMediaType)
  const [addedByMe, setAddedByMe] = useState(defaultAddedByMe)

  const inputRef = useRef<HTMLInputElement>()

  const { setView, isGrid } = useMediaStore()
  const { user } = useUserStore()

  const { data, isPending, isError } = useGetMedia({
    page,
    search,
    orderBy,
    isAscending,
    filter: {
      addedByMe,
      fileType,
      accessLevel,
      orderByPriority: alreadyPresentMedia,
      companyId,
      companyUnitId,
      mediaType,
    },
  })

  const deleteBatchMediaMutation = useDeleteBatchMedia()

  const debouncedSetSearch = useDebounceCallback(setSearch, 500)

  const { openDialog } = useDialogStore()

  const deleteBatchMedia = () => {
    setIsLoading(true)
    deleteBatchMediaMutation.mutate(
      {
        body: checkedMedia,
      },
      {
        onSuccess: (data) => {
          setIsLoading(false)
          setIsOpen(false)
          toast.success(data.message)
          setCheckedMedia([])
          setPage(1)
        },
        onError: () => {
          setIsLoading(false)
          toast.error("Couldn't delete media")
          setIsOpen(false)
        },
      }
    )
  }

  const onChangeCheckbox = () => {
    if (checkedMedia.length > 0) {
      return setCheckedMedia([])
    }
    const media = data?.media.map((item) => item.id) || []
    setCheckedMedia(media)
  }

  useEffect(() => {
    if (alreadyPresentMedia) {
      alreadyPresentMedia?.length > 0 && setCheckedMedia(alreadyPresentMedia)
    }
  }, [alreadyPresentMedia])

  const handleInputReset = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
      debouncedSetSearch('')
    }
  }

  useEffect(() => {
    setPage(1)
  }, [search, fileType, mediaType, accessLevel, addedByMe])

  const { t } = useTranslation(['web', 'common'])

  const content = useMemo(() => {
    if (isPending)
      return (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100%', p: 8 }}
        >
          <CircularProgress />
        </Stack>
      )

    if (isError)
      return (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100%', p: 8 }}
          spacing={2}
        >
          <Warning fontSize="large" color="error" />
          <Typography color="error">{t('common:errorOccured')}</Typography>
        </Stack>
      )

    if (data.media.length < 1)
      return (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100%', p: 8 }}
          spacing={2}
        >
          <ImageNotSupported fontSize="large" color="disabled" />
          <Typography variant="subtitle1" color="disabled">
            {t('common:noResultsFound')}
          </Typography>
        </Stack>
      )

    return (
      <MediaGrid
        data={data.media}
        isMultiple={isMultiple}
        checkedMedia={checkedMedia}
        setCheckedMedia={setCheckedMedia}
      />
    )
  }, [isPending, isError, data, isMultiple, checkedMedia, setCheckedMedia, t])

  return (
    <BackdropUpload
      multiple={multiple}
      enabled={safeHasPermission(user?.groups, PERMISSIONS.MEDIA.CREATE)}
      launchAction={(acceptedFiles) =>
        openDialog(
          <AddMediaDialog
            fileAttachments={acceptedFiles}
            defaultMediaType={mediaType}
            defaultAccessLevel={defaultAccessLevel}
          />,
          t('web:media.dialogName'),
          {
            isUnique: false,
          }
        )
      }
    >
      <Box paddingX={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={4}
          mb={4}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            {isPending || isError ? (
              <Box p={1}>
                <Skeleton variant="rounded" width={26} height={26} />
              </Box>
            ) : (
              <Badge badgeContent={checkedMedia.length} color="primary">
                <Checkbox
                  disabled={data.media.length < 1}
                  onChange={onChangeCheckbox}
                  indeterminate={
                    checkedMedia.length > 0 &&
                    checkedMedia.length < data.media.length
                  }
                  checked={
                    data.media.length > 0 &&
                    checkedMedia.length >= data.media.length
                  }
                />
              </Badge>
            )}
            <IconButton
              onClick={() => setIsOpen(true)}
              disabled={checkedMedia.length === 0}
            >
              <DeleteForever />
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            width="100%"
            spacing={2}
            justifyContent="center"
          >
            <TextField
              inputRef={inputRef}
              id="search"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(event) => debouncedSetSearch(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={handleInputReset}
                    >
                      <Clear fontSize="inherit" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <MediaFilter
              fileType={fileType}
              setFileType={setFileType}
              accessLevel={accessLevel}
              setAccessLevel={setAccessLevel}
              mediaType={mediaType}
              setMediaType={setMediaType}
              disableAccessLevel={disableAccessLevel}
              disableFileType={disableFileType}
              addedByMe={addedByMe}
              setAddedByMe={setAddedByMe}
              disableAddedByMe={disableAddedByMe}
            />
            <MediaSort
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              isAscending={isAscending}
              setIsAscending={setIsAscending}
            />
          </Stack>
          <Stack direction="row" spacing={2} flexShrink={0} alignItems="center">
            <CheckPermissions permissions={[PERMISSIONS.MEDIA.CREATE]}>
              <Button
                startIcon={<Add />}
                variant="outlined"
                onClick={() =>
                  openDialog(
                    <AddMediaDialog
                      defaultAccessLevel={defaultAccessLevel}
                      defaultMediaType={mediaType}
                    />,
                    t('web:media.dialogName'),
                    {
                      isUnique: false,
                    }
                  )
                }
                sx={{ height: '100%' }}
                size="large"
              >
                {t('media.addFile')}
              </Button>
            </CheckPermissions>
            <IconButton onClick={setView}>
              {isGrid ? <ViewList /> : <GridView />}
            </IconButton>
          </Stack>
        </Stack>
      </Box>
      <DialogContent sx={{ p: 0 }}>{content}</DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', py: 2 }}>
        {isPending || isError ? (
          <PaginationSkeleton />
        ) : (
          <Pagination
            size="large"
            disabled={data.numberOfPages < 2}
            count={data.numberOfPages}
            page={page}
            onChange={(_, value) => setPage(value)}
          />
        )}
        {checkedMedia.length > 0 && callback && (
          <Button
            onClick={() => callback(checkedMedia)}
            variant="contained"
            startIcon={<AttachFile />}
          >
            {t('media.attachItemsWithCount', { count: checkedMedia?.length })}
          </Button>
        )}
      </DialogActions>
      <DeleteMediaDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={deleteBatchMedia}
        count={checkedMedia.length}
        isLoading={isLoading}
      />
    </BackdropUpload>
  )
}

export default GalleryDialog
