import { MIME_TYPES } from '@aeqoom/shared'
import { useGetManyMedia } from '@app/queries/useMedia'
import useMediaCarouselStore from '@app/src/stores/useMediaCarouselStore'
import {
  Alert,
  Card,
  CardActionArea,
  Grid,
  Skeleton,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomCardMedia from '../CustomCardMedia'
import FileIcon from '../FileIcon'

type Props = {
  media: number[]
}

const MediaPreviewSimple = ({ media }: Props) => {
  const { data, isLoading, isError } = useGetManyMedia({ media })

  const { t } = useTranslation()

  const { openCarousel } = useMediaCarouselStore()

  if (isLoading) return <Skeleton variant="rounded" height={256} />

  if (isError) return <Alert color="error">{t('media.failedToLoad')}</Alert>

  if (!data) return null

  return (
    <Grid container gap={4}>
      {data?.map((item) => (
        <Grid item xs={2.5}>
          <Card sx={{ borderRadius: 1 }}>
            <CardActionArea
              onClick={() => openCarousel({ media: data, currentMedia: item })}
            >
              {MIME_TYPES.image.includes(item.mimeType) ? (
                <CustomCardMedia
                  component="img"
                  src={item.signedUrl || undefined}
                  sx={{ height: 128 }}
                />
              ) : (
                <CustomCardMedia component="div" sx={{ height: 128 }}>
                  <FileIcon mimeType={item.mimeType} />
                </CustomCardMedia>
              )}
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

export default MediaPreviewSimple
