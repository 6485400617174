import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  Switch,
} from '@mui/material'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'

type Props<T extends FieldValues> = Omit<FormControlLabelProps, 'control'> & {
  legend?: string
  controller: UseControllerProps<T>
  readOnly?: boolean
  callback?: () => void
}

export const FormSwitchField = <T extends FieldValues>({
  controller,
  label,
  legend,
  readOnly = false,
  callback,
}: Props<T>) => {
  return (
    <Controller
      {...controller}
      render={({ field }) => {
        return (
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={field.value}
                  onChange={(_, checked) => {
                    field.onChange(checked)
                    callback && callback()
                  }}
                  disabled={readOnly}
                />
              }
              label={label}
            />
            <FormHelperText component="legend" sx={{ margin: 0 }}>
              {legend}
            </FormHelperText>
          </FormControl>
        )
      }}
    />
  )
}
