import Typography from '@mui/material/Typography'

type Props = {
  text: string
}

const SectionTitle = ({ text }: Props) => (
  <Typography sx={{ pb: 1 }} variant="body1Bold">
    {text}
  </Typography>
)

export default SectionTitle
