import { accessLevelSchema, mediaTypeSchema } from '@aeqoom/shared'
import { z } from '@aeqoom/zod'

export const addMediaForm = z.object({
  media: z.array(
    z.object({
      name: z.string().optional(),
      description: z.string().optional(),
      file: z.union([
        z.array(z.instanceof(File)).nonempty().optional(),
        z.null(),
      ]),
      defaultAccessLevel: accessLevelSchema,
      mediaType: mediaTypeSchema,
    })
  ),
})

export type AddMediaForm = z.infer<typeof addMediaForm>

export const mediaDetailForm = z.object({
  id: z.number(),
  name: z.string().nullable(),
  description: z.string().nullable(),
})

export type MediaDetailForm = z.infer<typeof mediaDetailForm>
