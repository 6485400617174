import { TaskPriority, TaskStatus, TaskType } from '@aeqoom/shared'
import { BACKLOG_KEYS } from '@aeqoom/types'
import { QueryClient, useQuery } from '@tanstack/react-query'

import { client } from './client'
import parseIncoming from './parseIncoming'

export const invalidateBacklogQueries = (queryClient: QueryClient) => {
  const allKeys: string[] = Object.values(BACKLOG_KEYS).flat()

  queryClient.invalidateQueries({
    predicate: (query) => allKeys.includes(query.queryKey[0] + ''),
  })
}

export const useGetBacklog = ({
  companyId,
  companyUnitId,
  type,
  status,
  priority,
  assigneeId,
}: {
  companyId: number | null
  companyUnitId: number | null
  type: TaskType[]
  status: TaskStatus[]
  priority: TaskPriority[]
  assigneeId?: number
}) => {
  return useQuery({
    queryKey: [
      BACKLOG_KEYS.getBacklog,
      companyId,
      companyUnitId,
      type,
      status,
      priority,
      assigneeId,
    ],
    queryFn: () =>
      client.backlog
        .getBacklog({
          query: {
            companyId,
            companyUnitId,
            status,
            priority,
            type,
            assigneeId,
          },
        })
        .then(parseIncoming),
  })
}
