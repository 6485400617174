import { companies, companyContacts, companyUnits } from '@aeqoom/db'
import { z } from '@aeqoom/zod'
import { initContract } from '@ts-rest/core'
import { ClientInferRequest } from '@ts-rest/core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
const companyResponse200 = z.object({
  ...createSelectSchema(companies).shape,
  contacts: z.array(createSelectSchema(companyContacts)),
})

const c = initContract()

const companiesRouter = c.router({
  getCompanies: {
    method: 'GET',
    path: '/companies',
    responses: {
      200: z.array(
        z.object({
          ...createSelectSchema(companies).shape,
          companyUnits: z.array(createSelectSchema(companyUnits)),
        })
      ),
    },
  },
  getCompany: {
    method: 'GET',
    path: '/companies/:id/get',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: companyResponse200,
      404: z.object({ message: z.string() }),
    },
  },
  addCompany: {
    method: 'POST',
    path: '/companies',
    body: z.object({
      ...createInsertSchema(companies).shape,
      contacts: z.array(
        createInsertSchema(companyContacts).pick({
          contactId: true,
          main: true,
        })
      ),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  deleteCompany: {
    method: 'DELETE',
    path: '/companies/:id/delete',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({}),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  editCompany: {
    method: 'POST',
    path: '/companies/:id/edit',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({
      ...createInsertSchema(companies).shape,
      contacts: z.array(
        createInsertSchema(companyContacts).pick({
          contactId: true,
          main: true,
        })
      ),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
})

export type AddCompanyRequest = ClientInferRequest<
  typeof companiesRouter.addCompany
>

export type EditCompanyRequest = ClientInferRequest<
  typeof companiesRouter.editCompany
>

export type DeleteCompanyRequest = ClientInferRequest<
  typeof companiesRouter.deleteCompany
>

export type CompanyResponse = z.infer<typeof companyResponse200>

export default companiesRouter
