import { TaskPriority } from '@aeqoom/db'
import { FiberManualRecord } from '@mui/icons-material'
import { Chip, ChipProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

const TASK_PRIORITY_MAP: Record<TaskPriority, ChipProps['color']> = {
  [TaskPriority.HIGH]: 'error',
  [TaskPriority.MEDIUM]: 'warning',
  [TaskPriority.LOW]: 'info',
}

type Props = Omit<ChipProps, 'label'> & {
  label: string
}

const isTaskPriority = (item: string): item is TaskPriority =>
  Object.values<string>(TaskPriority).includes(item)

export const PriorityChip = ({ label, ...props }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'priorityChip' })

  if (!isTaskPriority(label)) return undefined

  return (
    <Chip
      icon={<FiberManualRecord />}
      variant="outlined"
      color={TASK_PRIORITY_MAP[label]}
      {...props}
      label={t(label)}
    />
  )
}
