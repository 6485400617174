import { Avatar, Chip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

type Props = {
  value: File
  onDelete: () => void
}

const DropzonePreview = ({ value, onDelete }: Props) => {
  const [preview, setPreview] = useState<string | undefined>()

  useEffect(() => {
    if (value) {
      const cachedURL = URL.createObjectURL(value)
      setPreview(cachedURL)
    }
  }, [value])

  return (
    <Chip
      size="medium"
      avatar={<Avatar alt={value.name} src={preview} />}
      onDelete={onDelete}
      sx={{ maxWidth: 320 }}
      label={
        <Typography textTransform="none" variant="body2" noWrap>
          {value.name}
        </Typography>
      }
      variant="outlined"
    />
  )
}

export default DropzonePreview
