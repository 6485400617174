import { componentInstances, componentTemplates } from '@aeqoom/db'
import { z } from '@aeqoom/zod'
import { ClientInferRequest, initContract } from '@ts-rest/core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

import { globalFilter } from '../schemas'

const c = initContract()

const componentInstanceResponse200 = z.object({
  ...createSelectSchema(componentInstances).shape,
  media: z.array(z.number()),
  qrcode: z.string().nullable(),
})

const componentInstancesRouter = c.router({
  getComponentInstances: {
    method: 'GET',
    path: '/component-instances',
    query: z.intersection(
      z.object({
        machineInstanceId: z.coerce.number().optional(),
        companyId: z.coerce.number().nullish(),
        companyUnitId: z.coerce.number().optional(),
        serviceLevelAgreementId: z.coerce.number().nullish(),
      }),
      globalFilter
    ),
    responses: {
      200: z.array(
        z.object({
          ...createSelectSchema(componentInstances).shape,
          componentTemplate: createSelectSchema(componentTemplates).nullable(),
        })
      ),
    },
  },
  getComponentInstance: {
    method: 'GET',
    path: '/component-instances/:id/get',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: componentInstanceResponse200,
      404: z.object({ message: z.string() }),
    },
  },
  addComponentInstance: {
    method: 'POST',
    path: '/component-instances',
    body: z.object({
      ...createInsertSchema(componentInstances).shape,
      media: z.array(z.number()),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  deleteComponentInstance: {
    method: 'DELETE',
    path: '/companent-instances/:id/delete',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({}),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  editComponentInstance: {
    method: 'POST',
    path: '/companent-instances/:id/edit',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({
      ...createInsertSchema(componentInstances).shape,
      media: z.array(z.number()),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
})

export type AddComponentInstanceRequest = ClientInferRequest<
  typeof componentInstancesRouter.addComponentInstance
>

export type EditComponentInstanceRequest = ClientInferRequest<
  typeof componentInstancesRouter.editComponentInstance
>

export type DeleteComponentInstanceRequest = ClientInferRequest<
  typeof componentInstancesRouter.deleteComponentInstance
>

export type GetComponentInstancesRequest = ClientInferRequest<
  typeof componentInstancesRouter.getComponentInstances
>

export type ComponentInstanceResponse = z.infer<
  typeof componentInstanceResponse200
>

export default componentInstancesRouter
