import { Permission, safeHasPermission } from '@aeqoom/permissions'
import useUserStore from '@app/src/stores/useUserStore'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  permissions: Permission[]
}

const CheckPermissions = ({ children, permissions }: Props) => {
  const { user } = useUserStore()

  const hasPermissions = (() =>
    permissions.some(
      (permission) => user?.groups && safeHasPermission(user.groups, permission)
    ))()

  if (!hasPermissions) return undefined

  return children
}

export default CheckPermissions
