import useUser from '@app/hooks/useUser'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const UserProvider = () => {
  const { setStoreUser } = useUser()

  useEffect(() => {
    setStoreUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Outlet />
}

export default UserProvider
