import { useThemeColors } from '@aeqoom/hooks'
import { PERMISSIONS } from '@aeqoom/permissions'
import { useGetCompanies } from '@app/queries/useCompanies'
import { useGetCompanyUnits } from '@app/queries/useCompanyUnits'
import useUserStore from '@app/stores/useUserStore'
import { Autocomplete, Stack, TextField } from '@mui/material'

type Props = {
  hideCompanyUnit?: boolean
}

const SelectClient = ({ hideCompanyUnit = false }: Props) => {
  const {
    setCurrentActiveCompany,
    getCurrentActiveCompany,
    setCurrentActiveCompanyUnit,
    getCurrentActiveCompanyUnit,
    hasPermission,
  } = useUserStore()

  const companyValue = getCurrentActiveCompany()
  const companyUnitValue = getCurrentActiveCompanyUnit()

  const { data, isLoading, isError } = useGetCompanies()

  const getCompanyUnits = useGetCompanyUnits({
    companyId: companyValue,
  })

  const { white } = useThemeColors()

  return (
    <Stack direction="row" gap={2}>
      <Autocomplete
        fullWidth
        sx={{
          marginBottom: 2,
          width: { xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 2 / 12 },
        }}
        value={data?.find((option) => option.id === companyValue) || null}
        onChange={(_, value) => setCurrentActiveCompany(value?.id ?? null)}
        getOptionLabel={(option) => option.name}
        options={data ?? []}
        readOnly={!hasPermission(PERMISSIONS.COMPANY.LIST)}
        renderInput={(params) => (
          <TextField
            error={isError}
            helperText={isError && 'Failed to load companies'}
            placeholder="Company"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                background: white.main,
                '& fieldset': {
                  borderWidth: 0,
                },
              },
            }}
            {...params}
          />
        )}
        loading={isLoading}
        loadingText="Loading..."
      />
      {!hideCompanyUnit && (
        <Autocomplete
          fullWidth
          sx={{
            marginBottom: 2,
            width: { xs: 12 / 12, sm: 6 / 12, md: 4 / 12, lg: 2 / 12 },
          }}
          value={
            getCompanyUnits.data?.find(
              (option) => option.id === companyUnitValue
            ) || null
          }
          onChange={(_, value) => {
            setCurrentActiveCompanyUnit(value?.id ?? null)
          }}
          getOptionLabel={(option) => option.name}
          options={getCompanyUnits.data ?? []}
          readOnly={!hasPermission(PERMISSIONS.COMPANY_UNIT.LIST)}
          renderInput={(params) => (
            <TextField
              error={isError}
              helperText={isError && 'Failed to load company units'}
              placeholder="Company unit"
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  background: white.main,
                  '& fieldset': {
                    borderWidth: 0,
                  },
                },
              }}
              {...params}
            />
          )}
          loading={getCompanyUnits.isLoading}
          loadingText="Loading..."
        />
      )}
    </Stack>
  )
}

export default SelectClient
