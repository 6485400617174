import './calendar.css'

import { GetTasks } from '@aeqoom/types'
import {
  DayHeaderContentArg,
  EventContentArg,
  EventSourceInput,
} from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import FullCalendar from '@fullcalendar/react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { has, is } from 'ramda'
import { useCallback, useEffect, useState } from 'react'

import DayHeader from './DayHeader'
import Event from './Event'
import Header from './Header'

dayjs.extend(advancedFormat)

const PRIORITY_LIST = ['low', 'medium', 'high']

const sortEvents = (a: unknown, b: unknown) => {
  if (
    !has('extendedProps', a) ||
    !has('priority', a.extendedProps) ||
    !is(String, a.extendedProps.priority)
  )
    return -1
  if (
    !has('extendedProps', b) ||
    !has('priority', b.extendedProps) ||
    !is(String, b.extendedProps.priority)
  )
    return 1

  return (
    PRIORITY_LIST.indexOf(b.extendedProps.priority) -
    PRIORITY_LIST.indexOf(a.extendedProps.priority)
  )
}

const renderEventContent = (eventInfo: EventContentArg) => {
  return <Event eventInfo={eventInfo} />
}

const renderDayHeaderContent = ({ date }: DayHeaderContentArg) => {
  return <DayHeader date={date} />
}

type Props = {
  data: GetTasks | undefined
}

export default function CalendarView({ data = [] }: Props) {
  const [calendarNode, setCalendarNode] = useState<FullCalendar | null>(null)
  const onRefChange = useCallback((node: FullCalendar) => {
    setCalendarNode(node)
  }, [])

  const [date, setDate] = useState(dayjs())

  const events: EventSourceInput =
    data.map((task) => ({
      extendedProps: task,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textColor: '#000000',
      end: task.plannedTo ?? undefined,
      start: task.plannedFrom ?? undefined,
    })) ?? []

  useEffect(() => {
    if (calendarNode) {
      calendarNode.getApi().on('datesSet', (info) => {
        setDate(dayjs(info.view.currentStart))
      })
    }
  }, [calendarNode])

  return (
    <>
      <Header calendarNode={calendarNode} date={date} />
      <FullCalendar
        eventOrder={sortEvents}
        ref={onRefChange}
        plugins={[dayGridPlugin]}
        initialView="dayGridWeek"
        headerToolbar={{
          start: 'title',
          center: 'prev next',
          end: 'today',
        }}
        titleFormat={{ year: 'numeric', month: 'long' }}
        firstDay={1}
        events={events}
        eventContent={(event) => renderEventContent(event)}
        buttonIcons={{ prev: 'chevron-left', next: 'chevron-right' }}
        dayHeaderContent={renderDayHeaderContent}
      />
    </>
  )
}
