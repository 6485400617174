import CloseConfirmDialog from '@app/components/base/dialogs/CloseConfirmDialog'
import { BreadcrumbItem } from '@app/components/BreadcrumbItem'
import { useDialogStore } from '@app/stores/useDialogStore'
import {
  DialogId,
  DialogStatus,
  IDialog,
} from '@app/stores/useDialogStore/types'
import { getDialogAttributes } from '@app/stores/useDialogStore/utils'
import CloseIcon from '@mui/icons-material/Close'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import { Breadcrumbs, Dialog, DialogTitle, Icon, Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useEffect, useState } from 'react'

type Props = {
  dialog: IDialog
}

const MainDialog = ({ dialog }: Props) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState<DialogId[]>([])
  const [isCloseConfirmOpen, setIsConfirmCloseDialogOpen] = useState(false)

  const {
    previousDialogs,
    activeDialogs,
    shouldAskBeforeClose,
    closeDialog,
    minimizeDialog,
    maximizeDialog,
  } = useDialogStore()

  const dialogAttributes = getDialogAttributes(dialog.config)

  useEffect(() => {
    Object.values(previousDialogs).map(({ previousIds }) => {
      if (previousIds.includes(dialog.dialogId)) {
        setBreadcrumbItems(previousIds)
      }
      return null
    })
  }, [previousDialogs, activeDialogs, dialog.dialogId])

  const handleOnCloseClick = () => {
    return shouldAskBeforeClose
      ? setIsConfirmCloseDialogOpen(true)
      : closeDialog()
  }

  return (
    <>
      <Dialog
        {...dialogAttributes}
        open={dialog.status === DialogStatus.OPEN}
        keepMounted
        sx={{
          pointerEvents: dialog.status === DialogStatus.OPEN ? 'auto' : 'none',
        }}
        onClose={(_e, reason) => {
          if (
            reason &&
            reason === 'backdropClick' &&
            !dialog.config.closeOnBackdrop
          )
            return
          if (reason && reason === 'escapeKeyDown' && !dialog.config.closeOnEsc)
            return
          closeDialog()
        }}
      >
        <DialogTitle
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          px={3}
          py={1.5}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            {dialog.config.shouldShowBreadcrumb &&
            breadcrumbItems.length > 1 ? (
              <Breadcrumbs>
                {breadcrumbItems.map((item) => {
                  return (
                    <BreadcrumbItem
                      key={item}
                      icon={activeDialogs[item]?.config.icon}
                      text={activeDialogs[item]?.title}
                      isActive={dialog.dialogId === item}
                      id={item}
                      handleClick={(id) => id && maximizeDialog(id)}
                    />
                  )
                })}
              </Breadcrumbs>
            ) : (
              dialog.config.shouldShowTitle && (
                <Stack direction="row" spacing={2} alignItems="center">
                  <Icon component={dialog.config.icon} sx={{ fontSize: 22 }} />
                  <DialogTitle sx={{ p: 0, fontSize: 18, fontWeight: 700 }}>
                    {dialog.title}
                  </DialogTitle>
                </Stack>
              )
            )}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <IconButton onClick={() => minimizeDialog()}>
              <HorizontalRuleIcon />
            </IconButton>
            <IconButton onClick={handleOnCloseClick}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Stack sx={{ height: '80vh' }}>{dialog.mainContent}</Stack>
      </Dialog>
      <CloseConfirmDialog
        isOpen={isCloseConfirmOpen}
        onConfirm={() => closeDialog()}
        onCancel={() => setIsConfirmCloseDialogOpen(false)}
      />
    </>
  )
}

export default MainDialog
