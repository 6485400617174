import FullCalendar from '@fullcalendar/react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

type HeaderProps = {
  calendarNode: FullCalendar | null
  date: dayjs.Dayjs
}

const Header = ({ calendarNode, date }: HeaderProps) => {
  const calendarApi = calendarNode?.getApi()

  const { t } = useTranslation('web', { keyPrefix: 'calendar.header' })

  const title = date.format('MMMM YYYY')
  const weekNumber = date.format('wo')

  return (
    <Stack
      direction={{
        xs: 'column',
        md: 'row',
      }}
      alignItems={{
        xs: 'flex-start',
        md: 'center',
      }}
      justifyContent="space-between"
      mb={3}
      gap={2}
    >
      <Typography variant="h4" flex="1">
        {title}
      </Typography>
      <Stack
        flex="1"
        direction="row"
        gap={2}
        alignItems="center"
        justifyContent={{
          sx: 'flex-start',
          md: 'flex-end',
          lg: 'center',
        }}
      >
        <Button color="secondary" onClick={() => calendarApi?.prev()}>
          <KeyboardArrowLeft />
        </Button>
        {t('weekNumber', { weekNumber })}
        <Button color="secondary" onClick={() => calendarApi?.next()}>
          <KeyboardArrowRight />
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            calendarApi?.today()
          }}
        >
          {t('today')}
        </Button>
      </Stack>
      <Box flex="1" display={{ xs: 'none', lg: 'block' }} />
    </Stack>
  )
}

export default Header
