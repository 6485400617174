import { relations } from 'drizzle-orm'
import {
  boolean,
  integer,
  pgEnum,
  pgTable,
  serial,
  text,
  timestamp,
  varchar,
} from 'drizzle-orm/pg-core'

import { tasks, users } from './index'

export const statusEnum = pgEnum('work_protocol_status', [
  'in_concept',
  'completed',
])

export const workProtocols = pgTable('work_protocols', {
  id: serial('id').primaryKey(),
  userId: integer('user_id')
    .references(() => users.id)
    .notNull(),
  createdAt: timestamp('created_at', { mode: 'string' }).defaultNow().notNull(),
  taskId: integer('task_id')
    .references(() => tasks.id, { onDelete: 'cascade' })
    .notNull(),
  arrival: timestamp('arrival', { mode: 'string' }),
  repairFrom: timestamp('repair_from', { mode: 'string' }),
  repairTo: timestamp('repair_to', { mode: 'string' }),
  departure: timestamp('departure', { mode: 'string' }),
  distance: integer('distance'),
  status: statusEnum('status_enum'),
  hours: integer('hours'),
  descriptionOfWork: varchar('description_of_work', { length: 1024 }),
  usedSpareParts: varchar('used_spare_parts', { length: 1024 }),
  sparePartsToOrder: varchar('spare_parts_to_order', { length: 1024 }),
  isConfirmed: boolean('isConfirmed').default(false),
  signature: text('signature'),
})

export const workProtocolRelations = relations(workProtocols, ({ one }) => ({
  user: one(users, {
    fields: [workProtocols.userId],
    references: [users.id],
  }),
  task: one(tasks, {
    fields: [workProtocols.taskId],
    references: [tasks.id],
  }),
}))

export type WorkProtocol = typeof workProtocols.$inferSelect
export type NewWorkProtocol = typeof workProtocols.$inferInsert
