import { PriorityChip, StatusChip } from '@aeqoom/components-web'
import { GetBacklogResponseBody } from '@aeqoom/contracts'
import { TaskPriority } from '@aeqoom/db'
import { createUniqueKey } from '@aeqoom/utils'
import {
  ColumnDirection,
  ColumnVariant,
} from '@app/components/BacklogColumn/types'
import Date from '@app/components/Date'
import TaskChip from '@app/components/TaskChip'
import { useDialogStore } from '@app/stores/useDialogStore'
import { DateVariant } from '@app/types'
import TaskIcon from '@mui/icons-material/Task'
import { CardContent, Stack, useTheme } from '@mui/material'
import { Card, CardActionArea } from '@mui/material'
import { grey } from '@mui/material/colors'
import Typography from '@mui/material/Typography'

import TaskDetailDialogContent from '../base/dialogs/_detailViewDialogs/TaskDetailDialogContent'
import CustomAvatar from '../CustomAvatar'

type Props = {
  task: GetBacklogResponseBody['tasks'][number]
  direction?: ColumnDirection
  variant: ColumnVariant
}
const TaskItem = ({
  task,
  direction = ColumnDirection.ROW,
  variant,
}: Props) => {
  const { openDialog } = useDialogStore()

  const handleDialogOpen = () => {
    openDialog(<TaskDetailDialogContent selectedId={task.id} />, task.name, {
      icon: TaskIcon,
      uniqueKey: createUniqueKey(['task', task.id]),
    })
  }

  const theme = useTheme()

  const isHighPriorityItem =
    variant !== ColumnVariant.HIGH_PRIORITY &&
    task.priority === TaskPriority.HIGH

  return (
    <Card
      variant="outlined"
      sx={{
        flexShrink: 0,
        width: '100%',
        borderStyle: 'solid',
        borderWidth: isHighPriorityItem ? 2 : 1,
        borderColor: isHighPriorityItem ? theme.palette.red.main : grey[300],
        maxWidth: direction === ColumnDirection.ROW ? 420 : '100%',
      }}
    >
      <CardActionArea onClick={handleDialogOpen}>
        <CardContent>
          <Stack spacing={2}>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="body2Regular"
                color={theme.palette.green.main}
              >
                #{task.id}
              </Typography>
              <TaskChip label={task.type} />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <CustomAvatar src={task?.companyUnit?.avatar} />
              {task?.companyUnit.name && (
                <Typography variant="body3Regular">
                  {task?.companyUnit.name}
                </Typography>
              )}
            </Stack>
            <Typography variant="body1Bold">{task.name}</Typography>
            <Stack spacing={1} direction="row" alignItems="center">
              <StatusChip label={task.status} />
              {task.priority && <PriorityChip label={task.priority} />}
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="body3Regular">Assigned to:</Typography>
              <Stack spacing={0.5} direction="row" alignItems="center">
                <CustomAvatar
                  src={task?.assignedTo?.avatar}
                  width={24}
                  height={24}
                />
                <Typography variant="body3Regular">
                  {task.assignedTo?.firstName} {task.assignedTo?.lastName}
                </Typography>
              </Stack>
            </Stack>
            {task.deadline && (
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  borderTop: `1px solid ${theme.palette.lightGray.main}`,
                  pt: 1,
                }}
              >
                <Date variant={DateVariant.DEADLINE} date={task.deadline} />
              </Stack>
            )}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default TaskItem
