import { AccessLevel, AddMedia, notEmpty, S3Media } from '@aeqoom/shared'
import {
  getAverageFromNumbers,
  getCleanMedia,
  getFileHeaders,
} from '@aeqoom/utils'
import { client } from '@app/queries/client'
import parseIncoming from '@app/queries/parseIncoming'
import axios from 'axios'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

const useUpload = () => {
  const { t } = useTranslation()
  const [uploadPercentage, setUploadPercentage] = useState<number[]>([])

  const uploadFile = async (
    files: { file: File; accessLevel: AccessLevel }[]
  ): Promise<{ key: string; file: File }[]> => {
    setUploadPercentage(new Array(files.length).fill(0))

    const filesToUpload = files.map(async ({ file, accessLevel }, index) => {
      const { type: contentType, name: fileName, size: fileSize } = file

      try {
        const res = await client.media.getSignedUrl({
          query: { fileName, accessLevel, contentType },
        })

        const { key, signedUrl, contentDisposition } = parseIncoming(res)

        const headers = getFileHeaders(contentType, contentDisposition)

        try {
          await axios.put(signedUrl, file, {
            headers,
            onUploadProgress: (progressEvent) => {
              if (progressEvent) {
                setUploadPercentage((currentUploadPercentage) => {
                  const newUploadPercentage = [...currentUploadPercentage]
                  newUploadPercentage[index] = Math.round(
                    (progressEvent.loaded / fileSize) * 100
                  )
                  return newUploadPercentage
                })
              }
            },
          })

          return { key, file }
        } catch (error) {
          toast.error(t('media.failedToS3'))
          return null
        }
      } catch (error) {
        toast.error(t('media.failedToSignedUrl'))
        return null
      }
    })

    const filePromises = await Promise.all(filesToUpload)
    const uploadedFiles = filePromises.filter(notEmpty)

    if (uploadedFiles.length > 0) toast.success(t('media.filesUploaded'))

    return uploadedFiles
  }

  const addMedia = async (media: S3Media[]): Promise<AddMedia[]> => {
    const cleanMedia = getCleanMedia(media)
    const files = cleanMedia.map((item) => ({
      file: item.file[0],
      accessLevel: item.defaultAccessLevel,
    }))
    const uploadedFiles = await uploadFile(files)

    const mediaUploaded = uploadedFiles
      .map((uploaded, index) => {
        const item = cleanMedia[index]
        const { key, file } = uploaded
        return {
          name: item.name,
          description: item.description,
          accessLevel: item.defaultAccessLevel,
          mediaType: item.mediaType,
          originalFilename: file.name,
          mimeType: file.type,
          key,
          fileSize: file.size,
        }
      })
      .filter(notEmpty)

    return mediaUploaded
  }

  const averageUploadPercentage = getAverageFromNumbers(uploadPercentage)

  return {
    uploadPercentage,
    addMedia,
    uploadFile,
    averageUploadPercentage,
  }
}

export default useUpload
