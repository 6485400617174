import { truncateString } from '@aeqoom/utils'
import { FormHelperText, Tooltip } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  helperText: ReactNode
  error?: boolean
}

const FormHelperTooltip = ({ helperText = ' ', error }: Props) => {
  const truncatedString = truncateString(helperText)

  return (
    <Tooltip
      title={helperText}
      placement="bottom-start"
      disableHoverListener={helperText === ' '}
    >
      <FormHelperText error={error} variant="filled">
        {truncatedString}
      </FormHelperText>
    </Tooltip>
  )
}

export default FormHelperTooltip
