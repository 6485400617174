import useMediaCarouselStore from '@app/src/stores/useMediaCarouselStore'
import { Box, Container } from '@mui/material'

import MediaCarouselNotFound from './NotFound'

const MediaCarouselVideo = () => {
  const { currentMedia } = useMediaCarouselStore()

  if (!currentMedia || !currentMedia.signedUrl) return <MediaCarouselNotFound />

  return (
    <Container
      maxWidth="lg"
      sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
    >
      <Box component="video" controls width="100%">
        <Box component="source" src={currentMedia.signedUrl} />
      </Box>
    </Container>
  )
}

export default MediaCarouselVideo
