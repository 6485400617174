import { ErrorHttpStatusCode, SuccessfulHttpStatusCode } from '@ts-rest/core'

interface SuccessResponse<T> {
  status: SuccessfulHttpStatusCode
  body: T
}

interface ErrorResponse {
  status: ErrorHttpStatusCode
  body: { message: string }
}

type ApiResponse<T> = SuccessResponse<T> | ErrorResponse

const parseIncoming = <T>(data: ApiResponse<T>): T => {
  if (
    data.status !== 200 &&
    data.status !== 201 &&
    data.status !== 202 &&
    data.status !== 203 &&
    data.status !== 204 &&
    data.status !== 205 &&
    data.status !== 206 &&
    data.status !== 207
  )
    throw Error
  return data.body
}

export default parseIncoming
