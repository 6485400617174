import { create } from 'zustand'

interface MediaState {
  isGrid: boolean
  setView: () => void
}

const useMediaStore = create<MediaState>((set) => ({
  isGrid: true,
  setView: () =>
    set(({ isGrid }) => ({
      isGrid: !isGrid,
    })),
}))

export default useMediaStore
