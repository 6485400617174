import { GetTaskResponseBody } from '@aeqoom/contracts'
import { createUniqueKey } from '@aeqoom/utils'
import QrDialog from '@app/components/base/dialogs/QrDialog'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { QrCode } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  task: GetTaskResponseBody
}

const ToDoListQRButton = ({ task }: Props) => {
  const { t } = useTranslation('web', { keyPrefix: 'backlog.taskDetail' })

  const { openDialog } = useDialogStore()

  if (!task.qrcode) return null

  return (
    <Tooltip title={t('todoListQRCodeToolTip')}>
      <IconButton
        onClick={() => {
          if (task.qrcode)
            openDialog(
              <QrDialog qrCode={task.qrcode} />,
              `QR code - #${task.id} ${task.name}`,
              {
                uniqueKey: createUniqueKey(['qrcode', 'machine', task.uuid]),
              }
            )
        }}
      >
        <QrCode />
      </IconButton>
    </Tooltip>
  )
}

export default ToDoListQRButton
