import LoadingScreen from '@app/components/LoadingScreen'
import UnauthorizedScreen from '@app/components/UnauthorizedScreen'
import { PATHS_PERMISSIONS_MAP } from '@app/constants/paths'
import useUserStore from '@app/stores/useUserStore'
import { getCurrentUser } from 'aws-amplify/auth'
import { ReactNode, useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

type Props = {
  children: ReactNode
}

const RequireAuth = ({ children }: Props) => {
  const [auth, setAuth] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { user, hasPermission } = useUserStore()
  const location = useLocation()

  const isAuthenticated = async () => {
    try {
      await getCurrentUser()
      setAuth(true)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    isAuthenticated()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) return <LoadingScreen />

  if (auth === false) return <Navigate to="/sign-in" replace />

  if (user?.groups) {
    if (!hasPermission(PATHS_PERMISSIONS_MAP[location.pathname])) {
      return <UnauthorizedScreen />
    }
    return children
  }
}

export default RequireAuth
