import { z } from '@aeqoom/zod'
import { useTranslation } from 'react-i18next'
import { makeZodI18nMap } from 'zod-i18n-map'

export const signInSchema = z.object({
  emailAddress: z.string().email(),
  password: z.string(),
})

export const useSignInSchema = () => {
  return signInSchema
}

export type SignInSchemaForm = z.infer<ReturnType<typeof useSignInSchema>>

export const forgotPasswordSchema = z.object({
  emailAddress: z.string().email(),
})

export const useForgotPasswordSchema = () => {
  return forgotPasswordSchema
}

export type ForgotPasswordSchemaForm = z.infer<
  ReturnType<typeof useForgotPasswordSchema>
>

export const verifyPasswordSchema = z.object({
  emailAddress: z.string().email(),
  confirmationCode: z.string(),
  newPassword: z.string(),
})

export const useVerifyPasswordSchema = () => {
  const { t } = useTranslation(['web', 'zod'])

  z.setErrorMap(makeZodI18nMap({ t }))

  return verifyPasswordSchema.superRefine(({ newPassword }, ctx) => {
    if (newPassword.length < 8) {
      return ctx.addIssue({
        code: 'custom',
        path: ['newPassword'],
        message: t('zod:errors.signIn.passwordTooShort'),
      })
    }

    const containsUppercase = (ch: string) => /[A-Z]/.test(ch)
    const containsLowercase = (ch: string) => /[a-z]/.test(ch)
    const containsSpecialChar = (ch: string) =>
      /[`!@#$%^&*()_\-+=[\]{};':"\\|,.<>/?~ ]/.test(ch)

    let countOfUpperCase = 0,
      countOfLowerCase = 0,
      countOfNumbers = 0,
      countOfSpecialChar = 0

    for (let i = 0; i < newPassword.length; i++) {
      const ch = newPassword.charAt(i)
      if (!isNaN(+ch)) countOfNumbers++
      else if (containsUppercase(ch)) countOfUpperCase++
      else if (containsLowercase(ch)) countOfLowerCase++
      else if (containsSpecialChar(ch)) countOfSpecialChar++
    }

    if (
      countOfLowerCase < 1 ||
      countOfUpperCase < 1 ||
      countOfSpecialChar < 1 ||
      countOfNumbers < 1
    ) {
      ctx.addIssue({
        path: ['newPassword'],
        code: 'custom',
        message: t('zod:errors.signIn.unsecurePassword'),
      })
    }
  })
}

export type VerifyPasswordSchemaForm = z.infer<
  ReturnType<typeof useVerifyPasswordSchema>
>
