import { componentInstances } from '@aeqoom/db'
import { createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

export const componentInstance = createSelectSchema(componentInstances)

export type ComponentInstance = z.infer<typeof componentInstance> & {
  children?: ComponentInstance[]
}

export const componentInstanceSchema: z.ZodType<ComponentInstance> =
  componentInstance.extend({
    children: z.lazy(() => componentInstanceSchema.array().optional()),
  })
