import { AddMediaForm } from '@aeqoom/forms'
import { AccessLevel, MediaType } from '@aeqoom/shared'
import {
  Business,
  CheckCircle,
  DeleteForever,
  Download,
  Person,
  Public,
} from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { get, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import FormChipListPicker from '../base/form/FormChipListPicker'
import { FormTextField } from '../base/form/FormTextField'
import CustomProgressBar from '../CustomProgressBar'
import FormDropzone from '../FormDropzone'
import MediaCard from '../MediaCard'

type Props = {
  path: `media.${number}`
  remove: () => void
  uploadPercentage: number
  defaultAccessLevel: AccessLevel[]
  defaultMediaType: MediaType[]
}

type Option = {
  icon?: ReactElement
  label: string
  hidden?: boolean
}

type AccessLevelOption = Option & {
  value: AccessLevel
}

type MediaTypeOption = Option & {
  value: MediaType
}

const accessLevelOptions: AccessLevelOption[] = [
  { icon: <Public />, label: 'Public', value: 'public' },
  {
    icon: <Business />,
    label: 'Company unit',
    value: 'companyUnit',
  },
  { icon: <Business />, label: 'Company', value: 'company' },
  { icon: <Person />, label: 'User', value: 'user', hidden: true },
]

const mediaTypeOptions: MediaTypeOption[] = [
  { label: 'General', value: 'general' },
  {
    label: 'Agreement',
    value: 'agreement',
  },
  {
    label: 'Avatar',
    value: 'avatar',
  },
]

const MediaUploadCard = ({
  path,
  remove,
  uploadPercentage = 0,
  defaultAccessLevel,
  defaultMediaType,
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<AddMediaForm>()

  const value = useWatch({ name: path, control })

  const { t } = useTranslation(['web', 'common'])

  const [open, setOpen] = useState(false)
  const [preview, setPreview] = useState<string | undefined>()

  const error = get(errors, path)

  const file = value.file && value.file[0]

  useEffect(() => {
    if (file) {
      const cachedURL = URL.createObjectURL(file)
      setPreview(cachedURL)
    }
  }, [value, file])

  return (
    <>
      <MediaCard
        accessLevel={value?.defaultAccessLevel}
        fullUrl={preview}
        mimeType={file?.type}
        onClick={() => setOpen(true)}
        isError={!!error}
        cardProgress={<CustomProgressBar value={uploadPercentage} />}
        cardContent={
          <Stack sx={{ minWidth: 0, width: '100%' }}>
            <Typography variant="body1" noWrap gutterBottom>
              {file?.name || t('web:media.noFileProvided')}
            </Typography>
            <Typography variant="caption" noWrap gutterBottom>
              {value?.name || t('web:media.noNameProvided')}
            </Typography>
          </Stack>
        }
        cardActions={
          <>
            {preview && (
              <IconButton
                href={preview}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <Download />
              </IconButton>
            )}
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                remove()
              }}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <DeleteForever />
            </IconButton>
          </>
        }
      />
      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle component="div">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {t('media.editMedia')}
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Grid container spacing={4} alignItems="stretch">
              <Grid item xs={12} md={6}>
                <FormDropzone control={control} name={`${path}.file`} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <FormTextField
                    controller={{ control, name: `${path}.name` }}
                    field={{ label: t('media.upload.fileName') }}
                  />
                  <FormTextField
                    controller={{ control, name: `${path}.description` }}
                    field={{
                      label: t('media.upload.description'),
                      rows: 4,
                      multiline: true,
                    }}
                  />
                  <FormChipListPicker
                    controller={{
                      control,
                      name: `${path}.defaultAccessLevel`,
                      defaultValue: defaultAccessLevel[0],
                      rules: { required: true },
                    }}
                    label="Permission"
                    options={accessLevelOptions
                      .filter((item) => !item.hidden)
                      .map((item) => ({
                        ...item,
                        disabled:
                          defaultAccessLevel.length > 0 &&
                          !defaultAccessLevel.includes(item.value),
                      }))}
                  />
                  <FormChipListPicker
                    controller={{
                      control,
                      name: `${path}.mediaType`,
                      defaultValue: defaultMediaType[0],
                      rules: { required: true },
                    }}
                    label={t('media.mediaType')}
                    options={mediaTypeOptions
                      .filter((item) => !item.hidden)
                      .map((item) => ({
                        ...item,
                        disabled:
                          defaultMediaType.length > 0 &&
                          !defaultMediaType.includes(item.value),
                      }))}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<CheckCircle />}
            onClick={() => setOpen(false)}
          >
            {t('common:done')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MediaUploadCard
