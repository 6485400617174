import { SupportContent, SupportHeader } from '@app/pages/Supports/styled'
import { Paper, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Support = () => {
  const { t } = useTranslation('web', { keyPrefix: 'support' })

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        height="80%"
        lineHeight={2}
        textAlign="center"
      >
        <div>
          <SupportHeader>{t('header')}</SupportHeader>
          <p>
            <b>{t('introText')}</b>
          </p>
          <SupportContent>
            {t('text')}
            {' '}
            <a href={`mailto:${t('email')}`}>{t('email')}</a>
          </SupportContent>
        </div>
      </Stack>
    </Paper>
  )
}

export default Support
