import { useQuery } from '@tanstack/react-query'

import { client } from './client'
import parseIncoming from './parseIncoming'

const keys = {
  getDashboard: ['get-dashboard'],
}

export const useGetDashboard = ({ id }: { id?: number | null } = {}) => {
  return useQuery({
    queryKey: [...keys.getDashboard, id],
    queryFn: () =>
      client.dashboard
        .getDashboard({ query: { companyId: id } })
        .then(parseIncoming),
  })
}
