import FetchableAvatar from '@app/components/FetchableAvatar'
import { useGetCompanyUnit } from '@app/queries/useCompanyUnits'
import { DomainDisabledOutlined } from '@mui/icons-material'
import { CircularProgress, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ICON_HEIGHT } from './constants'

type Props = {
  companyUnitId: number | null | undefined
}

const CompanyUnit = ({ companyUnitId }: Props) => {
  const { t } = useTranslation('web', { keyPrefix: 'calendar' })

  const { data, isPending, isError } = useGetCompanyUnit({
    id: companyUnitId,
  })

  if (isPending) return <CircularProgress size={ICON_HEIGHT} />

  if (isError)
    return (
      <Tooltip title={t('companyFailedToLoad')}>
        <DomainDisabledOutlined />
      </Tooltip>
    )

  return (
    <Tooltip title={data?.name}>
      <div>
        <FetchableAvatar
          id={data?.avatar}
          width={ICON_HEIGHT}
          height={ICON_HEIGHT}
        />
      </div>
    </Tooltip>
  )
}

export default CompanyUnit
