import {
  AddCompanyUnitRequest,
  DeleteCompanyUnitRequest,
  EditCompanyUnitRequest,
} from '@aeqoom/contracts'
import { PERMISSIONS } from '@aeqoom/permissions'
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import useUserStore from '../stores/useUserStore'
import { client } from './client'
import parseIncoming from './parseIncoming'
import { invalidateContactQueries } from './useContacts'

const keys = {
  getCompanyUnits: ['company-units'],
  getCompanyUnit: ['company-unit'],
}

export const invalidateCompanyUnitQueries = (queryClient: QueryClient) => {
  const allKeys = Object.values(keys).flat()
  queryClient.invalidateQueries({
    predicate: (query) => allKeys.includes(query.queryKey[0] + ''),
  })
}

export const useGetCompanyUnits = ({
  companyId,
}: { companyId?: number | null } = {}) => {
  const { hasPermission } = useUserStore()

  return useQuery({
    queryKey: [...keys.getCompanyUnits, companyId],
    enabled: hasPermission(PERMISSIONS.COMPANY_UNIT.LIST),
    queryFn: () =>
      client.companyUnits
        .getCompanyUnits({ query: { companyId } })
        .then(parseIncoming),
  })
}

export const useGetCompanyUnit = ({
  id,
  enabled = !!id,
  staleTime,
}: {
  id?: number | null
  enabled?: boolean
  staleTime?: number
}) => {
  const { hasPermission } = useUserStore()

  return useQuery({
    enabled: enabled && hasPermission(PERMISSIONS.COMPANY_UNIT.READ),
    queryKey: [...keys.getCompanyUnit, id],
    queryFn: () =>
      client.companyUnits
        .getCompanyUnit({ params: { id: id! } })
        .then(parseIncoming),
    staleTime,
  })
}

export const useCreateCompanyUnit = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateCompanyUnitQueries(queryClient)
      invalidateContactQueries(queryClient)
    },
    mutationFn: (body: AddCompanyUnitRequest['body']) =>
      client.companyUnits.addCompanyUnit({ body }).then(parseIncoming),
  })
}

export const useEditCompanyUnit = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateCompanyUnitQueries(queryClient)
      invalidateContactQueries(queryClient)
    },
    mutationFn: async ({
      body,
      params,
    }: {
      body: EditCompanyUnitRequest['body']
      params: EditCompanyUnitRequest['params']
    }) =>
      client.companyUnits.editCompanyUnit({ body, params }).then(parseIncoming),
  })
}

export const useDeleteCompanyUnit = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: DeleteCompanyUnitRequest['params']) =>
      client.companyUnits.deleteCompanyUnit({ params }).then(parseIncoming),
    onSuccess: () => {
      invalidateCompanyUnitQueries(queryClient)
    },
  })
}
