import { Stack } from '@mui/material'

import ToDoListContent from './ToDoListContent'

type Props = {
  taskId: number
  toDoListId?: number | null
}

const TodoList = ({ taskId, toDoListId }: Props) => {
  return (
    <Stack gap={4}>
      <Stack gap={2}>
        <ToDoListContent taskId={taskId} toDoListId={toDoListId} />
      </Stack>
    </Stack>
  )
}

export default TodoList
