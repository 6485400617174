import { ContractResponse } from '@aeqoom/contracts'
import DialogContent from '@app/components/base/dialogs/_detailViewDialogs/_components/DialogContent'
import Media from '@app/components/base/dialogs/_detailViewDialogs/_components/Media'
import MachinesList from '@app/components/base/dialogs/_detailViewDialogs/ContractDialog/Content/_tabs/MachinesList'
import { useTranslation } from 'react-i18next'

import ContractInfo from './_tabs/ContractInfo'

type Props = {
  data: ContractResponse
}

const Content = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'contracts.detail',
  })

  const TABS = [
    {
      component: (data: ContractResponse) => <ContractInfo data={data} />,
      header: t('tabs.contractDetail.label'),
    },
    {
      component: (data: ContractResponse) => <Media media={data.media} />,
      header: t('tabs.media.label'),
      isHidden: data.media.length <= 0,
    },
    {
      component: (data: ContractResponse) => <MachinesList data={data} />,
      header: t('tabs.machinesList.label'),
    },
  ]

  return (
    <DialogContent
      title={t('header.title', { name: data.name })}
      tabs={TABS}
      data={data}
    />
  )
}

export default Content
