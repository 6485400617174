import logo from '@app/assets/img/logo-full.svg'
import { AppBar, Box, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'

import UserMenu from './UserMenu'

const CustomAppBar = () => {
  const theme = useTheme()

  return (
    <AppBar
      position="relative"
      elevation={0}
      sx={{
        height: theme.constants.appBarHeight,
        backgroundColor: theme.palette.white.main,
      }}
    >
      <Toolbar>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Box component="img" src={logo} alt="Aeqoom logo" width={86} />
          <UserMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default CustomAppBar
