import { GetTaskResponseBody } from '@aeqoom/contracts'
import Media from '@app/components/base/dialogs/_detailViewDialogs/_components/Media'
import {
  FormatListNumbered,
  InfoOutlined,
  PermMedia,
} from '@mui/icons-material'
import { Stack, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import TaskDetail from './_tabs/TaskDetail'
import TodoList from './_tabs/TodoList'
import ToDoListQRButton from './ToDoListQRButton'

const TABS = [
  (task: GetTaskResponseBody) => <TaskDetail task={task} />,
  (task: GetTaskResponseBody) => (
    <TodoList taskId={task.id} toDoListId={task.toDoListId} />
  ),
  (task: GetTaskResponseBody) => <Media media={task.media} />,
]

type Props = {
  task: GetTaskResponseBody
}

const TaskContent = ({ task }: Props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0)

  const TAB_HEADER = [
    { icon: <InfoOutlined />, labelPath: 'taskDetail.label' },
    { icon: <FormatListNumbered />, labelPath: 'todoList.label' },
    { icon: <PermMedia />, labelPath: 'media.label' },
  ] as const

  const { t } = useTranslation('web', { keyPrefix: 'backlog.taskDetail.tabs' })

  return (
    <Stack>
      <Stack direction="row" alignItems="center" paddingBottom={4}>
        <Tabs
          value={currentTabIndex}
          onChange={(_, index) => {
            setCurrentTabIndex(index)
          }}
          sx={{ flex: '1 0' }}
        >
          {TAB_HEADER.map(({ icon, labelPath }) => (
            <Tab
              icon={icon}
              iconPosition="start"
              label={t(labelPath)}
              key={labelPath}
            />
          ))}
        </Tabs>
        <ToDoListQRButton task={task} />
      </Stack>
      {TABS[currentTabIndex](task)}
    </Stack>
  )
}

export default TaskContent
