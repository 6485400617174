import { relations } from 'drizzle-orm'
import { integer, pgEnum, pgTable, serial, varchar } from 'drizzle-orm/pg-core'

import { companyContacts, companyUnitContacts } from './contacts'
import { machineInstances, media, serviceLevelAgreements, users } from './index'

export const companyType = pgEnum('company_type', [
  'customer',
  'own_company',
  'parts_supplier',
  'producer',
  'revision',
  'service_partner',
  'supplier',
])

export const companies = pgTable('companies', {
  id: serial('id').primaryKey(),
  name: varchar('name', { length: 256 }).notNull(),
  registrationNumber: varchar('registration_number', { length: 256 }),
  companyType: companyType('company_type').notNull(),
  taxId: varchar('tax_id', { length: 256 }),
  invoiceDueDate: integer('invoice_due_date'),
  segment: varchar('segment', { length: 256 }),
  street: varchar('street', { length: 256 }),
  currency: varchar('currency', { length: 256 }),
  city: varchar('city', { length: 256 }),
  country: varchar('country', { length: 256 }),
  houseNumber: varchar('house_number', { length: 256 }),
  zipCode: varchar('zip_code', { length: 256 }),
  avatar: integer('avatar').references(() => media.id, {
    onDelete: 'set null',
  }),
})

export type Company = typeof companies.$inferSelect
export type NewCompany = typeof companies.$inferInsert

export const companiesRelations = relations(companies, ({ many, one }) => ({
  companyUnits: many(companyUnits),
  avatar: one(media, {
    fields: [companies.avatar],
    references: [media.id],
  }),
  contacts: many(companyContacts),
}))

export const companyUnits = pgTable('company_units', {
  id: serial('id').primaryKey(),
  name: varchar('name', { length: 256 }).notNull(),
  street: varchar('street', { length: 256 }),
  city: varchar('city', { length: 256 }),
  houseNumber: varchar('house_number', { length: 256 }),
  country: varchar('country', { length: 256 }),
  companyId: integer('company_id')
    .references(() => companies.id, { onDelete: 'cascade' })
    .notNull(),
  zipCode: varchar('zip_code', { length: 256 }),
  avatar: integer('avatar').references(() => media.id, {
    onDelete: 'set null',
  }),
})

export const companyUnitsRelations = relations(
  companyUnits,
  ({ one, many }) => ({
    company: one(companies, {
      fields: [companyUnits.companyId],
      references: [companies.id],
    }),
    users: many(users),
    serviceLevelAgreements: many(serviceLevelAgreements),
    machineInstances: many(machineInstances),
    avatar: one(media, {
      fields: [companyUnits.avatar],
      references: [media.id],
    }),
    contacts: many(companyUnitContacts),
  })
)

export type CompanyUnit = typeof companyUnits.$inferSelect
export type NewCompanyUnit = typeof companyUnits.$inferInsert
