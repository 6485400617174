import {
  companies,
  companyContacts,
  companyUnitContacts,
  companyUnits,
} from '@aeqoom/db'
import { z } from '@aeqoom/zod'
import { ClientInferRequest, initContract } from '@ts-rest/core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

const c = initContract()

const companyUnitResponse200 = z.object({
  ...createSelectSchema(companyUnits).shape,
  contacts: z.array(createSelectSchema(companyUnitContacts)),
})

const companyUnitsRouter = c.router({
  getCompanyUnits: {
    method: 'GET',
    path: '/company-units',
    query: z.object({
      companyId: z.coerce.number().optional().nullable(),
    }),
    responses: {
      200: z.array(
        z.object({
          ...createSelectSchema(companyUnits).shape,
          company: createSelectSchema(companies),
        })
      ),
    },
  },
  getCompanyUnit: {
    method: 'GET',
    path: '/company-units/:id/get',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: companyUnitResponse200,
      404: z.object({ message: z.string() }),
    },
  },
  addCompanyUnit: {
    method: 'POST',
    path: '/company-units',
    body: z.object({
      ...createInsertSchema(companyUnits).shape,
      contacts: z.array(
        createInsertSchema(companyContacts).pick({
          contactId: true,
          main: true,
        })
      ),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  deleteCompanyUnit: {
    method: 'DELETE',
    path: '/company-units/:id/delete',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({}),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  editCompanyUnit: {
    method: 'POST',
    path: '/company-units/:id/edit',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({
      ...createInsertSchema(companyUnits).shape,
      contacts: z.array(
        createInsertSchema(companyContacts).pick({
          contactId: true,
          main: true,
        })
      ),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
})

export type AddCompanyUnitRequest = ClientInferRequest<
  typeof companyUnitsRouter.addCompanyUnit
>

export type EditCompanyUnitRequest = ClientInferRequest<
  typeof companyUnitsRouter.editCompanyUnit
>

export type DeleteCompanyUnitRequest = ClientInferRequest<
  typeof companyUnitsRouter.deleteCompanyUnit
>

export type CompanyUnitResponse = z.infer<typeof companyUnitResponse200>

export default companyUnitsRouter
