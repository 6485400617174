import { AddContactRequest, DeleteContactRequest,EditContactRequest } from '@aeqoom/contracts'
import { CONTACT_KEYS } from '@aeqoom/types'
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { client } from './client'
import parseIncoming from './parseIncoming'

export const invalidateContactQueries = (queryClient: QueryClient) => {
  const allKeys: string[] = Object.values(CONTACT_KEYS).flat()
  queryClient.invalidateQueries({
    predicate: (query) => allKeys.includes(query.queryKey[0] + ''),
  })
}

export const useGetContacts = () => {
  return useQuery({
    queryKey: [CONTACT_KEYS.getContacts],
    queryFn: () => client.contacts.getContacts().then(parseIncoming),
  })
}

export const useGetContact = ({
  id,
  enabled = !!id,
}: {
  id?: number | null
  enabled?: boolean
}) => {
  return useQuery({
    enabled,
    queryKey: [CONTACT_KEYS.getContact, id],
    queryFn: () =>
      client.contacts.getContact({ params: { id: id! } }).then(parseIncoming),
  })
}

export const useGetCompanyContacts = ({
  id,
  enabled = !!id,
}: {
  id?: number
  enabled?: boolean
}) => {
  return useQuery({
    enabled,
    queryKey: [CONTACT_KEYS.getCompanyContacts, id],
    queryFn: () =>
      client.contacts
        .getCompanyContacts({
          query: {
            id: id!,
          },
        })
        .then(parseIncoming),
  })
}

export const useGetCompanyUnitContacts = ({
  id,
  enabled = !!id,
}: {
  id?: number
  enabled?: boolean
}) => {
  return useQuery({
    enabled,
    queryKey: [CONTACT_KEYS.getCompanyUnitContacts, id],
    queryFn: () =>
      client.contacts
        .getCompanyUnitContacts({
          query: {
            id: id!,
          },
        })
        .then(parseIncoming),
  })
}

export const useCreateContact = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateContactQueries(queryClient)
    },
    mutationFn: (data: AddContactRequest) =>
      client.contacts.addContact(data).then(parseIncoming),
  })
}

export const useEditContact = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateContactQueries(queryClient)
    },
    mutationFn: (data: EditContactRequest) =>
      client.contacts.editContact(data).then(parseIncoming),
  })
}

export const useDeleteContact = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: DeleteContactRequest['params']) =>
      client.contacts
        .deleteContact({ params })
        .then(parseIncoming),
    onSuccess: () => {
      invalidateContactQueries(queryClient)
    },
  })
}
