import TextRow from '@app/components/base/dialogs/WorkProtocolDialogContent/TextRow'
import { useGetComponentInstance } from '@app/queries/useComponentInstances'

type Props = {
  componentInstanceId: number
}

const ComponentInstance = ({ componentInstanceId }: Props) => {
  const { data, isError, isPending } = useGetComponentInstance({
    id: componentInstanceId,
  })

  if (isError || isPending) return null

  return <TextRow label="Machine" value={data.name} />
}

export default ComponentInstance
