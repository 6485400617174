import { GridColDef } from '@mui/x-data-grid'
import dayjs from 'dayjs'

const componentInstancesDataCol: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 80 },
  { field: 'name', headerName: 'Name', width: 250 },
  {
    field: 'componentTemplate',
    headerName: 'Component template',
    width: 150,
    valueGetter: (params) => params.row.componentTemplate?.name,
  },
  { field: 'inventoryNumber', headerName: 'Inventory number', width: 150 },
  {
    field: 'yearProduced',
    headerName: 'Year produced',
    width: 150,
    valueFormatter: (params) => dayjs.utc(params.value).format('YYYY'),
  },
  {
    field: 'warrantyUntil',
    headerName: 'Warranty until',
    width: 150,
    valueFormatter: (params) => dayjs.utc(params.value).format('LL'),
  },
  { field: 'description', headerName: 'Description', width: 150 },
  { field: 'counterState', headerName: 'Counter state', width: 150 },
  { field: 'counterType', headerName: 'Counter type', width: 150 },
]

export default componentInstancesDataCol
