import DrawerItem from '@app/components/AdminLayout/DrawerItem'
import DrawerItemNested from '@app/components/AdminLayout/DrawerItemNested'
import {
  sidebarFooter,
  sidebarItems,
} from '@app/components/AdminLayout/SidebarData'
import useUserStore from '@app/stores/useUserStore'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button, Divider, List, Stack } from '@mui/material'

import { Drawer } from './styled'

type Props = {
  open: boolean
  handleOpen: (isOpen: boolean) => void
}

const CustomDrawer = ({ open, handleOpen }: Props) => {
  const { user, hasPermission } = useUserStore()

  return (
    <Drawer
      variant="permanent"
      open={open}
      elevation={0}
      PaperProps={{
        sx: {
          borderRadius: 1,
          position: 'static',
          border: 0,
        },
      }}
    >
      <Stack sx={{ p: 1, height: '100%', justifyContent: 'space-between' }}>
        <List sx={{ paddingY: 0 }}>
          {sidebarItems.map((item) => {
            return item?.subItems ? (
              <DrawerItemNested
                item={item}
                isOpenDrawer={open}
                subItems={item.subItems}
                key={item.name}
                handleOpenDrawer={handleOpen}
              />
            ) : (
              user?.groups &&
                item.permission &&
                hasPermission(item.permission) && (
                  <DrawerItem item={item} isOpen={open} key={item.name} />
                )
            )
          })}
        </List>
        <Stack divider={<Divider />} spacing={1}>
          {open ? (
            <Button color="inherit" fullWidth onClick={() => handleOpen(false)}>
              <ChevronLeftIcon />
            </Button>
          ) : (
            <Button color="inherit" fullWidth onClick={() => handleOpen(true)}>
              <ChevronRightIcon />
            </Button>
          )}
          <DrawerItem
            item={sidebarFooter}
            isOpen={open}
            key={sidebarFooter.name}
          />
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default CustomDrawer
