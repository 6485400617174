import { z } from '@aeqoom/zod'

import {
  accessLevelSchema,
  addMediaSchema,
  companyTypeSchema,
  getContactsSchema,
  getMediaSchema,
  mediaSchema,
  mediaTypeSchema,
  taskPrioritySchema,
  taskStatusSchema,
  taskTypeSchema,
} from '../schemas'

export enum AwsRegion {
  EU = 'eu-west-1',
  US = 'us-east-1',
}

export interface ICurrency {
  title: string
  logo: string
  id: string
  symbol: string
}

export type Address = {
  street: string | null
  houseNumber: string | null
  city: string | null
  zipCode: string | null
  country: string | null
}

export enum FileType {
  Image = 'image',
  Video = 'video',
  Document = 'document',
  Audio = 'audio',
}

export type MediaOrder = 'name' | 'originalFilename' | 'mimeType'

export type AccessLevel = z.infer<typeof accessLevelSchema>

export type TaskType = z.infer<typeof taskTypeSchema>

export type MediaType = z.infer<typeof mediaTypeSchema>

export type TaskStatus = z.infer<typeof taskStatusSchema>

export type CompanyType = z.infer<typeof companyTypeSchema>

export type TaskPriority = z.infer<typeof taskPrioritySchema>

export type Media = z.infer<typeof mediaSchema>

export type AddMedia = z.infer<typeof addMediaSchema>

export type GetMedia = z.infer<typeof getMediaSchema>

export type GetContacts = z.infer<typeof getContactsSchema>

export type S3Media = {
  name?: string
  description?: string
  file?: File[] | null
  defaultAccessLevel: AccessLevel
  uri?: string
  mediaType: MediaType
}

export type FormMedia = {
  media: S3Media[]
}
