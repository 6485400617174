import {
  AutocompleteProps,
  FormAutoComplete,
  FormAutoCompleteProps,
} from '@aeqoom/components-web'
import { MachineInstance } from '@aeqoom/db'
import { useGetMachineInstances } from '@app/queries/useMachineInstances'
import React from 'react'
import { Path, useFormContext, useWatch } from 'react-hook-form'

const MachineInstanceInputByCompanies = <
  T extends {
    companyId: number
    companyUnitId: number
  } & Record<string, unknown>
>({
  autocomplete,
  fieldProps,
  path,
  add,
}: Omit<
  FormAutoCompleteProps<T, MachineInstance>,
  'name' | 'control' | 'autocomplete'
> & {
  autocomplete: Omit<
    AutocompleteProps<MachineInstance>,
    'options' | 'getOptionLabel'
  >
  path: Path<T>
}) => {
  const { control } = useFormContext<T>()
  const { companyId, companyUnitId } = useWatch({ control })

  const { data } = useGetMachineInstances({
    query: {
      companyId,
      companyUnitId,
    },
  })

  const isDisabled = !companyId || !companyUnitId

  return (
    <FormAutoComplete
      name={path}
      control={control}
      autocomplete={{
        ...autocomplete,
        options: data,
        getOptionLabel: (option) => option.name,
        disabled: isDisabled || autocomplete.disabled,
      }}
      fieldProps={{
        ...fieldProps,
        disabled: isDisabled || fieldProps?.disabled,
      }}
      add={add}
    />
  )
}

export default MachineInstanceInputByCompanies
