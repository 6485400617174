import AccordionDetails, {
  AccordionDetailsProps,
} from '@mui/material/AccordionDetails'
import React from 'react'

const Details = ({ children, sx = {}, ...rest }: AccordionDetailsProps) => {
  return (
    <AccordionDetails {...rest} sx={{ padding: 3, ...sx }}>
      {children}
    </AccordionDetails>
  )
}

export default Details
