import { AccessLevel, FileType, MediaType } from '@aeqoom/shared'
import {
  AudioFile,
  Business,
  FilterAlt,
  Help,
  Image,
  Person,
  PictureAsPdf,
  Public,
  VideoCall,
} from '@mui/icons-material'
import {
  Badge,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  Dispatch,
  MouseEvent,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  fileType: FileType[]
  setFileType: Dispatch<SetStateAction<FileType[]>>
  accessLevel: AccessLevel[]
  setAccessLevel: Dispatch<SetStateAction<AccessLevel[]>>
  mediaType: MediaType[]
  setMediaType: Dispatch<SetStateAction<MediaType[]>>
  disableAccessLevel: boolean
  disableFileType: boolean
  addedByMe: boolean
  setAddedByMe: Dispatch<SetStateAction<boolean>>
  disableAddedByMe?: boolean
}

type Filter = {
  name: string
  icon?: ReactElement
  hidden?: boolean
}

type FileTypeFilter = Filter & {
  value: FileType
}

type AccessLevelFilter = Filter & {
  value: AccessLevel
}

type MediaTypeFilter = Filter & {
  value: MediaType
}

const FILE_TYPE_FILTER: FileTypeFilter[] = [
  {
    name: 'Image',
    value: FileType.Image,
    icon: <Image />,
  },
  {
    name: 'Video',
    value: FileType.Video,
    icon: <VideoCall />,
  },
  {
    name: 'Documents',
    value: FileType.Document,
    icon: <PictureAsPdf />,
  },
  {
    name: 'Audio',
    value: FileType.Audio,
    icon: <AudioFile />,
  },
]

const ACCESS_LEVEL_FILTER: AccessLevelFilter[] = [
  {
    name: 'Public',
    value: 'public',
    icon: <Public />,
  },
  {
    name: 'Company',
    value: 'company',
    icon: <Business />,
  },
  {
    name: 'Company unit',
    value: 'companyUnit',
    icon: <Business />,
  },
  {
    name: 'User',
    value: 'user',
    icon: <Person />,
    hidden: true,
  },
]

const MEDIA_TYPE_FILTER: MediaTypeFilter[] = [
  {
    name: 'General',
    value: 'general',
  },
  {
    name: 'Agreement',
    value: 'agreement',
  },
  {
    name: 'Avatar',
    value: 'avatar',
  },
]

const MediaFilter = ({
  fileType,
  setFileType,
  accessLevel,
  setAccessLevel,
  mediaType,
  setMediaType,
  disableAccessLevel,
  disableFileType,
  addedByMe,
  setAddedByMe,
  disableAddedByMe = false,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [badgeValue, setBadgeValue] = useState(0)
  const { t } = useTranslation('web')

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const addFilter = (item: FileTypeFilter) => {
    fileType.includes(item.value)
      ? setFileType(fileType.filter((a) => a !== item.value))
      : setFileType([...fileType, item.value])
  }

  const handleAccessLevelChange = (item: AccessLevelFilter) => {
    accessLevel.includes(item.value)
      ? setAccessLevel(accessLevel.filter((a) => a !== item.value))
      : setAccessLevel([...accessLevel, item.value])
  }

  const handleMediaTypeChange = (item: MediaTypeFilter) => {
    mediaType.includes(item.value)
      ? setMediaType(mediaType.filter((a) => a !== item.value))
      : setMediaType([...mediaType, item.value])
  }

  useEffect(() => {
    const arrayOfArrays = [fileType, accessLevel, mediaType]
    let computedValue = 0

    arrayOfArrays.forEach((array) => {
      if (array.length > 0) {
        computedValue += 1
      }
    })

    if (addedByMe) {
      computedValue += 1
    }

    setBadgeValue(computedValue)
  }, [fileType, accessLevel, addedByMe, mediaType])

  const open = Boolean(anchorEl)
  return (
    <>
      <IconButton onClick={handleClick}>
        <Badge badgeContent={badgeValue} color="secondary">
          <FilterAlt />
        </Badge>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{ paper: { elevation: 1 } }}
      >
        <Box sx={{ minWidth: '256px', p: 2 }}>
          <Stack spacing={4} alignItems="flex-start">
            <Box>
              <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                <Typography variant="body1">
                  {t('media.fileType.label')}
                </Typography>
                <Tooltip
                  title={t('media.fileType.description')}
                  placement="top"
                >
                  <IconButton size="small" disableRipple>
                    <Help fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Stack spacing={2} direction="row">
                {FILE_TYPE_FILTER.map((item) => (
                  <Chip
                    key={item.value}
                    label={item.name}
                    icon={item.icon}
                    disabled={disableFileType}
                    onClick={() => addFilter(item)}
                    color={
                      fileType.includes(item.value) ? 'primary' : 'default'
                    }
                  />
                ))}
              </Stack>
            </Box>
            <Box>
              <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                <Typography variant="body1" gutterBottom>
                  {t('media.accessLevelFilter.label')}
                </Typography>
                <Tooltip
                  title={t('media.accessLevelFilter.description')}
                  placement="top"
                >
                  <IconButton size="small" disableRipple>
                    <Help fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Stack spacing={2} direction="row">
                {ACCESS_LEVEL_FILTER.filter((item) => !item.hidden).map(
                  (item) => (
                    <Chip
                      key={item.value}
                      label={item.name}
                      icon={item.icon}
                      disabled={disableAccessLevel}
                      onClick={() => handleAccessLevelChange(item)}
                      color={
                        accessLevel.includes(item.value) ? 'primary' : 'default'
                      }
                    />
                  )
                )}
              </Stack>
            </Box>
            <Box>
              <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                <Typography variant="body1" gutterBottom>
                  {t('media.mediaTypeFilter.label')}
                </Typography>
                <Tooltip
                  title={t('media.mediaTypeFilter.description')}
                  placement="top"
                >
                  <IconButton size="small" disableRipple>
                    <Help fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Stack spacing={2} direction="row">
                {MEDIA_TYPE_FILTER.filter((item) => !item.hidden).map(
                  (item) => (
                    <Chip
                      key={item.value}
                      label={item.name}
                      icon={item.icon}
                      disabled={disableAccessLevel}
                      onClick={() => handleMediaTypeChange(item)}
                      color={
                        mediaType.includes(item.value) ? 'primary' : 'default'
                      }
                    />
                  )
                )}
              </Stack>
            </Box>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={addedByMe}
                    disabled={disableAddedByMe}
                    onChange={() => setAddedByMe(!addedByMe)}
                  />
                }
                label={t('media.addedByMeFilter.label')}
              />
              <FormHelperText>
                {t('media.addedByMeFilter.description')}
              </FormHelperText>
            </FormControl>
          </Stack>
        </Box>
      </Popover>
    </>
  )
}

export default MediaFilter
