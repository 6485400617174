import { ContractResponse, Flatten } from '@aeqoom/contracts'
import ComponentInstanceDialog from '@app/components/base/dialogs/_detailViewDialogs/ComponentInstanceDialog'
import { useGetComponentInstance } from '@app/queries/useComponentInstances'
import { useGetTaskTemplate } from '@app/queries/useTaskTemplates'
import { useDialogStore } from '@app/stores/useDialogStore'
import { TableCell, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  componentInstance: Flatten<
    Flatten<ContractResponse['machineInstances']>['componentInstances']
  >
}

const ComponentRow = ({ componentInstance: componentInstanceProp }: Props) => {
  const { t } = useTranslation('web')

  const { openDialog } = useDialogStore()

  const {
    data: componentInstance,
    isPending,
    isError,
  } = useGetComponentInstance({
    id: componentInstanceProp.componentInstanceId,
  })

  const taskTemplateQuery = useGetTaskTemplate({
    id: componentInstanceProp.taskTemplateId,
  })

  if (isPending) {
    return t('loading')
  }

  if (isError) return null

  const frequency = () => {
    if (componentInstanceProp.frequency && componentInstanceProp.type) {
      return `${componentInstanceProp.frequency}x ${componentInstanceProp.type}`
    } else {
      return '--'
    }
  }

  return (
    <TableRow
      hover
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        openDialog(
          <ComponentInstanceDialog id={componentInstance.id} />,
          `Component - ${componentInstance.name}`
        )
      }}
    >
      <TableCell sx={{ width: '40%', paddingLeft: 12 }}>
        {componentInstance.name}
      </TableCell>
      <TableCell sx={{ width: '19%' }}>
        {componentInstance.inventoryNumber}
      </TableCell>
      <TableCell>{frequency()}</TableCell>
      <TableCell>
        {taskTemplateQuery.data ? taskTemplateQuery.data.name : '--'}
      </TableCell>
    </TableRow>
  )
}

export default ComponentRow
