import { GetMedia, MIME_TYPES } from '@aeqoom/shared'
import useMediaCarouselStore from '@app/src/stores/useMediaCarouselStore'
import { Box, Pagination, Skeleton, Stack, useTheme } from '@mui/material'
import { SxProps, Theme } from '@mui/system'
import { useState } from 'react'
import { Document, Page } from 'react-pdf'
import { useResizeDetector } from 'react-resize-detector'

import CustomImage from '../CustomImage'
import FileIcon from '../FileIcon'

type Props = {
  data: GetMedia
  imageSx?: SxProps<Theme>
}

const MediaContent = ({ data, imageSx }: Props) => {
  const { width, height, ref } = useResizeDetector()
  const theme = useTheme()

  const { openCarousel } = useMediaCarouselStore()

  const [numPages, setNumPages] = useState<number>()
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  if (MIME_TYPES.image.includes(data.mimeType))
    return (
      <CustomImage
        src={data.signedUrl || undefined}
        sx={{ ...imageSx, cursor: 'pointer' }}
        onClick={() => openCarousel({ currentMedia: data })}
      />
    )

  if (MIME_TYPES.video.includes(data?.mimeType))
    return (
      <Box
        component="video"
        controls
        sx={{
          borderRadius: 2,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        controlsList="nodownload"
      >
        <Box component="source" src={data?.signedUrl || undefined} />
      </Box>
    )

  if (MIME_TYPES.audio.includes(data?.mimeType))
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.grey[200],
          borderRadius: 2,
          justifyContent: 'center',
          alignItems: 'center',
          px: 8,
        }}
      >
        <Box
          component="audio"
          src={data?.signedUrl || undefined}
          controls
          width="100%"
        />
      </Box>
    )

  if (data?.mimeType === 'application/pdf')
    return (
      <Stack spacing={2}>
        <Box ref={ref}>
          <Document
            file={data.signedUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            loading
          >
            <Page
              pageNumber={pageNumber}
              width={width}
              height={height}
              loading={
                <Skeleton variant="rounded" width={width} height={height} />
              }
            />
          </Document>
        </Box>
        <Pagination
          count={numPages}
          page={pageNumber}
          onChange={(_, value) => setPageNumber(value)}
        />
      </Stack>
    )

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        borderRadius: 2,
        backgroundColor: theme.palette.grey[200],
      }}
    >
      <FileIcon mimeType={data?.mimeType} sx={{ fontSize: 72 }} />
    </Box>
  )
}

export default MediaContent
