import { UpdateUserPassword, useUpdateUserPassword } from '@aeqoom/forms'
import { usePasswordChangeError } from '@aeqoom/hooks'
import FormHelperTooltip from '@app/components/base/form/FormHelperTooltip'
import { FormTextField } from '@app/components/base/form/FormTextField'
import { zodResolver } from '@hookform/resolvers/zod'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Button, Collapse, Grid, Stack } from '@mui/material'
import { AuthError, updatePassword } from 'aws-amplify/auth'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

const UpdatePassword = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const updateUserPasswordSchema = useUpdateUserPassword()
  const mapErrorToMessage = usePasswordChangeError()

  const { control, handleSubmit } = useForm<UpdateUserPassword>({
    resolver: zodResolver(updateUserPasswordSchema),
  })

  const { t } = useTranslation('web', { keyPrefix: 'profile.password' })

  const onSubmit = async (formData: UpdateUserPassword) => {
    setIsSubmitting(true)

    try {
      await updatePassword({
        oldPassword: formData.oldPassword,
        newPassword: formData.newPassword,
      })

      setIsOpen(false)
      setIsSubmitting(false)

      toast.success(t('successChange'))
    } catch (err) {
      if (err instanceof AuthError) setError(mapErrorToMessage(err))
      setIsSubmitting(false)
    }
  }

  return (
    <Stack gap={2}>
      <Button
        color="secondary"
        variant="text"
        sx={{ alignSelf: 'flex-start' }}
        onClick={() => setIsOpen((value) => !value)}
        endIcon={isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      >
        {t('changePassword')}
      </Button>
      <Collapse in={isOpen} sx={{ paddingTop: 1 }}>
        <Grid container gap={1}>
          <Grid item xs={12}>
            <FormTextField
              controller={{ control, name: 'oldPassword' }}
              field={{
                type: 'password',
                required: true,
                label: t('currentPassword'),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              controller={{ control, name: 'newPassword' }}
              field={{
                type: 'password',
                required: true,
                label: t('newPassword'),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              controller={{ control, name: 'confirmPassword' }}
              field={{
                type: 'password',
                required: true,
                label: t('confirmPassword'),
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ height: '3rem' }}>
            <FormHelperTooltip helperText={error} error showFull />
          </Grid>
        </Grid>
      </Collapse>
      {isOpen && (
        <Button
          sx={{ alignSelf: 'flex-end' }}
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting}
        >
          {t('save')}
        </Button>
      )}
    </Stack>
  )
}

export default UpdatePassword
