import { MutationResult } from '@aeqoom/contracts'
import { UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

export const useDeleteAction = <T = { id: number },>(
  deleteMutation: UseMutationResult<MutationResult, Error, T>
) => {
  const [selectedItemId, setSelectedItemId] = useState<number>()
  const [isOpen, setIsOpen] = useState(false)

  const { mutate, isPending, isError, isSuccess, data, failureReason } =
    deleteMutation
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)
  // @ts-expect-error this is there for backward compatibility. TODO needs to be refactored
  const handleConfirmDelete = () => mutate({ id: selectedItemId! })
  const handleConfirmDeleteWithParams = (params: (chosenId: number) => T) =>
    mutate(params(selectedItemId!))

  useEffect(() => {
    if (isError) {
      let message = 'Failed to delete item'

      if (
        failureReason instanceof AxiosError &&
        failureReason.status !== 500 &&
        failureReason.response?.data.message
      ) {
        message = failureReason.response?.data.message
      }

      toast.error(message)
    }
    if (isSuccess) {
      toast.success(data.message)
    }
    if (!isPending) {
      setIsOpen(false)
    }
  }, [isPending, isError, isSuccess, failureReason, data])

  return {
    setSelectedItemId,
    isOpen,
    handleOpen,
    handleClose,
    handleConfirmDelete,
    handleConfirmDeleteWithParams,
  }
}
