import CustomImage from '@app/components/CustomImage'
import { useGetOneMedia } from '@app/queries/useMedia'
import { ImageNotSupportedOutlined } from '@mui/icons-material'
import { Alert, Skeleton, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  avatar: number | null
}

const CoverImage = ({ avatar }: Props) => {
  const {
    isPending,
    isError,
    data: mediaData,
  } = useGetOneMedia({
    id: avatar,
  })

  const { t } = useTranslation('common', {
    keyPrefix: 'error',
  })

  if (!avatar)
    return (
      <Stack
        sx={{ width: '100%', paddingY: 12 }}
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <ImageNotSupportedOutlined fontSize="large" />
        <Typography>{t('noAvatar')}</Typography>
      </Stack>
    )

  if (isPending) return <Skeleton variant="rounded" height="100%" />

  if (isError)
    return (
      <Alert
        color="error"
        variant="outlined"
        sx={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}
      >
        {t('failedToLoad')}
      </Alert>
    )

  if (!mediaData) return null

  return <CustomImage src={mediaData.signedUrl || undefined} />
}

export default CoverImage
