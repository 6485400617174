export enum TO_DO_LIST_ITEMS_KEYS {
  getToDoListItems = 'to-do-list-items',
  getToDoListItem = 'to-do-list-item',
}

export enum TASK_KEYS {
  getTask = 'task',
  getTasks = 'tasks',
  getToDoList = 'task-to-do-list',
  getPlan = 'task-plan',
}

export enum TASK_STATUS_KEYS {
  ToBeDone = 'tasks.toBeDone',
  Planned = 'tasks.planned',
  ToAccept = 'tasks.toAccept',
  HighPriority = 'tasks.highPriority',
}

export enum MACHINE_INSTANCE_KEYS {
  getMachineInstances = 'machine-instances',
  getMachineInstance = 'machine-instance',
}

export enum COMPONENT_INSTANCE_KEYS {
  getComponentInstances = 'component-instances',
  getComponentInstance = 'component-instance',
}

export enum MEDIA_KEYS {
  getMedia = 'media',
  getOneMedia = 'one-media',
  getManyMedia = 'many-media',
  getMediaVersions = 'media-versions',
}

export enum CONTACT_KEYS {
  getContacts = 'contacts',
  getContact = 'contact',
  getCompanyContacts = 'company-contacts',
  getCompanyUnitContacts = 'company-unit-contacts',
}

export enum BACKLOG_KEYS {
  getBacklog = 'backlog',
}

export enum SLA_KEYS {
  getSlas = 'service-level-agreements',
  getSla = 'service-level-agreement',
}

export enum SERVICE_LOG_KEYS {
  getForMachineInstance = 'machine-instance-service-log',
}
