import { styled } from '@mui/material'

export const IdentifierText = styled('span')(({ theme }) => ({
  fontWeight: 400,
  fontSize: 22,
  display: 'block',
  color: theme.palette.primary.main,
}))

export const TitleText = styled('span')({
  fontSize: 22,
  display: 'block',
  fontWeight: 700,
})
