import {
  AddMachineInstanceRequest,
  DeleteMachineInstanceRequest,
  EditMachineInstanceRequest,
  GetMachineInstancesRequest,
} from '@aeqoom/contracts'
import { MACHINE_INSTANCE_KEYS } from '@aeqoom/types'
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import dayjs from 'dayjs'

import { client } from './client'
import parseIncoming from './parseIncoming'

const invalidateMachineInstanceQueries = (queryClient: QueryClient) => {
  const allKeys: string[] = Object.values(MACHINE_INSTANCE_KEYS).flat()
  queryClient.invalidateQueries({
    predicate: (query) => allKeys.includes(query.queryKey[0] + ''),
  })
}

export const useGetMachineInstances = ({
  query = {},
}: {
  query?: GetMachineInstancesRequest['query']
} = {}) => {
  return useQuery({
    queryKey: [
      MACHINE_INSTANCE_KEYS.getMachineInstances,
      query.companyId,
      query.companyUnitId,
      query.serviceLevelAgreementId,
    ],
    queryFn: () =>
      client.machineInstances
        .getMachineInstances({ query })
        .then(parseIncoming),
  })
}

export const useEditMachineInstance = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateMachineInstanceQueries(queryClient)
    },
    mutationFn: async ({
      body,
      params,
    }: {
      body: EditMachineInstanceRequest['body']
      params: EditMachineInstanceRequest['params']
    }) =>
      client.machineInstances
        .editMachineInstance({ body, params })
        .then(parseIncoming),
  })
}

export const useGetMachineInstance = ({
  id,
  enabled = !!id,
  staleTime,
}: {
  id?: number | null
  enabled?: boolean
  staleTime?: number
}) => {
  return useQuery({
    enabled,
    queryKey: [MACHINE_INSTANCE_KEYS.getMachineInstance, id],
    queryFn: () =>
      client.machineInstances
        .getMachineInstance({ params: { id: id! } })
        .then(parseIncoming)
        .then((data) => ({
          ...data,
          yearProduced: dayjs.utc(data.yearProduced).toISOString(),
        })),
    staleTime,
  })
}

export const useCreateMachineInstance = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateMachineInstanceQueries(queryClient)
    },
    mutationFn: (body: AddMachineInstanceRequest['body']) =>
      client.machineInstances.addMachineInstance({ body }).then(parseIncoming),
  })
}

export const useDeleteMachineInstance = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: DeleteMachineInstanceRequest['params']) =>
      client.machineInstances
        .deleteMachineInstance({ params })
        .then(parseIncoming),
    onSuccess: () => {
      invalidateMachineInstanceQueries(queryClient)
    },
  })
}
