import { theme } from '@app/styles/theme'
import { styled } from '@mui/material/styles'

export const CardBox = styled('div')({
  padding: 13,
  borderRadius: '12px',
  backgroundColor: theme.palette.lightGray.main,
})

export const AmountToPlan = styled('span')({
  fontSize: '36px',
  lineHeight: '40px',
  fontWeight: 700,
})
