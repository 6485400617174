import { trimStringWithExtension } from '@aeqoom/utils'
import { useGetMediaVersions } from '@app/queries/useMedia'
import { formatDate } from '@app/src/utils/formatDate'
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { filesize } from 'filesize'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import CustomAvatar from '../CustomAvatar'

type Props = {
  id: number
  activeVersion?: number
  setActiveVersion: Dispatch<SetStateAction<number | undefined>>
}

const MediaVersion = ({ id, activeVersion, setActiveVersion }: Props) => {
  const { data } = useGetMediaVersions({ id })

  const { t } = useTranslation(['common'])

  return (
    <Box>
      <Typography variant="h6">{t('common:versions')}</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('common:version')}</TableCell>
              <TableCell>{t('common:filename')}</TableCell>
              <TableCell align="right">{t('common:fileSize')}</TableCell>
              <TableCell align="right">{t('common:author')}</TableCell>
              <TableCell align="right">{t('common:dateCreated')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((version, index) => (
              <TableRow
                hover
                onClick={() => setActiveVersion(version.id)}
                role="checkbox"
                tabIndex={-1}
                key={version.id}
                selected={
                  activeVersion
                    ? activeVersion === version.id
                    : version.isCurrent
                }
                sx={{ cursor: 'pointer' }}
              >
                <TableCell component="th" scope="row">
                  {data.length - index}
                </TableCell>
                <TableCell component="th" scope="row">
                  {trimStringWithExtension(version.originalFilename)}
                </TableCell>
                <TableCell align="right">
                  {filesize(version.fileSize)}
                </TableCell>
                <TableCell align="right">
                  <Stack
                    justifyContent="flex-end"
                    alignItems="center"
                    direction="row"
                    spacing={2}
                  >
                    <CustomAvatar src={version.uploadedBy.avatar} />
                    <Box>
                      {version.uploadedBy.firstName}{' '}
                      {version.uploadedBy.lastName}
                    </Box>
                  </Stack>
                </TableCell>
                <TableCell align="right">
                  {formatDate(version.dateCreated)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default MediaVersion
