import { MediaOrder } from '@aeqoom/shared'
import {
  ArrowDownward,
  ArrowUpward,
  Sort,
} from '@mui/icons-material'
import {
  Box,
  Chip,
  IconButton,
  Popover,
  Stack,
  Typography,
} from '@mui/material'
import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  orderBy: MediaOrder
  setOrderBy: Dispatch<SetStateAction<MediaOrder>>
  isAscending: boolean
  setIsAscending: Dispatch<SetStateAction<boolean>>
}

const ORDER: {
  name: string
  value: MediaOrder
}[] = [
  { name: 'Name', value: 'name' },
  { name: 'Filename', value: 'originalFilename' },
  { name: 'MIME type', value: 'mimeType' },
]

const MediaSort = ({
  orderBy,
  setOrderBy,
  isAscending,
  setIsAscending,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [badgeValue, setBadgeValue] = useState(0)
  const { t } = useTranslation('web')

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      <IconButton
        onClick={handleClick}
        color="secondary"
        sx={{ flexShrink: 0 }}
      >
        <Sort />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{ paper: { elevation: 1 } }}
      >
        <Box sx={{ minWidth: 128, p: 2 }}>
          <Stack spacing={4}>
            <Box>
              <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                <Typography variant="body1">Order by</Typography>
              </Stack>
              <Stack spacing={2} direction="row">
                {ORDER.map((item) => (
                  <Chip
                    key={item.value}
                    label={item.name}
                    onClick={() => setOrderBy(item.value)}
                    color={orderBy === item.value ? 'primary' : 'default'}
                  />
                ))}
              </Stack>
            </Box>
            <Box>
              <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                <Typography variant="body1">Order direction</Typography>
              </Stack>
              <Stack spacing={2} direction="column">
                <Chip
                  icon={<ArrowUpward />}
                  label="Ascending"
                  onClick={() => setIsAscending(true)}
                  color={isAscending ? 'primary' : 'default'}
                />
                <Chip
                  icon={<ArrowDownward />}
                  label="Descending"
                  onClick={() => setIsAscending(false)}
                  color={!isAscending ? 'primary' : 'default'}
                />
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Popover>
    </>
  )
}

export default MediaSort
