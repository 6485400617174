import { relations } from 'drizzle-orm'
import {
  date,
  foreignKey,
  integer,
  pgTable,
  serial,
  text,
  timestamp,
  varchar,
} from 'drizzle-orm/pg-core'
import { boolean } from 'drizzle-orm/pg-core'

import {
  companies,
  companyUnits,
  componentInstances,
  machineInstances,
  tasks,
  taskTemplates,
} from './index'
import { mediaOnServiceLevelAgreement } from './media'

export const serviceLevelAgreements = pgTable('service_level_agreements', {
  id: serial('id').primaryKey(),
  name: varchar('name', { length: 256 }).notNull(),
  companyId: integer('company_id')
    .references(() => companies.id, { onDelete: 'cascade' })
    .notNull(),
  companyUnitId: integer('company_unit_id').references(() => companyUnits.id, {
    onDelete: 'cascade',
  }),
  description: text('description'),
  createdAt: timestamp('created_at', { mode: 'string' }).defaultNow().notNull(),
  dateFrom: date('date_from').notNull(),
  dateTo: date('date_to'),
  isIndefinitely: boolean('is_indefinitely').default(false).notNull(),
})

export const serviceLevelAgreementsRelations = relations(
  serviceLevelAgreements,
  ({ one, many }) => ({
    company: one(companies, {
      fields: [serviceLevelAgreements.companyId],
      references: [companies.id],
    }),
    companyUnit: one(companyUnits, {
      fields: [serviceLevelAgreements.companyUnitId],
      references: [companyUnits.id],
    }),
    machineInstances: many(machineInstanceOnServiceLevelAgreement),
    componentInstances: many(componentInstanceOnServiceLevelAgreementMachine),
    tasks: many(tasks),
    media: many(mediaOnServiceLevelAgreement),
  })
)

export const machineInstanceOnServiceLevelAgreement = pgTable(
  'machine_instance_service_level_agreement',
  {
    id: serial('id').primaryKey(),
    serviceLevelAgreementId: integer('service_level_agreement_id')
      .references(() => serviceLevelAgreements.id, { onDelete: 'cascade' })
      .notNull(),
    machineInstanceId: integer('machine_instance_id')
      .references(() => machineInstances.id)
      .notNull(),
    taskTemplateId: integer('task_template_id').references(
      () => taskTemplates.id
    ),
    frequency: integer('frequency'),
    type: varchar('type', { length: 256 }),
    onCall: boolean('machine_instance_on_call').default(false),
  }
)

export const machineInstanceOnServiceLevelAgreementRelations = relations(
  machineInstanceOnServiceLevelAgreement,
  ({ one, many }) => ({
    serviceLevelAgreements: one(serviceLevelAgreements, {
      fields: [machineInstanceOnServiceLevelAgreement.serviceLevelAgreementId],
      references: [serviceLevelAgreements.id],
    }),
    machineInstance: one(machineInstances, {
      fields: [machineInstanceOnServiceLevelAgreement.machineInstanceId],
      references: [machineInstances.id],
    }),
    components: many(componentInstanceOnServiceLevelAgreementMachine),
  })
)

export const componentInstanceOnServiceLevelAgreementMachine = pgTable(
  'component_instance_service_level_agreement_machine',
  {
    id: serial('id').primaryKey(),
    serviceLevelAgreementId: integer('service_level_agreement_id').notNull(),
    serviceLevelAgreementMachineInstanceId: integer(
      'service_level_agreement_machine_instance_id'
    ).notNull(),
    componentInstanceId: integer('component_instance_id').notNull(),
    taskTemplateId: integer('task_template_id'),
    frequency: integer('frequency'),
    type: varchar('type', { length: 256 }),
  },
  (table) => {
    return {
      serviceLevelAgreementReference: foreignKey({
        columns: [table.serviceLevelAgreementId],
        foreignColumns: [serviceLevelAgreements.id],
        name: 'component_instance_sla_fkey',
      }).onDelete('cascade'),
      machineInstanceReference: foreignKey({
        columns: [table.serviceLevelAgreementMachineInstanceId],
        foreignColumns: [machineInstanceOnServiceLevelAgreement.id],
        name: 'component_instance_machine_instance_fkey',
      }).onDelete('cascade'),
      componentInstanceReference: foreignKey({
        columns: [table.componentInstanceId],
        foreignColumns: [componentInstances.id],
        name: 'component_instance_component_instance_fkey',
      }),
      taskTemplateReference: foreignKey({
        columns: [table.taskTemplateId],
        foreignColumns: [taskTemplates.id],
        name: 'component_instance_task_template_fkey',
      }),
    }
  }
)

export const componentInstanceOnServiceLevelAgreementMachineRelations =
  relations(
    componentInstanceOnServiceLevelAgreementMachine,
    ({ one, many }) => ({
      serviceLevelAgreement: one(serviceLevelAgreements, {
        fields: [
          componentInstanceOnServiceLevelAgreementMachine.serviceLevelAgreementId,
        ],
        references: [serviceLevelAgreements.id],
      }),
      machineInstanceOnServiceLevelAgreement: one(
        machineInstanceOnServiceLevelAgreement,
        {
          fields: [
            componentInstanceOnServiceLevelAgreementMachine.serviceLevelAgreementMachineInstanceId,
          ],
          references: [machineInstanceOnServiceLevelAgreement.id],
        }
      ),
      componentInstance: one(componentInstances, {
        fields: [
          componentInstanceOnServiceLevelAgreementMachine.componentInstanceId,
        ],
        references: [componentInstances.id],
      }),
      tasks: many(tasks),
    })
  )

export type ServiceLevelAgreement = typeof serviceLevelAgreements.$inferSelect

export type NewServiceLevelAgreement =
  typeof serviceLevelAgreements.$inferInsert

export type ComponentInstanceOnSLA =
  typeof componentInstanceOnServiceLevelAgreementMachine.$inferSelect
