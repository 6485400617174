import type { CreateTaskSchemaForm } from '@aeqoom/forms'

export const defaultValues: Partial<CreateTaskSchemaForm> = {
  media: [],
  toDoListId: null,
  description: null,
  componentInstanceId: null,
  machineInstanceId: null,
  serviceLevelAgreementId: null,
}
