import { TaskType } from '@aeqoom/shared'
import {
  BuildOutlined,
  ErrorOutline,
  ListAlt,
  Save,
  Search,
  Settings,
} from '@mui/icons-material'
import { Chip, Stack, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ICON_HEIGHT } from './constants'

const TYPE_ICON_MAP: Record<TaskType, JSX.Element> = {
  examination: <Search sx={{ fontSize: 'inherit' }} />,
  full_service: <Settings sx={{ fontSize: 'inherit' }} />,
  general: <ListAlt sx={{ fontSize: 'inherit' }} />,
  incident: (
    <Chip
      icon={<ErrorOutline sx={{ fontSize: 'inherit' }} />}
      color="error"
      label="incident"
    />
  ),
  installation: <Save sx={{ fontSize: 'inherit' }} />,
  maintenance: <BuildOutlined sx={{ fontSize: 'inherit' }} />,
}

const TypeIcon = ({ type }: { type: TaskType }) => {
  const { t } = useTranslation('common', { keyPrefix: 'taskType' })

  if (type === 'incident')
    return (
      <Chip
        icon={<ErrorOutline sx={{ fontSize: 'inherit' }} />}
        color="error"
        label="incident"
        sx={{ height: ICON_HEIGHT }}
      />
    )

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: ICON_HEIGHT,
        height: ICON_HEIGHT,
        borderRadius: '50%',
        border: '1px solid #000000',
      }}
    >
      <Tooltip title={t(type)}>{TYPE_ICON_MAP[type]}</Tooltip>
    </Stack>
  )
}

export default TypeIcon
