import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { IconButton } from '@mui/material'

const DeleteCellComponent = ({
  id,
  handleOpen,
  setSelectedItemId,
}: {
  id: number
  handleOpen: () => void
  setSelectedItemId: (id: number) => void
}) => (
  <IconButton
    className="actionButton"
    onClick={(e) => {
      e.stopPropagation()
      setSelectedItemId(id)
      handleOpen()
    }}
    color="error"
  >
    <DeleteForeverIcon />
  </IconButton>
)

export default DeleteCellComponent
