import BacklogColumn from '@app/components/BacklogColumn'
import {
  ColumnDirection,
  ColumnVariant,
} from '@app/components/BacklogColumn/types'
import SelectClient from '@app/src/components/SelectClient'
import { Card, CardContent } from '@mui/material'
import Grid from '@mui/material/Grid'

const Backlog = () => {
  return (
    <>
      <SelectClient />
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <BacklogColumn variant={ColumnVariant.TO_BE_DONE} />
            </Grid>
            <Grid
              item
              container
              spacing={2}
              xs={8}
              sx={{ alignContent: 'flex-start' }}
            >
              <Grid item xs={12}>
                <BacklogColumn
                  variant={ColumnVariant.HIGH_PRIORITY}
                  direction={ColumnDirection.ROW}
                />
              </Grid>
              <Grid item xs={6}>
                <BacklogColumn variant={ColumnVariant.PLANNED} />
              </Grid>
              <Grid item xs={6}>
                <BacklogColumn variant={ColumnVariant.TO_ACCEPT} />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default Backlog
