import { UserGroups } from '@aeqoom/shared'
import CompanyContext from '@app/src/contexts/CompanyContext'
import useUserStore from '@app/src/stores/useUserStore'
import { ReactNode, useContext } from 'react'

type Props = {
  children: ReactNode
}

const CheckMediaPermission = ({ children }: Props) => {
  const { user } = useUserStore()
  const context = useContext(CompanyContext)

  if (!context) {
    throw new Error('CheckMediaPermission must be used within a CompanyContext')
  }

  const { companyId, companyUnitId } = context

  const isClient =
    user?.groups?.includes(UserGroups.CLIENT_SUPER_ADMIN) ||
    user?.groups?.includes(UserGroups.CLIENT_UNIT_ADMIN)

  if (isClient && user?.company?.id !== companyId) return null

  return children
}

export default CheckMediaPermission
