import { DetailButton, LabeledValue } from '@aeqoom/components-web'
import MachineInstanceDialog from '@app/components/base/dialogs/_detailViewDialogs/MachineInstanceDialog'
import { useGetCompanyUnit } from '@app/queries/useCompanyUnits'
import { useGetMachineInstance } from '@app/queries/useMachineInstances'
import { useGetOneMedia } from '@app/queries/useMedia'
import { useDialogStore } from '@app/stores/useDialogStore'
import { Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { FIELD_SHARED_STYLES } from '../../constants'

type Props = {
  machineInstanceId: number | null
  shouldShowLabel?: boolean
}

const MachineInstance = ({
  machineInstanceId,
  shouldShowLabel = true,
}: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'detailViewDialog',
  })

  const { openDialog } = useDialogStore()

  const {
    isPending: isMachinePending,
    isError: isMachineError,
    data: machineData,
  } = useGetMachineInstance({
    id: machineInstanceId,
  })

  const { data: companyUnitData } = useGetCompanyUnit({
    id: machineData?.companyUnitId,
  })

  const { isLoading: isAvatarLoading, data: avatarImage } = useGetOneMedia({
    id: machineData?.avatar,
  })

  if (!machineInstanceId) return null

  if (isMachinePending || isAvatarLoading)
    return (
      <Skeleton
        variant="rectangular"
        sx={{ ...FIELD_SHARED_STYLES, borderRadius: 1 }}
      />
    )

  if (isMachineError) return null

  return (
    <LabeledValue
      label={shouldShowLabel ? t('machineInstance') : ''}
      value={
        <DetailButton
          name={machineData.name}
          inventoryNumber={machineData.inventoryNumber}
          address={companyUnitData}
          avatarUrl={avatarImage?.signedUrl || undefined}
          handleOnClick={() =>
            openDialog(
              <MachineInstanceDialog id={machineInstanceId} />,
              machineData.name
            )
          }
        />
      }
      sx={FIELD_SHARED_STYLES}
      type="react-node"
    />
  )
}

export default MachineInstance
