import { media, User } from '@aeqoom/db'
import { AppError, HttpStatusCode, UserGroups } from '@aeqoom/shared'
import { and, eq, or, SQL } from 'drizzle-orm'
import { PgColumn } from 'drizzle-orm/pg-core'

import { Permission, PERMISSIONS } from './definition'
import { roles } from './perRole'

export const getPermissions = (role: UserGroups) => roles[role] ?? []

export const hasPermission = (
  role: UserGroups | null | undefined,
  requiredPermission: Permission,
  userAttachedPermissions?: Permission[]
) => {
  if (!role) return false

  if (userAttachedPermissions === undefined) {
    userAttachedPermissions = getPermissions(role)
  }

  if (userAttachedPermissions.includes(PERMISSIONS.ALL.ANY)) {
    return true
  }

  if (userAttachedPermissions.includes(requiredPermission)) {
    return true
  }

  if (
    userAttachedPermissions.includes(
      (requiredPermission.split('.')[0] + '.*') as Permission
    )
  ) {
    return true
  }

  if (
    userAttachedPermissions.includes(
      ('*.' + requiredPermission.split('.')[1]) as Permission
    )
  ) {
    return true
  }

  throw new AppError(
    `Permission denied - ${requiredPermission} is required`,
    HttpStatusCode.FORBIDDEN
  )
}

export const safeHasPermission = (
  role: UserGroups | null | undefined,
  permission: Permission,
  permissions?: Permission[]
) => {
  try {
    return hasPermission(role, permission, permissions)
  } catch {
    return false
  }
}

type Table = {
  companyId?: PgColumn
  companyUnitId?: PgColumn
  id: PgColumn
}

export const drizzleEntityAccess = (
  user: User,
  table: Table,
  userColumn?: keyof User
) => {
  const permissions = getPermissions(user.group as UserGroups)

  if (
    permissions.includes(PERMISSIONS.ENTITY_ACCESS.NO_LIMIT) ||
    permissions.includes(PERMISSIONS.ALL.ANY)
  ) {
    return undefined
  }

  if (
    permissions.includes(PERMISSIONS.ENTITY_ACCESS.BY_COMPANY) &&
    table.companyId
  ) {
    return eq(table.companyId, user.companyId)
  }

  if (
    permissions.includes(PERMISSIONS.ENTITY_ACCESS.BY_COMPANY_UNIT) &&
    table.companyUnitId
  ) {
    return eq(table.companyUnitId, user.companyUnitId)
  }

  if (
    permissions.includes(PERMISSIONS.ENTITY_ACCESS.BY_COMPANY_UNIT) &&
    userColumn === 'companyUnitId'
  ) {
    return eq(table.id, user.companyUnitId)
  }

  if (
    permissions.includes(PERMISSIONS.ENTITY_ACCESS.BY_COMPANY_UNIT) &&
    userColumn === 'companyId'
  ) {
    return eq(table.id, user.companyId)
  }

  if (
    permissions.includes(PERMISSIONS.ENTITY_ACCESS.BY_COMPANY) &&
    userColumn === 'companyId'
  ) {
    return eq(table.id, user.companyId)
  }

  if (permissions.includes(PERMISSIONS.ENTITY_ACCESS.BOTH) && userColumn) {
    if (userColumn === 'companyId') {
      if (table.companyUnitId) {
        return or(
          eq(table.id, user.companyId),
          eq(table.companyUnitId, user.companyUnitId)
        )
      }
      return or(eq(table.id, user.companyId), eq(table.id, user.companyUnitId))
    }

    if (userColumn === 'companyUnitId') {
      if (table.companyId) {
        return or(
          eq(table.id, user.companyUnitId),
          eq(table.companyId, user.companyId)
        )
      }
      return or(eq(table.id, user.companyUnitId), eq(table.id, user.companyId))
    }
  }

  if (permissions.includes(PERMISSIONS.ENTITY_ACCESS.BOTH)) {
    if (table.companyId && table.companyUnitId) {
      return or(
        eq(table.companyId, user.companyId),
        eq(table.companyUnitId, user.companyUnitId)
      )
    }

    if (table.companyId) {
      return eq(table.companyId, user.companyId)
    }

    if (table.companyUnitId) {
      return eq(table.companyUnitId, user.companyUnitId)
    }
  }

  if (!userColumn && (!table.companyId || !table.companyUnitId)) {
    return undefined
  }

  throw new AppError('Permission denied', HttpStatusCode.FORBIDDEN)
}

/**
 * If user has permission of type ANY, do not restrict access
 *
 * Specific media type access is defined by permissions
 *
 * If no permissions regarding media type are specified, throw an error signaling incorrect permission setup
 * @param user User object
 * @returns SQL query
 */
export const drizzleMediaTypeAccess = (user: User) => {
  const permissions = getPermissions(user.group as UserGroups)
  const queries = []

  if (
    permissions.includes(PERMISSIONS.ALL.ANY) ||
    permissions.includes(PERMISSIONS.MEDIA.ANY)
  ) {
    return undefined
  }

  if (permissions.includes(PERMISSIONS.MEDIA_TYPE.AVATAR)) {
    queries.push(eq(media.mediaType, 'avatar'))
  }

  if (permissions.includes(PERMISSIONS.MEDIA_TYPE.GENERAL)) {
    queries.push(eq(media.mediaType, 'general'))
  }

  if (permissions.includes(PERMISSIONS.MEDIA_TYPE.CONTRACT)) {
    queries.push(eq(media.mediaType, 'agreement'))
  }

  if (queries.length) {
    return or(...queries)
  }

  throw new AppError('Permission denied', HttpStatusCode.FORBIDDEN)
}

/**
 * If user has some entity access defined (is not undefined), return with the entity specific media also media with public access
 *
 * No entity access defined means user has access to all media
 * @param user User object
 * @returns SQL query
 */
export const drizzleMediaEntityAccess = (user: User) => {
  const entityAccess = drizzleEntityAccessMiddleware({
    user,
    table: media,
  })

  if (entityAccess) return or(eq(media.accessLevel, 'public'), entityAccess)

  return entityAccess
}

/**
 * Return media access query based on entity access and media type access
 * @param user User object
 * @returns SQL query
 */
export const drizzleMediaAccess = (user: User) => {
  return or(drizzleMediaEntityAccess(user), drizzleMediaTypeAccess(user))
}

export const drizzleEntityAccessMiddleware = (
  params: { user: User; table: Table; userColumn?: keyof User },
  fn?: () => SQL | undefined
) => {
  if (!fn) {
    return drizzleEntityAccess(params.user, params.table, params.userColumn)
  }

  return and(
    drizzleEntityAccess(params.user, params.table, params.userColumn),
    fn()
  )
}
