import { ContractResponse, Flatten } from '@aeqoom/contracts'
import ComponentTable from '@app/components/base/dialogs/_detailViewDialogs/ContractDialog/Content/_tabs/MachinesList/ComponentTable'
import MachineInstanceDialog from '@app/components/base/dialogs/_detailViewDialogs/MachineInstanceDialog'
import { useGetMachineInstance } from '@app/queries/useMachineInstances'
import { useGetTaskTemplate } from '@app/queries/useTaskTemplates'
import { useDialogStore } from '@app/stores/useDialogStore'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  machineInstance: Flatten<ContractResponse['machineInstances']>
}

const MachineRow = ({ machineInstance: machineInstanceProp }: Props) => {
  const { t } = useTranslation('web')
  const { openDialog } = useDialogStore()
  const [isCollapsed, setIsCollapsed] = useState(false)

  const {
    data: machineInstance,
    isPending,
    isError,
  } = useGetMachineInstance({
    id: machineInstanceProp.machineInstanceId,
  })

  const taskTemplateQuery = useGetTaskTemplate({
    id: machineInstanceProp.taskTemplateId,
  })

  const onExpandClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setIsCollapsed(!isCollapsed)
  }

  if (isPending) {
    return t('loading')
  }

  if (isError) return null

  const frequency = () => {
    if (machineInstanceProp.frequency && machineInstanceProp.type) {
      return `${machineInstanceProp.frequency}x ${machineInstanceProp.type}`
    } else if (machineInstanceProp.onCall) {
      return t('contracts.detail.tabs.machinesList.frequency.onCall')
    } else {
      return '--'
    }
  }

  return (
    <>
      <TableRow
        hover
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          openDialog(
            <MachineInstanceDialog id={machineInstance.id} />,
            `Machine - ${machineInstance.name}`
          )
        }}
      >
        <TableCell sx={{ width: '40%' }}>
          {machineInstanceProp.componentInstances.length > 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => onExpandClick(e)}
              sx={{
                marginRight: 1,
              }}
            >
              {isCollapsed ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          )}
          {machineInstance.name}
        </TableCell>
        <TableCell>{machineInstance.inventoryNumber}</TableCell>
        <TableCell>{frequency()}</TableCell>
        <TableCell>
          {taskTemplateQuery.data ? taskTemplateQuery.data.name : '--'}
        </TableCell>
      </TableRow>
      {machineInstanceProp.componentInstances.length > 0 && (
        <ComponentTable
          data={machineInstanceProp.componentInstances}
          isCollapsed={isCollapsed}
        />
      )}
    </>
  )
}

export default MachineRow
