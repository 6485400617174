import { Accordion } from '@aeqoom/components-web'
import MediaPreviewSimple from '@app/components/MediaPreviewSimple'
import { useGetToDoList } from '@app/queries/useTasks'
import { useCheckToDoListItem } from '@app/queries/useToDoListItems'
import {
  Alert,
  AlertTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  taskId: number
  toDoListId?: number | null
}

const ToDoListContent = ({ taskId, toDoListId }: Props) => {
  const { mutate } = useCheckToDoListItem()

  const { t } = useTranslation()

  const { data, isLoading, isError } = useGetToDoList({
    taskId,
    toDoListId,
  })

  if (isLoading)
    return [...Array(2)].map(() => <Skeleton height={375} variant="rounded" />)

  if (isError)
    return (
      <Alert color="error">
        <AlertTitle>
          {t('backlog.taskDetail.tabs.todoList.failedToLoad.title')}
        </AlertTitle>
        {t('backlog.taskDetail.tabs.todoList.failedToLoad.description')}
      </Alert>
    )

  if (data?.toDoListItems?.length === 0) {
    return (
      <Alert color="info">
        <AlertTitle>
          {t('backlog.taskDetail.tabs.todoList.noItems.title')}
        </AlertTitle>
        {t('backlog.taskDetail.tabs.todoList.noItems.description')}
      </Alert>
    )
  }

  return data?.toDoListItems?.map((item, index) => (
    <Accordion
      isActive={false}
      key={item.id}
      defaultOpen
      title={
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h6">{++index}</Typography>
          <Typography>{item.name}</Typography>
        </Stack>
      }
    >
      <Accordion.Details>
        <Stack spacing={4}>
          <Typography variant="body1">{item.content}</Typography>
          <MediaPreviewSimple media={item.media} />
          <RadioGroup
            row
            name="to-do-list-item-value"
            sx={{ justifyContent: 'flex-end' }}
            value={item.value}
            onChange={(props) =>
              mutate({
                id: item?.id,
                value: parseInt(props.target.value),
              })
            }
          >
            <FormControlLabel value="1" control={<Radio />} label="Yes" />
            <FormControlLabel value="0" control={<Radio />} label="No" />
            <FormControlLabel value="2" control={<Radio />} label="N/A" />
          </RadioGroup>
        </Stack>
      </Accordion.Details>
    </Accordion>
  ))
}

export default ToDoListContent
