import MediaPreviewCard from '@app/components/MediaPreviewCard'
import { Box, Grid } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts'

type Props = {
  value: number[]
  showNumberOfRecords: number
  isEditing: boolean
  onRemove: (id: number) => void
}

type Size = {
  width?: number
  height?: number
}

const GridPreview = ({
  value,
  showNumberOfRecords,
  isEditing,
  onRemove,
}: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const [size, setSize] = useState<Size>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    setSize({
      width: containerRef.current?.clientWidth,
      height: containerRef.current?.clientHeight,
    })
  }, [setSize])

  const onResize = useDebounceCallback(setSize, 200)

  useResizeObserver({
    ref: containerRef,
    onResize,
  })

  const gridCellSpan = (() => {
    if (size.width && size.width > 1100) return 3
    if (size.width && size.width > 900) return 4
    if (size.width && size.width > 600) return 6

    return 12
  })()

  return (
    <Box>
      <Grid container spacing={2} ref={containerRef}>
        {value?.map(
          (item, index) =>
            index < showNumberOfRecords && (
              <Grid item xs={gridCellSpan} key={item}>
                <MediaPreviewCard
                  item={item}
                  isEditing={isEditing}
                  onRemove={onRemove}
                />
              </Grid>
            )
        )}
      </Grid>
    </Box>
  )
}

export default GridPreview
