import { Alert, Box } from '@mui/material'
import QRCode from 'qrcode'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = { value: string }

export const QrCodeRenderer = ({ value }: Props) => {
  const [qrCodeSVG, setQrCodeSVG] = useState<string>()
  const [isError, setIsError] = useState(false)

  const { t } = useTranslation('web')

  useEffect(() => {
    QRCode.toString(value, { type: 'svg', margin: 0 }, (err, svg) => {
      if (err) {
        return setIsError(true)
      }

      setQrCodeSVG(svg)
    })
  }, [value])

  if (isError)
    return <Alert severity="error">{t('qrCode.errorGenerating')}</Alert>

  if (qrCodeSVG)
    return (
      <Box
        component="div"
        dangerouslySetInnerHTML={{ __html: qrCodeSVG }}
        sx={{ width: '100%' }}
      />
    )
}
