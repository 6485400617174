import useMediaStore from '@app/src/stores/useMediaStore'
import {
  ArrowDownward,
  ArrowUpward,
  Clear,
  GridView,
  ImageNotSupportedOutlined,
  ViewList,
} from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import GridPreview from './GridPreview'
import ListPreview from './ListPreview'

type Props = {
  value: number[]
  onChange?: (value: number[]) => void
  defaultNumberOfRecords?: number
  isEditing?: boolean
}

const MediaPreview = ({
  value,
  onChange,
  defaultNumberOfRecords = 4,
  isEditing = true,
}: Props) => {
  const [showNumberOfRecords, setShowNumberOfRecords] = useState(
    defaultNumberOfRecords
  )

  const { t } = useTranslation('web')

  const { isGrid, setView } = useMediaStore()

  const showMore = () => {
    setShowNumberOfRecords(showNumberOfRecords + defaultNumberOfRecords)
  }

  const showLess = () => {
    setShowNumberOfRecords(showNumberOfRecords - defaultNumberOfRecords)
  }

  const onRemove = (id: number) => {
    onChange?.(value.filter((a) => a !== id))
  }
  if (!value || value?.length < 1)
    return (
      <Stack
        sx={{ width: '100%', paddingY: 4 }}
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <ImageNotSupportedOutlined fontSize="large" />
        <Typography>{t('media.noMediaUploaded')}</Typography>
      </Stack>
    )

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="flex-end" mb={4}>
        {isEditing && (
          <IconButton onClick={() => onChange?.([])}>
            <Clear />
          </IconButton>
        )}
        <IconButton onClick={setView}>
          {isGrid ? <ViewList /> : <GridView />}
        </IconButton>
      </Stack>
      {isGrid ? (
        <GridPreview
          value={value}
          showNumberOfRecords={showNumberOfRecords}
          isEditing={isEditing}
          onRemove={onRemove}
        />
      ) : (
        <ListPreview
          value={value}
          showNumberOfRecords={showNumberOfRecords}
          isEditing={isEditing}
          onRemove={onRemove}
        />
      )}
      {value?.length > defaultNumberOfRecords && (
        <Stack direction="row" justifyContent="center">
          <ButtonGroup variant="outlined">
            <Button
              onClick={showMore}
              startIcon={<ArrowDownward />}
              disabled={value?.length <= showNumberOfRecords}
            >
              {t('media.showMore')}
            </Button>
            <Button
              onClick={showLess}
              startIcon={<ArrowUpward />}
              disabled={showNumberOfRecords <= defaultNumberOfRecords}
            >
              {t('media.showLess')}
            </Button>
          </ButtonGroup>
        </Stack>
      )}
    </Stack>
  )
}

export default MediaPreview
