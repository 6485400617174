import { PriorityChip, StatusChip } from '@aeqoom/components-web'
import { GetTaskResponseBody } from '@aeqoom/contracts'
import { formatUserNamePosition } from '@aeqoom/utils'
import FetchableAvatar from '@app/components/FetchableAvatar'
import { useGetCompanyUnit } from '@app/queries/useCompanyUnits'
import {
  AccountCircleOutlined,
  BuildOutlined,
  CalendarMonthOutlined,
  EventAvailableOutlined,
  Info,
} from '@mui/icons-material'
import {
  Chip,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import GridItem from './GridItem'

type Props = {
  task: GetTaskResponseBody
}

const TaskDetailList = ({ task }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'backlog.taskDetail.tabs.taskDetail',
  })
  const { t: typeTranslation } = useTranslation('common', {
    keyPrefix: 'taskType',
  })

  const { data } = useGetCompanyUnit({
    id: task.companyUnitId,
  })

  return (
    <Grid container gap={2}>
      <GridItem xs={3}>{t('list.client')}</GridItem>
      <GridItem xs={8}>
        <FetchableAvatar id={data?.avatar} />
        <Typography variant="body2Regular">{data?.name}</Typography>
      </GridItem>
      <GridItem xs={3}>{t('list.type')}</GridItem>
      <GridItem xs={8}>
        <Chip label={typeTranslation(task.type)} icon={<BuildOutlined />} />
      </GridItem>
      <GridItem xs={3}>{t('list.status')}</GridItem>
      <GridItem xs={8}>
        {task.status && <StatusChip label={task.status} />}
      </GridItem>
      <GridItem xs={3}>{t('list.priority')}</GridItem>
      <GridItem xs={8}>
        {task.priority && <PriorityChip label={task.priority} />}
      </GridItem>
      <GridItem xs={3}>{t('list.reported')}</GridItem>
      <GridItem xs={8}>
        <EventAvailableOutlined />
        {dayjs(task.createdAt).format('DD/MM/YYYY')}
      </GridItem>
      <GridItem xs={3}>{t('list.deadline')}</GridItem>
      <GridItem xs={8}>
        <CalendarMonthOutlined />
        {dayjs(task.deadline).format('DD/MM/YYYY')}
      </GridItem>
      {task.plannedFrom && (
        <>
          <GridItem xs={3}>{t('list.plannedFrom')}</GridItem>
          <GridItem xs={8}>
            <CalendarMonthOutlined />
            {dayjs(task.plannedFrom).format('DD/MM/YYYY HH:mm')}
          </GridItem>
        </>
      )}
      {task.plannedTo && (
        <>
          <GridItem xs={3}>{t('list.plannedTo')}</GridItem>
          <GridItem xs={8}>
            <CalendarMonthOutlined />
            {dayjs(task.plannedTo).format('DD/MM/YYYY HH:mm')}
          </GridItem>
        </>
      )}
      <GridItem xs={3}>{t('list.assignedTo')}</GridItem>
      <GridItem xs={8}>
        {task.user ? (
          <>
            <AccountCircleOutlined />
            {formatUserNamePosition(task.user)}
          </>
        ) : (
          t('assignedTo.none')
        )}
      </GridItem>
      <GridItem xs={3}>{t('list.createdBy')}</GridItem>
      <GridItem xs={8}>
        {task.createdBy ? (
          <>
            <AccountCircleOutlined />
            {formatUserNamePosition(task.createdBy)}
          </>
        ) : (
          <Stack direction="row" alignItems="center" spacing={2}>
            {t('createdBy.none')}
            <Tooltip title={t('createdBy.tooltip')}>
              <IconButton size="small">
                <Info fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </GridItem>
    </Grid>
  )
}

export default TaskDetailList
