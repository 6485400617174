import TextRow from '@app/components/base/dialogs/WorkProtocolDialogContent/TextRow'
import { useGetMachineInstance } from '@app/queries/useMachineInstances'

type Props = {
  machineInstanceId: number
}

const MachineInstance = ({ machineInstanceId }: Props) => {
  const { data, isError, isPending } = useGetMachineInstance({
    id: machineInstanceId,
  })

  if (isError || isPending) return null

  return <TextRow label="Machine" value={data.name} />
}

export default MachineInstance
