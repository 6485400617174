export const trimStringWithExtension = (
  str: string,
  maxLength = 36
) => {
  const lastDotIndex = str.lastIndexOf('.')
  let extension = ''

  if (lastDotIndex !== -1) {
    extension = str.substring(lastDotIndex)
    str = str.substring(0, lastDotIndex)
  }

  if (str.length > maxLength) {
    str = str.substring(0, maxLength - extension.length)
  }

  return str + extension
}
