import { ACCESS_LEVELS, AccessLevel, MIME_TYPES } from '@aeqoom/shared'
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material'
import { MouseEvent, ReactElement, useState } from 'react'

import AccessLevelIcon from '../AccessLevelIcon'
import CustomCardMedia from '../CustomCardMedia'
import FileIcon from '../FileIcon'

type Props = {
  mimeType?: string
  fullUrl?: string
  isChecked?: boolean
  onClick: (e: MouseEvent) => void
  cardContent: ReactElement
  cardActions: ReactElement
  onDoubleClick?: (e: MouseEvent) => void
  showAccessLevel?: boolean
  cardProgress?: ReactElement
  accessLevel?: AccessLevel
  isError?: boolean
}

const MediaCard = ({
  mimeType = 'application/octet-stream',
  fullUrl,
  isChecked,
  onClick,
  onDoubleClick,
  cardContent,
  cardActions,
  cardProgress,
  accessLevel,
  showAccessLevel = false,
  isError = false,
}: Props) => {
  const theme = useTheme()

  const [isHover, setIsHover] = useState(false)

  const borderColor = isError
    ? theme.palette.error.main
    : isChecked
    ? theme.palette.success.main
    : theme.palette.lightGray

  return (
    <Card
      variant="outlined"
      sx={{
        borderColor,
      }}
    >
      <CardActionArea
        component="div"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        <Box position="relative">
          {MIME_TYPES.image.includes(mimeType) ? (
            <CustomCardMedia src={fullUrl} component="img" />
          ) : (
            <CustomCardMedia component="div">
              <FileIcon mimeType={mimeType} />
            </CustomCardMedia>
          )}
          {isHover && accessLevel && showAccessLevel && (
            <Box
              sx={{
                position: 'absolute',
                left: 8,
                top: 8,
              }}
            >
              <Tooltip title={ACCESS_LEVELS[accessLevel]}>
                <Avatar
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    width: 28,
                    height: 28,
                  }}
                >
                  <AccessLevelIcon
                    accessLevel={accessLevel}
                    sx={{ width: 16 }}
                  />
                </Avatar>
              </Tooltip>
            </Box>
          )}
        </Box>
        {cardProgress}
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            justifyContent="space-between"
          >
            {cardContent}
            <Stack
              direction="row"
              sx={{ visibility: isHover ? 'visible' : 'hidden' }}
            >
              {cardActions}
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default MediaCard
