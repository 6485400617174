import { PERMISSIONS } from '@aeqoom/permissions'
import { createUniqueKey } from '@aeqoom/utils'
import CustomDataGrid from '@app/components/base/datagrid/CustomDataGrid'
import TaskDetailDialogContent from '@app/components/base/dialogs/_detailViewDialogs/TaskDetailDialogContent'
import CreateTaskDialogContent from '@app/components/base/dialogs/createForms/CreateTaskDialogContent'
import DeleteConfirmDialog from '@app/components/base/dialogs/DeleteConfirmDialog'
import { useDeleteAction } from '@app/hooks/useDeleteAction'
import { useDeleteTask, useGetTasks } from '@app/queries/useTasks'
import useUserStore from '@app/src/stores/useUserStore'
import getDeleteActionCol from '@app/src/utils/getDeleteActionCol'
import { useDialogStore } from '@app/stores/useDialogStore'
import { PostAddOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import tasksDataCol from './col'

const Tasks = () => {
  const { hasPermission } = useUserStore()
  const { t } = useTranslation('common')

  const { data, isPending } = useGetTasks({
    onlyAssignedToMe: !hasPermission(PERMISSIONS.TASK.ASSIGN_USER),
  })
  const mutation = useDeleteTask()

  const mappedData =
    data &&
    data.map((task) => ({
      ...task,
      type: t(`taskType.${task.type}`),
      status: t(`taskStatus.${task.status}`),
      priority: task.priority && t(`priorityChip.${task.priority}`),
    }))

  const { openDialog } = useDialogStore()

  const {
    handleOpen,
    handleClose,
    handleConfirmDelete,
    isOpen,
    setSelectedItemId,
  } = useDeleteAction(mutation)

  const deleteActionCol = getDeleteActionCol({ handleOpen, setSelectedItemId })

  const columns = [
    ...tasksDataCol,
    ...(hasPermission(PERMISSIONS.TASK.DELETE) ? [deleteActionCol] : []),
  ]

  return (
    <>
      <DeleteConfirmDialog
        isPending={mutation.isPending}
        onClose={handleClose}
        onConfirm={handleConfirmDelete}
        isOpen={isOpen}
      />

      <CustomDataGrid
        disableCreateNew={!hasPermission(PERMISSIONS.TASK.CREATE)}
        addClickOptions={{
          Dialog: CreateTaskDialogContent,
          title: 'Create task',
          options: {
            icon: PostAddOutlined,
            isUnique: false,
          },
        }}
        onRowClick={({ row }) => {
          openDialog(
            <TaskDetailDialogContent selectedId={row.id} />,
            row.name,
            {
              uniqueKey: createUniqueKey(['taskDetail', row.id]),
            }
          )
        }}
        pageLabel="Tasks"
        loading={isPending}
        rows={mappedData || []}
        columns={columns}
      />
    </>
  )
}

export default Tasks
