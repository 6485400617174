import { GetTaskResponseBody } from '@aeqoom/contracts'
import { formatDate } from '@app/src/utils/formatDate'
import { GridColDef } from '@mui/x-data-grid'
import dayjs from 'dayjs'

const tasksDataCol: GridColDef<GetTaskResponseBody>[] = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'name', headerName: 'Name', width: 150 },
  { field: 'type', headerName: 'Type', width: 150 },
  { field: 'priority', headerName: 'Priority', width: 150 },
  { field: 'description', headerName: 'Description', width: 150 },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 200,
    valueFormatter: (params) => formatDate(params.value),
  },
  {
    field: 'finishedAt',
    headerName: 'Finished At',
    width: 200,
    valueFormatter: (params) =>
      params.value && formatDate(dayjs.utc(params.value)),
  },
  { field: 'status', headerName: 'Status', width: 120 },
  {
    field: 'deadline',
    headerName: 'Deadline',
    width: 200,
    valueFormatter: (params) => formatDate(dayjs.utc(params.value)),
  },
]

export default tasksDataCol
