import { AccessLevel } from '@aeqoom/shared'
import { Business, Person, Public } from '@mui/icons-material'
import { SvgIconProps, SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

type Props = {
  accessLevel: AccessLevel
} & SvgIconProps

const ICON: Record<AccessLevel, OverridableComponent<SvgIconTypeMap>> = {
  public: Public,
  user: Person,
  company: Business,
  companyUnit: Business,
}

const AccessLevelIcon = ({ accessLevel, ...props }: Props) => {
  const Icon = ICON[accessLevel]

  return <Icon fontSize="small" {...props} />
}

export default AccessLevelIcon
