import { companyUnits } from '@aeqoom/db'
import { z } from '@aeqoom/zod'
import { ClientInferResponseBody, initContract } from '@ts-rest/core'
import { createSelectSchema } from 'drizzle-zod'

import { globalFilter } from '../schemas'

const c = initContract()

const dashboardRouter = c.router({
  getDashboard: {
    method: 'GET',
    path: '/dashboard',
    query: z.intersection(
      z.object({
        companyId: z.coerce.number().optional().nullable(),
        companyUnitId: z.coerce.number().optional().nullable(),
      }),
      globalFilter
    ),
    responses: {
      200: z.array(
        z.object({
          ...createSelectSchema(companyUnits).shape,
          avatar: z.string().nullish(),
          numberOfMachineInstances: z.number(),
          numberOfIncidents: z.object({
            new: z.number(),
            done: z.number(),
            inProgress: z.number(),
          }),
          numberOfMaintenances: z.object({
            new: z.number(),
            done: z.number(),
            inProgress: z.number(),
            toBeCheckedByAdmin: z.number(),
            toBeCheckedByClient: z.number(),
            waitingOnWp: z.number(),
            planned: z.number(),
            acknowledged: z.number(),
            blocked: z.number(),
            recurred: z.number(),
          }),
        })
      ),
    },
  },
})

export type GetDashboardResponseBody = ClientInferResponseBody<
  typeof dashboardRouter.getDashboard
>

export default dashboardRouter
