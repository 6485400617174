import {
  AddTaskRequest,
  AssignUserRequest,
  ChangeStatusRequest,
  CompleteWorkProtocolRequest,
  DeleteTaskRequest,
  EditTaskRequest,
  PlanTaskRequest,
  SaveWorkProtocolRequest,
} from '@aeqoom/contracts'
import { TASK_KEYS } from '@aeqoom/types'
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'

import { client } from './client'
import parseIncoming from './parseIncoming'
import { invalidateBacklogQueries } from './useBacklog'

const invalidateTaskQueries = (queryClient: QueryClient) => {
  const allKeys: string[] = Object.values(TASK_KEYS).flat()
  queryClient.invalidateQueries({
    predicate: (query) => allKeys.includes(query.queryKey[0] + ''),
  })
}

export const useGetTasks = ({
  id,
  companyId,
  companyUnitId,
  onlyAssignedToMe = false,
  onlyPlanned = false,
  sort,
}: {
  id?: number | null
  companyId?: number | null
  companyUnitId?: number | null
  onlyAssignedToMe?: boolean
  onlyPlanned?: boolean
  sort?: {
    priority?: 'asc' | 'desc'
  }
} = {}) => {
  return useQuery({
    queryKey: [
      TASK_KEYS.getTasks,
      id,
      companyId,
      companyUnitId,
      onlyPlanned,
      onlyAssignedToMe,
      sort,
    ],
    queryFn: () =>
      client.tasks
        .getTasks({
          query: {
            companyId,
            companyUnitId: companyUnitId ?? id,
            onlyAssignedToMe,
            onlyPlanned,
            's.priority': sort?.priority,
          },
        })
        .then(parseIncoming),
  })
}

export const usePlanTask = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateTaskQueries(queryClient)
    },
    mutationFn: ({
      body,
      params,
    }: {
      body: PlanTaskRequest['body']
      params: PlanTaskRequest['params']
    }) => client.tasks.planTask({ body, params }).then(parseIncoming),
  })
}

export const useGetPlanTask = ({
  id,
  enabled = !!id,
}: { id?: number | null; enabled?: boolean } = {}) => {
  return useQuery({
    queryKey: [TASK_KEYS.getPlan, id],
    enabled,
    queryFn: () =>
      client.tasks.getPlanOfTask({ params: { id: id! } }).then(parseIncoming),
  })
}

export const useGetTask = ({
  id,
  enabled = !!id,
  staleTime,
}: {
  id?: number
  enabled?: boolean
  staleTime?: number
}) => {
  return useQuery({
    enabled,
    queryKey: [TASK_KEYS.getTask, id],
    queryFn: () =>
      client.tasks
        .getTask({ params: { id: id! } })
        .then(parseIncoming)
        .then((data) => ({
          ...data,
          deadline: dayjs.utc(data.deadline).toISOString(),
        })),
    staleTime,
  })
}

export const useAssignUserToTask = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateTaskQueries(queryClient)
    },
    mutationFn: ({
      body,
      params,
    }: {
      body: AssignUserRequest['body']
      params: AssignUserRequest['params']
    }) => client.tasks.assignUser({ body, params }).then(parseIncoming),
  })
}

export const useChangeTaskStatus = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateBacklogQueries(queryClient)
      invalidateTaskQueries(queryClient)
    },
    mutationFn: ({
      body,
      params,
    }: {
      body: ChangeStatusRequest['body']
      params: ChangeStatusRequest['params']
    }) => client.tasks.changeStatus({ body, params }).then(parseIncoming),
  })
}

export const useSaveWorkProtocol = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: ({ message }) => {
      invalidateTaskQueries(queryClient)
      toast.success(message)
    },
    mutationFn: ({
      body,
      params,
    }: {
      body: SaveWorkProtocolRequest['body']
      params: SaveWorkProtocolRequest['params']
    }) => client.tasks.saveWorkProtocol({ body, params }).then(parseIncoming),
  })
}

export const useCompleteWorkProtocol = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: ({ message }) => {
      invalidateBacklogQueries(queryClient)
      invalidateTaskQueries(queryClient)
      toast.success(message)
    },
    mutationFn: ({
      body,
      params,
    }: {
      body: CompleteWorkProtocolRequest['body']
      params: CompleteWorkProtocolRequest['params']
    }) =>
      client.tasks.completeWorkProtocol({ body, params }).then(parseIncoming),
  })
}

export const useCreateTask = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateBacklogQueries(queryClient)
      invalidateTaskQueries(queryClient)
    },
    mutationFn: (body: AddTaskRequest['body']) =>
      client.tasks.addTask({ body }).then(parseIncoming),
  })
}

export const useEditTask = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateBacklogQueries(queryClient)
      invalidateTaskQueries(queryClient)
    },
    mutationFn: ({
      body,
      params,
    }: {
      body: EditTaskRequest['body']
      params: EditTaskRequest['params']
    }) => client.tasks.editTask({ body, params }).then(parseIncoming),
  })
}

export const useDeleteTask = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: DeleteTaskRequest['params']) =>
      client.tasks.deleteTask({ params }).then(parseIncoming),
    onSuccess: () => {
      invalidateBacklogQueries(queryClient)
      invalidateTaskQueries(queryClient)
    },
  })
}

export const useGetToDoList = ({
  taskId,
  toDoListId,
  enabled = !!taskId && !!toDoListId,
}: {
  taskId?: number | null
  toDoListId?: number | null
  enabled?: boolean
} = {}) => {
  return useQuery({
    queryKey: [TASK_KEYS.getToDoList, taskId, toDoListId],
    enabled,
    queryFn: () =>
      client.tasks.getToDoList({ params: { id: taskId! } }).then(parseIncoming),
  })
}
