import { contract } from '@aeqoom/contracts'
import { initClient } from '@ts-rest/core'
import { fetchAuthSession } from 'aws-amplify/auth'
import axios from 'axios'

const apiEndpoint = import.meta.env.VITE_API_ENDPOINT

const IPv4 = import.meta.env.VITE_INTERNAL_IPv4

const BASE_URL = apiEndpoint
  ? `https://${apiEndpoint}`
  : `http://${IPv4 ?? 'localhost'}:3000`

export const client = initClient(contract, {
  baseUrl: BASE_URL,
  baseHeaders: {},
  api: async ({ path, method, headers, body }) => {
    const session = await fetchAuthSession()
    const result = await axios.request({
      method,
      url: path,
      headers: {
        ...headers,
        Authorization: session.tokens?.accessToken.toString(),
      },
      data: body,
    })

    return {
      status: result.status,
      body: result.data,
      headers: result.headers as unknown as Headers,
    }
  },
})
