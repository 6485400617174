import { FormDateTimePicker, FormNumberField } from '@aeqoom/components-web'
import { SaveWorkProtocolRequest } from '@aeqoom/contracts'
import { theme } from '@app/styles/theme'
import { Card, CardContent, Stack, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

type Props = {
  readOnly: boolean
}

export const ArrivalPart = ({ readOnly }: Props) => {
  const { control } = useFormContext<SaveWorkProtocolRequest['body']>()

  return (
    <Card sx={{ backgroundColor: theme.palette.primary.main }}>
      <CardContent>
        <Typography variant="body1Bold" color={theme.palette.white.main} pb={2}>
          Working hours
        </Typography>
        <Stack direction="row" gap={2}>
          <Card sx={{ width: '50%' }}>
            <CardContent>
              <Stack flexDirection="column">
                <FormDateTimePicker
                  controller={{
                    name: 'arrival',
                    control,
                  }}
                  timePicker={{
                    label: 'Arrival',
                    readOnly,
                  }}
                  field={{ fullWidth: true }}
                />
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  gap={2}
                >
                  <FormDateTimePicker
                    controller={{
                      name: 'repairFrom',
                      control,
                    }}
                    timePicker={{
                      label: 'Repair from',
                      readOnly,
                    }}
                    field={{ fullWidth: true }}
                  />
                  <FormDateTimePicker
                    controller={{
                      name: 'repairTo',
                      control,
                    }}
                    timePicker={{
                      label: 'Repair end',
                      readOnly,
                    }}
                    field={{ fullWidth: true }}
                  />
                </Stack>
                <FormDateTimePicker
                  controller={{
                    name: 'departure',
                    control,
                  }}
                  timePicker={{
                    label: 'Departure',
                    readOnly,
                  }}
                  field={{ fullWidth: true }}
                />
              </Stack>
            </CardContent>
          </Card>

          <Card
            sx={{
              width: '50%',
            }}
          >
            <CardContent>
              <Stack flexDirection="column">
                <FormNumberField
                  control={control}
                  name="distance"
                  label="Amount of km"
                  fullWidth
                  InputProps={{ readOnly }}
                />
                <FormNumberField
                  control={control}
                  name="hours"
                  label="Hours"
                  fullWidth
                  InputProps={{ readOnly }}
                />
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default ArrivalPart
