import { z } from 'zod'

import { PRETTIER_ERRORS_MAP } from '../constants'

type Error = {
  name: string
  message: string
}

export const isValidZodLiteralUnion = <T extends z.ZodLiteral<unknown>>(
  literals: T[]
): literals is [T, T, ...T[]] => {
  return literals.length >= 2
}

export const constructZodLiteralUnionType = <T extends z.ZodLiteral<unknown>>(
  literals: T[]
) => {
  if (!isValidZodLiteralUnion(literals)) {
    throw new Error(
      'Literals passed do not meet the criteria for constructing a union schema, the minimum length is 2'
    )
  }
  return z.union(literals)
}

export const prettyError = (error: Error | undefined) => {
  if (!error) return undefined
  return PRETTIER_ERRORS_MAP[error.name] ?? error.message
}

export const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => {
  return value !== null && value !== undefined
}
