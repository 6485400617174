import { PERMISSIONS } from '@aeqoom/permissions'
import CreateTaskDialogContent from '@app/components/base/dialogs/createForms/CreateTaskDialogContent'
import CheckPermissions from '@app/components/CheckPermissions'
import LoadingScreen from '@app/components/LoadingScreen'
import { useGetTask } from '@app/queries/useTasks'
import { useDialogStore } from '@app/stores/useDialogStore'
import { Button, DialogActions, DialogContent, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import DialogDetailError from '../../createForms/DialogDetailError'
import TaskContent from './TaskContent'
import TaskHeader from './TaskHeader'

type Props = {
  selectedId: number
}

const TaskDetailDialogContent = ({ selectedId }: Props) => {
  const { t } = useTranslation('common')
  const getTaskQuery = useGetTask({ id: selectedId })

  const { openDialog, closeTab } = useDialogStore()

  if (getTaskQuery.isPending) return <LoadingScreen />

  if (getTaskQuery.isError)
    return <DialogDetailError onRetry={() => getTaskQuery.refetch()} />

  const task = getTaskQuery.data

  return (
    <>
      <DialogContent dividers sx={{ p: 4 }}>
        <Stack>
          <TaskHeader task={task} />
          <TaskContent task={task} />
        </Stack>
      </DialogContent>
      <CheckPermissions permissions={[PERMISSIONS.TASK.UPDATE]}>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              openDialog(
                <CreateTaskDialogContent
                  isEditModal
                  id={selectedId}
                  callback={() => {
                    closeTab()
                  }}
                />,
                'Edit task'
              )
            }}
          >
            {t('actions.edit')}
          </Button>
        </DialogActions>
      </CheckPermissions>
    </>
  )
}

export default TaskDetailDialogContent
