import { Skeleton, Stack } from '@mui/material'

const PaginationSkeleton = () => (
  <Stack direction="row" spacing={2}>
    <Skeleton width={40} height={40} variant="circular" />
    <Skeleton width={40} height={40} variant="circular" />
    <Skeleton width={40} height={40} variant="circular" />
  </Stack>
)

export default PaginationSkeleton
