import { AccessLevel } from '@aeqoom/shared'
import Dropzone from '@app/components/Dropzone'
import useUpload from '@app/hooks/useUpload'
import { useAddVersion } from '@app/queries/useMedia'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { LoadingButton } from '@mui/lab'
import { DialogActions, DialogContent, Skeleton } from '@mui/material'
import { SetStateAction, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

type Props = {
  mediaId: number
  accessLevel?: AccessLevel
  fileAttachments?: File[]
  callback?: (value: SetStateAction<number | undefined>) => void
}

const MediaNewVersion = ({
  mediaId,
  accessLevel,
  fileAttachments = [],
  callback,
}: Props) => {
  const [isUploading, setIsUploading] = useState(false)
  const [files, setFiles] = useState(fileAttachments)

  const { closeTab } = useDialogStore()

  const { uploadFile } = useUpload()

  const addVersionMutation = useAddVersion()

  const { t } = useTranslation(['common', 'web'])

  const addNewVersion = async (accessLevel: AccessLevel) => {
    setIsUploading(true)
    const [uploadedMedia] = await uploadFile([{ file: files[0], accessLevel }])
    if (uploadedMedia) {
      addVersionMutation.mutate(
        {
          body: {
            key: uploadedMedia.key,
            originalFilename: uploadedMedia.file.name,
            fileSize: uploadedMedia.file.size,
            mimeType: uploadedMedia.file.type,
          },
          params: { id: mediaId },
        },
        {
          onSuccess: ({ data, message }) => {
            toast.success(message)

            callback && callback(data)

            closeTab()
          },
          onError: () => {
            toast.error(t('web:media.failedToAddNewVersion'))

            setIsUploading(false)
          },
        }
      )
    }
  }

  return (
    <>
      <DialogContent>
        <Dropzone value={files} onChange={(data) => setFiles(data)} />
      </DialogContent>
      <DialogActions>
        {accessLevel ? (
          <LoadingButton
            variant="contained"
            loading={isUploading}
            onClick={() => addNewVersion(accessLevel)}
          >
            {t('web:media.uploadNewVersion')}
          </LoadingButton>
        ) : (
          <Skeleton variant="rounded" height={36} width={120} />
        )}
      </DialogActions>
    </>
  )
}

export default MediaNewVersion
