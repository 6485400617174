import { PHONE_REGEX } from '@aeqoom/constants'
import { z } from '@aeqoom/zod'

export const addContactForm = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  position: z.string().min(1),
  phoneNumber: z.string().regex(PHONE_REGEX),
  emailAddress: z.string().min(1).email(),
})

export type AddContactForm = z.infer<typeof addContactForm>
