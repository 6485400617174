import {
  AddMachineTemplateRequest,
  EditMachineTemplateRequest,
} from '@aeqoom/contracts'
import { isObjectEmpty } from '@aeqoom/utils'
import { FormTextField } from '@app/components/base/form/FormTextField'
import Section from '@app/components/Section'
import {
  useCreateMachineTemplate,
  useEditMachineTemplate,
  useGetMachineTemplate,
} from '@app/queries/useMachineTemplates'
import { useDialogStore } from '@app/stores/useDialogStore'
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
} from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ActionControlButtons from '../ActionControlButtons'
import DialogDetailError from '../DialogDetailError'

type Props = {
  id?: AddMachineTemplateRequest['body']['id']
  readOnly?: boolean
  callback?: (id: number) => void
  isEditModal?: boolean
}

const CreateMachineTemplateDialogContent = ({
  readOnly = false,
  isEditModal = false,
  id,
  callback,
}: Props) => {
  const [isEditing, setIsEditing] = useState(!readOnly)

  const { closeDialog, setShouldAskBeforeClose } = useDialogStore()

  const {
    handleSubmit,
    control,
    reset,
    formState: { dirtyFields },
  } = useForm<AddMachineTemplateRequest['body']>()

  const getMachineTemplateQuery = useGetMachineTemplate({
    id,
  })
  const createMachineTemplateMutation = useCreateMachineTemplate()
  const editMachineTemplateMutation = useEditMachineTemplate()

  useEffect(() => {
    setShouldAskBeforeClose(isObjectEmpty(dirtyFields))
  }, [isObjectEmpty(dirtyFields)])

  useEffect(() => {
    if (
      !getMachineTemplateQuery.isPending ||
      !getMachineTemplateQuery.isError
    ) {
      reset(getMachineTemplateQuery.data)
    }
  }, [getMachineTemplateQuery.data, reset])

  const onSubmit = (data: AddMachineTemplateRequest['body']) => {
    createMachineTemplateMutation.mutate(data, {
      onSuccess: (data) => {
        callback ? callback(data.id) : closeDialog()
        toast.success(data.message)
        reset()
      },
    })
  }

  const onEditSubmit = (data: EditMachineTemplateRequest['body']) => {
    editMachineTemplateMutation.mutate(
      { body: data, params: { id: data.id! } },
      {
        onSuccess: (data) => {
          callback ? callback(data.id) : closeDialog()
          toast.success(data.message)
          setIsEditing(false)
        },
      }
    )
  }

  if (getMachineTemplateQuery.isPending && readOnly)
    return (
      <Stack alignItems="center">
        <CircularProgress size={80} />
      </Stack>
    )

  if (getMachineTemplateQuery.isError)
    return (
      <DialogDetailError onRetry={() => getMachineTemplateQuery.refetch()} />
    )

  return (
    <>
      <DialogContent>
        <Section title="General information">
          <Grid item xs={6}>
            <FormTextField
              controller={{
                control,
                name: 'name',
                rules: { required: true },
              }}
              field={{
                label: 'Name',
                fullWidth: true,
                required: true,
                InputProps: { readOnly: !isEditing },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              controller={{
                control,
                name: 'producer',
                rules: { required: true },
              }}
              field={{
                label: 'Producer',
                fullWidth: true,
                required: true,
                InputProps: { readOnly: !isEditing },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              controller={{
                control,
                name: 'description',
                rules: { required: true },
              }}
              field={{
                label: 'Description',
                fullWidth: true,
                required: true,
                rows: 4,
                multiline: true,
                InputProps: { readOnly: !isEditing },
              }}
            />
          </Grid>
        </Section>
      </DialogContent>
      <DialogActions>
        {isEditModal || (
          <Button
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={createMachineTemplateMutation.isPending}
          >
            Create machine template
          </Button>
        )}
        {isEditModal && (
          <ActionControlButtons
            isEditing={isEditing}
            onSave={handleSubmit(onEditSubmit)}
            onEdit={() => setIsEditing(true)}
            disabled={editMachineTemplateMutation.isPending}
          />
        )}
      </DialogActions>
    </>
  )
}

export default CreateMachineTemplateDialogContent
