import { relations } from 'drizzle-orm'
import {
  AnyPgColumn,
  boolean,
  integer,
  pgEnum,
  pgTable,
  serial,
  text,
  timestamp,
  varchar,
} from 'drizzle-orm/pg-core'

import { componentInstances } from './components'
import { companies, companyUnits, users } from './index'
import { machineInstances } from './machines'
import { serviceLevelAgreements } from './service-level-agreements'
import { tasks } from './tasks'
import { toDoListItems } from './to-do-lists'

export const accessLevelEnum = pgEnum('access_level', [
  'public',
  'company',
  'companyUnit',
  'user',
])

export const mediaTypeEnum = pgEnum('media_type', [
  'general',
  'agreement',
  'avatar',
])

export const media = pgTable('media', {
  id: serial('id').primaryKey(),
  name: varchar('name', { length: 256 }),
  description: text('description'),
  dateCreated: timestamp('date_created').defaultNow().notNull(),
  dateModified: timestamp('date_modified').defaultNow().notNull(),
  companyId: integer('company_id').references((): AnyPgColumn => companies.id),
  companyUnitId: integer('company_unit_id').references(
    (): AnyPgColumn => companyUnits.id
  ),
  accessLevel: accessLevelEnum('access_level').default('companyUnit').notNull(),
  mediaType: mediaTypeEnum('media_type').default('general').notNull(),
})

export type Media = typeof media.$inferSelect
export type NewMedia = typeof media.$inferInsert
export type MediaOnToDoListItem = typeof mediaOnToDoListItem.$inferSelect

export const mediaVersions = pgTable('media__versions', {
  id: serial('id').primaryKey(),
  originalFilename: varchar('original_filename', { length: 256 })
    .default('DEFAULT')
    .notNull(),
  key: varchar('key', { length: 256 }).notNull(),
  mimeType: varchar('mime_type', { length: 256 }).default('DEFAULT').notNull(),
  dateCreated: timestamp('date_created').defaultNow().notNull(),
  dateModified: timestamp('date_modified').defaultNow().notNull(),
  fileSize: integer('file_size').default(0).notNull(),
  uploadedBy: integer('uploaded_by').references((): AnyPgColumn => users.id),
  isCurrent: boolean('is_current').default(false).notNull(),
  mediaId: integer('media_id').references((): AnyPgColumn => media.id, {
    onDelete: 'cascade',
  }),
})

export type MediaVersion = typeof mediaVersions.$inferSelect
export type NewMediaVersion = typeof mediaVersions.$inferInsert

export const mediaOnServiceLevelAgreement = pgTable(
  'media_service_level_agreement',
  {
    id: serial('id').primaryKey(),
    serviceLevelAgreementId: integer('service_level_agreement_id')
      .references(() => serviceLevelAgreements.id, { onDelete: 'cascade' })
      .notNull(),
    mediaId: integer('media_id')
      .references(() => media.id, { onDelete: 'cascade' })
      .notNull(),
  }
)

export const mediaRelations = relations(media, ({ one }) => ({}))

export const mediaOnServiceLevelAgreementRelations = relations(
  mediaOnServiceLevelAgreement,
  ({ one }) => ({
    media: one(media, {
      fields: [mediaOnServiceLevelAgreement.mediaId],
      references: [media.id],
    }),
    serviceLevelAgreements: one(serviceLevelAgreements, {
      fields: [mediaOnServiceLevelAgreement.serviceLevelAgreementId],
      references: [serviceLevelAgreements.id],
    }),
  })
)

export const mediaOnToDoListItem = pgTable('media_to_do_list_item', {
  id: serial('id').primaryKey(),
  toDoListItemId: integer('to_do_list_item_id')
    .references(() => toDoListItems.id, { onDelete: 'cascade' })
    .notNull(),
  mediaId: integer('media_id')
    .references(() => media.id, { onDelete: 'cascade' })
    .notNull(),
})

export const mediaOnToDoListItemRelations = relations(
  mediaOnToDoListItem,
  ({ one }) => ({
    media: one(media, {
      fields: [mediaOnToDoListItem.mediaId],
      references: [media.id],
    }),
    toDoListItem: one(toDoListItems, {
      fields: [mediaOnToDoListItem.toDoListItemId],
      references: [toDoListItems.id],
    }),
  })
)

export const mediaOnComponentInstance = pgTable('media_component_instance', {
  id: serial('id').primaryKey(),
  componentInstanceId: integer('component_instance_id')
    .references(() => componentInstances.id, { onDelete: 'cascade' })
    .notNull(),
  mediaId: integer('media_id')
    .references(() => media.id, { onDelete: 'cascade' })
    .notNull(),
})

export const mediaOnComponentInstanceRelations = relations(
  mediaOnComponentInstance,
  ({ one }) => ({
    media: one(media, {
      fields: [mediaOnComponentInstance.mediaId],
      references: [media.id],
    }),
    componentInstance: one(componentInstances, {
      fields: [mediaOnComponentInstance.componentInstanceId],
      references: [componentInstances.id],
    }),
  })
)

export const mediaOnMachineInstance = pgTable('media_machine_instance', {
  id: serial('id').primaryKey(),
  machineInstanceId: integer('machine_instance_id')
    .references(() => machineInstances.id, { onDelete: 'cascade' })
    .notNull(),
  mediaId: integer('media_id')
    .references(() => media.id, { onDelete: 'cascade' })
    .notNull(),
})

export const mediaOnMachineInstanceRelations = relations(
  mediaOnMachineInstance,
  ({ one }) => ({
    media: one(media, {
      fields: [mediaOnMachineInstance.mediaId],
      references: [media.id],
    }),
    machineInstance: one(machineInstances, {
      fields: [mediaOnMachineInstance.machineInstanceId],
      references: [machineInstances.id],
    }),
  })
)

export const mediaOnTask = pgTable('media_task', {
  id: serial('id').primaryKey(),
  taskId: integer('task_id')
    .references(() => tasks.id, { onDelete: 'cascade' })
    .notNull(),
  mediaId: integer('media_id')
    .references(() => media.id, { onDelete: 'cascade' })
    .notNull(),
})

export const mediaOnTaskRelations = relations(mediaOnTask, ({ one }) => ({
  media: one(media, {
    fields: [mediaOnTask.mediaId],
    references: [media.id],
  }),
  task: one(tasks, {
    fields: [mediaOnTask.taskId],
    references: [tasks.id],
  }),
}))
