import { Clear, KeyboardArrowDown } from '@mui/icons-material'
import { alpha, Button, IconButton as MuiIconButton, styled } from '@mui/material'

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  marginBottom: 2.5,
  marginLeft: 2,
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.3),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
  },
}))

export const IconArrow = styled(KeyboardArrowDown)(({ theme }) => ({
  fill: theme.palette.secondary.main,
}))

export const IconClear = styled(Clear)(({ theme }) => ({
  fill: theme.palette.secondary.main,
}))
IconClear.defaultProps = {
  fontSize: 'small',
}


export const ListBoxButton = styled(Button)(() => ({
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  padding: '8px 16px',
}))