import { useEffect, useMemo, useState } from 'react'

import { useDialogStore } from '../stores/useDialogStore'
import { DialogStatus, IDialog } from '../stores/useDialogStore/types'

const useDialog = () => {
  const { activeDialogs, previousDialogs } = useDialogStore()
  const [currentOpenDialog, setCurrentOpenDialog] = useState<IDialog>()

  useEffect(() => {
    const openDialog = Object.values(activeDialogs).find(
      (dialog) => dialog.status === DialogStatus.OPEN
    )
    setCurrentOpenDialog(openDialog)
  }, [activeDialogs])

  const hasParent = useMemo(() => {
    if (!currentOpenDialog) return false
    return Object.values(previousDialogs).some((dialog) =>
      dialog.previousIds.includes(currentOpenDialog.dialogId)
    )
  }, [currentOpenDialog, previousDialogs])

  return { currentOpenDialog, hasParent }
}

export default useDialog
