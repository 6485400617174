import { TaskStatus } from '@aeqoom/db'
import { PersonOutline } from '@mui/icons-material'
import { Chip, ChipProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

const TASK_STATUS_MAP: Record<TaskStatus, ChipProps['color']> = {
  [TaskStatus.ACKNOWLEDGED]: 'success',
  [TaskStatus.ARCHIVED]: 'secondary',
  [TaskStatus.BLOCKED]: 'error',
  [TaskStatus.DONE]: 'primary',
  [TaskStatus.IN_PROGRESS]: 'info',
  [TaskStatus.NEW]: 'info',
  [TaskStatus.PLANNED]: 'info',
  [TaskStatus.RECURRED]: 'info',
  [TaskStatus.TO_BE_CHECKED_BY_ADMIN]: 'warning',
  [TaskStatus.TO_BE_CHECKED_BY_CLIENT]: 'warning',
  [TaskStatus.WAITING_ON_WP]: 'warning',
}

type Props = Omit<ChipProps, 'label'> & {
  label: string
}

const isTaskStatus = (item: string): item is TaskStatus =>
  Object.values<string>(TaskStatus).includes(item)

export const StatusChip = ({ label, ...props }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'taskStatus' })

  if (!isTaskStatus(label)) return undefined

  return (
    <Chip
      icon={<PersonOutline />}
      variant="outlined"
      color={TASK_STATUS_MAP[label]}
      sx={{ borderRadius: 1 }}
      {...props}
      label={t(label)}
    />
  )
}
