import { useEffect, useRef } from 'react'

export const useDidUpdateEffect = (fn: () => void, inputs: unknown[]) => {
  const isMountingRef = useRef(false)

  useEffect(() => {
    isMountingRef.current = true
  }, [])

  useEffect(() => {
    if (!isMountingRef.current) {
      return fn()
    } else {
      isMountingRef.current = false
    }
  }, inputs)
}
