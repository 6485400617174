import {
  AddComponentInstanceRequest,
  AddMachineInstanceRequest,
} from '@aeqoom/contracts'
import CreateComponentInstanceDialogContent from '@app/components/base/dialogs/createForms/CreateComponentInstanceDialogContent'
import FormAutoComplete from '@app/components/base/form/FormAutoComplete'
import CustomAccordion from '@app/components/CustomAccordion'
import { useGetComponentInstances } from '@app/queries/useComponentInstances'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { Errors } from '@app/src/types'
import { Stack } from '@mui/material'
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormTrigger,
  useWatch,
} from 'react-hook-form'

type Props = {
  remove: (index: number) => void
  index: number
  readOnly?: boolean
  control: Control<AddMachineInstanceRequest['body']>
  trigger: UseFormTrigger<AddMachineInstanceRequest['body']>
  setValue: UseFormSetValue<AddMachineInstanceRequest['body']>
  errors: FieldErrors<AddMachineInstanceRequest['body']>
}

const ComponentItem = ({
  remove,
  index,
  readOnly,
  control,
  setValue,
  trigger,
  errors,
}: Props) => {
  const { openDialog, closeTab } = useDialogStore()

  const { companyId, companyUnitId, id } = useWatch({
    control,
  })

  const getComponentInstancesQuery = useGetComponentInstances({
    query: { companyId, companyUnitId },
  })

  const componentInstanceId = useWatch({
    control,
    name: `componentInstances.${index}.id`,
  })

  const hasErrors = Array.isArray(errors.componentInstances)
    ? errors.componentInstances[index]
    : false

  const componentObject = componentInstanceId
    ? getComponentInstancesQuery.data?.find((option) => {
        return componentInstanceId === option.id
      }) ?? null
    : null

  const addComponentInstanceDialog = () => {
    const readOnlyFields: (keyof AddComponentInstanceRequest['body'])[] = [
      'companyId',
      'companyUnitId',
    ]

    if (id) readOnlyFields.push('machineInstanceId')

    openDialog(
      <CreateComponentInstanceDialogContent
        formData={{
          companyId,
          companyUnitId,
          machineInstanceId: id,
        }}
        readOnlyFields={readOnlyFields}
        callback={(id) => {
          setValue(`componentInstances.${index}.id`, id)
          trigger(`componentInstances.${index}.id`)
          closeTab()
        }}
      />,
      'Create component instance'
    )
  }

  return (
    <CustomAccordion
      title={componentObject?.name ?? `Component ${index + 1}`}
      remove={() => remove(index)}
      readonly={readOnly}
      error={hasErrors}
    >
      <Stack spacing={2}>
        <FormAutoComplete
          autocomplete={{
            getOptionLabel: (option) => {
              return option.name
            },
            options: getComponentInstancesQuery.data ?? [],
            fullWidth: true,
            readOnly,
          }}
          controller={{
            control,
            name: `componentInstances.${index}.id`,
            rules: { required: Errors.REQUIRED },
          }}
          field={{ label: 'Component', required: true }}
          add={{
            tooltip: 'Create new component instance',
            onClick: addComponentInstanceDialog,
          }}
        />
      </Stack>
    </CustomAccordion>
  )
}

export default ComponentItem
