import { FormAutoComplete, FormNumberField } from '@aeqoom/components-web'
import { AddComponentInstanceRequest, AddSLARequest } from '@aeqoom/contracts'
import CreateComponentInstanceDialogContent from '@app/components/base/dialogs/createForms/CreateComponentInstanceDialogContent'
import { useGetComponentInstances } from '@app/queries/useComponentInstances'
import { useGetTaskTemplates } from '@app/queries/useTaskTemplates'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { Clear, DeleteForever } from '@mui/icons-material'
import {
  Checkbox,
  IconButton,
  InputAdornment,
  Stack,
  useTheme,
} from '@mui/material'
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import FormButtonGroup from '../base/form/FormButtonGroup'

type Path = `machineInstances.${number}.componentInstances`

type Props = {
  isLevelZeroSet: boolean
  machineId: number
  setIsChecked: () => void
  isChecked: boolean
  parent: {
    fields: FieldArrayWithId<AddSLARequest['body'], Path, 'id'>[]
    append: UseFieldArrayAppend<AddSLARequest['body'], Path>
    remove: UseFieldArrayRemove
    index: number
    path: `${Path}.${number}`
  }
}

const ComponentInstanceIterator = ({
  isLevelZeroSet,
  machineId,
  parent,
  isChecked,
  setIsChecked,
}: Props) => {
  const { control, resetField, setValue, trigger } =
    useFormContext<AddSLARequest['body']>()

  const [componentInstanceId, companyId, companyUnitId] = useWatch({
    control,
    name: [`${parent.path}.componentInstanceId`, 'companyId', 'companyUnitId'],
  })

  const theme = useTheme()

  const getComponentInstancesQuery = useGetComponentInstances({
    query: {
      machineInstanceId: machineId,
    },
  })

  const getTaskTemplatesQuery = useGetTaskTemplates()

  const { openDialog, closeTab } = useDialogStore()

  const { t } = useTranslation('web', {
    keyPrefix: 'contracts.createContract.form',
  })

  const handleOnClick = ({ isEdit }: { isEdit: boolean }) => {
    const componentName =
      getComponentInstancesQuery.data?.find(
        (option) => option.id === componentInstanceId
      )?.name || 'Component'

    const readOnlyFields: (keyof AddComponentInstanceRequest['body'])[] = [
      'companyId',
      'machineInstanceId',
    ]

    if (companyUnitId) {
      readOnlyFields.push('companyUnitId')
    }

    if (isEdit) {
      openDialog(
        <CreateComponentInstanceDialogContent
          id={componentInstanceId}
          formData={{
            companyId,
            companyUnitId: companyUnitId ?? undefined,
            machineInstanceId: machineId,
          }}
          readOnlyFields={readOnlyFields}
          callback={(id: number) => {
            setValue(`${parent.path}.componentInstanceId`, id)
            trigger(`${parent.path}.componentInstanceId`)
            closeTab()
          }}
          isEditModal
        />,
        t('component.edit', { componentName })
      )
    } else {
      openDialog(
        <CreateComponentInstanceDialogContent
          formData={{
            companyId,
            companyUnitId: companyUnitId ?? undefined,
            machineInstanceId: machineId,
          }}
          readOnlyFields={readOnlyFields}
          callback={(id: number) => {
            setValue(`${parent.path}.componentInstanceId`, id)
            trigger(`${parent.path}.componentInstanceId`)
            closeTab()
          }}
        />,
        t('component.createNew')
      )
    }
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      mb={4}
      bgcolor={theme.palette.white.main}
      p={2}
      borderRadius={1}
    >
      <Stack paddingTop={1}>
        <Checkbox checked={isChecked} onChange={setIsChecked} />
      </Stack>
      <FormAutoComplete
        control={control}
        name={`${parent.path}.componentInstanceId`}
        autocomplete={{
          getOptionLabel: (option) => {
            return option.name
          },
          options: getComponentInstancesQuery.data,
          fullWidth: true,
        }}
        fieldProps={{ label: 'Component' }}
        add={{
          onClick: () => handleOnClick({ isEdit: false }),
        }}
        actions={[
          <FormAutoComplete.ActionButton
            key="edit"
            onClick={() => handleOnClick({ isEdit: true })}
          />,
        ]}
      />
      <FormAutoComplete
        control={control}
        name={`${parent.path}.taskTemplateId`}
        autocomplete={{
          getOptionLabel: (option) => {
            return option.name
          },
          options: getTaskTemplatesQuery.data,
          fullWidth: true,
          disabled: isLevelZeroSet,
        }}
        fieldProps={{ label: 'Task template' }}
      />
      <FormNumberField
        control={control}
        name={`${parent.path}.frequency`}
        disabled={isLevelZeroSet}
        label="Frequency"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => resetField(`${parent.path}.frequency`)}
                edge="end"
              >
                <Clear />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormButtonGroup
        controller={{
          disabled: isLevelZeroSet,
          control,
          name: `${parent.path}.type`,
        }}
        field={{
          disabled: isLevelZeroSet,
          fullWidth: true,
        }}
        options={[
          { label: 'Yearly', value: 'yearly' },
          { label: 'Monthly', value: 'monthly' },
          { label: 'Weekly', value: 'weekly' },
        ]}
      />
      <Stack direction="row" alignItems="flex-start">
        <IconButton
          aria-label="remove component"
          onClick={() => parent.remove(parent.index)}
          size="large"
        >
          <DeleteForever />
        </IconButton>
      </Stack>
    </Stack>
  )
}

export default ComponentInstanceIterator
