import { PlanTaskForm } from '@aeqoom/forms'
import { Checkbox, FormControl, FormControlLabel } from '@mui/material'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const AllDay = () => {
  const { control, setValue, watch } = useFormContext<PlanTaskForm>()

  const { field } = useController({
    name: 'allDay',
    control,
  })

  const { t } = useTranslation('web', {
    keyPrefix: 'backlog.taskDetail.header.plan',
  })

  const [plannedFrom, plannedTo] = watch(['plannedFrom', 'plannedTo'])

  const resetDateTime = () => {
    setValue('plannedFrom', plannedFrom.startOf('day'))
    setValue('plannedTo', plannedTo.startOf('day'))
  }

  return (
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            checked={!!field.value}
            onChange={() => {
              field.onChange(!field.value)
              resetDateTime()
            }}
          />
        }
        label={t('allDay')}
      />
    </FormControl>
  )
}

export default AllDay
