import { AddSLARequest, serviceLevelAgreementSchema } from '@aeqoom/contracts'
import { isObjectEmpty } from '@aeqoom/utils'
import CustomStepper, {
  CustomStepperActions,
  CustomStepperContent,
} from '@app/components/CustomStepper'
import {
  useCreateSla,
  useEditSla,
  useGetSla,
} from '@app/queries/useServiceLevelAgreement'
import { ISteps } from '@app/src/types'
import { useDialogStore } from '@app/stores/useDialogStore'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import DialogDetailError from '../DialogDetailError'
import defaultValues from './defaultValues'
import FirstStep from './FirstStep'
import SecondStep from './SecondStep'

type Props = {
  id?: AddSLARequest['body']['id']
  isEditModal?: boolean
  readOnly?: boolean
  callback?: (id?: number) => void
}

const CreateSLADialogContent = ({
  readOnly = false,
  isEditModal = false,
  id,
  callback,
}: Props) => {
  const [isEditing, setIsEditing] = useState(!readOnly)

  const { closeDialog, setShouldAskBeforeClose } = useDialogStore()

  const methods = useForm<AddSLARequest['body']>({
    defaultValues,
    resolver: zodResolver(serviceLevelAgreementSchema),
  })
  const {
    handleSubmit,
    trigger,
    reset,
    formState: { dirtyFields },
  } = methods

  const getSlaQuery = useGetSla({ id })
  const createSlaMutation = useCreateSla()
  const editSlaMutation = useEditSla()

  useEffect(() => {
    if (!getSlaQuery.isPending || !getSlaQuery.isError) {
      reset(getSlaQuery.data)
    }
  }, [getSlaQuery.data, reset])

  useEffect(() => {
    setShouldAskBeforeClose(isObjectEmpty(dirtyFields))
  }, [isObjectEmpty(dirtyFields)])

  const onSubmit = async (data: AddSLARequest['body']) => {
    createSlaMutation.mutate(data, {
      onSuccess: () => {
        callback ? callback(data.id) : closeDialog()
      },
    })
  }

  const onEditSubmit = async (data: AddSLARequest['body']) => {
    editSlaMutation.mutate(
      { body: data, params: { id: data.id! } },
      {
        onSuccess: () => {
          closeDialog()
          callback ? callback(data.id) : closeDialog()
        },
      }
    )
  }

  const tabs: ISteps<AddSLARequest['body']>[] = [
    {
      label: 'Basic information',
      fields: [
        'name',
        'companyId',
        'companyUnitId',
        'dateFrom',
        'dateTo',
        'description',
        'media',
        'isIndefinitely',
      ],
      Component: <FirstStep isEditing={isEditing} />,
    },
    {
      label: 'Machines declaration',
      Component: <SecondStep />,
    },
  ]

  if (getSlaQuery.isPending && readOnly)
    return (
      <Stack alignItems="center">
        <CircularProgress size={80} />
      </Stack>
    )

  if (getSlaQuery.isError)
    return <DialogDetailError onRetry={() => getSlaQuery.refetch()} />

  return (
    <CustomStepper<AddSLARequest['body']>
      steps={tabs}
      onStepChange={trigger}
      handleFinish={handleSubmit(isEditModal ? onEditSubmit : onSubmit)}
    >
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormProvider {...methods}>
            <CustomStepperContent />
          </FormProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        {isEditing && <CustomStepperActions />}
        {readOnly && isEditing && (
          <Button onClick={() => setIsEditing(true)} variant="contained">
            Edit
          </Button>
        )}
      </DialogActions>
    </CustomStepper>
  )
}

export default CreateSLADialogContent
