import { PERMISSIONS } from '@aeqoom/permissions'

const paths = {
  index: '/',
  signIn: '/sign-in',
  admin: {
    index: '/admin',
    profile: '/admin/profile',
    dashboard: '/admin/dashboard',
    backlog: '/admin/backlog',
    sla: '/admin/sla',
    maintenance: '/admin/maintenance',
    support: '/admin/support',
    users: '/admin/users',
    taskTemplates: '/admin/task-templates',
    tasks: '/admin/tasks',
    todolists: '/admin/todolists',
    todolistItems: '/admin/todolist-items',
    componentInstances: '/admin/component-instances',
    componentTemplates: '/admin/component-templates',
    machineInstances: '/admin/machine-instances',
    machineTemplates: '/admin/machine-templates',
    companyUnits: '/admin/company-units',
    companies: '/admin/companies',
    media: '/admin/media',
    contacts: '/admin/contacts',
    calendar: '/admin/calendar',
  },
}

export const PATHS_PERMISSIONS_MAP = {
  [paths.index]: [PERMISSIONS.ALL.ANY],
  [paths.signIn]: [PERMISSIONS.ALL.ANY],
  [paths.admin.index]: [PERMISSIONS.ALL.ANY],
  [paths.admin.profile]: [PERMISSIONS.USER.READ],
  [paths.admin.dashboard]: [
    PERMISSIONS.DASHBOARD.LIST,
    PERMISSIONS.COMPANY.LIST,
  ],
  [paths.admin.backlog]: [PERMISSIONS.TASK.LIST],
  [paths.admin.sla]: [PERMISSIONS.SERVICE_LEVEL_AGREEMENT.LIST],
  [paths.admin.maintenance]: [PERMISSIONS.ALL.ANY],
  [paths.admin.support]: [],
  [paths.admin.users]: [PERMISSIONS.USER.LIST],
  [paths.admin.taskTemplates]: [PERMISSIONS.TASK_TEMPLATE.LIST],
  [paths.admin.tasks]: [PERMISSIONS.TASK.LIST],
  [paths.admin.todolists]: [PERMISSIONS.TO_DO_LIST.LIST],
  [paths.admin.todolistItems]: [PERMISSIONS.TO_DO_LIST_ITEM.LIST],
  [paths.admin.componentInstances]: [PERMISSIONS.COMPONENT_INSTANCE.LIST],
  [paths.admin.componentTemplates]: [PERMISSIONS.COMPONENT_TEMPLATE.LIST],
  [paths.admin.machineInstances]: [PERMISSIONS.MACHINE_INSTANCE.LIST],
  [paths.admin.machineTemplates]: [PERMISSIONS.MACHINE_TEMPLATE.LIST],
  [paths.admin.companyUnits]: [PERMISSIONS.COMPANY_UNIT.LIST],
  [paths.admin.companies]: [PERMISSIONS.COMPANY.LIST],
  [paths.admin.media]: [PERMISSIONS.MEDIA.LIST],
  [paths.admin.calendar]: [PERMISSIONS.TASK.LIST],
  [paths.admin.contacts]: [PERMISSIONS.CONTACT.LIST],
}

export default paths
