import { styled } from '@mui/material/styles'

export const Incident = styled('div')(({ theme }) => ({
  padding: '5px 10px',
  marginBottom: 5,
  borderRadius: '12px',
  backgroundColor: theme.palette.warning.light,
}))

export const CardBox = styled('div')(({ theme }) => ({
  padding: 13,
  borderRadius: '12px',
  backgroundColor: theme.palette.error.main,
  color: theme.palette.text.secondary,
  height: '100%',
}))

export const NewIncidents = styled('span')(({ theme }) => ({
  fontSize: 64,
  fontWeight: 700,
  lineHeight: 1,
  color: theme.palette.red.main,
}))
