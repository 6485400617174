import { useContext } from 'react'

import EditingContext from '../contexts/EditingContext'

const useEditingContext = () => {
  const context = useContext(EditingContext)

  if (!context) {
    throw new Error('useEditingContext must be used within a EditingContext')
  }

  return context
}

export default useEditingContext
