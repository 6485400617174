import { createUniqueKey, trimStringWithExtension } from '@aeqoom/utils'
import { useGetOneMedia } from '@app/queries/useMedia'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { Delete, Download } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import MediaDetailDialog from '../base/dialogs/_detailViewDialogs/MediaDetailDialog'
import MediaCard from '../MediaCard'

type Props = {
  item: number
  onRemove: (id: number) => void
  isEditing: boolean
}

const MediaPreviewCard = ({ item, isEditing, onRemove }: Props) => {
  const { openDialog } = useDialogStore()

  const { t } = useTranslation('web')

  const { data, isPending, isError } = useGetOneMedia({
    id: item,
  })

  if (isPending) return <Skeleton variant="rounded" height={184} />

  if (isError)
    return (
      <Alert
        color="error"
        variant="outlined"
        action={
          <IconButton
            color="inherit"
            size="small"
            onClick={() => onRemove(item)}
          >
            <Delete />
          </IconButton>
        }
      >
        <AlertTitle>{t('media.failedToLoad')}</AlertTitle>
        {t('media.removeMedia')}
      </Alert>
    )

  return (
    <MediaCard
      accessLevel={data.accessLevel}
      mimeType={data.mimeType}
      fullUrl={data.signedUrl || undefined}
      onClick={() =>
        openDialog(
          <MediaDetailDialog activeMedia={item} />,
          data.name || trimStringWithExtension(data.originalFilename),
          {
            uniqueKey: createUniqueKey(['mediaDialog', data.id])
          }
        )
      }
      cardContent={
        <Stack sx={{ minWidth: 0, width: '100%' }}>
          <Typography variant="body1" noWrap>
            {data.name || data.originalFilename}
          </Typography>
        </Stack>
      }
      cardActions={
        <>
          <IconButton
            href={data.signedUrl || undefined}
            component="a"
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            <Download />
          </IconButton>
          {isEditing && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                onRemove(item)
              }}
            >
              <Delete />
            </IconButton>
          )}
        </>
      }
    />
  )
}

export default MediaPreviewCard
