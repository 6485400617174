import { tasks, users } from '@aeqoom/db'
import { createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

const taskSchema = createSelectSchema(tasks).pick({
  id: true,
  name: true,
  finishedAt: true,
  type: true,
  userId: true,
  deadline: true,
  plannedFrom: true,
  plannedTo: true,
})

const userSchema = createSelectSchema(users).pick({
  id: true,
  firstName: true,
  lastName: true,
  group: true,
  avatar: true,
})

export const serviceLogType = z.enum(['history', 'planned'])

export const serviceLogSchema = z.object({
  type: serviceLogType,
  tasks: z.array(
    taskSchema.merge(
      z.object({
        user: userSchema.nullable(),
        componentInstance: z
          .object({
            name: z.string(),
          })
          .nullable(),
      })
    )
  ),
})
