import { taskTemplates, toDoLists } from '@aeqoom/db'
import { z } from '@aeqoom/zod'
import { ClientInferRequest, initContract } from '@ts-rest/core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

const c = initContract()

const taskTemplatesRouter = c.router({
  getTaskTemplates: {
    method: 'GET',
    path: '/task-templates',
    responses: {
      200: z.array(createSelectSchema(taskTemplates).merge(z.object({
        toDoList: createSelectSchema(toDoLists).nullable(),
      }))),
    },
  },
  getTaskTemplate: {
    method: 'GET',
    path: '/task-templates/:id/get',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: createSelectSchema(taskTemplates),
      404: z.object({ message: z.string() }),
    },
  },
  addTaskTemplate: {
    method: 'POST',
    path: '/task-templates',
    body: createInsertSchema(taskTemplates),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  deleteTaskTemplate: {
    method: 'DELETE',
    path: '/task-templates/:id/delete',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({}),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  editTaskTemplate: {
    method: 'POST',
    path: '/task-templates/:id/edit',
    pathParams: z.object({ id: z.coerce.number() }),
    body: z.object({
      ...createInsertSchema(taskTemplates).shape,
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
})

export type AddTaskTemplateRequest = ClientInferRequest<
  typeof taskTemplatesRouter.addTaskTemplate
>

export type EditTaskTemplateRequest = ClientInferRequest<
  typeof taskTemplatesRouter.editTaskTemplate
>

export type DeleteTaskTemplateRequest = ClientInferRequest<
  typeof taskTemplatesRouter.deleteTaskTemplate
>

export default taskTemplatesRouter
