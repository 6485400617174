import { GetTaskResponseBody } from '@aeqoom/contracts'
import { PERMISSIONS } from '@aeqoom/permissions'
import useUserStore from '@app/stores/useUserStore'
import { Stack } from '@mui/material'

import Plan from './_components/Plan'
import WorkPanel from './_components/WorkPanel'

type Props = {
  task: GetTaskResponseBody
}

const ActionPanel = ({ task }: Props) => {
  const { hasPermission } = useUserStore()

  return (
    <Stack flex="1 0 100%" gap={4}>
      {hasPermission(PERMISSIONS.TASK.ASSIGN_USER) && <Plan task={task} />}
      <WorkPanel task={task} />
    </Stack>
  )
}

export default ActionPanel
