const Maintenance = () => {
  return (
    <div>
      <h1>Maintenance</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti, ad
        quos harum assumenda nisi molestias numquam similique doloribus
        eligendi. Accusantium totam aspernatur vero id repudiandae, consequatur
        aperiam debitis doloremque. Saepe.
      </p>
    </div>
  )
}

export default Maintenance
