import { Add } from '@mui/icons-material'
import React from 'react'

import { ListBoxButton } from './styled'

export const getListboxComponent = (add?: {
  onClick: () => void
  label: string
}) =>
  React.forwardRef<HTMLUListElement, { children: React.ReactNode }>(
    (props, ref) => {
      const { children, ...other } = props

      return (
        <>
          <ul {...other} ref={ref} style={{ padding: 0 }}>
            {children}
          </ul>
          {add && (
            <ListBoxButton
              onMouseDown={add.onClick}
              fullWidth
              size="small"
              variant="text"
            >
              <Add />
              {add.label}
            </ListBoxButton>
          )}
        </>
      )
    }
  ) as React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>>
