import Menu, { MenuProps } from '@mui/material/Menu'
import {
  Children,
  cloneElement,
  MouseEvent,
  ReactElement,
  useState,
} from 'react'

type Props = Omit<MenuProps, 'open'> & {
  button: ReactElement
  children: ReactElement | ReactElement[]
}

const CustomMenu = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }
  const handleClose = (e: MouseEvent) => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const clonedButton = cloneElement(props.button, {
    onClick: handleClick,
    onMouseDown: (e: MouseEvent) => e.stopPropagation(),
  })

  const clonedMenu = Children.map(props.children, (child) =>
    cloneElement(child, {
      onClick: (e: MouseEvent) => {
        e.stopPropagation()
        child.props.onClick()
        handleClose(e)
      },
    })
  )

  return (
    <>
      {clonedButton}
      <Menu {...props} onClose={handleClose} anchorEl={anchorEl} open={open}>
        {clonedMenu}
      </Menu>
    </>
  )
}

export default CustomMenu
