import { serviceLogSchema } from '@aeqoom/contracts'
import FetchableAvatar from '@app/components/FetchableAvatar'
import { Box, Tooltip } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

export const useGridDefinitions = () => {
  const { t } = useTranslation(['common', 'web'])

  const cols: GridColDef<z.infer<typeof serviceLogSchema>['tasks'][number]>[] =
    [
      {
        field: 'id',
        headerName: t('web:task.id'),
        width: 90,
      },
      {
        field: 'name',
        headerName: t('web:task.name'),
        flex: 1,
      },
      {
        headerName: t('web:task.date'),
        field: 'date',
        type: 'datetime',
        width: 250,
        valueGetter: ({ row }) =>
          dayjs
            .utc(row.finishedAt ?? row.plannedTo ?? row.deadline)
            .local()
            .format('LLL'),
      },
      {
        field: 'type',
        headerName: t('web:task.type'),
        width: 150,
        valueGetter: ({ row }) => t(`common:taskType.${row.type}`),
      },
      {
        field: 'componentInstance',
        headerName: t('web:task.componentInstance'),
        width: 150,
        valueGetter: ({ row }) => row.componentInstance?.name,
      },
      {
        field: 'user',
        headerName: t('web:task.worker'),
        width: 200,
        renderCell: ({ row }) => {
          const user = row.user

          if (user) {
            const fullName = `${user.firstName} ${user.lastName}`.trim()

            if (user.avatar) {
              return (
                <Tooltip title={fullName}>
                  <Box>
                    <FetchableAvatar id={user.avatar} width={24} height={24} />
                  </Box>
                </Tooltip>
              )
            }

            return fullName
          }
        },
      },
    ]

  const withShared: typeof cols = cols.map((col) => ({
    sortable: false,
    filterable: false,
    ...col,
  }))

  return withShared
}
