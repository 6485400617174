import { SERVICE_LOG_KEYS } from '@aeqoom/types'
import {
  useQuery,
} from '@tanstack/react-query'

import { client } from './client'
import parseIncoming from './parseIncoming'

export const useGetServiceLogForMachineInstance = ({
  machineInstanceId,
  type,
}: {
  type: 'history' | 'planned',
  machineInstanceId: number;
}) => {
  return useQuery({
    queryKey: [SERVICE_LOG_KEYS.getForMachineInstance, machineInstanceId, type],
    queryFn: () => client.serviceLog.getServiceLog({
      params: {
        machineInstanceId,
      },
      query: {
        type,
      }
    }).then(parseIncoming),
  })
}
