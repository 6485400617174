import {
  FormAutoComplete,
  FormNumberField,
  FormSwitchField,
} from '@aeqoom/components-web'
import { AddMachineInstanceRequest, AddSLARequest } from '@aeqoom/contracts'
import CreateMachineInstanceDialogContent from '@app/components/base/dialogs/createForms/CreateMachineInstanceDialogContent'
import CreateTaskTemplateDialogContent from '@app/components/base/dialogs/createForms/CreateTaskTemplateDialogContent'
import FormButtonGroup from '@app/components/base/form/FormButtonGroup'
import {
  useGetMachineInstance,
  useGetMachineInstances,
} from '@app/queries/useMachineInstances'
import { useGetTaskTemplates } from '@app/queries/useTaskTemplates'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { Clear } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import React, { useEffect, useState } from 'react'
import { UseFieldArrayAppend, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  append: UseFieldArrayAppend<
    AddSLARequest['body'],
    `machineInstances.${number}.componentInstances`
  >
  path: `machineInstances.${number}`
}

const MachineForm = ({ path, append }: Props) => {
  const [isChipDisabled, setIsChipDisabled] = useState(false)

  const { t } = useTranslation('web', {
    keyPrefix: 'contracts.createContract.form',
  })

  const { control, trigger, setValue, watch } =
    useFormContext<AddSLARequest['body']>()

  const [
    watchCompanyId,
    watchCompanyUnitId,
    machineId,
    taskTemplateId,
    onCall,
    components,
    fields,
  ] = useWatch({
    control,
    name: [
      'companyId',
      'companyUnitId',
      `${path}.machineInstanceId`,
      `${path}.taskTemplateId`,
      `${path}.onCall`,
      `${path}.componentInstances`,
      `${path}.componentInstances`,
    ],
  })

  const getTaskTemplatesQuery = useGetTaskTemplates()

  const [companyId, companyUnitId] = watch(['companyId', 'companyUnitId'])

  const getMachineInstancesQuery = useGetMachineInstances({
    query: { companyId, companyUnitId },
  })

  const getMachineInstanceQuery = useGetMachineInstance({
    id: machineId,
    enabled: !!machineId,
  })

  useEffect(() => {
    const existingIds = fields?.map((field) => field.componentInstanceId)

    setIsChipDisabled(
      getMachineInstanceQuery.data?.componentInstances?.every((instance) =>
        existingIds.includes(instance.id)
      ) || false
    )
  }, [fields, getMachineInstanceQuery.data?.componentInstances])

  const { openDialog, closeTab } = useDialogStore()

  const isLevelOne = components?.some((item) => item?.frequency != null)

  useEffect(() => {
    if (isLevelOne) {
      setValue(`${path}.type`, null)
      setValue(`${path}.frequency`, null)
    }
  }, [isLevelOne, path, setValue])

  useEffect(() => {
    if (onCall) {
      setValue(`${path}.type`, null)
      setValue(`${path}.frequency`, null)
    }
  }, [onCall, path, setValue])

  const viableComponentsLength =
    getMachineInstanceQuery.data?.componentInstances?.length

  const handleLoadComponentInstances = () => {
    if (!getMachineInstanceQuery.data?.componentInstances?.length) return

    const existingIds = fields?.map((field) => field.componentInstanceId)

    getMachineInstanceQuery.data.componentInstances.forEach(({ id }) => {
      if (!existingIds.includes(id)) {
        append({
          componentInstanceId: id,
          taskTemplateId: null,
          frequency: null,
          type: null,
        })
        setIsChipDisabled(true)
      }
    })
  }

  const options = getMachineInstancesQuery.data ?? []

  const handleOnClick = ({ isEdit }: { isEdit: boolean }) => {
    const readOnlyFields: (keyof AddMachineInstanceRequest['body'])[] = [
      'companyId',
    ]

    if (watchCompanyUnitId) {
      readOnlyFields.push('companyUnitId')
    }

    const formData = {
      companyId: watchCompanyId,
      companyUnitId: watchCompanyUnitId ?? undefined,
    }

    if (isEdit) {
      openDialog(
        <CreateMachineInstanceDialogContent
          id={machineId}
          callback={(id) => {
            setValue(`${path}.machineInstanceId`, id)
            trigger(`${path}.machineInstanceId`)
            closeTab()
          }}
          isEditModal
          formData={formData}
          readOnlyFields={readOnlyFields}
        />,
        getMachineInstanceQuery.data?.name
          ? `Edit ${getMachineInstanceQuery.data.name}`
          : 'Edit machine instance'
      )
    } else {
      openDialog(
        <CreateMachineInstanceDialogContent
          callback={(id) => {
            setValue(`${path}.machineInstanceId`, id)
            trigger(`${path}.machineInstanceId`)
            closeTab()
          }}
          formData={formData}
          readOnlyFields={readOnlyFields}
        />,
        t('machine.createNew')
      )
    }
  }

  const handleEditTaskTemplate = () => {
    if (!taskTemplateId) return

    const taskTemplateName = getTaskTemplatesQuery.data?.find(
      ({ id }) => taskTemplateId === id
    )?.name

    openDialog(
      <CreateTaskTemplateDialogContent
        id={taskTemplateId}
        callback={(id) => {
          setValue(`${path}.taskTemplateId`, id)
          trigger(`${path}.taskTemplateId`)
          closeTab()
        }}
        isEditModal
      />,
      taskTemplateName ? `Edit ${taskTemplateName}` : 'Edit machine instance'
    )
  }

  return (
    <Stack spacing={4}>
      <Stack spacing={4}>
        <Stack spacing={0}>
          <Stack direction="row" spacing={2} width="100%">
            <FormAutoComplete
              control={control}
              name={`${path}.machineInstanceId`}
              autocomplete={{
                getOptionLabel: (option) => option.name,
                options,
                fullWidth: true,
              }}
              fieldProps={{
                label: t('machine.label'),
                required: true,
              }}
              add={{
                label: 'Create new machine',
                onClick: () => handleOnClick({ isEdit: false }),
              }}
              actions={[
                <FormAutoComplete.ActionButton
                  key="edit"
                  onClick={() => handleOnClick({ isEdit: true })}
                />,
              ]}
            />
            <FormAutoComplete
              control={control}
              name={`${path}.taskTemplateId`}
              autocomplete={{
                getOptionLabel: (option) => option.name,
                options: getTaskTemplatesQuery.data,
                fullWidth: true,
                readOnly: isLevelOne,
              }}
              fieldProps={{
                label: t('taskTemplate.label'),
                required: !isLevelOne,
              }}
              add={{
                label: t('taskTemplate.createNew'),
                onClick: () =>
                  openDialog(
                    <CreateTaskTemplateDialogContent
                      callback={(id) => {
                        setValue(`${path}.taskTemplateId`, id)
                        trigger(`${path}.taskTemplateId`)
                        closeTab()
                      }}
                    />,
                    'Create task template'
                  ),
              }}
              actions={[
                taskTemplateId ? (
                  <FormAutoComplete.ActionButton
                    key="edit"
                    onClick={handleEditTaskTemplate}
                  />
                ) : undefined,
              ]}
            />
          </Stack>
          {!!viableComponentsLength && (
            <Chip
              label={`Load ${viableComponentsLength} component instances`}
              variant="filled"
              onClick={handleLoadComponentInstances}
              sx={{ width: '20%' }}
              disabled={isChipDisabled}
            />
          )}
        </Stack>
        <Stack direction="row" spacing={4}>
          <Stack direction="row" spacing={2} width="50%">
            <Stack>
              <FormSwitchField
                label="On-call"
                legend="If you toggle on-call, you cannot set frequency"
                controller={{
                  control,
                  name: `${path}.onCall`,
                }}
              />
            </Stack>
          </Stack>
          <Stack spacing={2} direction="row" width="50%">
            <FormNumberField
              control={control}
              name={`${path}.frequency`}
              disabled={isLevelOne || !!onCall}
              label="Frequency"
              required={!isLevelOne}
              helperText="Enter frequency of the task execution."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setValue(`${path}.frequency`, null)}
                      edge="end"
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormButtonGroup
              controller={{
                control,
                name: `${path}.type`,
              }}
              field={{
                disabled: isLevelOne || !!onCall,
              }}
              options={[
                { label: 'Yearly', value: 'yearly' },
                { label: 'Monthly', value: 'monthly' },
                { label: 'Weekly', value: 'weekly' },
              ]}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default MachineForm
