import { useDialogStore } from '@app/stores/useDialogStore'
import { DialogId } from '@app/stores/useDialogStore/types'
import { theme } from '@app/styles/theme'
import CloseIcon from '@mui/icons-material/Close'
import { Button, IconButton, Stack, Typography } from '@mui/material'
import { ElementType, MouseEvent, useRef } from 'react'
import { useHover } from 'usehooks-ts'

type Props = {
  icon?: ElementType
  text?: string
  isActive: boolean
  id?: DialogId
  handleClick?: (id?: DialogId) => void
}
export const BreadcrumbItem = ({
  icon: Icon,
  text = '',
  isActive,
  id,
  handleClick,
}: Props) => {
  const hoverRef = useRef(null)
  const isHovered = useHover(hoverRef)

  const { closeTab } = useDialogStore()

  const onCloseClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    id && closeTab(id)
  }

  return (
    <Stack
      onClick={() => handleClick && handleClick(id)}
      alignItems="center"
      direction="row"
      spacing={1}
    >
      <Button
        ref={hoverRef}
        variant="text"
        startIcon={
          Icon && (
            <Icon
              sx={{
                color: isActive
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main,
              }}
            />
          )
        }
        color="secondary"
      >
        <Typography
          variant={isActive ? 'body3Bold' : 'body3Regular'}
          color={
            isActive ? theme.palette.primary.main : theme.palette.secondary.main
          }
        >
          {text}
        </Typography>
        <IconButton
          onClick={(e) => onCloseClick(e)}
          size="small"
          sx={{
            padding: 0,
            marginLeft: 1,
            width: '8px',
          }}
        >
          {isHovered && (
            <CloseIcon
              sx={{
                fontSize: 16,
              }}
            />
          )}
        </IconButton>
      </Button>
    </Stack>
  )
}
