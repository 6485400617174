import { Company, CompanyUnit, UserGroups } from '@aeqoom/db'
import { Permission, safeHasPermission } from '@aeqoom/permissions'
import { create } from 'zustand'

export type State = {
  user?: {
    id: number | null
    avatar?: string | null
    firstName: string | null
    lastName: string | null
    sub: string | null
    email: string | null
    groups: UserGroups | null
    company: Company | null
    companyUnit: CompanyUnit | null
  }
  activeCompany: number | null
  activeCompanyUnit: number | null
}

type Actions = {
  clearUser: () => void
  setUser: (value: State) => void
  getCurrentActiveCompany: () => number | null
  getCurrentActiveCompanyUnit: () => number | null
  setCurrentActiveCompany: (value: number | null) => void
  setCurrentActiveCompanyUnit: (value: number | null) => void
  hasPermission: (permissions: Permission | Permission[]) => boolean
  getCanChangeCompanyId: () => boolean
  getCanChangeCompanyUnitId: () => boolean
}

const initialState: State = {
  user: {
    id: null,
    firstName: null,
    lastName: null,
    sub: null,
    email: null,
    groups: null,
    company: null,
    companyUnit: null,
  },
  activeCompany: null,
  activeCompanyUnit: null,
}

const useUserStore = create<State & Actions>((set, get) => ({
  ...initialState,
  clearUser: () => set(() => initialState),
  setUser: ({ ...args }) => set(() => ({ ...args })),
  setCurrentActiveCompany: (item) =>
    set(() => ({ activeCompany: item, activeCompanyUnit: null })),
  setCurrentActiveCompanyUnit: (item) =>
    set(() => ({ activeCompanyUnit: item })),
  getCurrentActiveCompany: () => {
    return get().activeCompany
  },
  getCurrentActiveCompanyUnit: () => {
    return get().activeCompanyUnit
  },
  getCanChangeCompanyId: () => {
    const userGroup = get().user?.groups

    return (
      userGroup !== UserGroups.CLIENT_SUPER_ADMIN &&
      userGroup !== UserGroups.CLIENT_UNIT_ADMIN
    )
  },
  getCanChangeCompanyUnitId: () => {
    const userGroup = get().user?.groups

    return userGroup !== UserGroups.CLIENT_UNIT_ADMIN
  },
  hasPermission: (permissions: Permission | Permission[]) => {
    const userGroup = get()?.user?.groups

    if (!userGroup) return false

    if (Array.isArray(permissions))
      return permissions.every((permission) =>
        safeHasPermission(userGroup, permission)
      )

    return safeHasPermission(userGroup, permissions)
  },
}))

export default useUserStore
