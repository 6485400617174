import { ComponentTemplateResponse } from '@aeqoom/contracts'
import DialogContent from '@app/components/base/dialogs/_detailViewDialogs/_components/DialogContent'
import { useTranslation } from 'react-i18next'

import ComponentInfo from './_tabs/MachineInfo'

type Props = {
  data: ComponentTemplateResponse
}

const Content = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'componentTemplate.detail',
  })

  const TABS = [
    {
      component: (data: ComponentTemplateResponse) => (
        <ComponentInfo data={data} />
      ),
      header: t('tabs.componentDetail.label'),
    },
  ]

  return (
    <DialogContent
      data={data}
      title={t('header.title', {
        name: data.name,
        producer: data.producer,
      })}
      tabs={TABS}
    />
  )
}

export default Content
