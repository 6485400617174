import { Flatten, GetDashboardResponseBody } from '@aeqoom/contracts'
import { TaskType } from '@aeqoom/db'
import { useThemeColors } from '@aeqoom/hooks'
import { BuildOutlined } from '@mui/icons-material'
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Card, CardContent, Stack, Typography } from '@mui/material'

import TaskCountByStatus from '../TaskCountByStatus'
import { AmountToPlan } from './styled'

type Props = {
  unit: Flatten<GetDashboardResponseBody>
  type: TaskType
}

const InfoBox = ({
  unit: { numberOfIncidents, numberOfMaintenances },
  type,
}: Props) => {
  const maintenancesToPlan =
    numberOfMaintenances?.new + numberOfMaintenances?.acknowledged

  const maintenancesPlanned =
    numberOfMaintenances?.inProgress +
    numberOfMaintenances?.toBeCheckedByAdmin +
    numberOfMaintenances?.planned +
    numberOfMaintenances?.toBeCheckedByClient +
    numberOfMaintenances?.waitingOnWp

  const allMaintenances =
    maintenancesToPlan +
    maintenancesPlanned +
    numberOfMaintenances?.done +
    numberOfMaintenances?.blocked +
    numberOfMaintenances?.recurred

  const { lightGray, secondary } = useThemeColors()

  return (
    <Card
      sx={{
        backgroundColor: lightGray.main,
      }}
    >
      <CardContent>
        <Stack direction="row" gap={1} sx={{ mb: 2 }}>
          {type === TaskType.INCIDENT ? (
            <WarningAmberOutlinedIcon sx={{ fontSize: 20 }} />
          ) : (
            <BuildOutlinedIcon sx={{ fontSize: 20 }} />
          )}
          <Typography variant="body2Regular" color={secondary.main}>
            {type === TaskType.INCIDENT ? 'Incidents' : 'Maintenance'}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Stack sx={{ alignItems: 'center' }} justifyContent="end">
            <AmountToPlan>
              {type === TaskType.INCIDENT
                ? numberOfIncidents?.new
                : maintenancesToPlan}
            </AmountToPlan>
            <Typography variant="body3Medium">
              {type === TaskType.INCIDENT ? 'New' : 'To plan'}
            </Typography>
          </Stack>
          <Stack sx={{ marginTop: 'auto' }}>
            {type === TaskType.INCIDENT ? (
              <TaskCountByStatus
                count={numberOfIncidents?.inProgress}
                label="In progress"
                icon={<BuildOutlined sx={{ fontSize: 20 }} />}
              />
            ) : (
              <TaskCountByStatus
                count={`${maintenancesPlanned}/${
                  maintenancesPlanned + maintenancesToPlan
                }`}
                label="Planned"
                icon={<NewspaperOutlinedIcon sx={{ fontSize: 20 }} />}
              />
            )}
            <TaskCountByStatus
              count={
                type === TaskType.INCIDENT
                  ? numberOfIncidents.done
                  : `${numberOfMaintenances?.done}/${allMaintenances}`
              }
              label="Done"
              icon={<DoneOutlinedIcon sx={{ fontSize: 20 }} />}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default InfoBox
