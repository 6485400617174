import { toDoListItemOnTask, toDoListItems } from '@aeqoom/db'
import { z } from '@aeqoom/zod'
import { createSelectSchema } from 'drizzle-zod'

export const toDoListItemSchema = z.object({
  ...createSelectSchema(toDoListItemOnTask).shape,
  template: z.object({
    ...createSelectSchema(toDoListItems).shape,
    media: z.array(z.number()),
  }),
})

export type ToDoListItem = z.infer<typeof toDoListItemSchema>
