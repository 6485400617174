import { useGetOneMedia } from '@app/queries/useMedia'
import { Portrait } from '@mui/icons-material'
import { AvatarProps, Skeleton, SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import CustomAvatar from '../CustomAvatar'

type Props = {
  id?: number | null
  width?: number
  height?: number
  avatarProps?: AvatarProps
  placeHolderIcon?: OverridableComponent<SvgIconTypeMap>
  variant?: 'bordered' | 'background'
}

const FetchableAvatar = ({
  id,
  width = 32,
  height = 32,
  avatarProps,
  placeHolderIcon = Portrait,
  variant = 'background',
}: Props) => {
  const { data, isLoading } = useGetOneMedia({ id })

  if (isLoading)
    return (
      <Skeleton
        variant="circular"
        width={width}
        height={height}
        sx={{ flexShrink: 0 }}
      />
    )

  return (
    <CustomAvatar
      src={data?.signedUrl}
      width={width}
      height={height}
      avatarProps={avatarProps}
      placeHolderIcon={placeHolderIcon}
      variant={variant}
    />
  )
}

export default FetchableAvatar
