import {
  Chip,
  FormControl,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  RadioGroupProps,
  Stack,
} from '@mui/material'
import { FormControlLabelProps } from '@mui/material/FormControlLabel'
import { ReactElement } from 'react'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'

type Props<T extends FieldValues> = {
  controller: UseControllerProps<T>
  radioGroup?: RadioGroupProps
  formLabel?: FormLabelProps
  options: {
    icon?: ReactElement
    label: string
    value: string
    disabled?: boolean
    props?: FormControlLabelProps
  }[]
  label: string
  helperText?: string
}

const FormChipListPicker = <T extends FieldValues>({
  controller,
  options,
  label,
  helperText = ' ',
}: Props<T>) => {
  return (
    <Controller
      {...controller}
      render={({
        field: { onChange, value: pickedValue },
        fieldState: { error },
      }) => (
        <FormControl variant="standard" error={!!error}>
          <FormLabel sx={{ mb: 1 }}>{label}</FormLabel>
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            mb={1}
            flexWrap="wrap"
          >
            {options.map(({ icon, label, value, disabled, props }) => {
              const isChecked = pickedValue === value
              return (
                <Chip
                  key={value}
                  icon={icon}
                  onClick={() => onChange(value)}
                  label={label}
                  disabled={controller.disabled || disabled}
                  variant={isChecked ? 'filled' : 'outlined'}
                  color={error ? 'error' : 'primary'}
                />
              )
            })}
          </Stack>
          <FormHelperText>{error?.message || helperText}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default FormChipListPicker
