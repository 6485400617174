export enum ColumnDirection {
  ROW = 'row',
  COLUMN = 'column',
}

export enum ColumnVariant {
  HIGH_PRIORITY = 'high-priority',
  TO_BE_DONE = 'to-be-done',
  PLANNED = 'planned',
  TO_ACCEPT = 'to-accept',
}
