import { relations } from 'drizzle-orm'
import {
  boolean,
  integer,
  pgEnum,
  pgTable,
  primaryKey,
  serial,
  smallint,
  text,
  timestamp,
  uuid,
  varchar,
} from 'drizzle-orm/pg-core'

import {
  companies,
  companyUnits,
  componentInstances,
  machineInstances,
  mediaOnTask,
  serviceLevelAgreements,
  toDoListItems,
  toDoLists,
  users,
  workProtocols,
} from './index'

export const taskType = pgEnum('task_type', [
  'maintenance',
  'incident',
  'general',
  'examination',
  'installation',
  'full_service',
])

export const taskStatus = pgEnum('task_status', [
  'new',
  'recurred',
  'acknowledged',
  'planned',
  'in_progress',
  'waiting_on_wp',
  'blocked',
  'archived',
  'to_be_checked_by_admin',
  'to_be_checked_by_client',
  'done',
])

export const taskPriority = pgEnum('task_priority', ['low', 'medium', 'high'])

export const tasks = pgTable('tasks', {
  id: serial('id').primaryKey(),
  name: varchar('name', { length: 256 }).notNull(),
  userId: integer('user_id').references(() => users.id),
  type: taskType('type').notNull(),
  priority: taskPriority('task_priority'),
  description: text('description'),
  createdAt: timestamp('created_at').defaultNow().notNull(),
  createdBy: integer('created_by').references(() => users.id),
  finishedAt: timestamp('finished_at'),
  serviceLevelAgreementId: integer('service_level_agreement_id').references(
    () => serviceLevelAgreements.id,
    { onDelete: 'cascade' }
  ),
  status: taskStatus('status').default('new').notNull(),
  toDoListId: integer('to_do_list_id').references(() => toDoLists.id, {
    onDelete: 'set null',
  }),
  componentInstanceId: integer('component_instance_id').references(
    () => componentInstances.id,
    { onDelete: 'cascade' }
  ),
  machineInstanceId: integer('machine_instance_id').references(
    () => machineInstances.id,
    { onDelete: 'cascade' }
  ),
  allDay: boolean('all_day').default(false),
  deadline: timestamp('deadline', { mode: 'string' }).notNull(),
  uuid: uuid('uuid').defaultRandom().unique().notNull(),
  companyId: integer('company_id').references(() => companies.id, {
    onDelete: 'cascade',
  }),
  companyUnitId: integer('company_unit_id')
    .references(() => companyUnits.id, {
      onDelete: 'cascade',
    })
    .notNull(),
  plannedFrom: timestamp('planned_from'),
  plannedTo: timestamp('planned_to'),
})

export const tasksRelations = relations(tasks, ({ one, many }) => ({
  user: one(users, {
    fields: [tasks.userId],
    references: [users.id],
  }),
  createdBy: one(users, {
    fields: [tasks.createdBy],
    references: [users.id],
  }),
  workProtocol: one(workProtocols, {
    fields: [tasks.id],
    references: [workProtocols.taskId],
  }),
  toDoList: one(toDoListOnTask, {
    fields: [tasks.id],
    references: [toDoListOnTask.taskId],
  }),
  company: one(companies, {
    fields: [tasks.companyId],
    references: [companies.id],
  }),
  serviceLevelAgreement: one(serviceLevelAgreements, {
    fields: [tasks.serviceLevelAgreementId],
    references: [serviceLevelAgreements.id],
  }),
  componentInstance: one(componentInstances, {
    fields: [tasks.componentInstanceId],
    references: [componentInstances.id],
  }),
  machineInstance: one(machineInstances, {
    fields: [tasks.machineInstanceId],
    references: [machineInstances.id],
  }),
  media: many(mediaOnTask),
}))

export const machineInstanceOnTask = pgTable(
  'machine_instance_tasks',
  {
    machineInstanceId: integer('machine_instance_id').references(
      () => machineInstances.id
    ),
    taskId: integer('task_id').references(() => tasks.id),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.machineInstanceId, t.taskId] }),
  })
)

export const toDoListOnTask = pgTable('to_do_list_tasks', {
  id: serial('id').primaryKey(),
  toDoListId: integer('to_do_list_id')
    .references(() => toDoLists.id)
    .notNull(),
  taskId: integer('task_id')
    .references(() => tasks.id, { onDelete: 'cascade' })
    .notNull(),
})

export const toDoListsOnTaskRelations = relations(
  toDoListOnTask,
  ({ one, many }) => ({
    toDoListItems: many(toDoListItemOnTask),
    template: one(toDoLists, {
      fields: [toDoListOnTask.toDoListId],
      references: [toDoLists.id],
    }),
  })
)

export const toDoListItemOnTask = pgTable('to_do_list_item_tasks', {
  id: serial('id').primaryKey(),
  toDoListOnTaskId: integer('to_do_list_task_id')
    .references(() => toDoListOnTask.id, { onDelete: 'cascade' })
    .notNull(),
  toDoListItemId: integer('to_do_list_list_item_id')
    .references(() => toDoListItems.id)
    .notNull(),
  value: smallint('value'),
})

export const toDoListItemOnTaskRelations = relations(
  toDoListItemOnTask,
  ({ one }) => ({
    toDoList: one(toDoListOnTask, {
      fields: [toDoListItemOnTask.toDoListOnTaskId],
      references: [toDoListOnTask.id],
    }),
    template: one(toDoListItems, {
      fields: [toDoListItemOnTask.toDoListItemId],
      references: [toDoListItems.id],
    }),
  })
)

export const machineInstanceOnTaskRelations = relations(
  machineInstanceOnTask,
  ({ one }) => ({
    machineInstance: one(machineInstances, {
      fields: [machineInstanceOnTask.machineInstanceId],
      references: [machineInstances.id],
    }),
    task: one(tasks, {
      fields: [machineInstanceOnTask.taskId],
      references: [tasks.id],
    }),
  })
)

export const componentInstanceOnTasks = pgTable(
  'component_instance_tasks',
  {
    componentInstanceId: integer('component_instance_id').references(
      () => componentInstances.id
    ),
    taskId: integer('task_id').references(() => tasks.id, {
      onDelete: 'cascade',
    }),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.componentInstanceId, t.taskId] }),
  })
)

export const componentInstanceOnTasksRelations = relations(
  componentInstanceOnTasks,
  ({ one }) => ({
    componentInstance: one(componentInstances, {
      fields: [componentInstanceOnTasks.componentInstanceId],
      references: [componentInstances.id],
    }),
    task: one(tasks, {
      fields: [componentInstanceOnTasks.taskId],
      references: [tasks.id],
    }),
  })
)

export type Task = typeof tasks.$inferSelect
export type TaskTemplate = typeof taskTemplates.$inferSelect

export type NewTask = typeof tasks.$inferInsert
export type NewTaskTemplate = typeof taskTemplates.$inferInsert

export const taskTemplates = pgTable('task_templates', {
  id: serial('id').primaryKey(),
  name: varchar('name', { length: 256 }).notNull(),
  description: text('description'),
  toDoListId: integer('to_do_list_id').references(() => toDoLists.id, {
    onDelete: 'cascade',
  }),
})

export const taskTemplatesRelations = relations(taskTemplates, ({ one }) => ({
  toDoList: one(toDoLists, {
    fields: [taskTemplates.toDoListId],
    references: [toDoLists.id],
  }),
}))
