import { GetTaskResponseBody } from '@aeqoom/contracts'
import ComponentInstance from '@app/components/base/dialogs/WorkProtocolDialogContent/ComponentInstance'
import MachineInstance from '@app/components/base/dialogs/WorkProtocolDialogContent/MachineInstance'
import TextRow from '@app/components/base/dialogs/WorkProtocolDialogContent/TextRow'
import { useGetCompany } from '@app/queries/useCompanies'
import { Card, CardContent, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'

type Props = {
  task: GetTaskResponseBody
}

export const Header = ({ task }: Props) => {
  const { type, machineInstanceId, componentInstanceId, companyId, user } = task

  const theme = useTheme()

  const { data: companyData } = useGetCompany({ id: companyId })

  return (
    <Card
      sx={{
        background: theme.palette.lightGray.main,
      }}
    >
      <CardContent>
        <Typography variant="body1Bold" pb={2}>
          General info
        </Typography>
        <Stack direction="row" gap={2} justifyContent="stretch">
          <Card sx={{ width: '50%' }}>
            <CardContent>
              <Stack gap={1}>
                {user && (
                  <TextRow
                    label="Assigned to"
                    value={`${user.firstName} ${user.lastName}`}
                  />
                )}
                <TextRow
                  label="Date of work"
                  value={dayjs().format('DD. MM. YYYY')}
                />
                <TextRow label="Task type" value={type} />
                {machineInstanceId && (
                  <MachineInstance machineInstanceId={machineInstanceId} />
                )}
                {componentInstanceId && (
                  <ComponentInstance
                    componentInstanceId={componentInstanceId}
                  />
                )}
              </Stack>
            </CardContent>
          </Card>
          <Card sx={{ width: '50%' }}>
            <CardContent>
              <Stack gap={1}>
                <TextRow label="Name" value={companyData?.name} />
                <TextRow label="Street" value={companyData?.street} />
                <TextRow
                  label="House number"
                  value={companyData?.houseNumber}
                />
                <TextRow label="Zip code" value={companyData?.zipCode} />
                <TextRow label="City" value={companyData?.city} />
                <TextRow label="Country" value={companyData?.country} />
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default Header
