import { companies, companyUnits, users } from '@aeqoom/db'
import { permissions } from '@aeqoom/permissions'
import { UserGroups } from '@aeqoom/shared'
import { constructZodLiteralUnionType } from '@aeqoom/shared'
import { z } from '@aeqoom/zod'
import { ClientInferRequest, initContract } from '@ts-rest/core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

import { globalFilter } from '../schemas'

const c = initContract()

const userResponse200 = z.object({
  ...createSelectSchema(users).shape,
  company: createSelectSchema(companies).nullable(),
  companyUnit: createSelectSchema(companyUnits).nullable(),
})

const usersRouter = c.router({
  getMe: {
    method: 'GET',
    path: '/users/me/get',
    responses: {
      200: z.object({
        ...createSelectSchema(users).shape,
        company: createSelectSchema(companies).nullable(),
        companyUnit: createSelectSchema(companyUnits).nullable(),
        permissions: z.array(
          constructZodLiteralUnionType(permissions.map((p) => z.literal(p)))
        ),
      }),
      404: z.object({ message: z.string() }),
    },
  },
  editProfile: {
    method: 'POST',
    path: '/users/me/edit',
    body: createInsertSchema(users).pick({
      avatar: true,
    }),
    responses: {
      200: z.object({
        id: z.number(),
      }),
    },
  },
  getUser: {
    method: 'GET',
    path: '/users/:id/get',
    pathParams: z.object({ id: z.coerce.number() }),
    responses: {
      200: userResponse200,
      404: z.object({ message: z.string() }),
    },
  },
  getUsers: {
    method: 'GET',
    path: '/users',
    query: z.intersection(
      z.object({
        group: z.nativeEnum(UserGroups).optional(),
      }),
      globalFilter
    ),
    responses: {
      200: z.array(
        z.object({
          ...createSelectSchema(users).shape,
          company: createSelectSchema(companies).nullable(),
          companyUnit: createSelectSchema(companyUnits).nullable(),
        })
      ),
    },
  },
  addUser: {
    method: 'POST',
    path: '/users',
    body: z.object({
      ...createInsertSchema(users).omit({
        sub: true,
        activationState: true,
      }).shape,
      emailAddress: z.string().email(),
    }),
    responses: {
      200: z.object({
        id: z.number(),
      }),
      204: z.undefined(),
      401: z.object({
        message: z.string(),
      }),
    },
  },
  editUser: {
    method: 'POST',
    path: '/users/:id/edit',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: createInsertSchema(users).pick({
      avatar: true,
      firstName: true,
      lastName: true,
      emailAddress: true,
    }),
    responses: {
      200: z.object({
        id: z.number(),
      }),
    },
  },
  setActivationStatus: {
    method: 'POST',
    path: '/users/:id/setActivation',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({
      isActive: z.boolean(),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
      401: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
})

export type AddUserRequest = ClientInferRequest<typeof usersRouter.addUser>

export type EditProfileRequest = ClientInferRequest<
  typeof usersRouter.editProfile
>

export type EditUserRequest = ClientInferRequest<typeof usersRouter.editUser>

export type SetUserActivationStatusRequest = ClientInferRequest<
  typeof usersRouter.setActivationStatus
>

export type UserResponse = z.infer<typeof userResponse200>

export default usersRouter
