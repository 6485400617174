import { FormDatePicker } from '@aeqoom/components-web'
import { AddMachineInstanceRequest } from '@aeqoom/contracts'
import { PERMISSIONS } from '@aeqoom/permissions'
import { isObjectEmpty } from '@aeqoom/utils'
import CreateCompanyDialogContent from '@app/components/base/dialogs/createForms/CreateCompanyDialogContent'
import CreateCompanyUnitDialogContent from '@app/components/base/dialogs/createForms/CreateCompanyUnitDialogContent'
import CreateMachineTemplateDialogContent from '@app/components/base/dialogs/createForms/CreateMachineTemplateDialogContent'
import FormAutoComplete from '@app/components/base/form/FormAutoComplete'
import { FormAvatarField } from '@app/components/base/form/FormAvatarField'
import { FormTextField } from '@app/components/base/form/FormTextField'
import { FormUploadField } from '@app/components/base/form/FormUploadField'
import CheckPermissions from '@app/components/CheckPermissions'
import Section from '@app/components/Section'
import { useGetCompanies } from '@app/queries/useCompanies'
import { useGetCompanyUnits } from '@app/queries/useCompanyUnits'
import {
  useCreateMachineInstance,
  useEditMachineInstance,
  useGetMachineInstance,
} from '@app/queries/useMachineInstances'
import { useGetMachineTemplates } from '@app/queries/useMachineTemplates'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { Errors } from '@app/src/types'
import { AddCircle, PrecisionManufacturing } from '@mui/icons-material'
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
} from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import toast from 'react-hot-toast'

import ActionControlButtons from '../ActionControlButtons'
import DialogDetailError from '../DialogDetailError'
import ComponentItem from './ComponentItem'
import { defaultValues } from './defaultValues'

type Props = {
  id?: AddMachineInstanceRequest['body']['id']
  formData?: Partial<AddMachineInstanceRequest['body']>
  readOnly?: boolean
  isEditModal?: boolean
  callback?: (id: number) => void
  readOnlyFields?: (keyof AddMachineInstanceRequest['body'])[]
}

const CreateMachineInstanceDialogContent = ({
  readOnly = false,
  isEditModal = false,
  callback,
  id,
  formData,
  readOnlyFields,
}: Props) => {
  const [isEditing, setIsEditing] = useState(!readOnly)

  const { openDialog, closeDialog, setShouldAskBeforeClose, closeTab } =
    useDialogStore()

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { dirtyFields, errors },
    trigger,
  } = useForm<AddMachineInstanceRequest['body']>({
    defaultValues,
  })

  const { companyId, companyUnitId } = useWatch({ control })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'componentInstances',
  })

  const getMachineInstanceQuery = useGetMachineInstance({
    id,
    staleTime: 0,
  })

  const getMachineTemplatesQuery = useGetMachineTemplates()

  const getCompaniesQuery = useGetCompanies()

  const getCompanyUnitsQuery = useGetCompanyUnits({
    companyId,
  })

  const createMachineInstanceMutation = useCreateMachineInstance()
  const editMachineInstanceMutation = useEditMachineInstance()

  useEffect(() => {
    setShouldAskBeforeClose(isObjectEmpty(dirtyFields))
  }, [isObjectEmpty(dirtyFields)])

  useEffect(() => {
    if (
      !getMachineInstanceQuery.isPending ||
      !getMachineInstanceQuery.isError
    ) {
      reset(getMachineInstanceQuery.data)
    }
    if (formData?.companyId) {
      setValue('companyId', formData.companyId)
    }
    if (formData?.companyUnitId) {
      setValue('companyUnitId', formData.companyUnitId)
    }
  }, [
    getMachineInstanceQuery.data,
    getMachineInstanceQuery.isError,
    getMachineInstanceQuery.isPending,
    reset,
    formData,
    setValue,
  ])

  const onSubmit = async (data: AddMachineInstanceRequest['body']) => {
    createMachineInstanceMutation.mutate(data, {
      onSuccess: (data) => {
        callback ? callback(data.id) : closeDialog()
        toast.success(data.message)
        reset()
      },
    })
  }

  const onEditSubmit = async (data: AddMachineInstanceRequest['body']) => {
    editMachineInstanceMutation.mutate(
      {
        body: data,
        params: { id: data.id! },
      },
      {
        onSuccess: (data) => {
          if (callback) callback(data.id)
          toast.success(data.message)
          setIsEditing(false)
        },
      }
    )
  }

  if (getMachineInstanceQuery.isPending && readOnly)
    return (
      <Stack alignItems="center">
        <CircularProgress size={80} />
      </Stack>
    )

  if (getMachineInstanceQuery.isError)
    return (
      <DialogDetailError onRetry={() => getMachineInstanceQuery.refetch()} />
    )

  return (
    <>
      <DialogContent>
        <Stack spacing={4}>
          <Section
            title="Machine information"
            qr={{
              image: getMachineInstanceQuery.data?.qrcode,
              name: getMachineInstanceQuery.data?.name,
            }}
          >
            <Grid item xs={12}>
              <FormAvatarField
                control={control}
                name="avatar"
                variant="rounded"
                height={256}
                fullWidth
                readOnly={!isEditing}
                placeHolderIcon={PrecisionManufacturing}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                controller={{
                  control,
                  name: 'name',
                  rules: { required: true },
                }}
                field={{
                  label: 'Name',
                  fullWidth: true,
                  required: true,
                  InputProps: { readOnly: !isEditing },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CheckPermissions
                permissions={[PERMISSIONS.MACHINE_TEMPLATE.LIST]}
              >
                <Stack>
                  <FormAutoComplete
                    autocomplete={{
                      getOptionLabel: (option) => {
                        return option.name!
                      },
                      options: getMachineTemplatesQuery.data || [],
                      fullWidth: true,
                      readOnly: !isEditing,
                    }}
                    controller={{
                      control,
                      name: 'machineTemplateId',
                    }}
                    field={{ label: 'Machine template' }}
                    add={{
                      tooltip: 'Create new machine template',
                      onClick: () =>
                        openDialog(
                          <CreateMachineTemplateDialogContent
                            callback={(id) => {
                              setValue('machineTemplateId', id)
                              closeTab()
                            }}
                          />,
                          'Create machine template'
                        ),
                    }}
                  />
                </Stack>
              </CheckPermissions>
            </Grid>
            <Grid item xs={6}>
              <FormDatePicker
                controller={{
                  control,
                  name: 'yearProduced',
                  rules: { required: Errors.REQUIRED },
                }}
                datePicker={{
                  label: 'Year produced',
                  views: ['year'],
                  readOnly: !isEditing,
                  timezone: 'UTC',
                }}
                field={{ fullWidth: true, required: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                controller={{
                  control,
                  name: 'inventoryNumber',
                }}
                field={{
                  label: 'Inventory number',
                  fullWidth: true,
                  InputProps: { readOnly: !isEditing },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                controller={{
                  control,
                  name: 'description',
                }}
                field={{
                  label: 'Description',
                  fullWidth: true,
                  rows: 4,
                  multiline: true,
                  InputProps: { readOnly: !isEditing },
                }}
              />
            </Grid>
          </Section>
          <Section title="Company">
            <Grid item xs={6}>
              <FormAutoComplete
                autocomplete={{
                  getOptionLabel: (option) => {
                    return option.name!
                  },
                  options: getCompaniesQuery.data || [],
                  fullWidth: true,
                  readOnly: !isEditing,
                  disabled: readOnlyFields?.includes('companyId'),
                }}
                controller={{
                  control,
                  name: 'companyId',
                  rules: { required: Errors.REQUIRED },
                }}
                field={{ label: 'Company', required: true }}
                add={{
                  tooltip: 'Create new company',
                  onClick: () =>
                    openDialog(
                      <CreateCompanyDialogContent
                        callback={(id) => {
                          setValue('companyId', id)
                          closeTab()
                        }}
                      />,
                      'Create company'
                    ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormAutoComplete
                autocomplete={{
                  getOptionLabel: (option) => {
                    return option.name!
                  },
                  options: getCompanyUnitsQuery.data || [],
                  fullWidth: true,
                  readOnly: !isEditing,
                  disabled: readOnlyFields?.includes('companyUnitId'),
                }}
                controller={{
                  control,
                  rules: { required: Errors.REQUIRED },
                  name: 'companyUnitId',
                }}
                field={{ label: 'Company unit', required: true }}
                add={{
                  tooltip: 'Create new company unit',
                  onClick: () =>
                    openDialog(
                      <CreateCompanyUnitDialogContent
                        callback={(id) => {
                          setValue('companyUnitId', id)
                          closeTab()
                        }}
                      />,
                      'Create company unit'
                    ),
                }}
              />
            </Grid>
          </Section>
          {companyId && companyUnitId && (
            <Section title="Components">
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <Stack spacing={1}>
                    {fields.map((item, index) => (
                      <ComponentItem
                        key={item.id}
                        remove={remove}
                        index={index}
                        readOnly={!isEditing}
                        control={control}
                        trigger={trigger}
                        setValue={setValue}
                        errors={errors}
                      />
                    ))}
                  </Stack>
                  <Button
                    color="info"
                    startIcon={<AddCircle />}
                    onClick={() => append({ id: null! })}
                    disabled={!isEditing}
                  >
                    Add new component
                  </Button>
                </Stack>
              </Grid>
            </Section>
          )}
          <Section title="Media">
            <Grid item xs={12}>
              <FormUploadField
                control={control}
                name="media"
                isEditing={isEditing}
                defaultAccessLevel={['company', 'companyUnit']}
                disableAccessLevel
              />
            </Grid>
          </Section>
        </Stack>
      </DialogContent>
      {isEditModal || (
        <CheckPermissions permissions={[PERMISSIONS.MACHINE_INSTANCE.CREATE]}>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={createMachineInstanceMutation.isPending}
            >
              Create machine instance
            </Button>
          </DialogActions>
        </CheckPermissions>
      )}
      {isEditModal && (
        <CheckPermissions permissions={[PERMISSIONS.MACHINE_INSTANCE.UPDATE]}>
          <DialogActions>
            <ActionControlButtons
              isEditing={isEditing}
              onSave={handleSubmit(onEditSubmit)}
              onEdit={() => setIsEditing(true)}
              disabled={editMachineInstanceMutation.isPending}
            />
          </DialogActions>
        </CheckPermissions>
      )}
    </>
  )
}

export default CreateMachineInstanceDialogContent
