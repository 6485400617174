import { LabeledValue } from '@aeqoom/components-web'
import { useGetMachineTemplate } from '@app/queries/useMachineTemplates'
import { Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { FIELD_SHARED_STYLES } from '../../constants'

type Props = {
  machineTemplateId: number | null
}

const MachineTemplate = ({ machineTemplateId }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'detailViewDialog',
  })

  const { isPending, isError, data } = useGetMachineTemplate({
    id: machineTemplateId,
  })

  if (!machineTemplateId) return null

  if (isPending)
    return (
      <Skeleton
        variant="rectangular"
        sx={{ ...FIELD_SHARED_STYLES, borderRadius: 1 }}
      />
    )

  if (isError) return null

  return (
    <LabeledValue
      label={t('machineTemplate')}
      value={data.name}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />
  )
}

export default MachineTemplate
