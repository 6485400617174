import { SvgIconProps, SxProps } from '@mui/material'
import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

type ChildrenProps = {
  icon: OverridableComponent<SvgIconTypeMap>
  iconProps?: SvgIconProps
}

const SHARED_PROPS: SxProps = {
  width: '50%',
  height: '50%',
  maxWidth: 96,
}

const AvatarIcon = ({ icon, iconProps }: ChildrenProps) => {
  const Icon = icon

  return <Icon sx={SHARED_PROPS} color="secondary" {...iconProps} />
}

export default AvatarIcon
