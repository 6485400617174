import { PlanTaskForm } from '@aeqoom/forms'
import { DateTimePicker } from '@mui/x-date-pickers'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const PlannedFrom = () => {
  const { control, watch } = useFormContext<PlanTaskForm>()

  const { t } = useTranslation('web', {
    keyPrefix: 'backlog.taskDetail.header.plan',
  })

  const { field } = useController({
    name: 'plannedFrom',
    control,
    rules: { deps: ['plannedTo'] },
  })

  const [plannedTo, allDay] = watch(['plannedTo', 'allDay'])

  return (
    <DateTimePicker
      label={t('plannedFrom')}
      disablePast
      maxDateTime={plannedTo}
      maxTime={plannedTo}
      inputRef={field.ref}
      slotProps={{ textField: { onBlur: field.onBlur } }}
      views={
        allDay
          ? ['year', 'month', 'day']
          : ['year', 'month', 'day', 'hours', 'minutes']
      }
      {...field}
    />
  )
}

export default PlannedFrom
