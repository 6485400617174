import { filesize } from 'filesize'
import { ErrorCode } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

type Options = {
  maxCount?: number
  maxSize: number
  minSize?: number
  multiple: boolean
}

type ValidationMessage = {
  key: ValidationMessageKey
  options?: Record<string, unknown>
}

type ValidationMessageKey =
  | 'web:media.dropzoneValidation.fileInvalidType'
  | 'web:media.dropzoneValidation.fileTooLarge'
  | 'web:media.dropzoneValidation.fileTooSmall'
  | 'web:media.dropzoneValidation.tooManyFiles'

export const useDropzoneValidation = () => {
  const { t } = useTranslation(['web'])

  const getValidationMessage = (code: ErrorCode | string, options: Options) => {
    const maxCount = options.maxCount ?? (options.multiple ? undefined : 1)

    const maxSize = filesize(options.maxSize ?? 0)

    const minSize = filesize(options.minSize ?? 0)

    const VALIDATION_MESSAGE: Record<ErrorCode | string, ValidationMessage> = {
      'file-invalid-type': {
        key: 'web:media.dropzoneValidation.fileInvalidType',
      },
      'file-too-large': {
        key: 'web:media.dropzoneValidation.fileTooLarge',
        options: { size: maxSize },
      },
      'file-too-small': {
        key: 'web:media.dropzoneValidation.fileTooSmall',
        options: { size: minSize },
      },
      'too-many-files': {
        key: 'web:media.dropzoneValidation.tooManyFiles',
        options: { count: maxCount },
      },
    }

    const validationMessage = VALIDATION_MESSAGE[code]

    return t(validationMessage.key, validationMessage.options)
  }

  return { getValidationMessage }
}
