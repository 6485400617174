import { Stack, Typography } from '@mui/material'

type Props = {
  label: string
  value?: string | null
}

const TextRow = ({ label, value }: Props) => {
  if (!value) return null
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography variant="body2Regular">{label}: </Typography>
      <Typography variant="body2Bold">{value}</Typography>
    </Stack>
  )
}

export default TextRow
