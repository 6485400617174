import { Skeleton, TableCell, TableRow } from '@mui/material'

type Props = {
  rowsNum?: number
  colsNum: number
}

const TableRowsLoader = ({ rowsNum = 6, colsNum = 4 }: Props) => {
  return [...Array(rowsNum)].map((_, index) => (
    <TableRow key={index}>
      {[...Array(colsNum)].map((_, index) => (
        <TableCell key={index}>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      ))}
    </TableRow>
  ))
}

export default TableRowsLoader
