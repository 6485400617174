import {
  NewCompany,
  NewCompanyUnit,
  NewComponentInstance,
  NewMachineInstance,
  NewServiceLevelAgreement,
  NewToDoList,
  NewToDoListItem,
  User,
} from '.'
import {
  Company,
  CompanyUnit,
  ComponentInstance,
  ComponentTemplate,
  Hall,
  HallSeparator,
  MachineInstance,
  MachineTemplate,
  ParentTask,
  ServiceBook,
  ServiceBookRecord,
  ServiceLevelAgreement,
  Task,
  ToDoList,
  ToDoListItem,
  WorkProtocol,
} from '.'
import { Media, NewMedia } from './media'

export enum UserGroups {
  CLIENT_SUPER_ADMIN = 'ClientSuperAdmin',
  CLIENT_UNIT_ADMIN = 'ClientUnitAdmin',
  PROVIDER_SUPER_ADMIN = 'ProviderSuperAdmin',
  PROVIDER_ADMIN = 'ProviderAdmin',
  TECHNICIAN = 'Technician',
}

/**
 * @deprecated use CompanyType from libs/shared/src/lib/types/index.ts
 */
export enum CompanyType {
  CUSTOMER = 'customer',
  OWN_COMPANY = 'own_company',
  PARTS_SUPPLIER = 'parts_supplier',
  PRODUCER = 'producer',
  REVISION = 'revision',
  SERVICE_PARTNER = 'service_partner',
  SUPPLIER = 'supplier',
}

export enum TaskStatus {
  NEW = 'new',
  RECURRED = 'recurred',
  ACKNOWLEDGED = 'acknowledged',
  PLANNED = 'planned',
  IN_PROGRESS = 'in_progress',
  WAITING_ON_WP = 'waiting_on_wp',
  BLOCKED = 'blocked',
  ARCHIVED = 'archived',
  TO_BE_CHECKED_BY_ADMIN = 'to_be_checked_by_admin',
  TO_BE_CHECKED_BY_CLIENT = 'to_be_checked_by_client',
  DONE = 'done',
}

/**
 * @deprecated use TaskType from libs/shared/src/lib/types/index.ts
 */
export enum TaskType {
  INCIDENT = 'incident',
  MAINTENANCE = 'maintenance',
  GENERAL = 'general',
  EXAMINATION = 'examination',
  INSTALLATION = 'installation',
  FULL_SERVICE = 'full_service',
}

export enum CounterState {
  ENVELOPES = 'envelopes',
  LISTS = 'lists',
  CUTS = 'cuts',
}

export enum TaskPriority {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export type GetUser = Omit<User, 'avatar'> & {
  avatar: Media
  company: Company
  companyUnit: CompanyUnit
}

export type GetCompanyUnit = Omit<CompanyUnit, 'avatar'> & {
  avatar: Media
}

export type GetServiceLevelAgreement = ServiceLevelAgreement & {
  machineInstances: MachineInstance[]
}

type MediaWithoutFullUrl = Omit<NewMedia, 'fullUrl'>

export type PostUser = Omit<User, 'avatar'> & {
  avatar?: MediaWithoutFullUrl
}

export type PostToDoList = NewToDoList & {
  toDoListItems: (NewToDoListItem & {
    media: MediaWithoutFullUrl[]
  })[]
}

export type GetToDoList = ToDoList & {
  toDoListItems?: (ToDoListItem & {
    media: Media[]
  })[]
}

export type PostServiceLevelAgreement = NewServiceLevelAgreement & {
  machineInstances: [
    {
      machineInstanceId: number | null
      type: string | null
      frequency: string | null
      taskTemplateId: number | null
      componentInstances: [
        {
          type: string | null
          frequency: string | null
          componentInstanceId: number | null
          taskTemplateId: number | null
        }
      ]
    }
  ]
  media?: MediaWithoutFullUrl[] | null
}

export type PostCompany = Omit<NewCompany, 'avatar'> & {
  avatar?: MediaWithoutFullUrl | null
  group: UserGroups
}

export type PostCompanyUnit = Omit<NewCompanyUnit, 'avatar'> & {
  avatar?: MediaWithoutFullUrl | null
}

export type PostComponentInstance = NewComponentInstance & {
  media?: MediaWithoutFullUrl[] | null
}

export type PostMachineInstance = NewMachineInstance & {
  media?: MediaWithoutFullUrl[] | null
}

export type AssignUserToTaskRequest = {
  userId: number
}

export type CreateResponse<T> = {
  message: string
  data?: T
}

export type CompanyJ = Company & {
  companyUnits: CompanyUnit[]
}

export type ComponentInstanceJ = ComponentInstance & {
  componentTemplate: ComponentTemplate[]
}

export type ComponentTemplateJ = ComponentTemplate & {
  componentInstances: ComponentInstance[]
  toDoLists: ToDoList[]
}

export type MachineInstanceJ = MachineInstance & {
  companyUnit: CompanyUnit
  hall: Hall
  hallSeparator: HallSeparator
  machineTemplate: MachineTemplate
  serviceLevelAgreement: ServiceLevelAgreement
  componentInstances: ComponentInstance[]
  tasks: Task[]
}

export type MachineTemplateJ = MachineTemplate & {
  toDoLists: ComponentInstance[]
  componentTemplates: ComponentTemplate[]
  machineInstances: MachineInstance[]
}

export type ParentTaskJ = ParentTask & {
  possibleAssignees: User[]
  possibleMachineInstances: MachineInstance[]
  possibleComponentInstances: ComponentInstance[]
  serviceLevelAgreement: ServiceLevelAgreementJ
  tasks: Task[]
  managedBy: User
}

export type ServiceBookJ = ServiceBook & {
  componentInstance: ComponentInstance
  serviceBookRecords: ServiceBookRecord[]
}

export type ServiceLevelAgreementJ = ServiceLevelAgreement & {
  companyUnit: CompanyUnit
  machineInstances: MachineInstance[]
  componentInstances: ComponentInstance[]
  parentTasks: ParentTask[]
}

export type GetTaskResponse = Task & {
  user: User
  workProtocol: WorkProtocol
  parentTask: ParentTaskJ
  toDoList: ToDoListJ
  machineInstances: MachineInstance[]
  serviceLevelAgreement: ServiceLevelAgreementJ
}

export type ToDoListJ = ToDoList & {
  machineTemplates: MachineTemplate[]
  componentTemplates: ComponentTemplate[]
  toDoListItems: ToDoListItem[]
}
