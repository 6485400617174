import { ContactBody } from '@aeqoom/contracts'
import DialogContent from '@app/components/base/dialogs/_detailViewDialogs/_components/DialogContent'
import { useTranslation } from 'react-i18next'

import ContactInfo from './_tabs/ContactInfo'

type Props = {
  data: ContactBody
}

const Content = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'contact.detail',
  })

  const TABS = [
    {
      component: (data: ContactBody) => <ContactInfo data={data} />,
      header: t('tabs.contactDetail.label'),
    },
  ]

  return (
    <DialogContent
      title={t('header.title', {
        firstName: data.firstName,
        lastName: data.lastName,
        position: data.position,
      })}
      tabs={TABS}
      data={data}
    />
  )
}

export default Content
