import { useThemeColors } from '@aeqoom/hooks'
import { Box, DialogContent, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  qrCode: string
}
const QrDialog = ({ qrCode }: Props) => {
  const { t } = useTranslation('common')

  const { secondary } = useThemeColors()

  return (
    <DialogContent>
      <Stack p={4} justifyContent="center" alignItems="center" height="100%">
        <Box
          component="img"
          sx={{
            width: 400,
            border: `solid 1px ${secondary.light}`,
            borderRadius: 2,
          }}
          src={qrCode}
          alt=""
        />
      </Stack>
    </DialogContent>
  )
}

export default QrDialog
