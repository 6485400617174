import { MachineTemplateResponse } from '@aeqoom/contracts'
import DialogContent from '@app/components/base/dialogs/_detailViewDialogs/_components/DialogContent'
import { useTranslation } from 'react-i18next'

import MachineInfo from './_tabs/MachineInfo'

type Props = {
  data: MachineTemplateResponse
}

const Content = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'machineTemplate.detail',
  })

  const TABS = [
    {
      component: (data: MachineTemplateResponse) => <MachineInfo data={data} />,
      header: t('tabs.machineDetail.label'),
    },
  ]

  return (
    <DialogContent
      data={data}
      title={t('header.title', {
        name: data.name,
        producer: data.producer,
      })}
      tabs={TABS}
    />
  )
}

export default Content
