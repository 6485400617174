import { AddComponentTemplate } from '@aeqoom/contracts'
import { isObjectEmpty } from '@aeqoom/utils'
import { FormTextField } from '@app/components/base/form/FormTextField'
import Section from '@app/components/Section'
import {
  useCreateComponentTemplate,
  useEditComponentTemplate,
  useGetComponentTemplate,
} from '@app/queries/useComponentTemplates'
import { useDialogStore } from '@app/stores/useDialogStore'
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
} from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ActionControlButtons from '../ActionControlButtons'
import DialogDetailError from '../DialogDetailError'

type Props = {
  id?: AddComponentTemplate['body']['id']
  readOnly?: boolean
  isEditModal?: boolean
  callback?: (id: number) => void
}

const CreateComponentTemplateDialogContent = ({
  readOnly = false,
  isEditModal = false,
  id,
  callback,
}: Props) => {
  const [isEditing, setIsEditing] = useState(!readOnly)

  const { closeDialog, setShouldAskBeforeClose } = useDialogStore()

  const {
    handleSubmit,
    control,
    reset,
    formState: { dirtyFields },
  } = useForm<AddComponentTemplate['body']>()

  const getComponentTemplateQuery = useGetComponentTemplate({
    id,
    staleTime: 0,
  })
  const createComponentTemplateMutation = useCreateComponentTemplate()
  const editComponentTemplateMutation = useEditComponentTemplate()

  useEffect(() => {
    setShouldAskBeforeClose(isObjectEmpty(dirtyFields))
  }, [isObjectEmpty(dirtyFields)])

  useEffect(() => {
    if (
      !getComponentTemplateQuery.isPending ||
      !getComponentTemplateQuery.isError
    ) {
      reset(getComponentTemplateQuery.data)
    }
  }, [getComponentTemplateQuery.data, reset])

  const onSubmit = (data: AddComponentTemplate['body']) => {
    createComponentTemplateMutation.mutate(data, {
      onSuccess: (data) => {
        callback ? callback(data.id) : closeDialog()
        toast.success(data.message)
        reset()
      },
    })
  }

  const onEditSubmit = (data: AddComponentTemplate['body']) => {
    editComponentTemplateMutation.mutate(
      { body: data, params: { id: data.id! } },
      {
        onSuccess: (data) => {
          toast.success(data.message)
          callback ? callback(data.id) : closeDialog()
          setIsEditing(false)
        },
        onError: () => toast.error('Something went wrong'),
      }
    )
  }

  if (getComponentTemplateQuery.isPending && readOnly)
    return (
      <Stack alignItems="center">
        <CircularProgress size={80} />
      </Stack>
    )

  if (getComponentTemplateQuery.isError)
    return (
      <DialogDetailError onRetry={() => getComponentTemplateQuery.refetch()} />
    )

  return (
    <>
      <DialogContent>
        <Stack>
          <Section title="Component template">
            <Grid item xs={6}>
              <FormTextField
                controller={{
                  control,
                  name: 'name',
                  rules: { required: true },
                }}
                field={{
                  label: 'Name',
                  fullWidth: true,
                  required: true,
                  InputProps: { readOnly: !isEditing },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                controller={{
                  control,
                  name: 'producer',
                  rules: { required: true },
                }}
                field={{
                  label: 'Producer',
                  fullWidth: true,
                  required: true,
                  InputProps: { readOnly: !isEditing },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                controller={{
                  control,
                  name: 'description',
                  rules: { required: true },
                }}
                field={{
                  label: 'Description',
                  fullWidth: true,
                  required: true,
                  rows: 4,
                  multiline: true,
                  InputProps: { readOnly: !isEditing },
                }}
              />
            </Grid>
          </Section>
        </Stack>
      </DialogContent>
      <DialogActions>
        {isEditModal || (
          <Button
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={createComponentTemplateMutation.isPending}
          >
            Create component template
          </Button>
        )}
        {isEditModal && (
          <ActionControlButtons
            isEditing={isEditing}
            onSave={handleSubmit(onEditSubmit)}
            onEdit={() => setIsEditing(true)}
            disabled={editComponentTemplateMutation.isPending}
          />
        )}
      </DialogActions>
    </>
  )
}

export default CreateComponentTemplateDialogContent
