import { useThemeColors } from '@aeqoom/hooks'
import { alpha } from '@mui/material'
import React from 'react'

type Props = Partial<React.HTMLAttributes<HTMLLIElement>> & {
  children: React.ReactNode
}

export const Option = ({ children, ...props }: Props) => {
  const { secondary } = useThemeColors()

  return (
    <li
      style={{
        borderBottom: `solid 1px ${alpha(secondary.light, .5)}`,
        padding: "8px 16px",
      }}
      {...props}
    >
      {children}
    </li>
  )
}
