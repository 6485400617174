import { TextFieldProps } from '@mui/material'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'

type Props<T extends FieldValues> = {
  controller: UseControllerProps<T>
  datePicker: DatePickerProps<Dayjs>
  field: TextFieldProps
}

export const FormDatePicker = <T extends FieldValues>({
  controller,
  datePicker,
  field,
}: Props<T>) => {
  return (
    <Controller
      {...controller}
      render={({
        field: { ref, onBlur, onChange, value, ...fieldProps },
        fieldState: { error },
      }) => (
        <DatePicker
          {...datePicker}
          {...fieldProps}
          value={value ? dayjs(value) : null}
          inputRef={ref}
          onClose={onBlur}
          onChange={(date) => {
            onChange(date?.toISOString())
          }}
          slotProps={{
            textField: {
              onBlur,
              error: !!error,
              helperText: error?.message ?? field?.helperText ?? ' ',
              ...field,
            },
          }}
        />
      )}
    />
  )
}
