import { HttpStatusCode } from '../enums'

export class AppError extends Error {
  statusCode: HttpStatusCode

  constructor(message?: string, statusCode?: HttpStatusCode) {
    super(message)
    this.statusCode = statusCode ?? HttpStatusCode.INTERNAL_SERVER_ERROR
  }
}
