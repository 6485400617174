import { ContractResponse, Flatten } from '@aeqoom/contracts'
import ComponentRow from '@app/components/base/dialogs/_detailViewDialogs/ContractDialog/Content/_tabs/MachinesList/ComponentRow'
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  data: Flatten<ContractResponse['machineInstances']>['componentInstances']
  isCollapsed: boolean
}

const ComponentTable = ({ data, isCollapsed }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'contracts.detail.tabs.machinesList',
  })
  return (
    <TableRow>
      <TableCell style={{ padding: 0 }} colSpan={4}>
        <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
          <TableContainer>
            <Table sx={{ width: '100%', minWidth: 700 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ paddingLeft: 12 }}>
                    {t('componentInstances')}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((componentInstance) => (
                  <ComponentRow
                    key={componentInstance.id}
                    componentInstance={componentInstance}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default ComponentTable
