import { PERMISSIONS } from '@aeqoom/permissions'
import CustomDataGrid from '@app/components/base/datagrid/CustomDataGrid'
import CreateTodoListDialogContent from '@app/components/base/dialogs/createForms/CreateTodoListDialogContent'
import DeleteConfirmDialog from '@app/components/base/dialogs/DeleteConfirmDialog'
import { useDeleteAction } from '@app/hooks/useDeleteAction'
import { useDeleteToDoList, useGetToDoLists } from '@app/queries/useToDoLists'
import useUserStore from '@app/src/stores/useUserStore'
import getDeleteActionCol from '@app/src/utils/getDeleteActionCol'
import { FormatListBulletedOutlined } from '@mui/icons-material'

import todosDataCol from './col'

const Todolists = () => {
  const { data, isPending } = useGetToDoLists()

  const mutation = useDeleteToDoList()

  const { hasPermission } = useUserStore()

  const {
    handleOpen,
    handleClose,
    handleConfirmDelete,
    isOpen,
    setSelectedItemId,
  } = useDeleteAction(mutation)

  const deleteActionCol = getDeleteActionCol({ handleOpen, setSelectedItemId })

  const columns = [
    ...todosDataCol,
    ...(hasPermission(PERMISSIONS.TO_DO_LIST.DELETE) ? [deleteActionCol] : []),
  ]

  return (
    <>
      <DeleteConfirmDialog
        isPending={mutation.isPending}
        onClose={handleClose}
        onConfirm={handleConfirmDelete}
        isOpen={isOpen}
      />

      <CustomDataGrid
        disableCreateNew={!hasPermission(PERMISSIONS.TO_DO_LIST.CREATE)}
        addClickOptions={{
          Dialog: CreateTodoListDialogContent,
          title: 'Create TODO list',
          options: {
            icon: FormatListBulletedOutlined,
            isUnique: false,
          },
        }}
        pageLabel="Todolists"
        loading={isPending}
        rows={data || []}
        columns={columns}
      />
    </>
  )
}

export default Todolists
