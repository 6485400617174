import { Box, CircularProgress } from '@mui/material'

const LoadingScreen = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <CircularProgress size={80} />
    </Box>
  )
}

export default LoadingScreen
