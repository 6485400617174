import { z, zodDayjs } from '@aeqoom/zod'
import { useTranslation } from 'react-i18next'
import { makeZodI18nMap } from 'zod-i18n-map'

const saveWorkProtocolDraftSchema = z.object({
  type: z.literal('draft'),
  id: z.number().optional(),
  createdAt: z.string().optional(),
  arrival: z.string().nullish(),
  repairFrom: z.string().nullish(),
  repairTo: z.string().nullish(),
  departure: z.string().nullish(),
  distance: z.number().min(0).nullish(),
  hours: z.number().min(0).nullish(),
  descriptionOfWork: z.string().nullish(),
  usedSpareParts: z.string().nullish(),
  sparePartsToOrder: z.string().nullish(),
  isConfirmed: z.boolean().optional(),
  signature: z.string().nullish(),
})

const saveWorkProtocolSchemaConfirmed = z.object({
  type: z.literal('confirmed'),
  id: z.number().optional(),
  createdAt: z.string().optional(),
  arrival: z.string(),
  repairFrom: z.string(),
  repairTo: z.string(),
  departure: z.string(),
  distance: z.number().min(0),
  hours: z.number().min(0),
  descriptionOfWork: z.string().nullish(),
  usedSpareParts: z.string().nullish(),
  sparePartsToOrder: z.string().nullish(),
  isConfirmed: z.boolean(),
  signature: z.string(),
})

export const useSaveWorkProtocolSchema = () => {
  const { t } = useTranslation(['web', 'zod', 'common'])

  z.setErrorMap(makeZodI18nMap({ t }))

  return z
    .discriminatedUnion('type', [
      saveWorkProtocolDraftSchema,
      saveWorkProtocolSchemaConfirmed,
    ])
    .refine(
      (data) =>
        !data.arrival || !data.repairFrom || data.arrival < data.repairFrom,
      {
        message: t('zod:saveWorkProtocol.repairFrom'),
        path: ['repairFrom'],
      }
    )
    .refine(
      (data) =>
        !data.repairFrom || !data.repairTo || data.repairFrom < data.repairTo,
      {
        message: t('zod:saveWorkProtocol.repairTo'),
        path: ['repairTo'],
      }
    )
    .refine(
      (data) =>
        !data.repairTo || !data.departure || data.repairTo < data.departure,
      {
        message: t('zod:saveWorkProtocol.departure'),
        path: ['departure'],
      }
    )
}

export type SaveWorkProtocolSchemaForm = z.infer<
  ReturnType<typeof useSaveWorkProtocolSchema>
>

export const usePlanTaskSchema = () => {
  const { t } = useTranslation(['web', 'zod', 'common'])

  z.setErrorMap(makeZodI18nMap({ t }))

  return z
    .object({
      plannedFrom: zodDayjs,
      plannedTo: zodDayjs,
      allDay: z.boolean().nullable(),
      userId: z.number().nullable(),
    })
    .superRefine((data, ctx) => {
      if (!data.plannedFrom.isValid()) {
        ctx.addIssue({
          code: 'custom',
          message: t('zod:planTaskForm.invalidPlannedFrom'),
          path: ['plannedFrom'],
        })
      }

      if (!data.plannedTo.isValid()) {
        ctx.addIssue({
          code: 'custom',
          message: t('zod:planTaskForm.invalidPlannedTo'),
          path: ['plannedTo'],
        })
      }

      if (data.plannedTo.isBefore(data.plannedFrom)) {
        ctx.addIssue({
          code: 'custom',
          message: t('zod:planTaskForm.plannedTo'),
          path: ['plannedTo'],
        })
      }

      if (!data.userId) {
        ctx.addIssue({
          code: 'custom',
          message: t('zod:planTaskForm.userId'),
          path: ['userId'],
        })
      }
    })
}

export type PlanTaskForm = z.infer<ReturnType<typeof usePlanTaskSchema>>

const taskTypeEnum = z.enum([
  'general',
  'maintenance',
  'incident',
  'examination',
  'installation',
  'full_service',
])

const taskPriorityEnum = z.enum(['low', 'medium', 'high'])

const taskStatusEnum = z.enum([
  'new',
  'recurred',
  'acknowledged',
  'planned',
  'in_progress',
  'waiting_on_wp',
  'blocked',
  'archived',
  'to_be_checked_by_admin',
  'to_be_checked_by_client',
  'done',
])

const createTaskSchema = z.object({
  name: z.string(),
  userId: z.number().nullish(),
  type: taskTypeEnum,
  priority: taskPriorityEnum,
  description: z.string().nullish(),
  serviceLevelAgreementId: z.number().nullable(),
  status: taskStatusEnum.optional(),
  toDoListId: z.number().nullable(),
  componentInstanceId: z.number().nullable(),
  machineInstanceId: z.number().nullable(),
  deadline: z.string(),
  companyId: z.number(),
  companyUnitId: z.number(),
  media: z.array(z.number()),
})

export const useCreateTaskSchema = () => {
  return createTaskSchema
}

export type CreateTaskSchemaForm = z.infer<
  ReturnType<typeof useCreateTaskSchema>
>

const editTaskSchema = z.object({
  id: z.number(),
  name: z.string(),
  userId: z.number().nullish(),
  type: taskTypeEnum,
  priority: taskPriorityEnum.nullable(),
  description: z.string().nullish(),
  serviceLevelAgreementId: z.number().nullish(),
  status: taskStatusEnum.optional(),
  toDoListId: z.number().nullish(),
  componentInstanceId: z.number().nullish(),
  machineInstanceId: z.number().nullish(),
  deadline: z.string(),
  companyId: z.number().nullish(),
  companyUnitId: z.number(),
  media: z.array(z.number()).nullish(),
})

export const useEditTaskSchema = () => {
  return editTaskSchema
}

export type EditTaskSchemaForm = z.infer<ReturnType<typeof useEditTaskSchema>>
