import DeleteCellComponent from '@app/components/base/datagrid/DeleteCellComponent'
import { GridColDef } from '@mui/x-data-grid'

interface Props {
  handleOpen: () => void
  setSelectedItemId: (id: number) => void
}

const getDeleteActionCol = ({
  handleOpen,
  setSelectedItemId,
}: Props): GridColDef => ({
  field: 'action',
  disableColumnMenu: true,
  headerName: 'Action',
  sortable: false,
  width: 70,
  align: 'left',
  cellClassName: 'actions',
  renderCell: (params) => (
    <DeleteCellComponent
      {...params}
      id={params.id as number}
      handleOpen={handleOpen}
      setSelectedItemId={setSelectedItemId}
    />
  ),
})

export default getDeleteActionCol
