import { useGetCompanyContacts } from '@app/queries/useContacts'

import ContactView from '../ContactView'

type Props = {
  companyId: number
}

const ContactCompany = ({ companyId }: Props) => {
  const { data, isPending, isError } = useGetCompanyContacts({ id: companyId })

  return <ContactView contacts={data} isPending={isPending} isError={isError} />
}

export default ContactCompany
