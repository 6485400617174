import { FormAutoComplete } from '@aeqoom/components-web'
import { AddTaskTemplateRequest } from '@aeqoom/contracts'
import { NewTaskTemplate } from '@aeqoom/db'
import { PERMISSIONS } from '@aeqoom/permissions'
import { isObjectEmpty } from '@aeqoom/utils'
import CreateTodoListDialogContent from '@app/components/base/dialogs/createForms/CreateTodoListDialogContent'
import { FormTextField } from '@app/components/base/form/FormTextField'
import CheckPermissions from '@app/components/CheckPermissions'
import Section from '@app/components/Section'
import {
  useCreateTaskTemplate,
  useEditTaskTemplate,
  useGetTaskTemplate,
} from '@app/queries/useTaskTemplates'
import { useGetToDoLists } from '@app/queries/useToDoLists'
import useUserStore from '@app/src/stores/useUserStore'
import { Errors } from '@app/src/types'
import { useDialogStore } from '@app/stores/useDialogStore'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ActionControlButtons from '../ActionControlButtons'
import DialogDetailError from '../DialogDetailError'

type Props = {
  id?: AddTaskTemplateRequest['body']['id']
  readOnly?: boolean
  isEditModal?: boolean
  callback?: (id: number) => void
}

const CreateTaskTemplateDialogContent = ({
  readOnly = false,
  isEditModal = false,
  id,
  callback,
}: Props) => {
  const [isEditing, setIsEditing] = useState(!readOnly)

  const { openDialog, closeDialog, closeTab, setShouldAskBeforeClose } =
    useDialogStore()

  const { hasPermission } = useUserStore()

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { dirtyFields },
  } = useForm<AddTaskTemplateRequest['body']>()

  const getTaskTemplateQuery = useGetTaskTemplate({
    id,
  })
  const getToDoListsQuery = useGetToDoLists()
  const createTaskTemplateMutation = useCreateTaskTemplate()
  const editTaskTemplateMutation = useEditTaskTemplate()

  useEffect(() => {
    if (!getTaskTemplateQuery.isPending || !getTaskTemplateQuery.isError) {
      reset(getTaskTemplateQuery.data)
    }
  }, [getTaskTemplateQuery.data, reset])

  useEffect(() => {
    setShouldAskBeforeClose(isObjectEmpty(dirtyFields))
  }, [isObjectEmpty(dirtyFields)])

  const onSubmit = (data: AddTaskTemplateRequest['body']) => {
    createTaskTemplateMutation.mutate(data, {
      onSuccess: (data) => {
        callback ? callback(data.id) : closeDialog()
        toast.success(data.message)
        reset()
      },
    })
  }

  const onEditSubmit = (data: AddTaskTemplateRequest['body']) => {
    editTaskTemplateMutation.mutate(
      { body: data, params: { id: data?.id! } },
      {
        onSuccess: (data) => {
          callback ? callback(data.id) : closeDialog()
          toast.success(data.message)
          setIsEditing(false)
        },
        onError: () => toast.error('Something went wrong'),
      }
    )
  }

  if (getTaskTemplateQuery.isPending && readOnly)
    return (
      <Stack alignItems="center">
        <CircularProgress size={80} />
      </Stack>
    )

  if (getTaskTemplateQuery.isError)
    return <DialogDetailError onRetry={() => getTaskTemplateQuery.refetch()} />

  return (
    <>
      <DialogContent>
        <Stack>
          <Section title="General info">
            <Grid item xs={12}>
              <FormTextField<NewTaskTemplate>
                controller={{
                  control,
                  name: 'name',
                  rules: { required: Errors.REQUIRED },
                }}
                field={{
                  label: 'Name',
                  required: true,
                  helperText: 'Enter name of the task template',
                  fullWidth: true,
                  InputProps: { readOnly: !isEditing },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField<NewTaskTemplate>
                field={{
                  label: 'Description',
                  fullWidth: true,
                  InputProps: { readOnly: !isEditing },
                }}
                controller={{
                  control,
                  name: 'description',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                {hasPermission(PERMISSIONS.TASK_TEMPLATE.LIST) ? (
                  <FormAutoComplete
                    control={control}
                    name="toDoListId"
                    autocomplete={{
                      getOptionLabel: (option) => option.name,
                      options: getToDoListsQuery.data,
                      fullWidth: true,
                      readOnly: !isEditing,
                    }}
                    fieldProps={{
                      label: 'To do list',
                      required: true,
                    }}
                    add={{
                      label: 'Create new to do list',
                      onClick: () =>
                        openDialog(
                          <CreateTodoListDialogContent
                            callback={(id) => {
                              setValue('toDoListId', id)
                              closeTab()
                            }}
                          />,
                          'Create ToDo list'
                        ),
                    }}
                  />
                ) : (
                  <FormTextField
                    field={{ label: 'To do list ID' }}
                    controller={{
                      control,
                      name: 'toDoListId',
                    }}
                  />
                )}
              </Stack>
            </Grid>
          </Section>
        </Stack>
      </DialogContent>
      {isEditModal || (
        <CheckPermissions permissions={[PERMISSIONS.TASK_TEMPLATE.CREATE]}>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={createTaskTemplateMutation.isPending}
            >
              Create task template
            </Button>
          </DialogActions>
        </CheckPermissions>
      )}
      {isEditModal && (
        <CheckPermissions permissions={[PERMISSIONS.TASK_TEMPLATE.UPDATE]}>
          <DialogActions>
            <ActionControlButtons
              hasView={false}
              isEditing={isEditing}
              onSave={handleSubmit(onEditSubmit)}
              onEdit={() => setIsEditing(true)}
              disabled={editTaskTemplateMutation.isPending}
            />
          </DialogActions>
        </CheckPermissions>
      )}
    </>
  )
}

export default CreateTaskTemplateDialogContent
