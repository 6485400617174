import { TaskType } from '@aeqoom/db'

import { CognitoErrors } from '../enums'
import { AccessLevel, FileType, ICurrency, MediaType } from '../types'

export const AUTH_TOKENS = ['16db5557-8c18-402f-af30-ee601ab291e2'] as const

export const CURRENCIES: ICurrency[] = [
  {
    title: 'Polish Zloty',
    logo: '🇵🇱',
    id: 'PLN',
    symbol: 'zł',
  },
  {
    title: 'Czech Koruna',
    logo: '🇨🇿',
    id: 'CZK',
    symbol: 'Kč',
  },
  {
    title: 'US Dollar',
    logo: '🇺🇸',
    id: 'USD',
    symbol: '$',
  },
  {
    title: 'Euro',
    logo: '🇪🇺',
    id: 'EUR',
    symbol: '€',
  },
  {
    title: 'British Pound',
    logo: '🇬🇧',
    id: 'GBP',
    symbol: '£',
  },
]

export const PRETTIER_ERRORS_MAP: Record<string, string> = {
  [CognitoErrors.USER_NOT_FOUND]: 'Oops! This username does not exist.',
  [CognitoErrors.NOT_AUTHORIZED]: 'Oops! Incorrect password.',
}

export const MIME_TYPES: Record<FileType, string[]> = {
  image: [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/webp',
    'image/svg+xml',
    'image/bmp',
    'image/tiff',
  ],
  video: [
    'video/mp4',
    'video/webm',
    'video/ogg',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-ms-wmv',
  ],
  document: [
    'text/plain',
    'text/html',
    'text/css',
    'application/javascript',
    'application/json',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/xml',
    'text/xml',
  ],
  audio: [
    'audio/mpeg',
    'audio/wav',
    'audio/ogg',
    'audio/midi',
    'audio/x-midi',
    'audio/webm',
    'audio/aac',
    'audio/mp4',
  ],
}

export const ACCESS_LEVELS: Record<AccessLevel, string> = {
  company: 'Company',
  user: 'User',
  public: 'Public',
  companyUnit: 'Company unit',
}

export const TASK_TYPES: Record<TaskType, string> = {
  maintenance: 'Maintenance',
  incident: 'Incident',
  general: 'General',
  examination: 'Examination',
  installation: 'Installation',
  full_service: 'Full service',
}

export const MEDIA_TYPES: Record<MediaType, string> = {
  general: 'General',
  agreement: 'Agreement',
  avatar: 'Avatar',
}
