import DashboardItem from '@app/components/Dashboard/DashboardItem'
import LoadingScreen from '@app/components/LoadingScreen'
import { useGetDashboard } from '@app/queries/useDashboard'
import SelectClient from '@app/src/components/SelectClient'
import useUserStore from '@app/src/stores/useUserStore'
import { Grid } from '@mui/material'

const Dashboard = () => {
  const { getCurrentActiveCompany } = useUserStore()

  const activeCompany = getCurrentActiveCompany()

  const { isError, isFetching, data } = useGetDashboard({ id: activeCompany })

  if (isError) return 'Error'

  if (isFetching) return <LoadingScreen />

  return (
    <>
      <SelectClient hideCompanyUnit />
      <Grid container spacing={{ xs: 2, xl: 2 }}>
        {data?.map((unit) => (
          <Grid item xs={12} lg={6} key={unit.id}>
            <DashboardItem unit={unit} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default Dashboard
