import useMediaCarouselStore from '@app/src/stores/useMediaCarouselStore'
import { Box, Fade } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import {
  useDebounceCallback,
  useEventListener,
  useResizeObserver,
} from 'usehooks-ts'

import MediaCarouselNotFound from './NotFound'

type Props = {
  multiplier?: number
  smoothStep?: number
  zoomFactor?: number
  scaleUp?: boolean
}

const MediaCarouselImage = ({
  multiplier = 0.8,
  smoothStep = 0.001,
  zoomFactor = 8,
  scaleUp = true,
}: Props) => {
  const { currentMedia } = useMediaCarouselStore()

  const [containerWidth, setContainerWidth] = useState(0)
  const [containerHeight, setContainerHeight] = useState(0)

  const [imageNaturalWidth, setImageNaturalWidth] = useState(0)
  const [imageNaturalHeight, setImageNaturalHeight] = useState(0)

  const containerRef = useRef<HTMLDivElement>(null)

  const onResize = useDebounceCallback(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect()
      setContainerWidth(rect.width)
      setContainerHeight(rect.height)
    } else {
      setContainerWidth(0)
      setContainerHeight(0)
    }
  }, 200)

  useResizeObserver({
    ref: containerRef,
    onResize,
  })

  useEventListener('resize', onResize)

  useEffect(() => {
    onResize()
  }, [onResize])

  const imageScale = useMemo(() => {
    if (
      containerWidth === 0 ||
      containerHeight === 0 ||
      imageNaturalWidth === 0 ||
      imageNaturalHeight === 0
    )
      return 0
    const scale = Math.min(
      containerWidth / imageNaturalWidth,
      containerHeight / imageNaturalHeight
    )
    return scaleUp ? scale : Math.max(scale, 1)
  }, [
    scaleUp,
    containerWidth,
    containerHeight,
    imageNaturalWidth,
    imageNaturalHeight,
  ])

  useEffect(() => {
    const handleImageOnLoad = (image: HTMLImageElement) => {
      setImageNaturalWidth(image.naturalWidth)
      setImageNaturalHeight(image.naturalHeight)
    }

    if (currentMedia) {
      if (currentMedia.signedUrl) {
        const image = new Image()
        image.onload = () => handleImageOnLoad(image)
        image.src = currentMedia.signedUrl
      }
    }
  }, [currentMedia])

  if (!currentMedia || !currentMedia.signedUrl) return <MediaCarouselNotFound />

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
      }}
      ref={containerRef}
    >
      {imageScale > 0 && (
        <TransformWrapper
          wheel={{ smoothStep }}
          key={`{${containerWidth}x${containerHeight}x${imageNaturalWidth}x${imageNaturalHeight}}`}
          initialScale={imageScale * multiplier}
          minScale={imageScale * multiplier}
          maxScale={imageScale * zoomFactor}
          centerOnInit
        >
          <TransformComponent
            wrapperStyle={{
              width: '100%',
              height: '100%',
            }}
          >
            <Fade in={true} unmountOnExit>
              <Box component="img" src={currentMedia.signedUrl} alt="" />
            </Fade>
          </TransformComponent>
        </TransformWrapper>
      )}
    </Box>
  )
}

export default MediaCarouselImage
