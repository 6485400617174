import { GridMapper, LabeledValue } from '@aeqoom/components-web'
import { CompanyUnitResponse } from '@aeqoom/contracts'
import { serializeAddress } from '@aeqoom/utils'
import CompanyField from '@app/components/base/dialogs/_detailViewDialogs/_components/CompanyField'
import CoverImage from '@app/components/base/dialogs/_detailViewDialogs/_components/CoverImage'
import { FIELD_SHARED_STYLES } from '@app/components/base/dialogs/_detailViewDialogs/constants'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  data: CompanyUnitResponse
}

const GRID_MAP = [5, 6]

const CompanyUnitInfo = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'companyUnit.detail.tabs.companyUnitDetail.content',
  })

  const content = [
    <LabeledValue
      label={t('name')}
      value={data.name}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('address')}
      value={serializeAddress({
        street: data.street,
        city: data.city,
        zipCode: data.zipCode,
        country: data.country,
      })}
      sx={FIELD_SHARED_STYLES}
      type="multi-line"
    />,
  ]

  return (
    <Grid container justifyContent="space-between">
      <Grid
        item
        xs={6}
        sx={{
          overflow: 'hidden',
        }}
      >
        <GridMapper template={GRID_MAP} paddingBottom={8}>
          {content}
        </GridMapper>
        <CompanyField companyId={data.companyId} />
      </Grid>
      <Grid xs={5}>
        <CoverImage avatar={data.avatar} />
      </Grid>
    </Grid>
  )
}

export default CompanyUnitInfo
