import CustomAppBar from '@app/components/AdminLayout/CustomAppBar'
import CustomDrawer from '@app/components/AdminLayout/CustomDrawer'
import { Box, Container, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useState } from 'react'
import { Outlet } from 'react-router-dom'

const AdminLayout = () => {
  const theme = useTheme()

  const [open, setOpen] = useState(false)

  const handleDrawerOpen = (shouldOpen: boolean) => {
    setOpen(shouldOpen)
  }

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.lightGray.main,
        overflow: 'hidden',
        height: '100vh',
      }}
    >
      <CustomAppBar />
      <Stack
        sx={{
          flexDirection: 'row',
          width: '100%',
          height: `calc(100vh - ${theme.constants?.appBarHeight}px)`,
        }}
      >
        <Box sx={{ p: 1.5 }}>
          <CustomDrawer
            open={open}
            handleOpen={(shouldOpen) => handleDrawerOpen(shouldOpen)}
          />
        </Box>
        <Box
          sx={{
            overflowY: 'scroll',
            width: '100%',
            height: '100%',
          }}
        >
          <Container maxWidth="xl" sx={{ p: 2, height: '100%' }}>
            <Outlet />
          </Container>
        </Box>
      </Stack>
    </Stack>
  )
}

export default AdminLayout
