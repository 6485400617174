import { z } from '@aeqoom/zod'

export const addToDoListForm = z.object({
  name: z.string().min(1),
  toDoListItems: z.array(
    z.object({
      id: z.number().optional(),
      name: z.string().min(1),
      order: z.number().positive(),
      content: z.string().min(1),
      media: z.array(z.number()),
    })
  ),
})

export type AddToDoListForm = z.infer<typeof addToDoListForm>
