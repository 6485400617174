import { GridMapper, LabeledValue } from '@aeqoom/components-web'
import { ContractResponse } from '@aeqoom/contracts'
import { serializeAddress } from '@aeqoom/utils'
import CompanyField from '@app/components/base/dialogs/_detailViewDialogs/_components/CompanyField'
import { Grid } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { FIELD_SHARED_STYLES } from '../../../../constants'

type Props = {
  data: ContractResponse
}

const GRID_MAP = [5, 6, 5, 6, 12]

const ContractInfo = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'contracts.detail.tabs.contractDetail.content',
  })

  const handleValidation = () => {
    if (data.dateTo) {
      return dayjs(data.dateTo).format('DD.MM.YYYY')
    } else if (data.isIndefinitely) {
      return t('isIndefinite')
    }
  }

  const content = [
    <LabeledValue
      label={t('name')}
      value={data.name}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('address')}
      value={serializeAddress({
        street: data.companyUnit?.street || data.company.street,
        city: data.companyUnit?.city || data.company.city,
        zipCode: data.companyUnit?.zipCode || data.company.zipCode,
        country: data.companyUnit?.country || data.company.country,
      })}
      sx={FIELD_SHARED_STYLES}
      type="multi-line"
    />,
    <LabeledValue
      label={t('validFrom')}
      value={dayjs(data.dateFrom).format('DD.MM.YYYY')}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('validTill')}
      value={handleValidation()}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('description')}
      value={data.description}
      sx={FIELD_SHARED_STYLES}
      type="multi-line"
    />,
  ]

  const companyContent = [
    <CompanyField companyId={data.companyId} />,
    <CompanyField companyUnitId={data.companyUnitId} />,
  ]

  return (
    <Grid container justifyContent="space-between">
      <Grid
        item
        xs={12}
        sx={{
          overflow: 'hidden',
        }}
      >
        <GridMapper template={GRID_MAP} paddingBottom={8}>
          {content}
        </GridMapper>
        <GridMapper template={GRID_MAP}>{companyContent}</GridMapper>
      </Grid>
    </Grid>
  )
}

export default ContractInfo
