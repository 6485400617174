import { FormSelect } from '@aeqoom/components-web'
import { AddCompanyRequest } from '@aeqoom/contracts'
import { CompanyType } from '@aeqoom/db'
import { AddCompanyForm, addCompanyForm } from '@aeqoom/forms'
import { PERMISSIONS } from '@aeqoom/permissions'
import { CURRENCIES } from '@aeqoom/shared'
import { isObjectEmpty } from '@aeqoom/utils'
import { FormAvatarField } from '@app/components/base/form/FormAvatarField'
import { FormTextField } from '@app/components/base/form/FormTextField'
import CheckPermissions from '@app/components/CheckPermissions'
import ContactList from '@app/components/ContactList'
import EditingProvider from '@app/components/providers/EditingProvider'
import Section from '@app/components/Section'
import {
  useCreateCompany,
  useEditCompany,
  useGetCompany,
} from '@app/queries/useCompanies'
import { COMPANY_TYPE } from '@app/src/utils/labels'
import { useDialogStore } from '@app/stores/useDialogStore'
import { zodResolver } from '@hookform/resolvers/zod'
import { Business } from '@mui/icons-material'
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ActionControlButtons from '../ActionControlButtons'
import DialogDetailError from '../DialogDetailError'
import defaultValues from './defaultValues'

type Props = {
  readOnly?: boolean
  isEditModal?: boolean
  id?: AddCompanyRequest['body']['id'] | null
  callback?: (id: number) => void
}

const CreateCompanyDialogContent = ({
  readOnly = false,
  isEditModal = false,
  id,
  callback,
}: Props) => {
  const [isEditing, setIsEditing] = useState(!readOnly)

  const { closeDialog, setShouldAskBeforeClose } = useDialogStore()

  const methods = useForm<AddCompanyForm>({
    resolver: zodResolver(addCompanyForm),
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    reset,
    formState: { dirtyFields },
  } = methods

  const getCompanyQuery = useGetCompany({
    id,
    staleTime: 0,
  })

  const createCompanyMutation = useCreateCompany()
  const editCompanyMutation = useEditCompany()

  useEffect(() => {
    setShouldAskBeforeClose(isObjectEmpty(dirtyFields))
  }, [isObjectEmpty(dirtyFields)])

  useEffect(() => {
    if (!getCompanyQuery.isPending || !getCompanyQuery.isError) {
      reset(getCompanyQuery.data)
    }
  }, [getCompanyQuery.data, reset])

  const onCreateSubmit = async (data: AddCompanyForm) => {
    createCompanyMutation.mutate(data, {
      onSuccess: ({ message, id }) => {
        toast.success(message)
        callback ? callback(id) : closeDialog()
        reset()
      },
    })
  }

  const onEditSubmit = async (data: AddCompanyForm) => {
    editCompanyMutation.mutate(
      { body: data, params: { id: id! } },
      {
        onSuccess: (data) => {
          if (callback) callback(data.id)
          toast.success(data.message)
          setIsEditing(false)
        },
      }
    )
  }

  if (getCompanyQuery.isPending && readOnly)
    return (
      <Stack alignItems="center">
        <CircularProgress size={80} />
      </Stack>
    )

  if (getCompanyQuery.isError)
    return <DialogDetailError onRetry={() => getCompanyQuery.refetch()} />

  return (
    <FormProvider {...methods}>
      <EditingProvider isEditing={isEditing}>
        <DialogContent>
          <Stack direction="column" spacing={8}>
            <Section title="Basic information">
              <Grid item xs={12}>
                <FormAvatarField
                  control={control}
                  name="avatar"
                  readOnly={!isEditing}
                  placeHolderIcon={Business}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  field={{
                    label: 'Company name',
                    required: true,
                    helperText: 'Enter name of company',
                    fullWidth: true,
                    InputProps: { readOnly: !isEditing },
                  }}
                  controller={{
                    control,
                    name: 'name',
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  field={{
                    label: 'Registration number',
                    required: true,
                    helperText: 'Enter the registration number',
                    fullWidth: true,
                    InputProps: { readOnly: !isEditing },
                  }}
                  controller={{
                    control,
                    name: 'registrationNumber',
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <FormSelect
                  label="Company type"
                  helperText="Enter the company type"
                  name="companyType"
                  control={control}
                  select={{
                    readOnly: !isEditing,
                    required: true,
                  }}
                  options={Object.values(CompanyType).map((value) => (
                    <MenuItem key={value} value={value}>
                      {COMPANY_TYPE[value]}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={4}>
                <FormTextField
                  field={{
                    label: 'Tax ID',
                    required: true,
                    helperText: 'Enter the tax ID',
                    fullWidth: true,
                    InputProps: { readOnly: !isEditing },
                  }}
                  controller={{
                    control,
                    name: 'taxId',
                  }}
                />
              </Grid>
            </Section>
            <Section title="Contacts">
              <Grid item xs={12}>
                <ContactList />
              </Grid>
            </Section>
            <Section title="Location">
              <Grid item xs={8}>
                <FormTextField
                  field={{
                    label: 'Street',
                    required: true,
                    fullWidth: true,
                    InputProps: { readOnly: !isEditing },
                  }}
                  controller={{
                    control,
                    name: 'street',
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormTextField
                  field={{
                    label: 'House number',
                    required: true,
                    fullWidth: true,
                    InputProps: { readOnly: !isEditing },
                  }}
                  controller={{
                    control,
                    name: 'houseNumber',
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormTextField
                  field={{
                    label: 'ZIP Code',
                    required: true,
                    fullWidth: true,
                    InputProps: { readOnly: !isEditing },
                  }}
                  controller={{
                    control,
                    name: 'zipCode',
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <FormTextField
                  field={{
                    label: 'City',
                    required: true,
                    fullWidth: true,
                    InputProps: { readOnly: !isEditing },
                  }}
                  controller={{
                    control,
                    name: 'city',
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <FormTextField
                  field={{
                    label: 'Country',
                    required: true,
                    fullWidth: true,
                    InputProps: { readOnly: !isEditing },
                  }}
                  controller={{
                    control,
                    name: 'country',
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormSelect
                  label="Currency"
                  name="currency"
                  select={{ readOnly: !isEditing }}
                  control={control}
                  options={CURRENCIES.map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.title}
                    </MenuItem>
                  ))}
                />
              </Grid>
            </Section>
          </Stack>
        </DialogContent>
        <CheckPermissions permissions={[PERMISSIONS.COMPANY.UPDATE]}>
          <DialogActions>
            {isEditModal || (
              <Stack direction="row" alignItems="center" spacing={6}>
                <Typography variant="body2" color="text.primary">
                  Company Unit will be created by default
                </Typography>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit(onCreateSubmit)}
                  disabled={createCompanyMutation.isPending}
                >
                  Create company
                </Button>
              </Stack>
            )}
            {isEditModal && (
              <ActionControlButtons
                isEditing={isEditing}
                onSave={handleSubmit(onEditSubmit)}
                onEdit={() => setIsEditing(true)}
                disabled={editCompanyMutation.isPending}
              />
            )}
          </DialogActions>
        </CheckPermissions>
      </EditingProvider>
    </FormProvider>
  )
}

export default CreateCompanyDialogContent
