import { AddSLARequest } from '@aeqoom/contracts'
import { DefaultValues } from 'react-hook-form'

const defaultValues: DefaultValues<AddSLARequest['body']> = {
  name: null!,
  description: null,
  machineInstances: [
    {
      taskTemplateId: null,
      machineInstanceId: null!,
      frequency: null,
      type: null,
      onCall: false,
    },
  ],
  media: [],
}

export default defaultValues
