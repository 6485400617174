import { FormAutoComplete, FormSelect } from '@aeqoom/components-web'
import { CreateUser, useCreateUserSchema } from '@aeqoom/forms'
import { UserGroups, UserGroupsByAccess } from '@aeqoom/shared'
import { isObjectEmpty } from '@aeqoom/utils'
import CreateCompanyDialogContent from '@app/components/base/dialogs/createForms/CreateCompanyDialogContent'
import CreateCompanyUnitDialogContent from '@app/components/base/dialogs/createForms/CreateCompanyUnitDialogContent'
import { FormAvatarField } from '@app/components/base/form/FormAvatarField'
import { FormTextField } from '@app/components/base/form/FormTextField'
import Section from '@app/components/Section'
import { useGetCompanies } from '@app/queries/useCompanies'
import { useGetCompanyUnits } from '@app/queries/useCompanyUnits'
import { useCreateUser, useEditUser, useGetUser } from '@app/queries/useUsers'
import { USER_GROUPS } from '@app/src/utils/labels'
import { useDialogStore } from '@app/stores/useDialogStore'
import useUserStore from '@app/stores/useUserStore'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  MenuItem,
  Stack,
} from '@mui/material'
import Button from '@mui/material/Button'
import { isAxiosError } from 'axios'
import { useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Trans, useTranslation } from 'react-i18next'

import DialogDetailError from '../DialogDetailError'

type Props = {
  id?: number
  isEditModal?: boolean
  callback?: (id?: number) => void
}

const CreateUserDialogContent = ({ id, isEditModal, callback }: Props) => {
  const { user, getCanChangeCompanyId } = useUserStore()

  const { t } = useTranslation()

  const getUserQuery = useGetUser({
    id,
    enabled: !!id,
  })

  const { closeDialog, closeTab, openDialog, setShouldAskBeforeClose } =
    useDialogStore()

  const createUserSchema = useCreateUserSchema()

  const {
    handleSubmit,
    control,
    reset,
    resetField,
    setValue,
    formState: { dirtyFields },
   } = useForm<CreateUser>({
    mode: 'onBlur',
    resolver: zodResolver(createUserSchema),
    defaultValues: {
      companyId: !getCanChangeCompanyId() ? user?.company?.id : undefined,
    },
  })

  const { companyId: watchCompanyId, group: watchUserGroup } = useWatch({
    control,
  })

  const getCompaniesQuery = useGetCompanies()
  const getCompanyUnitsQuery = useGetCompanyUnits({
    companyId: watchCompanyId,
  })
  const createUserMutation = useCreateUser()
  const editUserMutation = useEditUser({ id })

  useEffect(() => {
    setShouldAskBeforeClose(isObjectEmpty(dirtyFields))
  }, [isObjectEmpty(dirtyFields)])

  useEffect(() => {
    if (!getUserQuery.isPending || !getUserQuery.isError) {
      const data = getUserQuery.data as CreateUser

      reset({
        ...data,
        companyId: getUserQuery.data?.company?.id,
        companyUnitId: getUserQuery.data?.companyUnit?.id,
      })
    }
  }, [getUserQuery.data, reset])

  useEffect(() => {
    resetField('companyUnitId')
  }, [resetField, watchCompanyId])

  const onCreateSubmit = async (data: CreateUser) => {
    createUserMutation.mutate(data, {
      onSuccess: () => {
        callback ? callback() : closeDialog()
        toast.success(t('users.userCreated'))
      },
      onError: (error) => {
        if (isAxiosError(error) && error.response?.data.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error(error.message)
        }
      },
    })
  }

  const onEditSubmit = async (data: CreateUser) => {
    editUserMutation.mutate(data, {
      onSuccess: () => {
        callback ? callback() : closeDialog()
        toast.success(t('users.userEdited'))
      },
      onError: (error) => {
        toast.error(error.message)
      },
    })
  }

  if (getUserQuery.isPending && isEditModal)
    return (
      <Stack alignItems="center">
        <CircularProgress size={80} />
      </Stack>
    )

  if (getUserQuery.isError)
    return <DialogDetailError onRetry={() => getUserQuery.refetch()} />

  const rolesAllowedToAssign = UserGroupsByAccess.slice(
    0,
    UserGroupsByAccess.indexOf(user?.groups ?? UserGroups.TECHNICIAN) + 1
  )
  const isPlatformLevelUser =
    UserGroupsByAccess.indexOf(user?.groups ?? UserGroups.TECHNICIAN) >=
    UserGroupsByAccess.indexOf(UserGroups.PROVIDER_ADMIN)

  return (
    <>
      <DialogContent>
        <Stack spacing={8}>
          <Section title="General information">
            <Grid item xs={12}>
              <FormAvatarField control={control} name="avatar" />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                field={{
                  label: 'First name',
                  required: true,
                  helperText: 'Enter first name of the user',
                  fullWidth: true,
                }}
                controller={{
                  control,
                  name: 'firstName',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                field={{
                  label: 'Last name',
                  required: true,
                  helperText: 'Enter last name of the user',
                  fullWidth: true,
                }}
                controller={{
                  control,
                  name: 'lastName',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                field={{
                  label: 'Email address',
                  type: 'email',
                  required: true,
                  helperText: 'Enter email address of the user',
                  fullWidth: true,
                }}
                controller={{
                  control,
                  name: 'emailAddress',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormSelect
                label="User group"
                name="group"
                helperText="Enter the user group"
                select={{
                  readOnly: isEditModal,
                  required: true,
                }}
                control={control}
                options={rolesAllowedToAssign.map((value) => (
                  <MenuItem key={value} value={value}>
                    {USER_GROUPS[value]}
                  </MenuItem>
                ))}
              />
            </Grid>
          </Section>
          <Section title="Company">
            {watchUserGroup === UserGroups.TECHNICIAN &&
              isPlatformLevelUser && (
                <Grid item xs={12}>
                  <FormHelperText>
                    <Trans
                      i18nKey="users.technicianCreationWarning"
                      ns="web"
                      components={{ b: <b /> }}
                    />
                  </FormHelperText>
                </Grid>
              )}
            <Grid item xs={6}>
              <FormAutoComplete
                control={control}
                name="companyId"
                autocomplete={{
                  getOptionLabel: (option) => {
                    return option.name
                  },
                  options: getCompaniesQuery.data,
                  fullWidth: true,
                  loading: getCompaniesQuery.isLoading,
                  readOnly: isEditModal || !getCanChangeCompanyId(),
                }}
                fieldProps={{
                  label: 'Company',
                }}
                add={{
                  label: 'Create new company',
                  onClick: () =>
                    openDialog(
                      <CreateCompanyDialogContent
                        callback={(id) => {
                          setValue('companyId', id)
                          closeTab()
                        }}
                      />,
                      'Create new company'
                    ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              {watchCompanyId && (
                <FormAutoComplete
                  control={control}
                  name="companyUnitId"
                  autocomplete={{
                    getOptionLabel: (option) => {
                      return option.name
                    },
                    options: getCompanyUnitsQuery.data || [],
                    fullWidth: true,
                    loading: getCompanyUnitsQuery.isPending,
                    loadingText: 'Loading...',
                    readOnly: isEditModal,
                  }}
                  fieldProps={{ label: 'Company unit' }}
                  add={{
                    label: 'Create new company unit',
                    onClick: () =>
                      openDialog(
                        <CreateCompanyUnitDialogContent
                          callback={(id) => {
                            setValue(`companyUnitId`, id)
                            closeTab()
                          }}
                          formData={{
                            companyId:
                              watchCompanyId === null
                                ? undefined
                                : watchCompanyId,
                          }}
                          readOnlyFields={
                            watchCompanyId == null ? [] : ['companyId']
                          }
                        />,
                        'Create company unit'
                      ),
                  }}
                />
              )}
            </Grid>
          </Section>
        </Stack>
      </DialogContent>
      <DialogActions>
        {!isEditModal && (
          <Button
            type="submit"
            variant="contained"
            onClick={handleSubmit(onCreateSubmit)}
            disabled={createUserMutation.isPending}
          >
            {t('users.createUser')}
          </Button>
        )}
        {isEditModal && (
          <Button
            type="submit"
            variant="contained"
            onClick={handleSubmit(onEditSubmit)}
            disabled={createUserMutation.isPending}
          >
            {t('users.saveUser')}
          </Button>
        )}
      </DialogActions>
    </>
  )
}

export default CreateUserDialogContent
