import { Media } from '@aeqoom/shared'
import useMediaStore from '@app/src/stores/useMediaStore'
import { Box, Grid, List } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'

import MediaGalleryCard from '../MediaGalleryCard'
import MediaListItem from '../MediaListItem'

type Props = {
  data: Media[]
  checkedMedia: number[]
  setCheckedMedia: Dispatch<SetStateAction<number[]>>
  isMultiple: boolean
}

const MediaGrid = ({
  data,
  isMultiple,
  checkedMedia,
  setCheckedMedia,
}: Props) => {
  const { isGrid } = useMediaStore()

  const onChange = (item: Media, isChecked: boolean) => {
    if (isChecked)
      if (isMultiple) {
        setCheckedMedia([...checkedMedia, item.id])
      } else {
        setCheckedMedia([item.id])
      }
    else {
      setCheckedMedia(checkedMedia.filter((prevItem) => prevItem !== item.id))
    }
  }

  return (
    <Box>
      {isGrid ? (
        <Grid container spacing={2}>
          {data.map((item) => {
            const isChecked = checkedMedia?.some(
              (checkedItem) => checkedItem === item.id
            )
            return (
              <Grid item xs={12} sm={6} lg={4} key={item.id}>
                <MediaGalleryCard
                  isChecked={isChecked}
                  item={item}
                  onChange={onChange}
                />
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <List sx={{ paddingY: 0 }}>
          {data.map((item) => {
            const isChecked = checkedMedia?.some(
              (checkedItem) => checkedItem === item.id
            )

            return (
              <MediaListItem
                key={item.id}
                isChecked={isChecked}
                value={item}
                onChange={onChange}
              />
            )
          })}
        </List>
      )}
    </Box>
  )
}

export default MediaGrid
