import {
  AddMachineTemplateRequest,
  DeleteMachineTemplateRequest,
  EditMachineTemplateRequest,
} from '@aeqoom/contracts'
import { PERMISSIONS } from '@aeqoom/permissions'
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import useUserStore from '../stores/useUserStore'
import { client } from './client'
import parseIncoming from './parseIncoming'

const keys = {
  getMachineTemplates: ['machine-templates'],
  getMachineTemplate: ['machine-template'],
}

const invalidateMachineTemplateQueries = (queryClient: QueryClient) => {
  const allKeys = Object.values(keys).flat()
  queryClient.invalidateQueries({
    predicate: (query) => allKeys.includes(query.queryKey[0] + ''),
  })
}

export const useGetMachineTemplates = () => {
  const { hasPermission } = useUserStore()

  return useQuery({
    enabled: hasPermission(PERMISSIONS.MACHINE_TEMPLATE.LIST),
    queryKey: keys.getMachineTemplates,
    queryFn: () =>
      client.machineTemplates.getMachineTemplates().then(parseIncoming),
  })
}

export const useGetMachineTemplate = ({
  id,
  enabled = !!id,
  staleTime,
}: {
  id?: number | null
  enabled?: boolean
  staleTime?: number
}) => {
  const { hasPermission } = useUserStore()

  return useQuery({
    enabled: enabled && hasPermission(PERMISSIONS.MACHINE_TEMPLATE.READ),
    queryKey: [...keys.getMachineTemplate, id],
    queryFn: () =>
      client.machineTemplates
        .getMachineTemplate({ params: { id: id! } })
        .then(parseIncoming),
    staleTime,
  })
}

export const useEditMachineTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateMachineTemplateQueries(queryClient)
    },
    mutationFn: async ({
      body,
      params,
    }: {
      body: EditMachineTemplateRequest['body']
      params: EditMachineTemplateRequest['params']
    }) =>
      client.machineTemplates
        .editMachineTemplate({ body, params })
        .then(parseIncoming),
  })
}

export const useCreateMachineTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateMachineTemplateQueries(queryClient)
    },
    mutationFn: (body: AddMachineTemplateRequest['body']) =>
      client.machineTemplates.addMachineTemplate({ body }).then(parseIncoming),
  })
}

export const useDeleteMachineTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: DeleteMachineTemplateRequest['params']) =>
      client.machineTemplates
        .deleteMachineTemplate({ params })
        .then(parseIncoming),
    onSuccess: () => {
      invalidateMachineTemplateQueries(queryClient)
    },
  })
}
