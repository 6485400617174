import { GetTasksTask, TaskStatus } from '@aeqoom/types'
import {
  ArchiveOutlined,
  BlockOutlined,
  CheckCircleOutline,
  ConstructionOutlined,
  CreateNewFolder,
  DomainVerification,
  EditOutlined,
  HowToRegOutlined,
  UndoOutlined,
  VisibilityOutlined,
} from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useTaskStatusColor } from './useTaskStatusColor'

const getStatusIcon = (status: GetTasksTask['status'], color: string) => {
  switch (status) {
    case TaskStatus.IN_PROGRESS:
      return <ConstructionOutlined sx={{ color, fontSize: 'inherit' }} />
    case TaskStatus.PLANNED:
      return <DomainVerification sx={{ color, fontSize: 'inherit' }} />
    case TaskStatus.WAITING_ON_WP:
      return <EditOutlined sx={{ color, fontSize: 'inherit' }} />
    case TaskStatus.TO_BE_CHECKED_BY_ADMIN:
      return <HowToRegOutlined sx={{ color, fontSize: 'inherit' }} />
    case TaskStatus.TO_BE_CHECKED_BY_CLIENT:
      return <HowToRegOutlined sx={{ color, fontSize: 'inherit' }} />
    case TaskStatus.RECURRED:
      return <UndoOutlined sx={{ color, fontSize: 'inherit' }} />
    case TaskStatus.NEW:
      return <CreateNewFolder sx={{ color, fontSize: 'inherit' }} />
    case TaskStatus.DONE:
      return <CheckCircleOutline sx={{ color, fontSize: 'inherit' }} />
    case TaskStatus.ACKNOWLEDGED:
      return <VisibilityOutlined sx={{ color, fontSize: 'inherit' }} />
    case TaskStatus.BLOCKED:
      return <BlockOutlined sx={{ color, fontSize: 'inherit' }} />
    case TaskStatus.ARCHIVED:
      return <ArchiveOutlined sx={{ color, fontSize: 'inherit' }} />
    default:
      return <ConstructionOutlined sx={{ color, fontSize: 'inherit' }} />
  }
}

const StatusIcon = ({
  status,
  type,
  plannedTo,
}: {
  status: GetTasksTask['status']
  type: GetTasksTask['type']
  plannedTo: Date | null
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'taskStatus' })
  const color = useTaskStatusColor(status, type, plannedTo)

  return <Tooltip title={t(status)}>{getStatusIcon(status, color)}</Tooltip>
}

export default StatusIcon
