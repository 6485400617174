import { AddCompanyUnitForm, addCompanyUnitForm } from '@aeqoom/forms'
import { PERMISSIONS } from '@aeqoom/permissions'
import { isObjectEmpty } from '@aeqoom/utils'
import CreateCompanyDialogContent from '@app/components/base/dialogs/createForms/CreateCompanyDialogContent'
import FormAutoComplete from '@app/components/base/form/FormAutoComplete'
import { FormAvatarField } from '@app/components/base/form/FormAvatarField'
import { FormTextField } from '@app/components/base/form/FormTextField'
import CheckPermissions from '@app/components/CheckPermissions'
import ContactList from '@app/components/ContactList'
import EditingProvider from '@app/components/providers/EditingProvider'
import Section from '@app/components/Section'
import { useGetCompanies } from '@app/queries/useCompanies'
import {
  useCreateCompanyUnit,
  useEditCompanyUnit,
  useGetCompanyUnit,
} from '@app/queries/useCompanyUnits'
import { useDialogStore } from '@app/stores/useDialogStore'
import { zodResolver } from '@hookform/resolvers/zod'
import { Business } from '@mui/icons-material'
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
} from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ActionControlButtons from '../ActionControlButtons'
import DialogDetailError from '../DialogDetailError'

type Props = {
  id?: number
  formData?: Partial<AddCompanyUnitForm>
  readOnly?: boolean
  isEditModal?: boolean
  callback?: (id: number) => void
  readOnlyFields?: (keyof AddCompanyUnitForm)[]
}

const CreateCompanyUnitDialogContent = ({
  readOnly = false,
  isEditModal = false,
  id,
  formData,
  readOnlyFields,
  callback,
}: Props) => {
  const [isEditing, setIsEditing] = useState(!readOnly)

  const { closeDialog, closeTab, openDialog, setShouldAskBeforeClose } =
    useDialogStore()

  const methods = useForm<AddCompanyUnitForm>({
    defaultValues: {
      name: '',
      contacts: [],
    },
    resolver: zodResolver(addCompanyUnitForm),
  })

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { dirtyFields },
  } = methods

  const getCompaniesQuery = useGetCompanies()
  const getCompanyUnitQuery = useGetCompanyUnit({
    id,
    staleTime: 0,
  })
  const createCompanyUnitMutation = useCreateCompanyUnit()
  const editCompanyUnitMutation = useEditCompanyUnit()

  useEffect(() => {
    setShouldAskBeforeClose(isObjectEmpty(dirtyFields))
  }, [isObjectEmpty(dirtyFields)])

  useEffect(() => {
    if (!getCompanyUnitQuery.isPending || !getCompanyUnitQuery.isError) {
      reset(getCompanyUnitQuery.data)
    }
    if (formData?.companyId) {
      setValue('companyId', formData.companyId)
    }
  }, [getCompanyUnitQuery.data, reset, formData, setValue])

  const onSubmit = async (data: AddCompanyUnitForm) => {
    createCompanyUnitMutation.mutate(data, {
      onSuccess: (data) => {
        callback ? callback(data.id) : closeDialog()
        toast.success(data.message)
        reset()
      },
    })
  }

  const onEditSubmit = async (data: AddCompanyUnitForm) => {
    editCompanyUnitMutation.mutate(
      { body: data, params: { id: id! } },
      {
        onSuccess: (data) => {
          if (callback) callback(data.id)
          toast.success(data.message)
          setIsEditing(false)
        },
      }
    )
  }

  if (getCompanyUnitQuery.isPending && readOnly)
    return (
      <Stack alignItems="center">
        <CircularProgress size={80} />
      </Stack>
    )

  if (getCompanyUnitQuery.isError)
    return <DialogDetailError onRetry={() => getCompanyUnitQuery.refetch()} />

  return (
    <FormProvider {...methods}>
      <EditingProvider isEditing={isEditing}>
        <DialogContent>
          <Stack spacing={8}>
            <Section title="General">
              <Grid item xs={12}>
                <FormAvatarField
                  control={control}
                  name="avatar"
                  readOnly={!isEditing}
                  placeHolderIcon={Business}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  field={{
                    label: 'Name',
                    required: true,
                    helperText: 'Enter name of company unit',
                    fullWidth: true,
                    InputProps: { readOnly: !isEditing },
                  }}
                  controller={{
                    control,
                    name: 'name',
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormAutoComplete
                  autocomplete={{
                    getOptionLabel: (option) => {
                      return option.name
                    },
                    options: getCompaniesQuery.data || [],
                    fullWidth: true,
                    readOnly:
                      !isEditing || readOnlyFields?.includes('companyId'),
                  }}
                  controller={{
                    control,
                    name: 'companyId',
                  }}
                  field={{ label: 'Company', required: true }}
                  add={{
                    tooltip: 'Create new company',
                    onClick: () =>
                      openDialog(
                        <CreateCompanyDialogContent
                          callback={(id) => {
                            setValue('companyId', id)
                            closeTab()
                          }}
                        />,
                        'Create company'
                      ),
                  }}
                />
              </Grid>
            </Section>
            <Section title="Contacts">
              <Grid item xs={12}>
                <ContactList />
              </Grid>
            </Section>
            <Section title="Location">
              <Grid item xs={12}>
                <FormTextField
                  field={{
                    label: 'Street',
                    required: true,
                    fullWidth: true,
                    InputProps: { readOnly: !isEditing },
                  }}
                  controller={{
                    control,
                    name: 'street',
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  field={{
                    label: 'House number',
                    required: true,
                    fullWidth: true,
                    InputProps: { readOnly: !isEditing },
                  }}
                  controller={{
                    control,
                    name: 'houseNumber',
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  field={{
                    label: 'ZIP Code',
                    required: true,
                    fullWidth: true,
                    InputProps: { readOnly: !isEditing },
                  }}
                  controller={{
                    control,
                    name: 'zipCode',
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  field={{
                    label: 'City',
                    required: true,
                    fullWidth: true,
                    InputProps: { readOnly: !isEditing },
                  }}
                  controller={{
                    control,
                    name: 'city',
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  field={{
                    label: 'Country',
                    required: true,
                    fullWidth: true,
                    InputProps: { readOnly: !isEditing },
                  }}
                  controller={{
                    control,
                    name: 'country',
                  }}
                />
              </Grid>
            </Section>
          </Stack>
        </DialogContent>
        <CheckPermissions permissions={[PERMISSIONS.COMPANY_UNIT.UPDATE]}>
          <DialogActions>
            {isEditModal || (
              <Button
                type="submit"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={createCompanyUnitMutation.isPending}
              >
                Create company unit
              </Button>
            )}
            {isEditModal && (
              <ActionControlButtons
                isEditing={isEditing}
                onSave={handleSubmit(onEditSubmit)}
                onEdit={() => setIsEditing(true)}
                disabled={editCompanyUnitMutation.isPending}
              />
            )}
          </DialogActions>
        </CheckPermissions>
      </EditingProvider>
    </FormProvider>
  )
}

export default CreateCompanyUnitDialogContent
