import {
  FormAutoComplete,
  FormDatePicker,
  FormNumberField,
  FormSelect,
} from '@aeqoom/components-web'
import {
  AddComponentInstanceRequest,
  EditComponentInstanceRequest,
} from '@aeqoom/contracts'
import { CounterState } from '@aeqoom/db'
import { PERMISSIONS } from '@aeqoom/permissions'
import { isObjectEmpty } from '@aeqoom/utils'
import CreateCompanyDialogContent from '@app/components/base/dialogs/createForms/CreateCompanyDialogContent'
import CreateCompanyUnitDialogContent from '@app/components/base/dialogs/createForms/CreateCompanyUnitDialogContent'
import CreateComponentTemplateDialogContent from '@app/components/base/dialogs/createForms/CreateComponentTemplateDialogContent'
import CreateMachineInstanceDialogContent from '@app/components/base/dialogs/createForms/CreateMachineInstanceDialogContent'
import { FormAvatarField } from '@app/components/base/form/FormAvatarField'
import { FormTextField } from '@app/components/base/form/FormTextField'
import { FormUploadField } from '@app/components/base/form/FormUploadField'
import MachineInstanceAutoComplete from '@app/components/base/form/MachineInstanceAutoComplete'
import CheckPermissions from '@app/components/CheckPermissions'
import Section from '@app/components/Section'
import { useGetCompanies } from '@app/queries/useCompanies'
import { useGetCompanyUnits } from '@app/queries/useCompanyUnits'
import {
  useCreateComponentInstance,
  useEditComponentInstance,
  useGetComponentInstance,
} from '@app/queries/useComponentInstances'
import { useGetComponentTemplates } from '@app/queries/useComponentTemplates'
import { Errors } from '@app/src/types'
import { COUNTER_STATE } from '@app/src/utils/labels'
import { useDialogStore } from '@app/stores/useDialogStore'
import { Biotech } from '@mui/icons-material'
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Stack,
} from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import toast from 'react-hot-toast'

import ActionControlButtons from '../ActionControlButtons'
import DialogDetailError from '../DialogDetailError'
import { defaultValues } from './defaultValues'

type Props = {
  id?: AddComponentInstanceRequest['body']['id']
  formData?: Partial<AddComponentInstanceRequest['body']>
  readOnly?: boolean
  isEditModal?: boolean
  readOnlyFields?: (keyof AddComponentInstanceRequest['body'])[]
  callback?: (id: number) => void
}

const CreateComponentInstanceDialogContent = ({
  readOnly = false,
  isEditModal = false,
  id,
  formData,
  readOnlyFields,
  callback,
}: Props) => {
  const [isEditing, setIsEditing] = useState(!readOnly)

  const { closeDialog, setShouldAskBeforeClose, openDialog, closeTab } =
    useDialogStore()

  const methods = useForm<AddComponentInstanceRequest['body']>({
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { dirtyFields },
  } = methods

  const { companyId: watchCompanyId } = useWatch({ control })
  const getComponentTemplatesQuery = useGetComponentTemplates()
  const getCompaniesQuery = useGetCompanies()
  const getComponentInstanceQuery = useGetComponentInstance({
    id,
    staleTime: 0,
  })
  const getCompanyUnitsQuery = useGetCompanyUnits({
    companyId: watchCompanyId,
  })
  const mutation = useCreateComponentInstance()
  const editMutation = useEditComponentInstance()

  useEffect(() => {
    setShouldAskBeforeClose(isObjectEmpty(dirtyFields))
  }, [isObjectEmpty(dirtyFields)])

  useEffect(() => {
    if (
      !getComponentInstanceQuery.isPending ||
      !getComponentInstanceQuery.isError
    ) {
      reset(getComponentInstanceQuery.data)
    }
    if (formData?.companyId) {
      setValue('companyId', formData.companyId)
    }
    if (formData?.machineInstanceId) {
      setValue('machineInstanceId', formData.machineInstanceId)
    }
    if (formData?.companyUnitId) {
      setValue('companyUnitId', formData.companyUnitId)
    }
  }, [getComponentInstanceQuery.data, reset, formData, setValue])

  const onSubmit = async (data: AddComponentInstanceRequest['body']) => {
    mutation.mutate(data, {
      onSuccess: (data) => {
        callback ? callback(data.id) : closeDialog()
        toast.success(data.message)
        reset()
      },
    })
  }

  const onEditSubmit = async (data: EditComponentInstanceRequest['body']) => {
    editMutation.mutate(
      {
        body: data,
        params: { id: data.id! },
      },
      {
        onSuccess: (response) => {
          if (callback && data.id) callback(data.id)
          toast.success(response.message)
          setIsEditing(false)
        },
        onError: () => toast.error('Something went wrong'),
      }
    )
  }

  if (getComponentInstanceQuery.isPending && readOnly)
    return (
      <Stack alignItems="center">
        <CircularProgress size={80} />
      </Stack>
    )

  if (getComponentInstanceQuery.isError)
    return (
      <DialogDetailError onRetry={() => getComponentInstanceQuery.refetch()} />
    )

  const readOnlyFieldsMachineInstance = [
    ...(formData?.companyId ? (['companyId'] as const) : []),
    ...(formData?.companyUnitId ? (['companyUnitId'] as const) : []),
  ]

  return (
    <>
      <DialogContent>
        <FormProvider {...methods}>
          <Stack direction="column" spacing={8}>
            <Section title="Basic information">
              <Grid item xs={12}>
                <FormAvatarField
                  control={control}
                  name="avatar"
                  variant="rounded"
                  height={256}
                  fullWidth
                  readOnly={!isEditing}
                  placeHolderIcon={Biotech}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  controller={{
                    control,
                    name: 'name',
                    rules: { required: true },
                  }}
                  field={{
                    label: 'Name',
                    fullWidth: true,
                    required: true,
                    InputProps: { readOnly: !isEditing },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  field={{
                    label: 'Inventory number',
                    required: true,
                    fullWidth: true,
                    inputProps: {
                      readOnly: !isEditing,
                    },
                  }}
                  controller={{
                    rules: { required: Errors.REQUIRED },
                    control,
                    name: 'inventoryNumber',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormAutoComplete
                  control={control}
                  name="componentTemplateId"
                  autocomplete={{
                    getOptionLabel: (option) => {
                      return option.name!
                    },
                    readOnly: !isEditing,
                    options: getComponentTemplatesQuery.data,
                    loading: getComponentTemplatesQuery.isPending,
                    fullWidth: true,
                  }}
                  fieldProps={{ label: 'Component template' }}
                  add={{
                    label: 'Create new component template',
                    onClick: () =>
                      openDialog(
                        <CreateComponentTemplateDialogContent
                          callback={(id) => {
                            setValue('componentTemplateId', id)
                            closeTab()
                          }}
                        />,
                        'Create new component template'
                      ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormDatePicker
                  controller={{
                    control,
                    name: 'yearProduced',
                    rules: { required: Errors.REQUIRED },
                  }}
                  datePicker={{
                    label: 'Year produced',
                    views: ['year'],
                    readOnly: !isEditing,
                    timezone: 'UTC',
                  }}
                  field={{ fullWidth: true, required: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormDatePicker
                  controller={{
                    control,
                    name: 'warrantyUntil',
                    rules: { required: Errors.REQUIRED },
                  }}
                  datePicker={{
                    label: 'Warranty until',
                    readOnly: !isEditing,
                    timezone: 'UTC',
                  }}
                  field={{ fullWidth: true, required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  controller={{
                    control,
                    name: 'description',
                    rules: { required: true },
                  }}
                  field={{
                    label: 'Description',
                    fullWidth: true,
                    required: true,
                    rows: 4,
                    multiline: true,
                    InputProps: { readOnly: !isEditing },
                  }}
                />
              </Grid>
            </Section>
            <Section title="Counter">
              <Grid item xs={6}>
                <FormNumberField
                  control={control}
                  name="counterState"
                  label="Counter state"
                  fullWidth
                  required
                  InputProps={{ readOnly: !isEditing }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormSelect
                  label="Counter type"
                  select={{ readOnly: !isEditing }}
                  name="counterType"
                  control={control}
                  options={Object.values(CounterState).map((value) => (
                    <MenuItem key={value} value={value}>
                      {COUNTER_STATE[value]}
                    </MenuItem>
                  ))}
                />
              </Grid>
            </Section>
            <Section title="Company">
              <Grid item xs={6}>
                <FormAutoComplete
                  control={control}
                  rules={{ required: Errors.REQUIRED }}
                  name="companyId"
                  autocomplete={{
                    getOptionLabel: (option) => {
                      return option.name!
                    },
                    options: getCompaniesQuery.data,
                    loading: getCompaniesQuery.isLoading,
                    fullWidth: true,
                    readOnly:
                      readOnlyFields?.includes('companyId') || !isEditing,
                  }}
                  fieldProps={{ label: 'Company', required: true }}
                  add={{
                    label: 'Create new company',
                    onClick: () =>
                      openDialog(
                        <CreateCompanyDialogContent
                          callback={(id) => {
                            setValue('companyId', id)
                            closeTab()
                          }}
                        />,
                        'Create new company'
                      ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormAutoComplete
                  control={control}
                  rules={{ required: Errors.REQUIRED }}
                  name="companyUnitId"
                  autocomplete={{
                    getOptionLabel: (option) => {
                      return option.name!
                    },
                    options: getCompanyUnitsQuery.data,
                    loading: getCompanyUnitsQuery.isLoading,
                    fullWidth: true,
                    readOnly:
                      readOnlyFields?.includes('companyUnitId') || !isEditing,
                    disabled: !watchCompanyId,
                  }}
                  fieldProps={{
                    label: 'Company unit',
                    required: true,
                  }}
                  add={{
                    label: 'Create new company unit',
                    onClick: () =>
                      openDialog(
                        <CreateCompanyUnitDialogContent
                          callback={(id) => {
                            setValue(`companyUnitId`, id)
                            closeTab()
                          }}
                          formData={{
                            companyId:
                              watchCompanyId === null
                                ? undefined
                                : watchCompanyId,
                          }}
                          readOnlyFields={
                            watchCompanyId == null ? [] : ['companyId']
                          }
                        />,
                        'Create company unit'
                      ),
                  }}
                />
              </Grid>
            </Section>
            <Section title="Machine">
              <Grid item xs={12}>
                <MachineInstanceAutoComplete
                  path="machineInstanceId"
                  fieldProps={{ label: 'Machine' }}
                  autocomplete={{
                    fullWidth: true,
                    readOnly:
                      readOnlyFields?.includes('machineInstanceId') ||
                      !isEditing,
                  }}
                  add={{
                    label: 'Create new machine instance',
                    onClick: () =>
                      openDialog(
                        <CreateMachineInstanceDialogContent
                          formData={{
                            companyId: formData?.companyId,
                            companyUnitId: formData?.companyUnitId,
                          }}
                          readOnlyFields={readOnlyFieldsMachineInstance}
                          callback={(id) => {
                            setValue('machineInstanceId', id)
                            closeTab()
                          }}
                        />,
                        'Create new machine instance'
                      ),
                  }}
                />
              </Grid>
            </Section>
            <Section title="Media">
              <Grid item xs={12}>
                <FormUploadField
                  control={control}
                  name="media"
                  isEditing={isEditing}
                  defaultAccessLevel={['company', 'companyUnit']}
                  disableAccessLevel
                />
              </Grid>
            </Section>
          </Stack>
        </FormProvider>
      </DialogContent>
      {isEditModal || (
        <CheckPermissions permissions={[PERMISSIONS.COMPONENT_INSTANCE.CREATE]}>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={mutation.isPending}
            >
              Create component instance
            </Button>
          </DialogActions>
        </CheckPermissions>
      )}
      {isEditModal && (
        <CheckPermissions permissions={[PERMISSIONS.COMPONENT_INSTANCE.UPDATE]}>
          <DialogActions>
            <ActionControlButtons
              isEditing={isEditing}
              onSave={handleSubmit(onEditSubmit)}
              onEdit={() => setIsEditing(true)}
              disabled={editMutation.isPending}
            />
          </DialogActions>
        </CheckPermissions>
      )}
    </>
  )
}

export default CreateComponentInstanceDialogContent
