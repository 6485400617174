import {
  Alert,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import TableRowsLoader from '../TableRowsLoader'

type Contact = {
  id: number
  firstName: string
  lastName: string
  position: string
  emailAddress: string
  phoneNumber: string
}

type Props = {
  contacts?: {
    main: boolean | null
    contact: Contact
  }[]
  isPending: boolean
  isError: boolean
}

const ContactView = ({ contacts, isError, isPending }: Props) => {
  const { t } = useTranslation('web', { keyPrefix: 'contacts' })

  if (isError) return <Alert color="error">{t('failedToLoad')}</Alert>

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>{t('firstName')}</TableCell>
            <TableCell>{t('lastName')}</TableCell>
            <TableCell>{t('position')}</TableCell>
            <TableCell>{t('emailAddress')}</TableCell>
            <TableCell>{t('phoneNumber')}</TableCell>
            <TableCell align="center">{t('mainContact')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isPending ? (
            <TableRowsLoader colsNum={7} />
          ) : (
            contacts?.map(({ main, contact }) => (
              <TableRow
                key={contact.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {contact.id}
                </TableCell>
                <TableCell>{contact.firstName}</TableCell>
                <TableCell>{contact.lastName}</TableCell>
                <TableCell>{contact.position}</TableCell>
                <TableCell>{contact.emailAddress}</TableCell>
                <TableCell>{contact.phoneNumber}</TableCell>
                <TableCell align="center">
                  {main ? (
                    <Chip label={t('mainContact')} color="primary" />
                  ) : (
                    '-'
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ContactView
