import { PERMISSIONS } from '@aeqoom/permissions'
import CheckPermissions from '@app/components/CheckPermissions'
import ErrorScreen from '@app/components/ErrorScreen'
import { useGetContact } from '@app/queries/useContacts'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import CreateContactDialogContent from '../../createForms/CreateContactDialogContent'
import Content from './Content'

type Props = {
  id?: number
}

const ContactDialog = ({ id }: Props) => {
  const { isPending, isError, refetch, data } = useGetContact({
    id,
  })

  const { t } = useTranslation('web', { keyPrefix: 'contact.detail' })

  const { openDialog, openPreviousDialog } = useDialogStore()

  if (isPending)
    return (
      <DialogContent>
        <Stack alignItems="center" justifyContent="center" height="100%">
          <CircularProgress />
        </Stack>
      </DialogContent>
    )

  if (isError)
    return (
      <DialogContent>
        <Stack alignItems="center" justifyContent="center" height="100%">
          <ErrorScreen refetch={refetch} />
        </Stack>
      </DialogContent>
    )

  return (
    <>
      <Content data={data} />
      <CheckPermissions permissions={[PERMISSIONS.CONTACT.UPDATE]}>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              openDialog(
                <CreateContactDialogContent
                  id={id}
                  isEditModal
                  callback={() => {
                    openPreviousDialog()
                  }}
                />,
                'Edit contact'
              )
            }}
          >
            {t('actions.edit')}
          </Button>
        </DialogActions>
      </CheckPermissions>
    </>
  )
}

export default ContactDialog
