import { z } from '@aeqoom/zod'
import { initContract } from '@ts-rest/core'

import { contactsRouter } from './contracts'
import authRouter from './contracts/auth'
import backlogRouter from './contracts/backlog'
import companiesRouter from './contracts/companies'
import companyUnitsRouter from './contracts/company-units'
import componentInstancesRouter from './contracts/component-instances'
import componentTemplatesRouter from './contracts/component-templates'
import dashboardRouter from './contracts/dashboard'
import machineInstancesRouter from './contracts/machine-instances'
import machineTemplatesRouter from './contracts/machine-templates'
import mediaRouter from './contracts/media'
import qrcodeRouter from './contracts/qrcode'
import serviceLevelAgreementsRouter from './contracts/service-level-agreements'
import serviceLogRouter from './contracts/service-log'
import taskTemplatesRouter from './contracts/task-templates'
import tasksRouter from './contracts/tasks'
import toDoListItemsRouter from './contracts/to-do-list-items'
import toDoListsRouter from './contracts/to-do-lists'
import usersRouter from './contracts/users'
import workProtocolsRouter from './contracts/work-protocols'

const c = initContract()

export const contract = c.router(
  {
    auth: authRouter,
    backlog: backlogRouter,
    companies: companiesRouter,
    companyUnits: companyUnitsRouter,
    componentInstances: componentInstancesRouter,
    componentTemplates: componentTemplatesRouter,
    machineInstances: machineInstancesRouter,
    machineTemplates: machineTemplatesRouter,
    taskTemplates: taskTemplatesRouter,
    tasks: tasksRouter,
    toDoLists: toDoListsRouter,
    toDoListItems: toDoListItemsRouter,
    users: usersRouter,
    serviceLevelAgreements: serviceLevelAgreementsRouter,
    dashboard: dashboardRouter,
    workProtocols: workProtocolsRouter,
    qrcode: qrcodeRouter,
    contacts: contactsRouter,
    media: mediaRouter,
    serviceLog: serviceLogRouter,
  },
  {
    strictStatusCodes: true,
    baseHeaders: z.object({
      authorization: z.string().nullable().optional(),
    }),
  }
)

export type MutationResult = { id: number; message: string }
