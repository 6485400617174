import { Portrait } from '@mui/icons-material'
import { Avatar, AvatarProps, SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { useTheme } from '@mui/material/styles'

import AvatarIcon from '../AvatarIcon'

type Props = {
  src?: string | null
  width?: number
  height?: number
  avatarProps?: AvatarProps
  placeHolderIcon?: OverridableComponent<SvgIconTypeMap>
  variant?: 'bordered' | 'background'
}

const CustomAvatar = ({
  src,
  width = 32,
  height = 32,
  avatarProps,
  placeHolderIcon = Portrait,
  variant = 'background',
}: Props) => {
  const theme = useTheme()

  return (
    <Avatar
      src={src || undefined}
      sx={{
        width,
        height,
        ...(variant === 'bordered' && {
          backgroundColor: theme.palette.common.white,
          border: 1,
          borderColor: theme.palette.grey[300],
        }),
      }}
      {...avatarProps}
    >
      <AvatarIcon icon={placeHolderIcon} />
    </Avatar>
  )
}

export default CustomAvatar
