import AccordionActions, {
  AccordionActionsProps,
} from '@mui/material/AccordionActions'
import React from 'react'

const Actions = ({ children, sx = {}, ...rest }: AccordionActionsProps) => {
  return (
    <AccordionActions
      {...rest}
      sx={{
        padding: 2,
        ...sx,
      }}
    >
      {children}
    </AccordionActions>
  )
}

export default Actions
