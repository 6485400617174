import { LabeledValue } from '@aeqoom/components-web'
import { UpdateProfile, useUpdateProfile } from '@aeqoom/forms'
import { FormAvatarField } from '@app/components/base/form/FormAvatarField'
import ErrorScreen from '@app/components/ErrorScreen'
import { useEditProfile, useGetMe } from '@app/queries/useUsers'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  DialogContent,
  Grid,
  Stack,
} from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import UpdatePassword from './UpdatePassword'

const Profile = () => {
  const { t } = useTranslation()

  const updateProfileSchema = useUpdateProfile()

  const { handleSubmit, reset, control } = useForm<UpdateProfile>({
    resolver: zodResolver(updateProfileSchema),
    defaultValues: {
      avatar: null,
    },
  })

  const { data, isError, isPending, refetch } = useGetMe()

  const editProfileMutation = useEditProfile()

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data, reset])

  const editProfile = (data: UpdateProfile) => {
    editProfileMutation.mutate(data, {
      onSuccess: () => {
        toast.success(t('profile.successfullyUpdated'))
      },
    })
  }

  if (isPending)
    return (
      <DialogContent>
        <Stack alignItems="center" justifyContent="center" height="100%">
          <CircularProgress />
        </Stack>
      </DialogContent>
    )

  if (isError)
    return (
      <DialogContent>
        <Stack alignItems="center" justifyContent="center" height="100%">
          <ErrorScreen refetch={refetch} />
        </Stack>
      </DialogContent>
    )

  return (
    <Container>
      <Card>
        <CardContent sx={{ px: 4, py: 6 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} paddingBottom={2}>
              <FormAvatarField
                control={control}
                name="avatar"
                width={128}
                height={128}
                helperText=""
                defaultAddedByMe={true}
                disableAddedByMe={true}
              />
            </Grid>
            <Grid item xs={12}>
              <LabeledValue
                label={t('profile.emailAddress')}
                value={data.emailAddress}
              />
            </Grid>
            <Grid item xs={6}>
              <LabeledValue
                label={t('profile.firstName')}
                value={data.firstName}
              />
            </Grid>
            <Grid item xs={6}>
              <LabeledValue
                label={t('profile.lastName')}
                value={data.lastName}
              />
            </Grid>
            <Grid item xs={6}>
              <LabeledValue
                label={t('profile.company')}
                value={data.company?.name}
              />
            </Grid>
            <Grid item xs={6}>
              <LabeledValue
                label={t('profile.companyUnit')}
                value={data.companyUnit?.name}
              />
            </Grid>
            <Grid item xs={6}>
              <UpdatePassword />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', px: 4, py: 6 }}>
          <Button variant="contained" onClick={handleSubmit(editProfile)}>
            {t('profile.save')}
          </Button>
        </CardActions>
      </Card>
    </Container>
  )
}

export default Profile
