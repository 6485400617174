import { useThemeColors } from '@aeqoom/hooks'
import { Stack } from '@mui/material'
import dayjs from 'dayjs'

const DayHeader = ({ date }: { date: Date }) => {
  const day = dayjs(date)

  const { background, primary, text } = useThemeColors()

  const backgroundColor = day.isSame(dayjs(), 'day')
    ? primary.main
    : background.default

  const color = day.isSame(dayjs(), 'day') ? primary.contrastText : text.primary

  return (
    <Stack sx={{ padding: 1 }} direction="row" alignItems="center" gap={1}>
      {day.format('ddd')}
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor,
          color,
          width: '38px',
          height: '38px',
          borderRadius: '50%',
        }}
      >
        {day.format('D')}
      </Stack>
    </Stack>
  )
}

export default DayHeader
