import { GridMapper, LabeledValue } from '@aeqoom/components-web'
import { CompanyResponse } from '@aeqoom/contracts'
import { useGetTranslations } from '@aeqoom/hooks'
import { serializeAddress } from '@aeqoom/utils'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CoverImage from '../../../../_components/CoverImage'
import { FIELD_SHARED_STYLES } from '../../../../constants'

type Props = {
  data: CompanyResponse
}

const GRID_MAP = [6, 5, 6, 5, 6, 5]

const CompanyInfo = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'company.detail.tabs.companyDetail.content',
  })

  const { companyTypeTranslation } = useGetTranslations()

  const content = [
    <LabeledValue
      label={t('name')}
      value={data.name}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('registrationNumber')}
      value={data.registrationNumber}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('companyType')}
      value={companyTypeTranslation(data.companyType)}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('taxId')}
      value={data.taxId}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('address')}
      value={serializeAddress({
        street: data.street,
        city: data.city,
        zipCode: data.zipCode,
        country: data.country,
      })}
      sx={FIELD_SHARED_STYLES}
      type="multi-line"
    />,
    <LabeledValue
      label={t('currency')}
      value={data.currency}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
  ]

  return (
    <Grid container justifyContent="space-between">
      <Grid
        item
        xs={6}
        sx={{
          overflow: 'hidden',
        }}
      >
        <GridMapper template={GRID_MAP} paddingBottom={8}>
          {content}
        </GridMapper>
      </Grid>
      <Grid xs={5}>
        <CoverImage avatar={data.avatar} />
      </Grid>
    </Grid>
  )
}

export default CompanyInfo
