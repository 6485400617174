import { CompanyResponse } from '@aeqoom/contracts'
import DialogContent from '@app/components/base/dialogs/_detailViewDialogs/_components/DialogContent'
import ContactCompany from '@app/components/ContactCompany'
import { useTranslation } from 'react-i18next'

import CompanyInfo from './_tabs/CompanyInfo'

type Props = {
  data: CompanyResponse
}

const Content = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'company.detail',
  })

  const TABS = [
    {
      component: (data: CompanyResponse) => <CompanyInfo data={data} />,
      header: t('tabs.companyDetail.label'),
    },
    {
      component: (data: CompanyResponse) => (
        <ContactCompany companyId={data.id} />
      ),
      header: t('tabs.contacts.label'),
    },
  ]

  return (
    <DialogContent
      title={t('header.title', { name: data.name })}
      tabs={TABS}
      data={data}
    />
  )
}

export default Content
