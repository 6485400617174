import { TaskPriority, TaskStatus } from '@aeqoom/db'
import {
  ANTHRACITE,
  BACKGROUND,
  ERROR,
  ERROR_DARK,
  ERROR_LIGHT,
  INFO,
  INFO_DARK,
  INFO_LIGHT,
  LIGHT_GRAY,
  PASTEL_RED,
  PRIMARY,
  PRIMARY_DARK,
  PRIMARY_LIGHT,
  RED,
  SECONDARY,
  SECONDARY_DARK,
  SECONDARY_LIGHT,
  SILVER,
  SUCCESS,
  SUCCESS_DARK,
  SUCCESS_LIGHT,
  TEXT,
  TEXT_SECONDARY,
  WARNING,
  WARNING_DARK,
  WARNING_LIGHT,
  WHITE,
} from '@aeqoom/styles'
import { BORDER_RADIUS, DEFAULT_SPACING } from '@app/styles/constans'
import { colors, createTheme } from '@mui/material'

export const theme = createTheme({
  spacing: DEFAULT_SPACING,
  shape: {
    borderRadius: 8,
  },
  transforms: {
    scale: 'scale3d(1.02, 1.02, 1)',
  },
  constants: {
    appBarHeight: 64,
    drawerWidthOpen: 240,
    drawerWidthClosed: 64,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      '2xl': 1800,
    },
  },
  typography: {
    fontFamily: 'Noto Sans, sans-serif',
    body3Medium: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 400,
    },
    body3Regular: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 500,
    },
    body3Bold: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 700,
    },

    body2Regular: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 400,
      '@media (min-width:600px)': {
        lineHeight: '1.25rem',
        fontSize: '0.875rem',
      },
    },
    body2Medium: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 500,
      '@media (min-width:600px)': {
        lineHeight: '1.25rem',
        fontSize: '0.875rem',
      },
    },
    body2Bold: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 700,
      '@media (min-width:600px)': {
        lineHeight: '1.25rem',
        fontSize: '0.875rem',
      },
    },

    body1Regular: {
      fontSize: '0.875rem',
      lineHeight: '1.2rem',
      fontWeight: 400,
      '@media (min-width:600px)': {
        lineHeight: '1.5rem',
        fontSize: '1rem',
      },
    },
    body1Medium: {
      fontSize: '0.875rem',
      lineHeight: '1.2rem',
      fontWeight: 400,
      '@media (min-width:600px)': {
        lineHeight: '1.5rem',
        fontSize: '1rem',
      },
    },
    body1Bold: {
      fontSize: '0.875rem',
      lineHeight: '1.2rem',
      fontWeight: 700,
      '@media (min-width:600px)': {
        lineHeight: '1.5rem',
        fontSize: '1rem',
      },
    },
  },
  palette: {
    text: {
      primary: TEXT,
      secondary: TEXT_SECONDARY,
    },
    primary: {
      main: PRIMARY,
      light: PRIMARY_LIGHT,
      dark: PRIMARY_DARK,
    },
    secondary: {
      main: SECONDARY,
      light: SECONDARY_LIGHT,
      dark: SECONDARY_DARK,
    },
    success: {
      main: SUCCESS,
      light: SUCCESS_LIGHT,
      dark: SUCCESS_DARK,
    },
    info: {
      main: INFO,
      light: INFO_LIGHT,
      dark: INFO_DARK,
    },
    error: {
      main: ERROR,
      light: ERROR_LIGHT,
      dark: ERROR_DARK,
    },
    warning: {
      main: WARNING,
      light: WARNING_LIGHT,
      dark: WARNING_DARK,
    },
    background: {
      default: BACKGROUND,
    },

    status: {
      [TaskStatus.NEW]: {
        main: '#67C98F',
        contrastText: WHITE,
      },
      [TaskStatus.RECURRED]: {
        main: '#CBCBCB',
        contrastText: TEXT,
      },
      [TaskStatus.ACKNOWLEDGED]: {
        main: '#D9CCED',
        contrastText: TEXT,
      },
      [TaskStatus.IN_PROGRESS]: {
        main: '#D9CCED',
        contrastText: PRIMARY,
      },
      [TaskStatus.PLANNED]: {
        main: '#67C98F',
        contrastText: WHITE,
      },
      [TaskStatus.WAITING_ON_WP]: {
        main: '#CBCBCB',
        contrastText: WHITE,
      },
      [TaskStatus.TO_BE_CHECKED_BY_CLIENT]: {
        main: '#FFCF97',
        contrastText: TEXT,
      },
      [TaskStatus.TO_BE_CHECKED_BY_ADMIN]: {
        main: '#FFCF97',
        contrastText: TEXT,
      },
      [TaskStatus.BLOCKED]: {
        main: '#F39393',
        contrastText: WHITE,
      },
      [TaskStatus.ARCHIVED]: {
        main: '#F5F5F5',
        contrastText: '#919191',
      },
      [TaskStatus.DONE]: {
        main: '#37424A',
        contrastText: WHITE,
      },
    },
    priority: {
      [TaskPriority.LOW]: {
        main: LIGHT_GRAY,
        contrastText: TEXT,
      },
      [TaskPriority.MEDIUM]: {
        main: '#FDF3D9',
        contrastText: '#CF9500',
      },
      [TaskPriority.HIGH]: {
        main: '#FFD5CF',
        contrastText: RED,
      },
    },
    green: {
      main: PRIMARY,
      hover: '#01E960',
      light: '#E0F4E9',
      dark: '#CCEDDA',
    },
    red: {
      main: RED,
      pastel: PASTEL_RED,
      skeleton: '#D55151',
    },
    anthracite: {
      main: ANTHRACITE,
      skeleton: '#4B545C',
    },
    darkBlue: {
      main: TEXT,
    },
    silver: {
      main: SILVER,
    },
    lightGray: {
      main: LIGHT_GRAY,
      skeleton: '#e8e8e8',
    },
    white: {
      main: WHITE,
    },
    black: {
      main: '#000000',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      ::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        transition: background-color 0.2s ease;
      }
      ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.5);
      }
      `,
    },
    MuiTypography: {
      defaultProps: {
        variant: 'body1Regular',
        variantMapping: {
          body1Medium: 'p',
          body1Regular: 'p',
          body1Bold: 'p',
          body2Medium: 'p',
          body2Regular: 'p',
          body2Bold: 'p',
          body3Medium: 'p',
          body3Regular: 'p',
          body3Bold: 'p',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.grey[300],
        },
      },
    },
    MuiCard: {
      defaultProps: { variant: 'elevation', elevation: 0 },
      styleOverrides: {
        root: {
          borderRadius: `${BORDER_RADIUS * 2}px`,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: colors.grey[300],
          },
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { color: 'white' },
          style: {
            color: '#ffffff',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
            },
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        icon: {
          width: `${DEFAULT_SPACING * 2}px`,
          marginLeft: `${DEFAULT_SPACING}px`,
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        slotProps: {
          transition: {
            unmountOnExit: true,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: { minWidth: 0 },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderRadius: `${BORDER_RADIUS}px`,
        },
      },
    },
    MuiDialogContent: {
      defaultProps: { sx: { padding: 4 }, dividers: true },
    },
    MuiDialogActions: {
      defaultProps: { sx: { padding: 2 } },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            borderRadius: `${BORDER_RADIUS * 2}px`,
          },
        },
      },
    },
  },
})
