type NameAndPosition = {
  firstName: string | null
  lastName: string | null
  emailAddress: string
  group: string
}

export const formatUserNamePosition = <T extends NameAndPosition>(
  user: T
): string => {
  if (user.firstName === null || user.lastName === null)
    return `${user.emailAddress} - ${user.group}`
  return `${user.firstName} ${user.lastName} - ${user.group}`
}
