import { z } from '@aeqoom/zod'

export const globalFilter = z.object({
  'g.companyId': z
    .union([z.coerce.number(), z.array(z.coerce.number())])
    .optional()
    .nullable(),
  'g.companyUnitId': z
    .union([z.coerce.number(), z.array(z.coerce.number())])
    .optional()
    .nullable(),
})

export type GlobalFilter = z.infer<typeof globalFilter>
