import DrawerItem from '@app/components/AdminLayout/DrawerItem'
import useUserStore from '@app/stores/useUserStore'
import { theme } from '@app/styles/theme'
import { DrawerItem as DrawerItemType, DrawerSubItem } from '@app/types'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

type Props = {
  item: DrawerItemType
  isOpenDrawer: boolean
  subItems: DrawerSubItem[]
  handleOpenDrawer: (isOpen: boolean) => void
}

const DrawerItemNested = ({
  item: { name, icon },
  isOpenDrawer,
  subItems,
  handleOpenDrawer,
}: Props) => {
  const { pathname } = useLocation()
  const [isOpenList, setIsOpenList] = useState(true)
  const [subItemsWithPermissions, setSubItemsWithPermissions] = useState<
    DrawerSubItem[]
  >([])
  const [isOneOfItemsSelected, setIsOneOfItemsSelected] = useState(false)

  const { hasPermission } = useUserStore()

  const handleClickParent = (shouldOpenDrawer: boolean) => {
    handleOpenDrawer(shouldOpenDrawer)
    setIsOpenList(!isOpenList)
  }

  useEffect(() => {
    if (!isOpenDrawer) setIsOpenList(false)
  }, [isOpenDrawer])

  useEffect(() => {
    setSubItemsWithPermissions(
      subItems.filter(
        (item) => !item.permission || hasPermission(item.permission)
      )
    )
  }, [])

  useEffect(() => {
    setIsOneOfItemsSelected(
      subItemsWithPermissions.some((item) => item.to === pathname)
    )
  }, [pathname, isOpenDrawer, isOpenList])

  if (!subItemsWithPermissions.length) return undefined

  if (subItemsWithPermissions.length === 1) {
    const [subItem] = subItemsWithPermissions

    return (
      <DrawerItem
        item={{ ...subItem, icon, name: subItem.name }}
        isOpen={isOpenDrawer}
        key={subItem.to}
      />
    )
  }

  return (
    <>
      <Tooltip title={isOpenDrawer ? null : name} placement="right" arrow>
        <ListItemButton
          onClick={() => handleClickParent(true)}
          sx={{
            justifyContent: isOpenDrawer ? 'initial' : 'center',
            borderRadius: 1,
            minHeight: 48,
            px: 2.5,
            marginBottom: 1,
            '&.Mui-selected': {
              color: theme.palette.primary.light,
            },
          }}
          selected={isOneOfItemsSelected && (!isOpenDrawer || !isOpenList)}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isOpenDrawer ? 3 : 'auto',
            }}
          >
            {icon}
          </ListItemIcon>
          {isOpenDrawer && (
            <>
              <ListItemText primary={name} />
              {isOpenList ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </ListItemButton>
      </Tooltip>
      <Collapse in={isOpenList} timeout="auto" unmountOnExit>
        <List disablePadding>
          {subItemsWithPermissions.map((subItem: DrawerSubItem) => (
            <ListItem
              key={subItem.name}
              disablePadding
              sx={{ color: theme.palette.text.primary }}
              component={Link}
              to={subItem.to}
            >
              <ListItemButton
                sx={{
                  ml: 2,
                  borderRadius: 1,
                  minHeight: 48,
                  marginBottom: 1,
                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                  },
                }}
                selected={subItem.to === pathname}
              >
                <ListItemText primary={subItem.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  )
}

export default DrawerItemNested
