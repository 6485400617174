import paths, { PATHS_PERMISSIONS_MAP } from '@app/constants/paths'
import { DrawerItem } from '@app/types'
import {
  BiotechOutlined,
  BusinessCenter,
  CalendarMonth,
  Collections,
  PrecisionManufacturingOutlined,
  TaskOutlined,
} from '@mui/icons-material'
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined'
import GridViewIcon from '@mui/icons-material/GridView'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import PeopleIcon from '@mui/icons-material/People'

export const sidebarItems: DrawerItem[] = [
  {
    name: 'Dashboard',
    icon: <GridViewIcon />,
    to: paths.admin.dashboard,
    permission: PATHS_PERMISSIONS_MAP[paths.admin.dashboard],
  },
  {
    name: 'Backlog',
    icon: <BallotOutlinedIcon />,
    to: paths.admin.backlog,
    permission: PATHS_PERMISSIONS_MAP[paths.admin.backlog],
  },
  {
    name: 'Contracts',
    icon: <InsertDriveFileOutlinedIcon />,
    to: paths.admin.sla,
    permission: PATHS_PERMISSIONS_MAP[paths.admin.sla],
  },
  {
    name: 'Companies',
    icon: <BusinessCenter />,
    subItems: [
      {
        name: 'Companies',
        to: paths.admin.companies,
        permission: PATHS_PERMISSIONS_MAP[paths.admin.companies],
      },
      {
        name: 'Company units',
        to: paths.admin.companyUnits,
        permission: PATHS_PERMISSIONS_MAP[paths.admin.companyUnits],
      },
    ],
  },
  {
    name: 'Machines',
    icon: <PrecisionManufacturingOutlined />,
    subItems: [
      {
        name: 'Machine templates',
        to: paths.admin.machineTemplates,
        permission: PATHS_PERMISSIONS_MAP[paths.admin.machineTemplates],
      },
      {
        name: 'Machine instances',
        to: paths.admin.machineInstances,
        permission: PATHS_PERMISSIONS_MAP[paths.admin.machineInstances],
      },
    ],
  },
  {
    name: 'Components',
    icon: <BiotechOutlined />,
    subItems: [
      {
        name: 'Components templates',
        to: paths.admin.componentTemplates,
        permission: PATHS_PERMISSIONS_MAP[paths.admin.componentTemplates],
      },
      {
        name: 'Components instances',
        to: paths.admin.componentInstances,
        permission: PATHS_PERMISSIONS_MAP[paths.admin.componentInstances],
      },
    ],
  },
  {
    name: 'Tasks',
    icon: <TaskOutlined />,
    subItems: [
      {
        name: 'All Tasks',
        to: paths.admin.tasks,
        permission: PATHS_PERMISSIONS_MAP[paths.admin.tasks],
      },
      {
        name: 'Tasks templates',
        to: paths.admin.taskTemplates,
        permission: PATHS_PERMISSIONS_MAP[paths.admin.taskTemplates],
      },
      {
        name: 'To do lists',
        to: paths.admin.todolists,
        permission: PATHS_PERMISSIONS_MAP[paths.admin.todolists],
      },
      {
        name: 'To do list items',
        to: paths.admin.todolistItems,
        permission: PATHS_PERMISSIONS_MAP[paths.admin.todolistItems],
      },
    ],
  },
  {
    name: 'People',
    icon: <PeopleIcon />,
    subItems: [
      {
        name: 'Users',
        to: paths.admin.users,
        permission: PATHS_PERMISSIONS_MAP[paths.admin.users],
      },
      {
        name: 'Contacts',
        to: paths.admin.contacts,
        permission: PATHS_PERMISSIONS_MAP[paths.admin.contacts],
      },
    ],
  },
  {
    name: 'Media',
    icon: <Collections />,
    to: paths.admin.media,
    permission: PATHS_PERMISSIONS_MAP[paths.admin.media],
  },
  {
    name: 'Calendar',
    icon:<CalendarMonth />,
    to: paths.admin.calendar,
    permission: PATHS_PERMISSIONS_MAP[paths.admin.calendar],
  },
]

export const sidebarFooter: DrawerItem = {
  name: 'Support',
  icon: <HelpOutlineOutlinedIcon />,
  to: paths.admin.support,
}
