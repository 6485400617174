import { CircularProgress, SvgIconProps, SxProps } from '@mui/material'
import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { useIsMounted } from 'usehooks-ts'

type ChildrenProps = {
  isLoading: boolean
  icon: OverridableComponent<SvgIconTypeMap>
  iconProps?: SvgIconProps
}

const SHARED_PROPS: SxProps = {
  width: '50%',
  height: '50%',
  maxWidth: 96,
}

const AvatarIcon = ({ isLoading, icon, iconProps }: ChildrenProps) => {
  const isMounted = useIsMounted()

  const isFirstRender = !isMounted()

  const Icon = icon

  if (isFirstRender && isLoading) {
    return <CircularProgress />
  }

  return <Icon sx={SHARED_PROPS} color="secondary" {...iconProps} />
}

export default AvatarIcon
