import {
  AddToDoListRequest,
  DeleteToDoListRequest,
  EditToDoListRequest,
} from '@aeqoom/contracts'
import { PERMISSIONS } from '@aeqoom/permissions'
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import useUserStore from '../stores/useUserStore'
import { client } from './client'
import parseIncoming from './parseIncoming'

const keys = {
  getToDoLists: ['to-do-lists'],
  getToDoList: ['to-do-list'],
}

const invalidateToDoListQueries = (queryClient: QueryClient) => {
  const allKeys = Object.values(keys).flat()
  queryClient.invalidateQueries({
    predicate: (query) => allKeys.includes(query.queryKey[0] + ''),
  })
}

export const useGetToDoLists = () => {
  const { hasPermission } = useUserStore()

  return useQuery({
    enabled: hasPermission(PERMISSIONS.TO_DO_LIST.LIST),
    queryKey: keys.getToDoLists,
    queryFn: () => client.toDoLists.getToDoLists().then(parseIncoming),
  })
}

export const useGetToDoList = ({
  id,
  enabled = !!id,
}: {
  id?: number
  enabled?: boolean
}) => {
  const { hasPermission } = useUserStore()

  return useQuery({
    enabled: enabled && hasPermission(PERMISSIONS.TO_DO_LIST.READ),
    queryKey: [...keys.getToDoList, id],
    queryFn: () =>
      client.toDoLists.getToDoList({ params: { id: id! } }).then(parseIncoming),
  })
}

export const useEditToDoList = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateToDoListQueries(queryClient)
    },
    mutationFn: async ({
      body,
      params,
    }: {
      body: EditToDoListRequest['body']
      params: EditToDoListRequest['params']
    }) => client.toDoLists.editToDoList({ body, params }).then(parseIncoming),
  })
}

export const useCreateToDoList = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => invalidateToDoListQueries(queryClient),
    mutationFn: (body: AddToDoListRequest['body']) =>
      client.toDoLists.addToDoList({ body }).then(parseIncoming),
  })
}

export const useDeleteToDoList = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: DeleteToDoListRequest['params']) =>
      client.toDoLists.deleteToDoList({ params }).then(parseIncoming),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === keys.getToDoLists[0] ||
          query.queryKey[0] === keys.getToDoList[0],
      })
    },
  })
}
