import { Accordion } from '@aeqoom/components-web'
import { GetTaskResponseBody } from '@aeqoom/contracts'
import SectionTitle from '@app/components/base/form/SectionTitle'
import MediaPreview from '@app/components/MediaPreview'
import { useCheckToDoListItem } from '@app/queries/useToDoListItems'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'

type Props = {
  items: GetTaskResponseBody['toDoList']
  readOnly: boolean
}

export const TodoList = ({ items, readOnly }: Props) => {
  const { mutate } = useCheckToDoListItem()

  const toDoListItems = items?.toDoListItems ?? []

  return (
    items && (
      <Stack
        flexDirection="column"
        gap={1}
        sx={{ '@media print': { display: 'none' } }}
      >
        <SectionTitle text="To-do list" />
        {toDoListItems.map((item, index) => {
          return (
            <Accordion
              key={item.id}
              title={
                <Stack spacing={2} direction="row" alignItems="center">
                  <Typography variant="h6">{++index}</Typography>
                  <Typography>{item.template.name}</Typography>
                </Stack>
              }
            >
              <Accordion.Details>
                <Typography pb={2}>{item.template.content}</Typography>
                <MediaPreview value={item.template.media} isEditing={false} />
                <RadioGroup
                  row
                  name={`todolist${item.toDoListOnTaskId}-item-${item.id}`}
                  sx={{ justifyContent: 'flex-end' }}
                  value={item.value}
                  onChange={(props) =>
                    mutate({
                      id: item?.id,
                      value: parseInt(props.target.value),
                    })
                  }
                >
                  <FormControlLabel
                    disabled={readOnly}
                    value="1"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    disabled={readOnly}
                    value="0"
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    disabled={readOnly}
                    value="2"
                    control={<Radio />}
                    label="N/A"
                  />
                </RadioGroup>
              </Accordion.Details>
            </Accordion>
          )
        })}
      </Stack>
    )
  )
}

export default TodoList
