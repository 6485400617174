import { CompanyType } from '@aeqoom/shared'
import { GridColDef } from '@mui/x-data-grid'

const companiesDataCol = (
  companyTypeTranslation: (type: CompanyType) => void
): GridColDef[] => [
  {
    field: 'id',
    headerName: 'ID',
    width: 80,
  },
  {
    field: 'name',
    headerName: 'Company name',
    width: 150,
  },
  {
    field: 'registrationNumber',
    headerName: 'Registration number',
    width: 150,
  },
  {
    field: 'companyType',
    headerName: 'Company type',
    width: 150,
    valueFormatter: ({ value }: { value: CompanyType }) =>
      companyTypeTranslation(value),
  },
  {
    field: 'taxId',
    headerName: 'Tax ID',
    width: 150,
  },
  {
    field: 'city',
    headerName: 'City',
    width: 150,
  },
  {
    field: 'zipCode',
    headerName: 'Postal code',
    width: 150,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    width: 150,
  },
]

export default companiesDataCol
