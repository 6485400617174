import MediaPreview from '@app/components/MediaPreview'
import { Grid } from '@mui/material'

type Props = {
  media: number[]
}

const Media = ({ media }: Props) => {
  return (
    <Grid container>
      <Grid item xs>
        <MediaPreview value={media} isEditing={false} />
      </Grid>
    </Grid>
  )
}

export default Media
