import {
  AddComponentInstanceRequest,
  DeleteComponentInstanceRequest,
  EditComponentInstanceRequest,
  GetComponentInstancesRequest,
} from '@aeqoom/contracts'
import { COMPONENT_INSTANCE_KEYS } from '@aeqoom/types'
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import dayjs from 'dayjs'

import { client } from './client'
import parseIncoming from './parseIncoming'

const invalidateComponentInstanceQueries = (queryClient: QueryClient) => {
  const allKeys: string[] = Object.values(COMPONENT_INSTANCE_KEYS).flat()
  queryClient.invalidateQueries({
    predicate: (query) => allKeys.includes(query.queryKey[0] + ''),
  })
}

export const useGetComponentInstances = ({
  query = {},
}: {
  query?: GetComponentInstancesRequest['query']
} = {}) => {
  return useQuery({
    queryKey: [
      COMPONENT_INSTANCE_KEYS.getComponentInstances,
      query.machineInstanceId,
      query.companyId,
      query.companyUnitId,
      query.serviceLevelAgreementId,
    ],
    queryFn: () =>
      client.componentInstances
        .getComponentInstances({ query })
        .then(parseIncoming),
  })
}

export const useGetComponentInstance = ({
  id,
  enabled = !!id,
  staleTime,
}: {
  id?: number | null
  enabled?: boolean
  staleTime?: number
}) => {
  return useQuery({
    enabled,
    queryKey: [COMPONENT_INSTANCE_KEYS.getComponentInstance, id],
    queryFn: () =>
      client.componentInstances
        .getComponentInstance({ params: { id: id! } })
        .then(parseIncoming)
        .then((data) => ({
          ...data,
          yearProduced: dayjs.utc(data.yearProduced).toISOString(),
          warrantyUntil: dayjs.utc(data.warrantyUntil).toISOString(),
        })),
    staleTime,
  })
}

export const useCreateComponentInstance = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateComponentInstanceQueries(queryClient)
    },
    mutationFn: (body: AddComponentInstanceRequest['body']) =>
      client.componentInstances
        .addComponentInstance({ body })
        .then(parseIncoming),
  })
}

export const useEditComponentInstance = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateComponentInstanceQueries(queryClient)
    },
    mutationFn: ({
      body,
      params,
    }: {
      body: EditComponentInstanceRequest['body']
      params: EditComponentInstanceRequest['params']
    }) =>
      client.componentInstances
        .editComponentInstance({ body, params })
        .then(parseIncoming),
  })
}

export const useDeleteComponentInstance = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: DeleteComponentInstanceRequest['params']) =>
      client.componentInstances
        .deleteComponentInstance({ params })
        .then(parseIncoming),
    onSuccess: () => {
      invalidateComponentInstanceQueries(queryClient)
    },
  })
}
