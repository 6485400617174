import { SaveWorkProtocolSchemaForm } from '@aeqoom/forms'

const defaultValues: SaveWorkProtocolSchemaForm = {
  type: 'draft',
  arrival: null,
  repairFrom: null,
  repairTo: null,
  departure: null,
  distance: null,
  hours: null,
  descriptionOfWork: null,
  usedSpareParts: null,
  sparePartsToOrder: null,
  isConfirmed: false,
  signature: '',
}

export default defaultValues
