import PersonIcon from '@mui/icons-material/Person'
import PersonOffIcon from '@mui/icons-material/PersonOff'
import { IconButton } from '@mui/material'

export type Props = {
  isActive: boolean
  handleAction: () => void
}

function ChangeUserStateCell({ isActive, handleAction }: Props) {
  return (
    <IconButton
      className="actionButton"
      onClick={(e) => {
        e.stopPropagation()
        handleAction()
      }}
      color={!isActive ? 'success' : 'error'}
    >
      {!isActive ? <PersonIcon /> : <PersonOffIcon />}
    </IconButton>
  )
}

export default ChangeUserStateCell
