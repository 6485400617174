import {
  AddSLARequest,
  EditSLARequest,
  GenerateTasksRequest,
  GetSLAsRequest,
} from '@aeqoom/contracts'
import { SLA_KEYS } from '@aeqoom/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { client } from './client'
import parseIncoming from './parseIncoming'

export const useGetSlas = ({
  query = {},
  enabled = true,
}: {
  query?: GetSLAsRequest['query']
  enabled?: boolean
} = {}) => {
  return useQuery({
    enabled,
    queryKey: [SLA_KEYS.getSlas, query],
    queryFn: () =>
      client.serviceLevelAgreements
        .getServiceLevelAgreements({ query })
        .then(parseIncoming),
  })
}

export const useCreateSla = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: (body) => {
      queryClient.invalidateQueries()
      toast.success(body.message)
    },
    mutationFn: (body: AddSLARequest['body']) => {
      return client.serviceLevelAgreements
        .addServiceLevelAgreement({ body })
        .then(parseIncoming)
    },
  })
}

export const useEditSla = () => {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: (body) => {
      queryClient.invalidateQueries()
      toast.success(body.message)
    },
    mutationFn: ({
      body,
      params,
    }: {
      body: EditSLARequest['body']
      params: EditSLARequest['params']
    }) =>
      client.serviceLevelAgreements
        .editServiceLevelAgreement({ body, params })
        .then(parseIncoming),
  })
}

export const useGetSla = ({
  id,
  enabled = !!id,
}: {
  id?: number
  enabled?: boolean
}) => {
  return useQuery({
    enabled,
    queryKey: [SLA_KEYS.getSla, id],
    queryFn: () =>
      client.serviceLevelAgreements
        .getServiceLevelAgreement({
          params: { id: id! },
        })
        .then(parseIncoming),
  })
}

export const useGenerateTasks = () => {
  const queryClient = useQueryClient()
  return useMutation({
    onSuccess: (body) => {
      queryClient.invalidateQueries()
      toast.success(body.message)
    },
    mutationFn: ({ params }: { params: GenerateTasksRequest['params'] }) =>
      client.serviceLevelAgreements
        .generateTasks({ params })
        .then(parseIncoming),
  })
}
