import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  isLoading: boolean
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  count?: number
}

const DeleteMediaDialog = ({
  isLoading,
  isOpen,
  onClose,
  onConfirm,
  count = 1,
}: Props) => {
  const { t } = useTranslation('web', { keyPrefix: 'media' })

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t('deleteMediaTitle', { count })}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <DialogContentText>
            {t('deleteMediaDescription', { count })}
          </DialogContentText>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" color="secondary">
          {t('deleteMediaCancelButton')}
        </Button>
        <LoadingButton
          onClick={onConfirm}
          color="error"
          variant="contained"
          autoFocus
          loading={isLoading}
        >
          {t('deleteMediaConfirmButton', { count })}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteMediaDialog
