import {
  componentInstanceOnServiceLevelAgreementMachine,
  machineInstanceOnServiceLevelAgreement,
  serviceLevelAgreements,
} from '@aeqoom/db'
import { z } from '@aeqoom/zod'
import { createInsertSchema } from 'drizzle-zod'

export const serviceLevelAgreementSchema = createInsertSchema(
  serviceLevelAgreements
)
  .superRefine(({ isIndefinitely, dateTo }, ctx) => {
    if (isIndefinitely && dateTo) {
      ctx.addIssue({
        code: 'custom',
        message:
          'Date must be empty if contract is an indefinite period of time',
        path: ['dateTo'],
      })
    } else if (!isIndefinitely && !dateTo) {
      ctx.addIssue({
        code: 'custom',
        message: 'Check indefinitely if "date to" is not filled',
        path: ['isIndefinitely'],
      })
    }
  })
  .and(
    z.object({
      machineInstances: z
        .array(
          z
            .object({
              ...createInsertSchema(machineInstanceOnServiceLevelAgreement, {
                frequency: z.number().positive().optional(),
              }).omit({
                serviceLevelAgreementId: true,
              }).shape,
              componentInstances: z
                .array(
                  z.object({
                    ...createInsertSchema(
                      componentInstanceOnServiceLevelAgreementMachine,
                      {
                        frequency: z.number().positive().optional().nullable(),
                      }
                    ).omit({
                      serviceLevelAgreementId: true,
                      serviceLevelAgreementMachineInstanceId: true,
                    }).shape,
                  })
                )
                .superRefine((componentInstances, ctx) => {
                  const seenComponentInstanceIds = new Set()

                  componentInstances.forEach((obj, index) => {
                    if (seenComponentInstanceIds.has(obj.componentInstanceId)) {
                      ctx.addIssue({
                        code: 'custom',
                        message: 'Duplicate component instance found.',
                        path: [index, 'componentInstanceId'],
                      })
                    }
                    seenComponentInstanceIds.add(obj.componentInstanceId)
                  })
                }),
            })
            .superRefine(
              (
                { taskTemplateId, frequency, type, componentInstances, onCall },
                ctx
              ) => {
                if (componentInstances.length < 1) {
                  taskTemplateId === null &&
                    ctx.addIssue({
                      code: 'custom',
                      message:
                        'Task template is not set which is required for machine instance without component instances.',
                      path: ['taskTemplateId'],
                    })

                  frequency === null &&
                    !onCall &&
                    ctx.addIssue({
                      code: 'custom',
                      message:
                        'Frequency is not set which is required for machine instance without component instances.',
                      path: ['frequency'],
                    })

                  type === null &&
                    !onCall &&
                    ctx.addIssue({
                      code: 'custom',
                      message:
                        'Type is not set which is required for machine instance without component instances.',
                      path: ['type'],
                    })
                } else {
                  componentInstances.forEach((item, index) => {
                    if (!taskTemplateId) {
                      if (!item.taskTemplateId) {
                        ctx.addIssue({
                          code: 'custom',
                          message:
                            'Task template is not set, which is required if the machine instance itself does not have a task template.',
                          path: ['componentInstances', index, 'taskTemplateId'],
                        })
                      }
                    }

                    if (!frequency) {
                      if (!item.frequency && !onCall) {
                        ctx.addIssue({
                          code: 'custom',
                          message:
                            'Frequency is not set, which is required if the machine instance itself does not have a frequency.',
                          path: ['componentInstances', index, 'frequency'],
                        })
                      }
                    }

                    if (!type) {
                      if (!item.type && !onCall) {
                        ctx.addIssue({
                          code: 'custom',
                          message:
                            'Type is not set, which is required if the machine instance itself does not have a type.',
                          path: ['componentInstances', index, 'type'],
                        })
                      }
                    }
                  })
                }
              }
            )
        )
        .min(0),
      media: z.array(z.number()),
    })
  )
