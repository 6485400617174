import { Flatten, GetDashboardResponseBody } from '@aeqoom/contracts'
import { useThemeColors } from '@aeqoom/hooks'
import { BuildOutlined } from '@mui/icons-material'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Card, CardContent, Stack, Typography } from '@mui/material'

import TaskCountByStatus from '../TaskCountByStatus'
import { NewIncidents } from './styled'

type Props = {
  unit: Flatten<GetDashboardResponseBody>
}

const IncidentsBox = ({ unit: { numberOfIncidents } }: Props) => {
  const { red } = useThemeColors()

  return (
    <Card sx={{ backgroundColor: red.pastel }}>
      <CardContent>
        <Stack direction="row" alignItems="center" gap={1}>
          <WarningAmberOutlinedIcon sx={{ fontSize: 20 }} />
          <Typography variant="body2Regular">Incidents</Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Stack direction="column" alignItems="center">
            <NewIncidents>{numberOfIncidents?.new}</NewIncidents>
            <Typography variant="body3Bold" color={red.main}>
              NEW
            </Typography>
          </Stack>

          <Stack
            direction="column"
            justifyContent="space-between"
            sx={{ marginTop: 'auto' }}
          >
            <TaskCountByStatus
              count={numberOfIncidents?.inProgress}
              label="In progress"
              icon={<BuildOutlined sx={{ fontSize: 20 }} />}
            />
            <TaskCountByStatus
              count={numberOfIncidents?.done}
              label="Done"
              icon={<DoneOutlinedIcon sx={{ fontSize: 20 }} />}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default IncidentsBox
