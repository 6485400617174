import { GridColDef } from '@mui/x-data-grid'

const companyUnitsDataCol: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 80,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
  },
  {
    field: 'company',
    headerName: 'Company',
    width: 150,
    valueGetter: (params) => params.row?.company?.name,
  },
  {
    field: 'street',
    headerName: 'Street',
    width: 150,
  },
  {
    field: 'houseNumber',
    headerName: 'House number',
    width: 150,
  },
  {
    field: 'city',
    headerName: 'City',
    width: 150,
  },
  {
    field: 'country',
    headerName: 'Country',
    width: 150,
  },
]

export default companyUnitsDataCol
