import { Alert, AlertTitle, Box } from '@mui/material'

const UnauthorizedScreen = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Alert severity="error">
        <AlertTitle>Warning</AlertTitle>
        You don't have sufficient permissions to view this page.
      </Alert>
    </Box>
  )
}

export default UnauthorizedScreen
