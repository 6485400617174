import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

type Props = {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
}

const CloseConfirmationDialog = ({ isOpen, onConfirm, onCancel }: Props) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>Close window</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to close this window? You can lose your data.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="text" color="secondary">
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained" autoFocus>
          Close window
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CloseConfirmationDialog
