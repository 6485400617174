import { DrawerItem as DrawerItemType } from '@app/types'
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { Link, useLocation } from 'react-router-dom'

type Props = {
  item: DrawerItemType
  isOpen: boolean
}

const DrawerItem = ({ item: { name, icon, to }, isOpen }: Props) => {
  const { pathname } = useLocation()
  const theme = useTheme()

  return (
    to && (
      <ListItem
        key={name}
        disablePadding
        sx={{ display: 'block' }}
        component={Link}
        to={to}
      >
        <Tooltip title={isOpen ? '' : name} placement="right" arrow>
          <ListItemButton
            sx={{
              minHeight: 48,
              px: 2.5,
              color: theme.palette.text.primary,
              marginBottom: '8px',
              '&.Mui-selected': {
                color: theme.palette.primary.light,
                borderRadius: '8px',
              },
              '&:hover': {
                borderRadius: '8px',
              },
              justifyContent: isOpen ? 'initial' : 'center',
            }}
            selected={to === pathname}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isOpen ? 3 : 'auto',
                justifyContent: 'center',
                color: to === pathname ? theme.palette.green.main : '',
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText primary={name} sx={{ opacity: isOpen ? 1 : 0 }} />
          </ListItemButton>
        </Tooltip>
      </ListItem>
    )
  )
}

export default DrawerItem
