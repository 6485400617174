import { Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  paddingBottom?: number
  title: string
  endHeader?: ReactNode
}

const Header = ({ paddingBottom, title, endHeader }: Props) => {
  return (
    <Stack
      direction="row"
      paddingBottom={paddingBottom}
      justifyContent="space-between"
    >
      <Typography variant="h5" sx={{ fontWeight: '700' }}>
        {title}
      </Typography>
      {endHeader && endHeader}
    </Stack>
  )
}

export default Header
