import { AddSLARequest } from '@aeqoom/contracts'
import { useHandledCheckboxes } from '@aeqoom/hooks'
import ComponentInstanceIterator from '@app/components/ComponentInstanceIterator'
import {
  Add as AddIcon,
  DeleteForever,
  Settings as SettingsIcon,
} from '@mui/icons-material'
import { Button, Checkbox, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFormContext,
  useWatch,
} from 'react-hook-form'

type Path = `machineInstances.${number}`

type Props = {
  path: Path
  append: UseFieldArrayAppend<
    AddSLARequest['body'],
    `machineInstances.${number}.componentInstances`
  >
  remove: UseFieldArrayRemove
  fields: FieldArrayWithId<
    AddSLARequest['body'],
    `${Path}.componentInstances`,
    'id'
  >[]
}

export const ComponentsForm = ({ path, fields, append, remove }: Props) => {
  const { control, setValue } = useFormContext<AddSLARequest['body']>()

  const [machineId, frequency, onCall] = useWatch({
    control,
    name: [`${path}.machineInstanceId`, `${path}.frequency`, `${path}.onCall`],
  })

  const { childCheckBoxes, parentCheckBoxes, data } =
    useHandledCheckboxes(fields)

  const isLevelZeroSet = Number.isSafeInteger(frequency) || !!onCall

  useEffect(() => {
    if (isLevelZeroSet) {
      fields.forEach((_, index) => {
        setValue(`${path}.componentInstances.${index}.frequency`, null)
      })
    }
  }, [fields, isLevelZeroSet, path, setValue])

  return (
    <Stack width="100%" spacing={1}>
      <Stack direction="row" gap={1}>
        <SettingsIcon />
        <Typography variant="body1Bold">Components</Typography>
      </Stack>
      {machineId && (
        <>
          {!!fields.length && (
            <Stack alignSelf="flex-start" direction="row" paddingLeft={2}>
              <Checkbox
                checked={parentCheckBoxes.isChecked}
                indeterminate={parentCheckBoxes.isIndeterminate}
                onClick={parentCheckBoxes.toggleAll}
              />
              <IconButton
                disabled={!data.values.size}
                onClick={() => {
                  remove([...data.values])
                  data.clear()
                }}
                size="large"
              >
                <DeleteForever />
              </IconButton>
            </Stack>
          )}
          <Stack spacing={1} flexGrow={1}>
            {fields.map((field, index: number) => (
              <ComponentInstanceIterator
                key={field.id}
                machineId={machineId}
                isLevelZeroSet={isLevelZeroSet}
                isChecked={childCheckBoxes.isChecked(index)}
                setIsChecked={() => childCheckBoxes.setIsChecked(index)}
                parent={{
                  path: `${path}.componentInstances.${index}`,
                  fields,
                  index,
                  remove,
                  append,
                }}
              />
            ))}
          </Stack>
        </>
      )}
      <Stack direction="row" padding={1} justifyContent="center">
        <Button
          onClick={() =>
            append({
              componentInstanceId: null!,
              taskTemplateId: null,
              frequency: null,
              type: null,
            })
          }
          disabled={!machineId}
          color="primary"
        >
          <AddIcon /> Add new component
        </Button>
      </Stack>
    </Stack>
  )
}
