import { ISteps } from '@app/src/types'
import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import { createContext, ReactNode, useContext, useState } from 'react'
import { FieldValues, UseFormTrigger } from 'react-hook-form'

type Context = {
  steps: {
    label: string
    Component: ReactNode
  }[]
  activeStep: number
  handleBack?: () => void
  handleNext?: () => void
  isStepSkipped?: (step: number) => boolean
  handleFinish?: () => void
}

const CustomStepperContext = createContext<Context>({
  steps: [],
  activeStep: 0,
})

export const CustomStepperActions = () => {
  const { activeStep, steps, handleBack, handleNext, handleFinish } =
    useContext(CustomStepperContext)

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      width="100%"
      spacing={2}
    >
      <Button
        variant="outlined"
        disabled={activeStep === 0}
        onClick={handleBack}
      >
        Back
      </Button>
      {activeStep !== steps.length - 1 && (
        <Button onClick={handleNext} variant="outlined">
          Next step
        </Button>
      )}
      {activeStep === steps.length - 1 && (
        <Button variant="contained" onClick={handleFinish}>
          Finish
        </Button>
      )}
    </Stack>
  )
}

export const CustomStepperContent = () => {
  const { activeStep, steps } = useContext(CustomStepperContext)

  return (
    <>
      <Stepper sx={{ width: '80%', margin: 'auto' }} activeStep={activeStep}>
        {steps.map(({ label }) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <Box sx={{ mt: 8, mb: 4 }}>{steps[activeStep].Component}</Box>
    </>
  )
}

type Props<T extends FieldValues> = {
  steps: ISteps<T>[]
  onStepChange: UseFormTrigger<T>
  children: ReactNode
  handleFinish: () => void
}

const CustomStepper = <T extends FieldValues>({
  steps,
  onStepChange,
  children,
  handleFinish,
}: Props<T>) => {
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = async () => {
    const fields = steps[activeStep].fields
    const isStepValid = await onStepChange(fields)
    if (isStepValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <CustomStepperContext.Provider
      value={{
        activeStep,
        steps,
        handleBack,
        handleNext,
        handleFinish,
      }}
    >
      {children}
    </CustomStepperContext.Provider>
  )
}

export default CustomStepper
