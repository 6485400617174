import { MediaDetailForm, mediaDetailForm } from '@aeqoom/forms'
import { ACCESS_LEVELS, MEDIA_TYPES } from '@aeqoom/shared'
import { trimStringWithExtension } from '@aeqoom/utils'
import BackdropUpload from '@app/components/BackdropUpload'
import CheckMediaPermission from '@app/components/CheckMediaPermission'
import DeleteMediaDialog from '@app/components/DeleteMediaDialog'
import FetchableAvatar from '@app/components/FetchableAvatar'
import MediaContent from '@app/components/MediaContent'
import MediaVersion from '@app/components/MediaVersion'
import useDialog from '@app/hooks/useDialog'
import {
  useDeleteMedia,
  useEditMedia,
  useGetOneMedia,
} from '@app/queries/useMedia'
import CompanyContext from '@app/src/contexts/CompanyContext'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { formatDate } from '@app/src/utils/formatDate'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Category,
  DeleteForever,
  Download,
  Edit,
  EditOff,
  Inventory,
  Label,
  Lock,
  Source,
  UploadFile,
  Warning,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Chip,
  DialogActions,
  DialogContent,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { CalendarIcon } from '@mui/x-date-pickers'
import { filesize } from 'filesize'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { FormTextField } from '../../../form/FormTextField'
import MediaNewVersion from '../../MediaNewVersion'

type Props = {
  activeMedia: number
}

const MediaDetailDialog = ({ activeMedia }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const [activeVersion, setActiveVersion] = useState<number>()

  const { hasParent } = useDialog()

  const { data, isError, isPending } = useGetOneMedia({
    id: activeMedia,
    version: activeVersion,
  })

  const { t } = useTranslation(['common', 'web'])

  const { closeTab, closeDialog, openDialog } = useDialogStore()

  const editMediaMutation = useEditMedia()
  const deleteMediaMutation = useDeleteMedia()

  const { control, handleSubmit, reset } = useForm<MediaDetailForm>({
    resolver: zodResolver(mediaDetailForm),
    defaultValues: { name: '', description: '' },
  })

  const editMedia: SubmitHandler<MediaDetailForm> = async (data) => {
    setIsLoading(true)
    editMediaMutation.mutate(
      { body: data, params: { id: data.id } },
      {
        onSuccess: (data) => {
          toast.success(data.message)
          setIsLoading(false)
        },
        onError: () => {
          toast.error(t('web:media.couldNotEditMedia'))
          setIsLoading(false)
        },
      }
    )
  }

  const deleteMedia = async (id: number) => {
    setIsLoading(true)
    deleteMediaMutation.mutate(
      { params: { id } },
      {
        onSuccess: (data) => {
          setIsOpen(false)
          setIsLoading(false);
          hasParent ? closeTab() : closeDialog()
          toast.success(data.message)
        },
        onError: () => {
          setIsOpen(false)
          setIsLoading(false)
          toast.error(t('web:media.couldNotDeleteMedia'))
        },
      }
    )
  }

  useEffect(() => {
    if (data) {
      reset({
        id: data.id,
        name: data.name,
        description: data.description,
      })
    }
  }, [data, reset])

  if (isError)
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100%' }}
        spacing={2}
      >
        <Warning fontSize="large" color="error" />
        <Typography color="error">{t('common:errorOccured')}</Typography>
      </Stack>
    )

  return (
    <CompanyContext.Provider
      value={{
        companyId: data?.company?.id,
        companyUnitId: data?.companyUnit?.id,
      }}
    >
      <DialogContent>
        <BackdropUpload
          launchAction={(acceptedFiles) =>
            openDialog(
              <MediaNewVersion
                mediaId={activeMedia}
                accessLevel={data?.accessLevel}
                fileAttachments={acceptedFiles}
              />,
              'Media new Version'
            )
          }
        >
          <Stack spacing={8}>
            <Stack direction="row" spacing={4} flexShrink={0} flexGrow={0}>
              <Box width="60%" position="relative">
                {isPending ? (
                  <Skeleton width="100%" height="100%" variant="rounded" />
                ) : (
                  <MediaContent data={data} />
                )}
              </Box>
              <Stack sx={{ width: '40%' }} spacing={4}>
                <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
                  <CheckMediaPermission>
                    {isPending ? (
                      <Skeleton variant="circular" width={40} height={40} />
                    ) : (
                      <Tooltip title={t('common:deleteItem')}>
                        <IconButton onClick={() => setIsOpen(true)}>
                          <DeleteForever />
                        </IconButton>
                      </Tooltip>
                    )}
                  </CheckMediaPermission>
                  {isPending ? (
                    <Skeleton variant="circular" width={40} height={40} />
                  ) : (
                    <Tooltip title={t('common:download')}>
                      <IconButton
                        href={data.signedUrl || undefined}
                        component="a"
                      >
                        <Download />
                      </IconButton>
                    </Tooltip>
                  )}
                  <CheckMediaPermission>
                    {isPending ? (
                      <Skeleton width={40} height={40} variant="circular" />
                    ) : (
                      <Tooltip title={t('web:media.uploadNewVersion')}>
                        <IconButton
                          onClick={() =>
                            openDialog(
                              <MediaNewVersion
                                mediaId={activeMedia}
                                accessLevel={data.accessLevel}
                              />,
                              'New version'
                            )
                          }
                        >
                          <UploadFile />
                        </IconButton>
                      </Tooltip>
                    )}
                  </CheckMediaPermission>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  {isPending ? (
                    <Skeleton
                      width={46}
                      height={46}
                      variant="circular"
                      sx={{ flexShrink: 0 }}
                    />
                  ) : (
                    <FetchableAvatar id={data?.uploadedBy.avatar} />
                  )}

                  <Typography variant="subtitle1" width="100%">
                    {isPending ? (
                      <Skeleton variant="rounded" />
                    ) : (
                      data.uploadedBy.firstName + ' ' + data.uploadedBy.lastName
                    )}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  width="100%"
                >
                  <Controller
                    name="name"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        onChange={onChange}
                        value={value}
                        placeholder={t('web:media.noNameProvided')}
                        variant="standard"
                        fullWidth
                        spellCheck={false}
                        inputProps={{ style: { fontSize: 24 } }}
                        InputLabelProps={{ style: { fontSize: 24 } }}
                        InputProps={{
                          disableUnderline: !isEditable,
                          readOnly: !isEditable,
                        }}
                        sx={{
                          '& .MuiInputBase-input': {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          },
                        }}
                      />
                    )}
                  />
                  <CheckMediaPermission>
                    <IconButton onClick={() => setIsEditable(!isEditable)}>
                      {isEditable ? <EditOff /> : <Edit />}
                    </IconButton>
                  </CheckMediaPermission>
                </Stack>
                <Stack spacing={2}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <FetchableAvatar id={data?.company?.avatar} />
                    <Typography variant="body1" width="100%">
                      {isPending ? <Skeleton /> : data.company?.name}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <CalendarIcon sx={{ width: '32px' }} />
                    <Typography variant="body1" width="100%">
                      {isPending ? <Skeleton /> : formatDate(data?.dateCreated)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Inventory sx={{ width: '32px' }} />
                    <Typography variant="body1" width="100%" noWrap>
                      {isPending ? (
                        <Skeleton />
                      ) : (
                        trimStringWithExtension(data.originalFilename, 28)
                      )}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Source sx={{ width: '32px' }} />
                    <Typography variant="body1" width="100%">
                      {isPending ? <Skeleton /> : filesize(data?.fileSize)}
                    </Typography>
                  </Stack>
                  {isPending ? (
                    <Skeleton />
                  ) : (
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Label sx={{ width: '32px' }} />
                      <Stack direction="row" spacing={2}>
                        <Chip
                          label={ACCESS_LEVELS[data?.accessLevel]}
                          icon={<Lock />}
                        />
                        <Chip
                          label={MEDIA_TYPES[data?.mediaType]}
                          icon={<Category />}
                        />
                      </Stack>
                    </Stack>
                  )}
                </Stack>
                <FormTextField
                  controller={{ name: 'description', control }}
                  field={{
                    multiline: true,
                    rows: 3,
                    helperText: '',
                  }}
                />
              </Stack>
            </Stack>
            <MediaVersion
              id={activeMedia}
              activeVersion={activeVersion}
              setActiveVersion={setActiveVersion}
            />
          </Stack>
          <DeleteMediaDialog
            isLoading={isLoading}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onConfirm={() => deleteMedia(activeMedia)}
          />
        </BackdropUpload>
      </DialogContent>
      <CheckMediaPermission>
        <DialogActions>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            onClick={handleSubmit(editMedia)}
          >
            {t('common:save')}
          </LoadingButton>
        </DialogActions>
      </CheckMediaPermission>
    </CompanyContext.Provider>
  )
}

export default MediaDetailDialog
