import GalleryDialog from '@app/components/base/dialogs/GalleryDialog'
import { Container, Paper, Stack } from '@mui/material'

const Media = () => (
  <Container>
    <Paper sx={{ pt: 4, height: '100%', borderRadius: 2 }}>
      <Stack sx={{ height: '100%' }}>
        <GalleryDialog />
      </Stack>
    </Paper>
  </Container>
)

export default Media
