import { Media } from '@aeqoom/shared'
import { createUniqueKey, trimStringWithExtension } from '@aeqoom/utils'
import { useDeleteMedia } from '@app/queries/useMedia'
import { useDialogStore } from '@app/src/stores/useDialogStore'
import { DeleteForever, Download, Info } from '@mui/icons-material'
import {
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'
import dayjs from 'dayjs'
import { MouseEvent } from 'react'
import toast from 'react-hot-toast'

import MediaDetailDialog from '../base/dialogs/_detailViewDialogs/MediaDetailDialog'
import CustomListItem from '../CustomListItem'
import FileIcon from '../FileIcon'

type Props = {
  value: Media
  isChecked: boolean
  onChange: (item: Media, checked: boolean) => void
}

const MediaListItem = ({ value, isChecked, onChange }: Props) => {
  const { openDialog } = useDialogStore()

  const deleteMediaMutation = useDeleteMedia()

  const deleteMedia = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    deleteMediaMutation.mutate(
      { params: { id: value.id } },
      {
        onSuccess: (data) => {
          toast.success(data.message)
        },
      }
    )
  }

  const openMediaDetailDialog = () => {
    openDialog(
      <MediaDetailDialog activeMedia={value.id} />,
      value.name || trimStringWithExtension(value.originalFilename),
      {
        uniqueKey: createUniqueKey(['mediaDialog', value.id]),
      }
    )
  }

  return (
    <CustomListItem
      disablePadding
      secondaryAction={
        <Stack direction="row" spacing={2}>
          <IconButton
            href={value.signedUrl || undefined}
            component="a"
            target="_blank"
          >
            <Download />
          </IconButton>
          <IconButton onClick={openMediaDetailDialog}>
            <Info />
          </IconButton>
          <IconButton onClick={deleteMedia}>
            <DeleteForever />
          </IconButton>
        </Stack>
      }
    >
      <ListItemButton
        sx={{ py: 2 }}
        selected={isChecked}
        onClick={(e) => {
          e.stopPropagation()
          onChange(value, !isChecked)
        }}
        onDoubleClick={openMediaDetailDialog}
      >
        <ListItemIcon>
          <FileIcon mimeType={value.mimeType} fontSize="medium" />
        </ListItemIcon>
        <Stack direction="row" spacing={8} sx={{ width: '100%' }}>
          <ListItemText
            primary={
              value.name || trimStringWithExtension(value.originalFilename)
            }
            sx={{ width: '50%' }}
          />
          <ListItemText disableTypography sx={{ width: '50%' }}>
            {value.dateCreated && dayjs(value.dateCreated).format('DD/MM/YYYY')}
          </ListItemText>
        </Stack>
      </ListItemButton>
    </CustomListItem>
  )
}

export default MediaListItem
