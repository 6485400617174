import { TaskStatus } from '@aeqoom/db'
import {
  SaveWorkProtocolSchemaForm,
  useSaveWorkProtocolSchema,
} from '@aeqoom/forms'
import { UserGroups } from '@aeqoom/shared'
import DialogDetailError from '@app/components/base/dialogs/createForms/DialogDetailError'
import ArrivalPart from '@app/components/base/dialogs/WorkProtocolDialogContent/ArrivalPart'
import Header from '@app/components/base/dialogs/WorkProtocolDialogContent/Header'
import Notes from '@app/components/base/dialogs/WorkProtocolDialogContent/Notes'
import TodoList from '@app/components/base/dialogs/WorkProtocolDialogContent/TodoList'
import FormCheckBox from '@app/components/base/form/FormCheckBox'
import {
  useChangeTaskStatus,
  useCompleteWorkProtocol,
  useGetTask,
  useSaveWorkProtocol,
} from '@app/queries/useTasks'
import { useGetWorkProtocol } from '@app/queries/useWorkProtocol'
import { useDialogStore } from '@app/stores/useDialogStore'
import useUserStore from '@app/stores/useUserStore'
import { theme } from '@app/styles/theme'
import { zodResolver } from '@hookform/resolvers/zod'
import { Print } from '@mui/icons-material'
import ReplayIcon from '@mui/icons-material/Replay'
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useRef, useState } from 'react'
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import SignatureCanvas from 'react-signature-canvas'
import { useReactToPrint } from 'react-to-print'

import defaultValues from './defaultValues'

type Props = {
  readOnly?: boolean
  taskId: number
}

const SHOULD_ENABLE_FORM: string[] = [
  'in_progress',
  'waiting_on_wp',
  'recurred',
]

const WorkProtocolDialogContent = ({
  readOnly: readOnlyParam = false,
  taskId,
}: Props) => {
  const signatureRef = useRef<SignatureCanvas | null>(null)
  const contentRef = useRef<HTMLDivElement | null>(null)

  const saveWorkProtocolSchema = useSaveWorkProtocolSchema()

  const handlePrint = useReactToPrint({ content: () => contentRef.current })

  const methods = useForm<SaveWorkProtocolSchemaForm>({
    defaultValues,
    resolver: zodResolver(saveWorkProtocolSchema),
  })

  const { handleSubmit, control, reset, setValue } = methods

  const [isEditingSignature, setIsEditingSignature] = useState(false)

  const { user } = useUserStore()
  const { closeTab } = useDialogStore()

  const getTaskQuery = useGetTask({ id: taskId })
  const { data, isPending } = useGetWorkProtocol({ id: taskId })
  const changeTaskStatusMutation = useChangeTaskStatus()
  const saveWorkProtocol = useSaveWorkProtocol()
  const completeWorkProtocol = useCompleteWorkProtocol()

  const useWatchIsConfirmed = useWatch({ control, name: 'isConfirmed' })
  const useWatchSignature = useWatch({ control, name: 'signature' })

  const readOnly =
    readOnlyParam ||
    !getTaskQuery.data ||
    !SHOULD_ENABLE_FORM.includes(getTaskQuery.data?.status)

  const checkBoxLabel =
    'We confirm that the work specified in this statement has been carried out and the spare parts installed or delivered. The machines have been transferred and are running to our satisfaction. '

  useEffect(() => {
    if (data) {
      const isConfirmed = !!data.isConfirmed

      reset({ ...data, isConfirmed })
    }
  }, [data])

  const onSaveAsConcept = async (data: SaveWorkProtocolSchemaForm) => {
    if (task.userId) {
      saveWorkProtocol.mutate(
        {
          body: data,
          params: { id: task.id },
        },
        { onSuccess: () => closeTab() }
      )
    }
  }

  const onComplete = async (data: SaveWorkProtocolSchemaForm) => {
    if (task.userId) {
      completeWorkProtocol.mutate({
        body: data,
        params: { id: task.id },
      })
    }
  }

  const returnToTechnician = () => {
    changeTaskStatusMutation.mutate(
      {
        body: { status: TaskStatus.RECURRED },
        params: { id: taskId },
      },
      {
        onSuccess: () => closeTab(),
      }
    )
  }

  const completeTask = () => {
    changeTaskStatusMutation.mutate(
      {
        body: { status: TaskStatus.DONE },
        params: { id: taskId },
      },
      {
        onSuccess: () => closeTab(),
      }
    )
  }

  const clearTrim = () => {
    setValue('signature', '')
    if (signatureRef && signatureRef.current && !readOnly) {
      signatureRef.current.clear()
    } else if (!readOnly) {
      setIsEditingSignature(true)
    }
  }

  const trim = () => {
    if (signatureRef && signatureRef.current) {
      return signatureRef.current.toDataURL()
    }
  }

  if (getTaskQuery.isPending || isPending)
    return (
      <Stack alignItems="center">
        <CircularProgress size={80} />
      </Stack>
    )

  if (getTaskQuery.isError)
    return <DialogDetailError onRetry={() => getTaskQuery.refetch()} />

  const task = getTaskQuery.data

  return (
    <FormProvider {...methods}>
      <DialogContent ref={contentRef}>
        <Stack direction="column" gap={4}>
          <Box>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1Bold" sx={{ pb: 2 }}>
                #{task.id} - {task.name}
              </Typography>
              <IconButton
                onClick={() => {
                  handlePrint()
                }}
                sx={{ '@media print': { display: 'none' } }}
              >
                <Print />
              </IconButton>
            </Stack>
            <Typography variant="body2Regular" sx={{ width: '75%' }}>
              {task.description}
            </Typography>
          </Box>
          <Header task={task} />
          <ArrivalPart readOnly={readOnly} />
          {task.toDoList && (
            <TodoList items={task.toDoList} readOnly={readOnly} />
          )}

          <Stack
            direction="column"
            justifyContent="space-between"
            sx={{
              pageBreakBefore: 'always',
              '@media print': { height: '90vh' },
            }}
          >
            <Notes readOnly={readOnly} />
            {task.status !== TaskStatus.IN_PROGRESS && (
              <Stack
                justifyContent="space-between"
                gap={1}
                sx={{
                  direction: 'row',
                  '@media print': {
                    direction: 'column',
                  },
                }}
              >
                <FormCheckBox
                  controller={{
                    control,
                    name: 'isConfirmed',
                  }}
                  field={{
                    disabled: readOnly,
                  }}
                  label={checkBoxLabel}
                />
                <Box
                  width={400}
                  height={150}
                  marginLeft="auto"
                  position="relative"
                >
                  <IconButton
                    sx={{
                      top: 2,
                      right: 2,
                      position: 'absolute',
                    }}
                    onClick={clearTrim}
                  >
                    <ReplayIcon />
                  </IconButton>
                  {data?.signature && !isEditingSignature ? (
                    <img src={data.signature} width={400} height={150} />
                  ) : (
                    <Controller
                      control={control}
                      name="signature"
                      render={({ field: { onChange } }) => (
                        <SignatureCanvas
                          penColor={theme.palette.primary.main}
                          canvasProps={{ width: 400, height: 150 }}
                          backgroundColor={theme.palette.lightGray.main}
                          ref={signatureRef}
                          onEnd={() => onChange(trim())}
                        />
                      )}
                    />
                  )}
                  <Typography variant="body3Regular">
                    Signature of client
                  </Typography>
                </Box>
              </Stack>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        {task.status === TaskStatus.TO_BE_CHECKED_BY_ADMIN ? (
          <>
            <Button
              variant="text"
              onClick={returnToTechnician}
              disabled={
                user?.groups !== UserGroups.PROVIDER_ADMIN &&
                user?.groups !== UserGroups.PROVIDER_SUPER_ADMIN
              }
            >
              Return to technician
            </Button>
            <Button
              variant="contained"
              onClick={completeTask}
              disabled={
                user?.groups !== UserGroups.PROVIDER_SUPER_ADMIN &&
                user?.groups !== UserGroups.PROVIDER_ADMIN
              }
            >
              Complete task
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="text"
              onClick={() => {
                setValue('type', 'draft')

                handleSubmit(onSaveAsConcept)()
              }}
            >
              Save as concept
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setValue('type', 'confirmed')

                handleSubmit(onComplete)()
              }}
              disabled={
                !useWatchSignature ||
                !useWatchIsConfirmed ||
                (task.status !== TaskStatus.WAITING_ON_WP &&
                  task.status !== TaskStatus.RECURRED)
              }
            >
              Sign and complete
            </Button>
          </>
        )}
      </DialogActions>
    </FormProvider>
  )
}

export default WorkProtocolDialogContent
