import {
  FormAutoComplete,
  FormDatePicker,
  FormSelect,
  FormTaskTypeSelect,
} from '@aeqoom/components-web'
import { TaskPriority } from '@aeqoom/db'
import { CreateTaskSchemaForm, useCreateTaskSchema } from '@aeqoom/forms'
import { useDidUpdateEffect } from '@aeqoom/hooks'
import { PERMISSIONS } from '@aeqoom/permissions'
import CreateTodoListDialogContent from '@app/components/base/dialogs/createForms/CreateTodoListDialogContent'
import { FormTextField } from '@app/components/base/form/FormTextField'
import { FormUploadField } from '@app/components/base/form/FormUploadField'
import CheckPermissions from '@app/components/CheckPermissions'
import Section from '@app/components/Section'
import { useGetCompanies } from '@app/queries/useCompanies'
import { useGetCompanyUnits } from '@app/queries/useCompanyUnits'
import { useGetComponentInstances } from '@app/queries/useComponentInstances'
import { useGetMachineInstances } from '@app/queries/useMachineInstances'
import { useGetSlas } from '@app/queries/useServiceLevelAgreement'
import { useCreateTask } from '@app/queries/useTasks'
import { useGetToDoLists } from '@app/queries/useToDoLists'
import useUserStore from '@app/src/stores/useUserStore'
import { TASK_PRIORITY } from '@app/src/utils/labels'
import { useDialogStore } from '@app/stores/useDialogStore'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Stack,
} from '@mui/material'
import { useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

type Props = {
  defaultValues: Partial<CreateTaskSchemaForm>
  callback?: (id?: number) => void
}

const CreateTask = ({ defaultValues, callback }: Props) => {
  const { user, hasPermission } = useUserStore()
  const canListSlas = hasPermission(PERMISSIONS.SERVICE_LEVEL_AGREEMENT.LIST)

  const { closeDialog, openDialog, closeTab, setShouldAskBeforeClose } =
    useDialogStore()

  const { t } = useTranslation('web', { keyPrefix: 'createTask' })

  const createTaskSchema = useCreateTaskSchema()

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    resetField,
    formState: { isDirty },
  } = useForm<CreateTaskSchemaForm>({
    mode: 'onBlur',
    resolver: zodResolver(createTaskSchema),
    defaultValues: {
      componentInstanceId: null,
      machineInstanceId: null,
      ...(!hasPermission(PERMISSIONS.COMPANY.LIST)
        ? {
            companyId: user?.company?.id,
          }
        : {}),
      ...(!hasPermission(PERMISSIONS.COMPANY_UNIT.LIST)
        ? {
            companyUnitId: user?.companyUnit?.id,
          }
        : {}),
      ...defaultValues,
    },
  })

  const [
    watchCompanyId,
    watchCompanyUnitId,
    watchComponentInstanceId,
    watchMachineInstanceId,
    watchServiceLevelAgreementId,
  ] = useWatch({
    control,
    name: [
      'companyId',
      'companyUnitId',
      'componentInstanceId',
      'machineInstanceId',
      'serviceLevelAgreementId',
    ],
  })

  const getToDoListsQuery = useGetToDoLists()

  const getSlasQuery = useGetSlas({
    query: { companyUnitId: watchCompanyUnitId, companyId: watchCompanyId },
    enabled: canListSlas,
  })

  const getComponentInstancesQuery = useGetComponentInstances({
    query: {
      companyId: watchCompanyId,
      companyUnitId: watchCompanyUnitId,
      serviceLevelAgreementId: watchServiceLevelAgreementId,
    },
  })

  const getMachineInstancesQuery = useGetMachineInstances({
    query: {
      companyId: watchCompanyId,
      companyUnitId: watchCompanyUnitId,
      serviceLevelAgreementId: watchServiceLevelAgreementId,
    },
  })

  const getCompaniesQuery = useGetCompanies()
  const getCompanyUnitsQuery = useGetCompanyUnits({
    companyId: watchCompanyId,
  })

  const createTaskMutation = useCreateTask()

  const onSubmit = (data: CreateTaskSchemaForm) => {
    const body = {
      ...data,
      // TODO fix after media in task feature is done
      media: data.media ? data.media : [],
    }

    createTaskMutation.mutate(
      { ...body, userId: user?.id },
      {
        onSuccess: (data) => {
          callback ? callback(data.id) : closeDialog()
          toast.success(data.message)
          reset()
        },
      }
    )
  }

  useEffect(() => {
    setShouldAskBeforeClose(isDirty)
  }, [isDirty])

  useDidUpdateEffect(() => {
    resetField('companyUnitId')
  }, [watchCompanyId])

  useDidUpdateEffect(() => {
    resetField('serviceLevelAgreementId')
  }, [watchCompanyUnitId])

  useDidUpdateEffect(() => {
    setValue('machineInstanceId', null)
    setValue('componentInstanceId', null)
  }, [watchCompanyId, watchCompanyUnitId, watchServiceLevelAgreementId])

  return (
    <>
      <DialogContent>
        <Grid item xs={12}>
          <Grid spacing={2} container>
            <Section title="Create task">
              <Grid item xs={12}>
                <FormTextField
                  field={{
                    label: 'Task name',
                    required: true,
                    helperText: 'Enter name of Task',
                    fullWidth: true,
                  }}
                  controller={{
                    control,
                    name: 'name',
                  }}
                />
              </Grid>
              <Grid item xs={6} justifyContent="start" container>
                <FormTaskTypeSelect
                  select={{ required: true }}
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <FormSelect
                  label="Priority"
                  name="priority"
                  select={{ required: true }}
                  control={control}
                  options={Object.values(TaskPriority).map((value) => (
                    <MenuItem key={value} value={value}>
                      {TASK_PRIORITY[value]}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={6}>
                <FormDatePicker
                  controller={{
                    control,
                    name: 'deadline',
                  }}
                  datePicker={{
                    label: 'Deadline',
                    timezone: 'UTC',
                  }}
                  field={{ fullWidth: true, required: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormAutoComplete
                  control={control}
                  name="toDoListId"
                  autocomplete={{
                    getOptionLabel: (option) => {
                      return option.name
                    },
                    options: getToDoListsQuery.data,
                    fullWidth: true,
                  }}
                  fieldProps={{ label: 'To do list' }}
                  add={{
                    onClick: () =>
                      openDialog(
                        <CreateTodoListDialogContent
                          callback={(id) => {
                            setValue('toDoListId', id)
                            closeTab()
                          }}
                        />,
                        'Create to do list'
                      ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  controller={{
                    control,
                    name: 'description',
                  }}
                  field={{
                    label: 'Description',
                    rows: 4,
                    fullWidth: true,
                    multiline: true,
                  }}
                />
              </Grid>
            </Section>
            <Section title="Select contract">
              <CheckPermissions permissions={[PERMISSIONS.COMPANY.LIST]}>
                <Grid item xs={6}>
                  <FormAutoComplete
                    control={control}
                    name="companyId"
                    autocomplete={{
                      getOptionLabel: (option) => {
                        return option.name
                      },
                      options: getCompaniesQuery.data,
                      fullWidth: true,
                    }}
                    fieldProps={{ label: 'Company', required: true }}
                  />
                </Grid>
              </CheckPermissions>
              <CheckPermissions permissions={[PERMISSIONS.COMPANY_UNIT.LIST]}>
                <Grid item xs={6}>
                  <FormAutoComplete
                    control={control}
                    name="companyUnitId"
                    autocomplete={{
                      getOptionLabel: (option) => {
                        return option.name
                      },
                      options: getCompanyUnitsQuery.data,
                      disabled: !watchCompanyId,
                      fullWidth: true,
                    }}
                    fieldProps={{
                      label: 'Company unit',
                      required: true,
                    }}
                  />
                </Grid>
              </CheckPermissions>
              <Grid item xs={6}>
                <FormAutoComplete
                  control={control}
                  name="serviceLevelAgreementId"
                  autocomplete={{
                    getOptionLabel: (option) => {
                      return option.name
                    },
                    options: getSlasQuery.data,
                    fullWidth: true,
                    disabled: !watchCompanyUnitId,
                    readOnly: !hasPermission([
                      PERMISSIONS.SERVICE_LEVEL_AGREEMENT.LIST,
                    ]),
                  }}
                  fieldProps={{
                    label: 'Service level agreement',
                  }}
                />
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <CheckPermissions
                  permissions={[
                    PERMISSIONS.MACHINE_INSTANCE.LIST,
                    PERMISSIONS.COMPONENT_INSTANCE.LIST,
                  ]}
                >
                  <Grid item xs={6}>
                    <FormAutoComplete
                      control={control}
                      name="machineInstanceId"
                      autocomplete={{
                        getOptionLabel: (option) => {
                          return option.name
                        },
                        options: getMachineInstancesQuery.data,
                        fullWidth: true,
                        disabled:
                          !!watchComponentInstanceId ||
                          !watchCompanyId ||
                          !watchCompanyUnitId,
                      }}
                      fieldProps={{
                        label: 'Machine Instance',
                        helperText: t('machineInstance.selectAtLeastCompany'),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormAutoComplete
                      control={control}
                      name="componentInstanceId"
                      autocomplete={{
                        getOptionLabel: (option) => {
                          return option.name
                        },
                        options: getComponentInstancesQuery.data,
                        fullWidth: true,
                        disabled:
                          !!watchMachineInstanceId ||
                          !watchCompanyId ||
                          !watchCompanyUnitId,
                      }}
                      fieldProps={{
                        label: 'Component Instance',
                        helperText: t('componentInstance.selectAtLeastCompany'),
                      }}
                    />
                  </Grid>
                </CheckPermissions>
              </Grid>
            </Section>
            <Section title="Media">
              <Grid item xs={12}>
                <FormUploadField control={control} name="media" />
              </Grid>
            </Section>
          </Grid>
        </Grid>
      </DialogContent>
      <CheckPermissions permissions={[PERMISSIONS.TASK.CREATE]}>
        <DialogActions>
          <Stack
            width="100%"
            alignItems="center"
            justifyContent="end"
            direction="row"
            gap={1}
          >
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={createTaskMutation.isPending}
            >
              {createTaskMutation.isPending ? 'Creating...' : 'Create task'}
            </Button>
          </Stack>
        </DialogActions>
      </CheckPermissions>
    </>
  )
}

export default CreateTask
