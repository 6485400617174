export enum UserGroups {
  CLIENT_SUPER_ADMIN = 'ClientSuperAdmin',
  CLIENT_UNIT_ADMIN = 'ClientUnitAdmin',
  PROVIDER_SUPER_ADMIN = 'ProviderSuperAdmin',
  PROVIDER_ADMIN = 'ProviderAdmin',
  TECHNICIAN = 'Technician',
}

export const UserGroupsByAccess = [
  UserGroups.TECHNICIAN,
  UserGroups.CLIENT_UNIT_ADMIN,
  UserGroups.CLIENT_SUPER_ADMIN,
  UserGroups.PROVIDER_ADMIN,
  UserGroups.PROVIDER_SUPER_ADMIN,
] as const;
