import { useThemeColors } from '@aeqoom/hooks'
import { TaskType } from '@aeqoom/shared'
import { GetTasksTask, TaskStatus } from '@aeqoom/types'
import dayjs from 'dayjs'

export const useTaskStatusColor = (
  status: GetTasksTask['status'],
  type: TaskType,
  plannedTo: Date | null
) => {
  const palette = useThemeColors()

  const { secondary, success, warning, info, error } = palette

  const statusColorMap: Record<GetTasksTask['status'], string> = {
    [TaskStatus.IN_PROGRESS]: dayjs(plannedTo).isBefore(Date.now())
      ? warning.main
      : success.main,
    [TaskStatus.PLANNED]: info.main,
    [TaskStatus.WAITING_ON_WP]: dayjs(plannedTo).isBefore(Date.now())
      ? warning.main
      : success.main,
    [TaskStatus.TO_BE_CHECKED_BY_ADMIN]: info.main,
    [TaskStatus.TO_BE_CHECKED_BY_CLIENT]: info.main,
    [TaskStatus.RECURRED]: warning.main,
    [TaskStatus.BLOCKED]: error.main,
    [TaskStatus.NEW]: secondary.main,
    [TaskStatus.DONE]: secondary.main,
    [TaskStatus.ACKNOWLEDGED]: info.main,
    [TaskStatus.ARCHIVED]: secondary.dark,
  }

  return type !== "incident"
    ? statusColorMap[status]
    : error.main
}
