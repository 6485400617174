import { CompanyUnitResponse } from '@aeqoom/contracts'
import DialogContent from '@app/components/base/dialogs/_detailViewDialogs/_components/DialogContent'
import ContactCompanyUnit from '@app/components/ContactCompanyUnit'
import { useTranslation } from 'react-i18next'

import CompanyInfo from './_tabs/CompanyUnitInfo'

type Props = {
  data: CompanyUnitResponse
}

const Content = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'companyUnit.detail',
  })

  const TABS = [
    {
      component: (data: CompanyUnitResponse) => <CompanyInfo data={data} />,
      header: t('tabs.companyUnitDetail.label'),
    },
    {
      component: (data: CompanyUnitResponse) => (
        <ContactCompanyUnit companyUnitId={data.id} />
      ),
      header: t('tabs.contacts.label'),
    },
  ]

  return (
    <DialogContent
      tabs={TABS}
      data={data}
      title={t('header.title', { name: data.name })}
    />
  )
}

export default Content
