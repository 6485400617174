import { IDialogConfig } from '@app/stores/useDialogStore/types'
import WebAssetIcon from '@mui/icons-material/WebAsset'

export const DEFAULT_DIALOG_CONFIG: Omit<IDialogConfig, 'dialogId'> = {
  shouldShowTitle: true,
  closeOnBackdrop: false,
  closeOnEsc: false,
  maxWidth: 'lg',
  fullWidth: true,
  icon: WebAssetIcon,
  shouldShowBreadcrumb: true,
  isUnique: true,
  uniqueKey: null,
}
