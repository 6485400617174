import { Add } from '@mui/icons-material'
import {
  Autocomplete,
  AutocompleteProps as MUIAutocompleteProps,
  Button,
  CircularProgress,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { ReactNode } from 'react'
import {
  Control,
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import FormHelperTooltip from '../FormHelperTooltip'
import ActionButton from './ActionButton'
import { getListboxComponent } from './ListBoxComponent'
import { Option } from './Option'
import { IconArrow, IconClear } from './styled'

export type AutocompleteProps<V extends { id: number }> = Omit<
  MUIAutocompleteProps<V, false, boolean | undefined, false>,
  'renderInput' | 'options'
> & { options: V[] | undefined }

export type FormAutoCompleteProps<
  T extends FieldValues,
  V extends { id: number }
> = {
  control: Control<T>
  name: Path<T>
  rules?: UseControllerProps<T>['rules']
  autocomplete?: AutocompleteProps<V>
  fieldProps?: TextFieldProps & { disableHelperText?: boolean }
  add?: {
    onClick: () => void
    label?: string
  } | null
  actions?: ReactNode[]
  sx?: AutocompleteProps<V>['sx']
  helperText?: string
}

const FormAutoComplete = <T extends FieldValues, V extends { id: number }>({
  control,
  name,
  autocomplete,
  fieldProps,
  add,
  actions = [],
  rules,
  helperText,
}: FormAutoCompleteProps<T, V>) => {
  const { t } = useTranslation('common', { keyPrefix: 'inputs.autoComplete' })

  const { field, fieldState } = useController({
    control,
    name,
    rules,
  })

  const { onChange, value, ref, onBlur, disabled } = field
  const { error } = fieldState

  const selectedOption =
    autocomplete?.options?.find((option) => option.id === value) ?? null

  const isInErrorState = !disabled && !!error

  return (
    <Autocomplete
      popupIcon={<IconArrow />}
      clearIcon={<IconClear />}
      forcePopupIcon
      noOptionsText={
        add ? (
          <Button
            onMouseDown={() => add.onClick()}
            fullWidth
            size="small"
            variant="text"
          >
            <Add />
            {add.label ?? t('defaults.add')}
          </Button>
        ) : undefined
      }
      ListboxComponent={getListboxComponent(
        add
          ? {
              onClick: () => add.onClick(),
              label: add.label ?? t('defaults.add'),
            }
          : undefined
      )}
      renderOption={(props, option) => (
        <Option {...props}>
          {autocomplete?.getOptionLabel?.(option) ?? option.id}
        </Option>
      )}
      {...autocomplete}
      options={autocomplete?.options ?? []}
      readOnly={autocomplete?.readOnly}
      value={selectedOption}
      onBlur={onBlur}
      onChange={(_, value) => onChange(value ? value.id : null)}
      loading={!autocomplete?.options}
      loadingText={
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      }
      renderInput={(params) => (
        <Stack>
          <TextField
            variant="outlined"
            error={isInErrorState}
            {...fieldProps}
            {...params}
            inputRef={ref}
            InputProps={{
              ...params.InputProps,
              endAdornment: !autocomplete?.readOnly && (
                <>
                  {!!actions.length && !!value && actions}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
          {!fieldProps?.disableHelperText && (
            <FormHelperTooltip
              helperText={error?.message ?? helperText}
              error={!!error}
            />
          )}
        </Stack>
      )}
    />
  )
}

FormAutoComplete.ActionButton = ActionButton

export { FormAutoComplete }
