// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { Add } from '@mui/icons-material'
import {
  Autocomplete,
  AutocompleteProps,
  IconButton,
  Stack,
  TextField,
  TextFieldProps,
  Tooltip,
} from '@mui/material'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'

import FormHelperTooltip from '../FormHelperTooltip'

type Props<T extends FieldValues, K> = {
  controller: UseControllerProps<T>
  autocomplete: Omit<
    AutocompleteProps<
      K,
      boolean | undefined,
      boolean | undefined,
      boolean | undefined
    >,
    'getOptionLabel' | 'renderInput'
  > & { getOptionLabel: (option: K) => string }
  field: TextFieldProps
  add?: {
    onClick: () => void
    tooltip?: string
  }
}
/**
 *
 * @deprecated use FormAutoComplete from libs
 */
const FormAutoComplete = <T extends FieldValues, K extends { id: number }>({
  autocomplete,
  controller,
  field,
  add,
}: Props<T, K>) => {
  return (
    <Controller
      {...controller}
      render={({
        field: { onChange, value, ref, onBlur },
        fieldState: { error },
      }) => {
        const helperText = error?.message ?? field.helperText ?? ' '

        return (
          <Stack width="100%" direction="row" alignItems="center">
            <Autocomplete
              value={
                value
                  ? autocomplete?.options?.find((option) => {
                      return value === option?.id
                    }) ?? null
                  : null
              }
              onBlur={onBlur}
              onChange={(_, value) => {
                if (Array.isArray(value)) {
                  onChange(value.map((item) => (item as K).id))
                } else {
                  onChange(value ? (value as K).id : null)
                }
              }}
              {...autocomplete}
              renderInput={(params) => (
                <Stack>
                  <TextField
                    error={!controller.disabled ? !!error : undefined}
                    {...params}
                    {...field}
                    inputRef={ref}
                    variant="outlined"
                  />
                  <FormHelperTooltip helperText={helperText} error={!!error} />
                </Stack>
              )}
            />
            {add && !autocomplete.disabled && !autocomplete.readOnly && (
              <Tooltip title={add?.tooltip}>
                <IconButton
                  sx={{
                    mb: 2.5,
                    ml: 2,
                    color: 'primary.main',
                    backgroundColor: 'green.light',
                    '&:hover': {
                      backgroundColor: 'green.dark',
                    },
                  }}
                  onClick={() => add?.onClick()}
                >
                  <Add />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        )
      }}
    />
  )
}

export default FormAutoComplete
