import { DateVariant } from '@app/types'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'

type Props = {
  variant?: DateVariant
  date: Date | string
  showTime?: boolean
}
const Date = ({
  variant = DateVariant.EXACT,
  date,
  showTime = true,
}: Props) => {
  const formattedDate = dayjs(date).format('DD. MM. YYYY')
  const formattedTime = dayjs(date).format('hh:mm:ss')
  const text = () => {
    switch (variant) {
      case DateVariant.FROM:
        return 'Date of creation:'
      case DateVariant.DEADLINE:
        return 'Deadline:'
      case DateVariant.EXACT:
        return ''
    }
  }

  return (
    <Stack flexWrap="wrap" direction="row" alignItems="center" gap={1}>
      <Stack direction="row" alignItems="center" gap={1}>
        <EventOutlinedIcon width={1} />
        {text().length > 0 && (
          <Typography variant="body3Regular">{text()}</Typography>
        )}
        <Typography variant="body3Regular">{formattedDate}</Typography>
      </Stack>
      {showTime && (
        <Typography variant="body3Regular">{formattedTime}</Typography>
      )}
    </Stack>
  )
}

export default Date
