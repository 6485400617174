import { SaveWorkProtocolRequest } from '@aeqoom/contracts'
import { FormTextField } from '@app/components/base/form/FormTextField'
import { Card, CardContent, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useFormContext } from 'react-hook-form'

type Props = {
  readOnly: boolean
}

export const Notes = ({ readOnly }: Props) => {
  const theme = useTheme()

  const { control } = useFormContext<SaveWorkProtocolRequest['body']>()

  return (
    <Card
      sx={{
        background: theme.palette.lightGray.main,
      }}
    >
      <CardContent>
        <Typography variant="body1Bold" pb={2}>
          Notes
        </Typography>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Stack gap={1}>
              <FormTextField
                controller={{
                  control,
                  name: 'descriptionOfWork',
                }}
                field={{
                  label: 'Description of work',
                  fullWidth: true,

                  rows: 6,
                  multiline: true,
                  InputProps: { readOnly },
                }}
              />
            </Stack>
          </CardContent>
        </Card>
        <Stack direction="row" gap={2} justifyContent="stretch">
          <Card sx={{ width: '50%' }}>
            <CardContent>
              <Stack gap={1}>
                <FormTextField
                  controller={{
                    control,
                    name: 'usedSpareParts',
                  }}
                  field={{
                    label: 'Used spare parts',
                    fullWidth: true,

                    rows: 6,
                    multiline: true,
                    InputProps: { readOnly },
                  }}
                />
              </Stack>
            </CardContent>
          </Card>
          <Card sx={{ width: '50%' }}>
            <CardContent>
              <Stack gap={1}>
                <FormTextField
                  controller={{
                    control,
                    name: 'sparePartsToOrder',
                  }}
                  field={{
                    label: 'Spare parts to order',
                    fullWidth: true,

                    rows: 6,
                    multiline: true,
                    InputProps: { readOnly },
                  }}
                />
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default Notes
