import { DetailButton } from '@aeqoom/components-web'
import { GetTaskResponseBody } from '@aeqoom/contracts'
import { createUniqueKey } from '@aeqoom/utils'
import ComponentInstanceDialog from '@app/components/base/dialogs/_detailViewDialogs/ComponentInstanceDialog'
import MachineInstanceDialog from '@app/components/base/dialogs/_detailViewDialogs/MachineInstanceDialog'
import { useGetCompanyUnit } from '@app/queries/useCompanyUnits'
import { useGetComponentInstance } from '@app/queries/useComponentInstances'
import { useGetMachineInstance } from '@app/queries/useMachineInstances'
import { useGetOneMedia } from '@app/queries/useMedia'
import { useDialogStore } from '@app/stores/useDialogStore'
import { Warning } from '@mui/icons-material'
import { CircularProgress, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  task: GetTaskResponseBody
}

const MachineDetail = ({ task }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'backlog.taskDetail.tabs.taskDetail.machine',
  })

  const machineInstance = useGetMachineInstance({
    id: task.machineInstanceId,
  })

  const componentInstance = useGetComponentInstance({
    id: task.componentInstanceId,
  })

  const isLoading = machineInstance.isLoading || componentInstance.isLoading
  const isError = machineInstance.isError || componentInstance.isError
  const data = machineInstance.data || componentInstance.data

  const { data: companyUnitData } = useGetCompanyUnit({
    id: data?.companyUnitId,
  })
  const { isLoading: isImgLoading, data: avatarUrl } = useGetOneMedia({
    id: data?.avatar,
  })

  const { openDialog } = useDialogStore()

  if (isLoading) {
    return (
      <Stack padding={2} justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    )
  }

  if (isError) {
    return (
      <Stack
        padding={2}
        paddingX={8}
        justifyContent="center"
        alignItems="center"
      >
        <Warning />
        {t('error')}
      </Stack>
    )
  }

  if (!data) {
    return (
      <Stack
        padding={2}
        paddingX={8}
        justifyContent="center"
        alignItems="center"
      >
        <Warning />
        {t('noMachine')}
      </Stack>
    )
  }

  const handleOnClick = () => {
    if (task.machineInstanceId)
      openDialog(
        <MachineInstanceDialog id={task.machineInstanceId} />,
        data.name,
        { uniqueKey: createUniqueKey(['machineInstanceDialog', data.id]) }
      )
    else if (task.componentInstanceId)
      openDialog(
        <ComponentInstanceDialog id={task.componentInstanceId} />,
        data.name,
        { uniqueKey: createUniqueKey(['componentInstanceDialog', data.id]) }
      )
  }

  return (
    <DetailButton
      handleOnClick={handleOnClick}
      name={data.name}
      inventoryNumber={data.inventoryNumber}
      address={companyUnitData}
      avatarUrl={avatarUrl?.signedUrl || undefined}
      isAvatarLoading={isImgLoading}
    />
  )
}

export default MachineDetail
