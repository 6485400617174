import { GridColDef } from '@mui/x-data-grid'

const todoslistItemsDataCol: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 80,
  },
  {
    field: 'toDoListId',
    headerName: 'Todolist ID',
    width: 100,
  },
  {
    field: 'toDoListName',
    valueGetter: (params) => params.row.toDoList?.name,
    headerName: 'Todolist name',
    width: 250,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
  },
  {
    field: 'content',
    headerName: 'Content',
    width: 300,
  },
]

export default todoslistItemsDataCol
