import { GridMapper, LabeledValue } from '@aeqoom/components-web'
import { MachineTemplateResponse } from '@aeqoom/contracts'
import { FIELD_SHARED_STYLES } from '@app/components/base/dialogs/_detailViewDialogs/constants'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  data: MachineTemplateResponse
}

const GRID_MAP = [6, 5, 12]

const MachineInfo = ({ data }: Props) => {
  const { t } = useTranslation('web', {
    keyPrefix: 'machineTemplate.detail.tabs.machineDetail.content',
  })

  const content = [
    <LabeledValue
      label={t('name')}
      value={data.name}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('producer')}
      value={data.producer}
      sx={FIELD_SHARED_STYLES}
      type="single-line"
    />,
    <LabeledValue
      label={t('description')}
      value={data.description}
      sx={FIELD_SHARED_STYLES}
      type="multi-line"
    />,
  ]

  return (
    <Grid container justifyContent="space-between">
      <Grid
        item
        xs={12}
        sx={{
          overflow: 'hidden',
        }}
      >
        <GridMapper template={GRID_MAP} paddingBottom={8}>
          {content}
        </GridMapper>
      </Grid>
    </Grid>
  )
}

export default MachineInfo
