import ErrorOutline from '@mui/icons-material/ErrorOutline'
import { Button, Stack, Typography } from '@mui/material'

const DialogDetailError = ({ onRetry }: { onRetry: () => void }) => {
  return (
    <>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        pb={5}
      >
        <ErrorOutline color="error" style={{ fontSize: 40 }} />
        <Typography variant="h6" color="textSecondary">
          Failed to load data
        </Typography>
      </Stack>
      <Button fullWidth onClick={onRetry} variant="outlined" color="primary">
        Retry
      </Button>
    </>
  )
}

export default DialogDetailError
