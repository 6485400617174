import { DEFAULT_DIALOG_CONFIG } from '@app/stores/useDialogStore/constants'
import {
  DialogId,
  DialogStatus,
  DialogStore,
  State,
} from '@app/stores/useDialogStore/types'
import {
  createDialogId,
  findTargetRoot,
  getCurrentOpenDialog,
  getDialogOrder,
  getDialogToOpenId,
  updateDialogConfig,
} from '@app/stores/useDialogStore/utils'
import { create } from 'zustand'

const initialState: State = {
  activeDialogs: {},
  previousDialogs: {},
  shouldAskBeforeClose: false,
}

export const useDialogStore = create<DialogStore>()((set) => {
  return {
    ...initialState,
    setShouldAskBeforeClose: (shouldAskBeforeClose) =>
      set((prev) => {
        return {
          ...prev,
          shouldAskBeforeClose,
        }
      }),

    openDialog: (mainContent, title, options = {}) =>
      set((prev) => {
        const config = updateDialogConfig(DEFAULT_DIALOG_CONFIG, options)
        const dialogId = createDialogId(title, config, prev)

        const newDialog = {
          [dialogId]: {
            status: DialogStatus.OPEN,
            dialogId,
            mainContent,
            title,
            config,
          },
        }

        const currentOpenDialogId = getCurrentOpenDialog(prev)?.dialogId
        const targetRoot = currentOpenDialogId
          ? findTargetRoot(prev, currentOpenDialogId)
          : undefined

        const isSelectedDialogInActiveDialogs = Object.values(
          prev.activeDialogs
        ).some((dialog) => dialog.dialogId === dialogId)
        const isSelectedDialogInTargetRoot =
          targetRoot?.previousIds.includes(dialogId)

        if (!currentOpenDialogId) {
          if (!isSelectedDialogInActiveDialogs) {
            return {
              ...prev,
              activeDialogs: {
                ...prev.activeDialogs,
                ...newDialog,
              },
            }
          }

          if (isSelectedDialogInActiveDialogs) {
            return {
              ...prev,
              activeDialogs: {
                ...prev.activeDialogs,
                [dialogId]: {
                  ...prev.activeDialogs[dialogId],
                  status: DialogStatus.OPEN,
                },
              },
            }
          }
        }

        if (currentOpenDialogId) {
          if (!targetRoot?.rootId) {
            return {
              ...prev,
              activeDialogs: {
                ...prev.activeDialogs,
                ...newDialog,
                [currentOpenDialogId]: {
                  ...prev.activeDialogs[currentOpenDialogId],
                  status: DialogStatus.CLOSED,
                },
              },
              previousDialogs: {
                ...prev.previousDialogs,
                [currentOpenDialogId]: {
                  rootId: currentOpenDialogId,
                  previousIds: [currentOpenDialogId, dialogId],
                },
              },
            }
          }

          if (targetRoot?.rootId) {
            if (!isSelectedDialogInTargetRoot) {
              return {
                ...prev,
                activeDialogs: {
                  ...prev.activeDialogs,
                  ...newDialog,
                  [currentOpenDialogId]: {
                    ...prev.activeDialogs[currentOpenDialogId],
                    status: DialogStatus.CLOSED,
                  },
                },
                previousDialogs: {
                  ...prev.previousDialogs,
                  [targetRoot.rootId]: {
                    ...prev.previousDialogs[targetRoot.rootId],
                    previousIds: [
                      ...prev.previousDialogs[
                        targetRoot.rootId
                      ].previousIds.slice(
                        0,
                        getDialogOrder(currentOpenDialogId, targetRoot)
                      ),
                      dialogId,
                      ...prev.previousDialogs[
                        targetRoot.rootId
                      ].previousIds.slice(
                        getDialogOrder(currentOpenDialogId, targetRoot)
                      ),
                    ],
                  },
                },
              }
            }
            if (isSelectedDialogInTargetRoot) {
              return {
                ...prev,
                activeDialogs: {
                  ...prev.activeDialogs,
                  [currentOpenDialogId]: {
                    ...prev.activeDialogs[currentOpenDialogId],
                    status: DialogStatus.CLOSED,
                  },
                  [dialogId]: {
                    ...prev.activeDialogs[dialogId],
                    status: DialogStatus.OPEN,
                  },
                },
              }
            }
          }
        }
        return prev
      }),

    closeDialog: () =>
      set((prev) => {
        const prevDialogStore = { ...prev }
        const currentOpenDialogId =
          getCurrentOpenDialog(prevDialogStore)?.dialogId

        if (!currentOpenDialogId) {
          // eslint-disable-next-line no-console
          console.log('No dialog is open')
          return prev
        }

        if (currentOpenDialogId) {
          const targetRoot = findTargetRoot(
            prevDialogStore,
            currentOpenDialogId
          )

          if (targetRoot) {
            targetRoot.previousIds.forEach(
              (dialogId) => delete prevDialogStore.activeDialogs[dialogId]
            )
            delete prevDialogStore.previousDialogs[targetRoot.rootId]

            return {
              ...prevDialogStore,
              shouldAskBeforeClose: false,
            }
          }

          if (!targetRoot) {
            delete prevDialogStore.activeDialogs[currentOpenDialogId]

            return {
              ...prevDialogStore,
              shouldAskBeforeClose: false,
            }
          }
        }
        return prev
      }),

    closeTab: (id, direction) => {
      set((prev) => {
        const currentOpenDialog = getCurrentOpenDialog(prev)
        const selectedDialogId = id
          ? prev.activeDialogs[id].dialogId
          : currentOpenDialog?.dialogId

        if (!selectedDialogId) {
          // eslint-disable-next-line no-console
          console.log('No dialog is selected')
          return prev
        }

        const targetRoot = findTargetRoot(prev, selectedDialogId)

        if (!targetRoot) prev.closeDialog()

        if (targetRoot) {
          if (targetRoot.previousIds.length === 1) prev.closeDialog()
          if (targetRoot.previousIds.length > 1) {
            if (
              prev.activeDialogs[selectedDialogId].status === DialogStatus.OPEN
            ) {
              const dialogToOpenId = getDialogToOpenId(
                targetRoot,
                selectedDialogId,
                direction
              )

              delete prev.activeDialogs[selectedDialogId]

              return {
                ...prev,
                activeDialogs: {
                  ...prev.activeDialogs,
                  [dialogToOpenId]: {
                    ...prev.activeDialogs[dialogToOpenId],
                    status: DialogStatus.OPEN,
                  },
                },
                previousDialogs: {
                  ...prev.previousDialogs,
                  [targetRoot.rootId]: {
                    ...prev.previousDialogs[targetRoot.rootId],
                    previousIds: targetRoot.previousIds.filter(
                      (dialogId) => dialogId !== selectedDialogId
                    ),
                  },
                },
                shouldAskBeforeClose: false,
              }
            }
            if (
              prev.activeDialogs[selectedDialogId].status !== DialogStatus.OPEN
            ) {
              delete prev.activeDialogs[selectedDialogId]

              return {
                ...prev,
                previousDialogs: {
                  ...prev.previousDialogs,
                  [targetRoot.rootId]: {
                    ...prev.previousDialogs[targetRoot.rootId],
                    previousIds: targetRoot.previousIds.filter(
                      (dialogId) => dialogId !== selectedDialogId
                    ),
                  },
                },
                shouldAskBeforeClose: false,
              }
            }
          }
        }
        return prev
      })
    },

    openPreviousDialog: () =>
      set((prev) => {
        const currentOpenDialogId = getCurrentOpenDialog(prev)?.dialogId

        if (!currentOpenDialogId) {
          // eslint-disable-next-line no-console
          console.log('No dialog is open')
          return prev
        }

        if (currentOpenDialogId) {
          const targetRoot = findTargetRoot(prev, currentOpenDialogId)
          if (targetRoot) {
            if (targetRoot.previousIds.indexOf(currentOpenDialogId) === 0)
              throw new Error('No previous dialog')
            if (targetRoot.previousIds.indexOf(currentOpenDialogId) > 0) {
              const DIALOG_TO_OPEN_ID = getDialogToOpenId(
                targetRoot,
                currentOpenDialogId,
                'previous'
              )

              return {
                ...prev,
                activeDialogs: {
                  ...prev.activeDialogs,
                  [currentOpenDialogId]: {
                    ...prev.activeDialogs[currentOpenDialogId],
                    status: DialogStatus.CLOSED,
                  },
                  [DIALOG_TO_OPEN_ID]: {
                    ...prev.activeDialogs[DIALOG_TO_OPEN_ID],
                    status: DialogStatus.OPEN,
                  },
                },
              }
            }
          }
          if (!targetRoot) {
            // eslint-disable-next-line no-console
            console.log('No previous dialog')
            return prev
          }
        }
        return prev
      }),

    minimizeDialog: () =>
      set((prev) => {
        const currentOpenDialogId = getCurrentOpenDialog(prev)?.dialogId

        if (currentOpenDialogId) {
          return {
            ...prev,
            activeDialogs: {
              ...prev.activeDialogs,
              [currentOpenDialogId]: {
                ...prev.activeDialogs[currentOpenDialogId],
                status: DialogStatus.MINIMIZED,
              },
            },
            shouldAskBeforeClose: false,
          }
        } else {
          // eslint-disable-next-line no-console
          console.log('No dialog is open')
          return prev
        }
      }),

    maximizeDialog: (id: DialogId) =>
      set((prev) => {
        const currentOpenDialogId = getCurrentOpenDialog(prev)?.dialogId

        const isInActiveDialogs = !!prev.activeDialogs[id]

        if (!isInActiveDialogs) {
          // eslint-disable-next-line no-console
          console.log('Dialog does not exist in active dialogs')
          return prev
        }

        if (currentOpenDialogId) {
          return {
            ...prev,
            activeDialogs: {
              ...prev.activeDialogs,
              [currentOpenDialogId]: {
                ...prev.activeDialogs[currentOpenDialogId],
                status: DialogStatus.CLOSED,
              },
              [id]: {
                ...prev.activeDialogs[id],
                status: DialogStatus.OPEN,
              },
            },
          }
        } else {
          return {
            ...prev,
            activeDialogs: {
              ...prev.activeDialogs,
              [id]: {
                ...prev.activeDialogs[id],
                status: DialogStatus.OPEN,
              },
            },
          }
        }
      }),

    clearDialogStore: () => set(() => initialState),
  }
})
