import { tasks } from '@aeqoom/db'
import {
  taskPrioritySchema,
  taskStatusSchema,
  taskTypeSchema,
} from '@aeqoom/shared'
import { z } from '@aeqoom/zod'
import { ClientInferResponseBody, initContract } from '@ts-rest/core'
import { createSelectSchema } from 'drizzle-zod'

import { globalFilter } from '../schemas'

const c = initContract()

const backlogRouter = c.router(
  {
    getBacklog: {
      method: 'GET',
      path: '/backlog',
      query: z.intersection(
        z.object({
          companyId: z.coerce.number().nullish(),
          companyUnitId: z.coerce.number().nullish(),
          type: z.array(taskTypeSchema).optional(),
          status: z.array(taskStatusSchema).optional(),
          priority: z.array(taskPrioritySchema).optional(),
          assigneeId: z.coerce.number().optional(),
        }),
        globalFilter
      ),
      responses: {
        200: z.object({
          count: z.number(),
          tasks: z.array(
            z.object({
              ...createSelectSchema(tasks).pick({
                id: true,
                name: true,
                deadline: true,
                type: true,
                priority: true,
                status: true,
              }).shape,
              companyUnit: z.object({
                name: z.string(),
                avatar: z.string().nullish(),
              }),
              assignedTo: z.object({
                firstName: z.string(),
                lastName: z.string(),
                avatar: z.string().nullish(),
              }),
            })
          ),
        }),
      },
    },
  },
  { strictStatusCodes: true }
)

export type GetBacklogResponseBody = ClientInferResponseBody<
  typeof backlogRouter.getBacklog,
  200
>

export default backlogRouter
