import { MIME_TYPES } from '@aeqoom/shared'
import useMediaCarouselStore from '@app/src/stores/useMediaCarouselStore'

import MediaCarouselAudio from './Audio'
import MediaCarouselDocument from './Document'
import MediaCarouselImage from './Image'
import MediaCarouselVideo from './Video'

const MediaCarouselContent = () => {
  const { currentMedia } = useMediaCarouselStore()

  if (!currentMedia) return null

  if (MIME_TYPES.image.includes(currentMedia.mimeType))
    return <MediaCarouselImage />

  if (MIME_TYPES.audio.includes(currentMedia.mimeType))
    return <MediaCarouselAudio />

  if (MIME_TYPES.video.includes(currentMedia.mimeType))
    return <MediaCarouselVideo />

  if (MIME_TYPES.document.includes(currentMedia.mimeType))
    return <MediaCarouselDocument />
}

export default MediaCarouselContent
