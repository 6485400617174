import EditingContext from '@app/src/contexts/EditingContext'
import { ReactNode } from 'react'

type Props = { children: ReactNode; isEditing: boolean }

const EditingProvider = ({ children, isEditing }: Props) => {
  return (
    <EditingContext.Provider
      value={{
        isEditing,
      }}
    >
      {children}
    </EditingContext.Provider>
  )
}

export default EditingProvider
