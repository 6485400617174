import { TaskPriority, TaskStatus, TaskType } from '@aeqoom/db'
import { useThemeColors } from "@aeqoom/hooks"
import { TASK_PRIORITY, TASK_STATUS, TASK_TYPE } from '@app/src/utils/labels'
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Chip, Tooltip, useTheme } from '@mui/material'

type Props = {
  label: string
}

const TaskChip = ({ label }: Props) => {
  const { status, lightGray, priority, red } = useThemeColors()
  const theme = useTheme()

  const chipIcon = () => {
    if (label === TaskType.INCIDENT) {
      return <WarningAmberIcon />
    } else if (label === TaskType.MAINTENANCE) {
      return <BuildOutlinedIcon />
    }
  }

  if (Object.values(TaskType).includes(label as TaskType)) {
    return (
      <Tooltip title={TASK_TYPE[label as TaskType]}>
        <Chip
          label={TASK_TYPE[label as TaskType]}
          icon={chipIcon()}
          sx={{
            background:
              label === TaskType.INCIDENT ? `${red.main}1A` : lightGray.main,
          }}
        />
      </Tooltip>
    )
  } else if (Object.values(TaskPriority).includes(label as TaskPriority)) {
    return (
      <Tooltip title={TASK_PRIORITY[label as TaskPriority]}>
        <Chip
          label={TASK_PRIORITY[label as TaskPriority]}
          sx={{
            background: priority[label as TaskPriority].main,
            color: priority[label as TaskPriority].contrastText,
            borderRadius: theme.spacing(1),
          }}
        />
      </Tooltip>
    )
  } else if (Object.values(TaskStatus).includes(label as TaskStatus)) {
    return (
      <Tooltip title={TASK_STATUS[label as TaskStatus]}>
        <Chip
          label={TASK_STATUS[label as TaskStatus]}
          icon={
            <FiberManualRecordIcon
              sx={{ fill: status[label as TaskStatus].contrastText }}
            />
          }
          sx={{
            background: status[label as TaskStatus].main,
            color: status[label as TaskStatus].contrastText,
            maxWidth: 120,
          }}
        />
      </Tooltip>
    )
  }
}

export default TaskChip
