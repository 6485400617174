import { GridColDef } from '@mui/x-data-grid'

export const usersDataCol: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 80 },
  { field: 'emailAddress', headerName: 'Email', flex: 4 },
  { field: 'firstName', headerName: 'First name', flex: 3 },
  { field: 'lastName', headerName: 'Last name', flex: 3 },
  {
    field: 'company',
    headerName: 'Company',
    flex: 2,
    valueGetter: (params) => params.row.company?.name,
  },
  {
    field: 'companyUnit',
    headerName: 'Company unit',
    flex: 2,
    valueGetter: (params) => params.row.companyUnit?.name,
  },
  {
    field: 'activationState',
    headerName: 'Status',
    flex: 2,
    valueGetter: (params) =>
      params.row.activationState === 'disabled' ? 'Deactivated' : 'Active',
  },
]

export const changeActivationCellProps = {
  field: 'action',
  disableColumnMenu: true,
  headerName: 'Action',
  sortable: false,
  width: 70,
  align: 'left',
  cellClassName: 'actions',
} as const

export default usersDataCol
