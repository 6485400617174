import { componentTemplates } from '@aeqoom/db'
import { z } from '@aeqoom/zod'
import { ClientInferRequest, initContract } from '@ts-rest/core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

const componentTemplateResponse200 = createSelectSchema(componentTemplates)

const c = initContract()

const componentTemplatesRouter = c.router({
  getComponentTemplates: {
    method: 'GET',
    path: '/component-templates',
    responses: {
      200: z.array(createSelectSchema(componentTemplates)),
    },
  },
  getComponentTemplate: {
    method: 'GET',
    path: '/component-templates/:id/get',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: componentTemplateResponse200,
      404: z.object({ message: z.string() }),
    },
  },
  addComponentTemplate: {
    method: 'POST',
    path: '/component-templates',
    body: createInsertSchema(componentTemplates),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  deleteComponentTemplate: {
    method: 'DELETE',
    path: '/component-templates/:id/delete',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({}),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
  editComponentTemplate: {
    method: 'POST',
    path: '/component-templates/:id/edit',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    body: z.object({
      ...createInsertSchema(componentTemplates).shape,
    }),
    responses: {
      200: z.object({
        id: z.number(),
        message: z.string(),
      }),
    },
  },
})

export type AddComponentTemplate = ClientInferRequest<
  typeof componentTemplatesRouter.addComponentTemplate
>

export type EditComponentTemplate = ClientInferRequest<
  typeof componentTemplatesRouter.editComponentTemplate
>

export type DeleteComponentTemplate = ClientInferRequest<
  typeof componentTemplatesRouter.deleteComponentTemplate
>

export type ComponentTemplateResponse = z.infer<
  typeof componentTemplateResponse200
>

export default componentTemplatesRouter
