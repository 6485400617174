import {
  AddComponentTemplate,
  DeleteComponentTemplate,
  EditComponentTemplate,
} from '@aeqoom/contracts'
import { PERMISSIONS } from '@aeqoom/permissions'
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import useUserStore from '../stores/useUserStore'
import { client } from './client'
import parseIncoming from './parseIncoming'

const keys = {
  getComponentTemplates: ['component-templates'],
  getComponentTemplate: ['component-template'],
}

const invalidateComponentTemplateQueries = (queryClient: QueryClient) => {
  const allKeys = Object.values(keys).flat()
  queryClient.invalidateQueries({
    predicate: (query) => allKeys.includes(query.queryKey[0] + ''),
  })
}

export const useGetComponentTemplates = () => {
  const { hasPermission } = useUserStore()

  return useQuery({
    queryKey: keys.getComponentTemplates,
    enabled: hasPermission(PERMISSIONS.COMPONENT_TEMPLATE.LIST),
    queryFn: () =>
      client.componentTemplates.getComponentTemplates().then(parseIncoming),
  })
}

export const useEditComponentTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateComponentTemplateQueries(queryClient)
    },
    mutationFn: async ({
      body,
      params,
    }: {
      body: EditComponentTemplate['body']
      params: EditComponentTemplate['params']
    }) =>
      client.componentTemplates
        .editComponentTemplate({ body, params })
        .then(parseIncoming),
  })
}

export const useGetComponentTemplate = ({
  id,
  enabled = !!id,
  staleTime,
}: {
  id?: number | null
  enabled?: boolean
  staleTime?: number
}) => {
  const { hasPermission } = useUserStore()

  return useQuery({
    enabled: enabled && hasPermission(PERMISSIONS.COMPONENT_TEMPLATE.READ),
    queryKey: [...keys.getComponentTemplate, id],
    queryFn: () =>
      client.componentTemplates
        .getComponentTemplate({ params: { id: id! } })
        .then(parseIncoming),
    staleTime,
  })
}

export const useCreateComponentTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      invalidateComponentTemplateQueries(queryClient)
    },
    mutationFn: (body: AddComponentTemplate['body']) =>
      client.componentTemplates
        .addComponentTemplate({ body })
        .then(parseIncoming),
  })
}

export const useDeleteComponentTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: DeleteComponentTemplate['params']) =>
      client.componentTemplates
        .deleteComponentTemplate({ params })
        .then(parseIncoming),
    onSuccess: () => {
      invalidateComponentTemplateQueries(queryClient)
    },
  })
}
