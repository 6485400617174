import { Grid, GridProps } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode[]
  template: number[]
} & GridProps

export const GridMapper = ({ children, template, ...props }: Props) => {
  return (
    <Grid container gap={4} {...props}>
      {children.map((child, index) => (
        <Grid item xs={template[index]} key={index}>
          {child}
        </Grid>
      ))}
    </Grid>
  )
}
