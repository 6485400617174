import {
  companyUnits,
  componentInstances,
  machineInstances,
  media,
  mediaOnToDoListItem,
  serviceLevelAgreements,
  tasks,
  toDoListItemOnTask,
  toDoListItems,
  toDoListOnTask,
  toDoLists,
  users,
} from '@aeqoom/db'
import { z } from '@aeqoom/zod'
import { initContract } from '@ts-rest/core'
import { createSelectSchema } from 'drizzle-zod'

const c = initContract()

const qrDataResponse200 = z.union([
  z.object({
    entity: z.literal('componentInstances'),
    data: z.object({
      ...createSelectSchema(componentInstances).shape,
      media: z.array(createSelectSchema(media)).nullable(),
      serviceLevelAgreementId: z.number().nullable().optional(),
      serviceLevelAgreement: z.union([
        z.object({
          ...createSelectSchema(serviceLevelAgreements).shape,
        }),
        z.undefined(),
      ]),
    }),
  }),
  z.object({
    entity: z.literal('machineInstances'),
    data: z.object({
      ...createSelectSchema(machineInstances).shape,
      componentInstances: z
        .array(
          z.object({
            ...createSelectSchema(componentInstances).shape,
          })
        )
        .optional(),
      media: z.array(createSelectSchema(media)).nullable().optional(),
      serviceLevelAgreementId: z.number().nullable().optional(),
      serviceLevelAgreement: z.union([
        z.object({
          ...createSelectSchema(serviceLevelAgreements).shape,
        }),
        z.undefined(),
      ]),
    }),
  }),
  z.object({
    entity: z.literal('tasks'),
    data: z.object({
      ...createSelectSchema(tasks).shape,
      user: createSelectSchema(users, {
        avatar: createSelectSchema(media),
      }).nullable(),
      toDoList: z
        .object({
          ...createSelectSchema(toDoListOnTask).shape,
          toDoListItems: z.array(
            z.object({
              ...createSelectSchema(toDoListItemOnTask).shape,
              template: z.object({
                ...createSelectSchema(toDoListItems).shape,
                media: z.array(
                  z.object({
                    ...createSelectSchema(mediaOnToDoListItem).shape,
                    media: createSelectSchema(media),
                  })
                ),
              }),
            })
          ),
          template: createSelectSchema(toDoLists),
        })
        .nullable(),
      serviceLevelAgreement: z
        .object({
          ...createSelectSchema(serviceLevelAgreements).shape,
          companyUnit: z.object({
            ...createSelectSchema(companyUnits).shape,
            avatar: createSelectSchema(media).nullable(),
          }).nullable(),
        })
        .nullable(),
    }),
  }),
])

const qrcodeRouter = c.router({
  getQrcodeData: {
    method: 'GET',
    path: '/qrcode/:entity/:uuid/get',
    pathParams: z.object({
      entity: z.enum(['componentInstances', 'machineInstances', 'tasks']),
      uuid: z.string(),
    }),
    responses: {
      200: qrDataResponse200,
      404: z.object({ message: z.string() }),
    },
  },
  getQrcode: {
    method: 'GET',
    path: '/qrcode/:entity/:uuid',
    pathParams: z.object({
      entity: z.enum(['componentInstances', 'machineInstances', 'tasks']),
      uuid: z.string(),
    }),
    responses: {
      200: z.object({ qrcode: z.string() }),
      500: z.object({ message: z.string() }),
    },
  },
})

export type QrData = z.infer<typeof qrDataResponse200>

export default qrcodeRouter
