import { MIME_TYPES } from '@aeqoom/shared'
import {
  Article,
  AudioFile,
  Image,
  InsertDriveFile,
  VideoFile,
} from '@mui/icons-material'
import { SvgIconProps } from '@mui/material'

type Props = {
  mimeType: string
} & SvgIconProps

const ICONS = [
  {
    mimeType: MIME_TYPES.image,
    icon: Image,
  },
  {
    mimeType: MIME_TYPES.video,
    icon: VideoFile,
  },
  {
    mimeType: MIME_TYPES.document,
    icon: Article,
  },
  {
    mimeType: MIME_TYPES.audio,
    icon: AudioFile,
  },
]

const FileIcon = ({ mimeType, ...props }: Props) => {
  const Icon =
    ICONS.find((icon) => icon.mimeType.includes(mimeType))?.icon ??
    InsertDriveFile

  return <Icon fontSize="large" {...props} />
}

export default FileIcon
