import { signInSchema, SignInSchemaForm } from '@aeqoom/forms'
import { FormTextField } from '@app/components/base/form/FormTextField'
import useUser from '@app/hooks/useUser'
import { zodResolver } from '@hookform/resolvers/zod'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { LoadingButton } from '@mui/lab'
import { Alert, Avatar, Box, Button, Stack, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import { getCurrentUser } from 'aws-amplify/auth'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Props } from '../types'

const SignIn = ({ setTab, setCachedEmail, cachedEmail }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [signInError, setSignInError] = useState<string | null>(null)

  const { handleSubmit, control } = useForm<SignInSchemaForm>({
    defaultValues: {
      emailAddress: cachedEmail,
      password: '',
    },
    resolver: zodResolver(signInSchema),
    mode: 'onChange',
  })

  const emailAddress = useWatch({ control, name: 'emailAddress' })

  useEffect(() => {
    if (emailAddress) {
      setCachedEmail(emailAddress)
    }
  }, [emailAddress, setCachedEmail])

  const navigate = useNavigate()
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        await getCurrentUser()
        navigate('/')
      } catch {
        return
      }
    }

    checkAuthStatus()
  }, [navigate])

  const { signInSetProfile } = useUser()
  const onSubmit: SubmitHandler<SignInSchemaForm> = async (data) => {
    setIsLoading(true)
    try {
      await signInSetProfile(data)
    } catch (error) {
      if (error instanceof Error) {
        setSignInError(error?.message)
      }
      Sentry.captureException(error)
    } finally {
      setIsLoading(false)
    }
  }

  const { t } = useTranslation('web', { keyPrefix: 'signInFlow.signIn' })

  return (
    <>
      <Stack alignItems="center" spacing={2} sx={{ mb: 2 }}>
        <Avatar sx={{ bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('header')}
        </Typography>
      </Stack>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 1, width: '100%' }}
        noValidate
      >
        {signInError && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {signInError}
          </Alert>
        )}
        <Stack spacing={1}>
          <FormTextField
            field={{
              label: t('emailLabel'),
              required: true,
              fullWidth: true,
              type: 'email',
              autoComplete: 'email',
            }}
            controller={{
              control,
              name: 'emailAddress',
            }}
          />
          <FormTextField
            field={{
              label: t('passwordLabel'),
              required: true,
              fullWidth: true,
              type: 'password',
              autoComplete: 'password',
            }}
            controller={{
              control,
              name: 'password',
            }}
          />
        </Stack>
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={isLoading}
          loadingPosition="start"
        >
          {t('submitButton')}
        </LoadingButton>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="secondary"
          sx={{ mb: 2 }}
          onClick={() => setTab(1)}
          disabled={isLoading}
        >
          {t('forgotPasswordButton')}
        </Button>
      </Box>
    </>
  )
}
export default SignIn
