import { TaskType } from '@aeqoom/shared'
import { MenuItem, SelectProps } from '@mui/material'
import { Control, FieldValues, Path } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormSelect } from '../FormSelect'

const TASK_TYPES: TaskType[] = [
  'maintenance',
  'incident',
  'general',
  'examination',
  'installation',
  'full_service',
]

type ExtendedFieldValues = FieldValues & {
  type: Path<FieldValues>
}

type Props<T extends ExtendedFieldValues> = {
  control: Control<T>
  select?: Omit<SelectProps, 'variant'>
}

export const FormTaskTypeSelect = <T extends ExtendedFieldValues>({
  control,
  select,
}: Props<T>) => {
  const { t } = useTranslation('common', { keyPrefix: 'taskType' })

  return (
    <FormSelect
      label="Task type"
      name={'type' as Path<T>}
      control={control}
      select={select}
      options={TASK_TYPES.map((item) => (
        <MenuItem key={item} value={item}>
          {t(item)}
        </MenuItem>
      ))}
    />
  )
}
