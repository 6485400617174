import { GetTasksTask } from '@aeqoom/types'
import { formatUserNamePosition, opacityColor } from '@aeqoom/utils'
import { EventContentArg } from '@fullcalendar/core'
import { Person } from '@mui/icons-material'
import { Stack, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs'

import CompanyUnit from './CompanyUnit'
import { ICON_HEIGHT } from './constants'
import StatusIcon from './StatusIcon'
import TypeIcon from './TypeIcon'
import useDialogHandler from './useDialogHanlder'
import { useTaskStatusColor } from './useTaskStatusColor'

type Props = {
  eventInfo: EventContentArg
}

const Event = ({ eventInfo }: Props) => {
  const {
    name,
    type,
    status,
    user,
    plannedFrom,
    plannedTo,
    id,
    companyUnitId,
  } = eventInfo.event.extendedProps as GetTasksTask

  const handleDialogOpen = useDialogHandler({ id, name })

  const color = useTaskStatusColor(status, type, plannedTo)

  const borderStart = dayjs(plannedFrom).isBefore(eventInfo.view.activeStart)
    ? { borderLeft: 'none' }
    : {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      }

  const borderEnd = dayjs(plannedTo).isAfter(eventInfo.view.activeEnd)
    ? { borderRight: 'none' }
    : {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      }

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        background: opacityColor(color, 12),
        border: '1px solid ' + opacityColor(color, 50),
        padding: 1,
        cursor: 'pointer',
        transition: 'background 0.1s ease-out',
        ...borderStart,
        ...borderEnd,
        '&:hover': {
          background: opacityColor(color, 20),
        },
      }}
      gap={1}
      onClick={handleDialogOpen}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {name}
        </Typography>
        <StatusIcon status={status} type={type} plannedTo={plannedTo} />
      </Stack>
      <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
        <TypeIcon type={type} />
        <CompanyUnit companyUnitId={companyUnitId} />
        <Tooltip title={user && formatUserNamePosition(user)}>
          <Person sx={{ fontSize: ICON_HEIGHT }} />
        </Tooltip>
      </Stack>
    </Stack>
  )
}

export default Event
